import React, { memo, useMemo, useRef, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import TableHead from "./head";
import TableHeadSticKy from "./sticky";
import MemoTableRow from "./memoRow";

import { TableContext } from "./context";

function TableComponent() {
  const { name, dataKey, uniqId } = useContext(TableContext);
  const tableData = useSelector((state) => state.root.table[name].tableData);
  const selected =
    useSelector((state) => state.root.table[name].selected) || [];
  const hoverRowAsin = useSelector(
    (state) => state.root.table[name].hoverRowAsin
  );

  const selectedRef = useRef(selected);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  const tableList = useMemo(() => {
    return tableData?.[dataKey];
  }, [tableData]);

  return (
    <TableHeadSticKy
      head={<TableHead />}
      body={tableList?.map((row, idx) => {
        return (
          <MemoTableRow
            row={row}
            key={row[uniqId]}
            hover={row[uniqId] === hoverRowAsin}
            checked={selected && selected?.indexOf(row[uniqId]) !== -1}
            idx={idx}
          />
        );
      })}
    />
  );
}

export default memo(TableComponent);
