import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import HoverImage from "src/components/imgWrap/hoverImg";
import { ReactComponent as VAIcon } from "./VA.svg";
import style from "./index.module.scss";

function Image({ rowData }) {
  return (
    <div className={style.hoverImageWrap}>
      {rowData?.variant && (
        <div className={style.va}>
          <VAIcon width={28} height={16} />
        </div>
      )}

      <HoverImage img={rowData?.img} />
    </div>
  );
}

export default customWithErrorBoundary(memo(Image));
