import { useTranslation } from "react-i18next";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import useLanguage from "src/hooksOrClass/language";
import { getCountry } from "src/utils";
import style from "./index.module.scss";

function RankTooltip({ localTime, siteTime, children }) {
  const { t } = useTranslation();
  const { autoFormatLocale } = useLanguage();
  const site = getCountry();
  const Wrap = localTime || siteTime ? SimpleTooltip : "div";
  return (
    <Wrap
      paddingY="10px"
      title={
        <div className={style.rankTipWrap}>
          <div>{t(`keywords.rankTip.content`)}</div>
          <div className={style.time}>
            <div>
              {t(`keywords.rankTip.localTime`, {
                time: autoFormatLocale(localTime),
              })}
            </div>
            <div>
              {t(`keywords.rankTip.siteTime`, {
                site: t(`country.${site}`),
                time: autoFormatLocale(siteTime),
              })}
            </div>
          </div>
          <div className={style.tip}>* {t("clickViewHisData")}</div>
        </div>
      }
    >
      {children}
    </Wrap>
  );
}

export default RankTooltip;
