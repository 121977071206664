import SerialNumber from "src/components/table/components/serialNumber";
import tableVariables from "src/styles/_table.scss";

import TrendRank from "./trendRank";
import TrendVolume from "src/components/table/components/trendVolume";
import PageKeyword from "./keyword";
import AdsTraffic from "./adsTraffic";
import OrganicRank from "./organicRank";
import SpAdRank from "./spAdRank";
import Variants from "./variants";
import Action from "./action";
import OtherContent from "./otherContent";
import Traffic from "./traffic";
import Position from "src/components/table/components/position";
import Volume from "src/components/table/components/volume";

export const getColumns = ({
  isListingSearch = false,
  time,
  sortKeys,
  name,
}) => {
  const middleChild = isListingSearch
    ? [
        {
          id: "variants",
          width: 160,
          render: function ({ row }) {
            return <Variants rowData={row} />;
          },
        },
      ]
    : [];

  return [
    {
      id: "checkbox",
      width: 20,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
    },
    {
      id: "#",
      width: 35,
      sticky: true,
      stickyStyle: {
        left: 20 + 16,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "keyword",
      width: 70,
      sticky: true,
      tooltip: "keywordHover",
      stickyStyle: {
        left: 20 + 16 * 2 + 35,
      },
      render: function ({ row }) {
        return <PageKeyword rowData={row} />;
      },
    },
    {
      id: "traffic",
      // ignoreId: true,
      width: 60,
      align: "right",
      tooltip: "trafficHover",
      needSort: true,
      sortKeys,
      sticky: true,
      style: { paddingRight: "30px" },
      className: tableVariables.leftLast,
      stickyStyle: {
        left: 20 + 16 * 3 + 35 + 70,
      },

      render: function ({ row }) {
        return <Traffic rowData={row} />;
      },
    },
    {
      id: "position",
      width: 128,
      tooltip: "positionHover",
      render: function ({ row }) {
        return <Position rowData={row} />;
      },
    },
    {
      id: "adsTraffic",
      width: 100,
      tooltip: "adsTrafficHover",
      render: function ({ row }) {
        return <AdsTraffic rowData={row} />;
      },
    },
    ...middleChild,
    {
      id: "organicRank",
      width: 104,
      needSort: true,
      sortKey: "ogRank",
      tooltip: "organicRankHover",
      otherContent: <OtherContent />,
      render: function ({ row }) {
        return (
          <OrganicRank
            rowData={row}
            isListingSearch={isListingSearch}
            time={time}
          />
        );
      },
    },
    {
      id: "spAdRank",
      width: 100,
      needSort: true,
      sortKey: "spRank",
      tooltip: "spAdRankHover",
      otherContent: <OtherContent />,
      render: function ({ row }) {
        return (
          <SpAdRank
            rowData={row}
            isListingSearch={isListingSearch}
            time={time}
          />
        );
      },
    },
    {
      id: "trendByRank",
      width: 100,
      align: "center",
      render: function ({ row, last }) {
        return <TrendRank rowData={row} />;
      },
    },
    {
      id: "volume",
      width: 80,
      tooltip: "volumeHover",
      sortKey: "abaSearchVolume",
      align: "right",
      needSort: true,
      render: function ({ row }) {
        return <Volume rowData={row} />;
      },
    },
    {
      id: "trendByVolume",
      width: 114,
      align: "center",
      render: function ({ row, last }) {
        return <TrendVolume rowData={row} name={name} />;
      },
    },
    {
      id: "action",
      align: "center",
      width: 60,
      sticky: true,
      className: tableVariables.rightLast,
      stickyStyle: {
        right: 0,
      },
      render: function ({ row }) {
        return <Action rowData={row} />;
      },
    },
  ];
};
