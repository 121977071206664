import classNames from "classnames";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "src/components/customModal";
import customWithErrorBoundary from "src/components/errorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { updateEmptyModalOpen } from "../../store";
import { pageAction } from "src/constants/page";
import { asinsKey } from "src/constants/form";
import { openNewUrl } from "src/utils";
function EmptyModal() {
  const { t } = useTranslation();
  const emptyModalOpen = useSelector((state) => state.keywords.emptyModalOpen);
  const baseSearchValues = useSelector((state) => state.keywords.baseSearch);
  const dispath = useDispatch();
  const handleClose = useCallback(() => {
    dispath(updateEmptyModalOpen(false));
  }, []);

  const handleConfirm = useCallback(() => {
    dispath(updateEmptyModalOpen(false));
    const linkUrl = pageAction.trafficStructure.getParamsLink({
      [asinsKey]: baseSearchValues.asins,
    });

    openNewUrl(linkUrl);
  }, [baseSearchValues]);

  return (
    <CustomModal
      open={emptyModalOpen}
      onClose={handleClose}
      title={t(`keywords.emptyModal.title`)}
      content={t(`keywords.emptyModal.content`)}
      btns={
        <div className={classNames("btnsWrap")}>
          <div
            className={classNames("cancelBtn", "button")}
            onClick={handleClose}
          >
            {t(`keywords.emptyModal.cancelBtn`)}
          </div>
          <div
            className={classNames("okBtn", "button")}
            onClick={handleConfirm}
          >
            {t(`keywords.emptyModal.okBtn`)}
          </div>
        </div>
      }
    ></CustomModal>
  );
}

export default customWithErrorBoundary(memo(EmptyModal));
