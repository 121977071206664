import { memo } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import SalesChart from "src/components/table/components/salesChart";
import { timeKey } from "src/constants/form";

function BoughtHistory({ rowData = {} }) {
  const time = useSelector(
    (state) => state.structure?.memoBaseSearch?.[timeKey]
  );

  return (
    <div style={{ padding: "0 10px" }}>
      <SalesChart rowData={rowData} propDate={time?.date} />
    </div>
  );
}

export default customWithErrorBoundary(memo(BoughtHistory));
