import { memo } from "react";
import { getPlaceHolderData } from "src/utils";

interface Props {
    value: string;
}

function CellEmpty({ value }: Props) {
    return <>{getPlaceHolderData(value)}</>
}

export default memo(CellEmpty);