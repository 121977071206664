import classNames from "classnames";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import CustomModal from "src/components/customModal";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateRuleModalOpen } from "../../store";
function RuleModal() {
  const { t } = useTranslation();
  const ruleModalOpen = useSelector((state) => state.keywords.ruleModalOpen);
  const dispath = useDispatch();
  const handleClose = useCallback(() => {
    dispath(updateRuleModalOpen(false));
  }, []);

  return (
    <CustomModal
      open={ruleModalOpen}
      onClose={handleClose}
      title={t(`keywords.modal.rule.title`)}
      content={t(`keywords.modal.rule.content`)}
      btns={
        <div className="btnsWrap" style={{ marginTop: "10px" }}>
          <div className={classNames("okBtn", "button")} onClick={handleClose}>
            {t(`keywords.modal.rule.btn`)}
          </div>
        </div>
      }
    >
      <div>
        <div className={style.imgWrap}>
          <img
            src="https://minusflow-public.s3.amazonaws.com/Traffic+keywords-public/traffic+keywords_popup.png"
            alt=""
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default customWithErrorBoundary(memo(RuleModal));
