import classNames from "classnames";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import Slider from "src/components/slider";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { usePosition } from "../../hook";
import commonStyle from "../../index.module.scss";
import { getSliceData } from "src/components/slider/util";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import HoverOverview from "../hoverOverview";
import { overCodes } from "../../constant";
import {
  resetDataSearch,
  updateActiveOverviewKey,
  updateDataSearch,
  updateLastClickActiveInfo,
} from "../../store";
import { AdStrategyContext } from "../../context";
function TimeLine() {
  const { t } = useTranslation();
  const overviewData = useSelector((state) => state.adStrategy.overviewData);
  const { fetchTable } = useContext(AdStrategyContext);

  const activeOverviewKey = useSelector(
    (state) => state.adStrategy.activeOverviewKey
  );

  const wrapRef = useRef();

  const lastClickActiveInfo = useSelector(
    (state) => state.adStrategy.lastClickActiveInfo
  );
  const timerRef = useRef();

  const [dates, setDates] = useState([]);

  useEffect(() => {
    setDates(overviewData?.dates || []);
  }, [overviewData]);
  const nodeRef = useRef();
  const dispatch = useDispatch();

  const [showDates] = usePosition({
    nodeRef: nodeRef,
    data: dates,
  });

  const data = [
    {
      title: "广告活动类型",
    },
    {
      code: overCodes[0],
      title: t(`terminology.sp`),
    },
    {
      code: overCodes[1],
      title: t(`terminology.sb`),
    },
    {
      code: overCodes[2],
      title: t(`terminology.sbv`),
    },
    {
      code: overCodes[3],
      title: t(`terminology.sbSbv`),
    },
  ];

  const handleUpdate = useCallback(
    (rangeIdxs) => {
      const newDates = getSliceData(overviewData.dates, rangeIdxs);
      setDates(newDates);
    },
    [dates]
  );

  const overviews = useMemo(() => {
    const originOverviews = overviewData.overviews;
    const newOverviews = [];
    originOverviews &&
      Object.keys(originOverviews)?.forEach((key) => {
        const item = originOverviews[key];
        const idx = dates.findIndex((item) => item === key);
        if (idx > -1) {
          const newItem = {
            ...item,
            key,
            left: (idx / (dates.length - 1)) * 100 + "%",
          };
          newOverviews.push(newItem);
        }
      });
    return newOverviews || [];
  }, [dates, overviewData]);

  const handleMouseEnter = (overData) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      dispatch(updateActiveOverviewKey(overData.key));
    }, 300);
  };

  const handleCloseTooltip = useCallback(() => {
    dispatch(updateActiveOverviewKey(""));
  }, []);

  const handleEnterTooltip = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  const scrollIntoViewWrap = useCallback(() => {
    if (!wrapRef.current) {
      return;
    }

    const res = wrapRef.current.getBoundingClientRect();
    if (res.top > 0) {
      wrapRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleClickSearch = (overData, type) => {
    scrollIntoViewWrap();
    dispatch(resetDataSearch());
    dispatch(updateLastClickActiveInfo({ key: overData.key, type }));

    const data = overData[type].map((item) => item.encryptCampaignId);
    dispatch(
      updateDataSearch({
        key: "encryptCampaignId",
        value: data,
      })
    );
    fetchTable();
  };

  const tooltipSx = useMemo(() => {
    return {
      maxWidth: "none",
    };
  }, []);

  return (
    <div className={classNames("wrap", "wrapContentInfo")} ref={wrapRef}>
      <div className={style.content}>
        <div className={classNames(style.left, "textFont")}>
          {data.map((item) => {
            return (
              <div key={item.code + "title"} className={style.row}>
                {item.title}
              </div>
            );
          })}
        </div>
        <div className={style.right} ref={nodeRef}>
          <div className={style.dashed}>
            {data.map((item) => {
              return (
                <div
                  key={item.code + "dashed"}
                  className={classNames(style.dashedRow, {
                    [style.dashedLine]: item.code,
                  })}
                ></div>
              );
            })}
          </div>
          <div className={style.bubbleCircle}>
            {overviews.map((over) => {
              const active = activeOverviewKey === over.key;
              return (
                <SimpleTooltip
                  placement="top"
                  leaveDelay={300}
                  tooltipSx={tooltipSx}
                  classes={style.tooltipWrap}
                  key={over?.key}
                  open={active}
                  onClose={handleCloseTooltip}
                  title={
                    <HoverOverview
                      data={over}
                      onMouseEnter={handleEnterTooltip}
                      scrollIntoViewWrap={scrollIntoViewWrap}
                    />
                  }
                >
                  <div
                    style={{ left: over.left }}
                    className={classNames(style.colLine, {
                      [style.active]: active,
                      [style.isClicked]: lastClickActiveInfo.key === over.key,
                    })}
                    onMouseEnter={() => handleMouseEnter(over)}
                  >
                    {data.map((item) => {
                      const num = over[item.code + "Num"];
                      if (!item.code) {
                        return null;
                      }
                      return (
                        <div
                          className={classNames(style.row, item.code)}
                          key={item.code}
                        >
                          {num <= 0 || !num ? null : (
                            <span
                              className={classNames(style.circle, {
                                [style.circleActive]:
                                  lastClickActiveInfo.type === item.code &&
                                  lastClickActiveInfo.key === over.key,
                              })}
                              onClick={() => handleClickSearch(over, item.code)}
                            >
                              {num > 99 ? "99+" : `+${num}`}
                            </span>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </SimpleTooltip>
              );
            })}
          </div>

          {showDates.length > 0 && (
            <>
              <div className={style.datesWrap}>
                {showDates?.map((item, idx) => {
                  const isFirstStyle =
                    idx === 0
                      ? {
                          position: "initial",
                          display: "inline-block",
                        }
                      : {};
                  return (
                    <span
                      key={item.date}
                      className={classNames(
                        commonStyle.axisInfoHorText,
                        commonStyle.dateText
                      )}
                      style={{
                        position: "absolute",
                        left: item.position,
                        right: "initial",
                        marginTop: 0,
                        top: 0,
                        ...isFirstStyle,
                      }}
                    >
                      <div>{item?.showDate}</div>
                      <div>{item?.year}&nbsp;</div>
                    </span>
                  );
                })}
              </div>
              <Slider data={overviewData.dates} update={handleUpdate} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(TimeLine));
