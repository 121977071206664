import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import { getPercentage } from "src/utils";

function Traffic({ rowData }) {
  return (
    <div>
      <CellEmpty value={getPercentage(rowData.spScoreRatio)} />
    </div>
  );
}

export default customWithErrorBoundary(memo(Traffic));
