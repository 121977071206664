import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import classNames from "classnames";

import { updateListing } from "src/service";

function UpdateVariants({ asins, className = "" }) {
  const { t } = useTranslation();

  const handleUpdateListing = async () => {
    if (!asins) {
      return;
    }
    await updateListing(asins);
  };

  return (
    <Tooltip title={t("updateVariantsTips")} placement="bottom">
      <div
        className={classNames(className, "button")}
        onClick={handleUpdateListing}
      >
        <span className="icon-update"></span>
        <span> {t("updateVariants")}</span>
      </div>
    </Tooltip>
  );
}

export default UpdateVariants;
