const tableService = {};

export const registerService = (key, request, callback, getReset) => {
  tableService[key] = {
    request,
    callback,
    getReset,
  };
};

export const unregisterService = (key) => {
  delete tableService[key];
};

export const getSerialNumber = ({ page = 1, pageSize = 100, idx = 0 }) => {
  return (page - 1) * pageSize + (idx + 1);
};

export const formatTableSearch = (tableSearch = {}) => {
  try {
    const tableSearchData = {
      sortBy: tableSearch?.sort?.key,
      desc: tableSearch?.sort?.by === "desc",
      page: tableSearch?.pageNum,
      pageSize: tableSearch?.pageSize,
    };

    return tableSearchData;
  } catch (e) {}
};
export default tableService;
