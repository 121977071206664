import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { weekMonthEnum } from "src/constants/time";

import { asinsKey, periodKey } from "src/constants/form";
import { all } from "src/utils/select";
import {
  downloadKeywordsApi,
  getAdHistoryApi,
  getModalSelectOptionsApi,
  getOverviewsApi,
} from "./service";
import { fetchStatus } from "src/constants";
import {
  getFormatParams,
  getServiceDateStartAndEnd,
} from "src/components/picker/inputRange/util";
import { exportPureFn } from "src/hooksOrClass/export";
import { overData, timeEnum } from "./constant";
import { updateGlobalLoading } from "src/store/rootReducer";
import { connectSymbol } from "src/components/picker/inputRange/constant";
import { endOfMonth, endOfWeek, format } from "date-fns";
import { getDateFormatFn, getEndFn } from "./util";

let allResolvePromise = null;
let allCancelReject = null;

let cycleResolvePromise = null;
let cycleCancelReject = null;

export const defaultDataSearch = {
  encryptCampaignId: all,
  adAsin: all,
  time: "",
  campaignId: "",
};

const initialState = {
  baseSearch: {
    [periodKey]: weekMonthEnum.weekly,
    [asinsKey]: "",
  },
  campaignsAndAsinsOptions: {},
  dataSearch: defaultDataSearch,

  activeDateInCampaignId: "",

  detailEncryptAdInfo: {},
  // detailEncryptAdInfo: {
  //   displayAdId: "ACAB",
  //   encryptAdId: "-zNo4PdFjzUuL8M81yi0TGzGS1dV",
  //   asin: "B07D3DDJ4L",
  //   targetKeyword: "TODO",
  //   matchType: "TODO",
  //   keywordCount: 154,
  //   time: "2023-08",
  //   displayCampaignId: "E3B4",
  //   keyword: "",
  //   img: "https://m.media-amazon.com/images/I/61aeen0K1NL._AC_UY218_.jpg",
  // },

  adHistoryFetchStatus: fetchStatus.initial,
  adHistoryController: null,
  adHistoryData: {},

  detailHistoryFetchStatus: fetchStatus.initial,
  detailHistoryController: null,
  detailModalListInfo: {},
  detailSelectAdsData: [],

  detailSelectAdValue: {
    value: "",
    info: {},
  },
  drawModalOpen: false,
  // drawModalOpen: true,
  exportCycleKeywordStatus: fetchStatus.initial,
  exportAllKeywordStatus: fetchStatus.initial,
  overviewData: {},
  activeOverviewKey: "",
  overviewsFetchStatus: fetchStatus.initial,
  lastClickActiveInfo: "",
  trafficTime: "",
  dateFormatFn: getDateFormatFn(weekMonthEnum.weekly),
};

export const AdStrategySlice = createSlice({
  name: "adStrategy",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    updateCampaignsAndAsinsOptions: (state, action) => {
      state.campaignsAndAsinsOptions = action.payload;
    },
    updateBasePeriod: (state, action) => {
      state.baseSearch[periodKey] = action.payload;
      state.dateFormatFn = getDateFormatFn(action.payload);
    },
    updateLastClickActiveInfo: (state, action) => {
      state.lastClickActiveInfo = action.payload;
    },
    syncBaseSearch: (state, action) => {
      state.baseSearch = action.payload;
      state.dateFormatFn = getDateFormatFn(state.baseSearch[periodKey]);
    },
    updateActiveOverviewKey: (state, action) => {
      state.activeOverviewKey = action.payload;
    },
    updateDataSearch: (state, action) => {
      const { key, value } = action.payload;
      state.dataSearch[key] = value;
    },
    updateDetailEncryptAdIdInfo: (state, action) => {
      state.detailEncryptAdInfo = action.payload;
    },
    updateAdHistoryFetchStatus: (state, action) => {
      state.adHistoryFetchStatus = action.payload;
    },
    updateAdHistoryController: (state, action) => {
      const oldController = state.adHistoryController;
      oldController?.abort && oldController?.abort();
      state.adHistoryController = action.payload;
    },
    updateDetailHistoryFetchStatus: (state, action) => {
      state.detailHistoryFetchStatus = action.payload;
    },
    updateDetailEncryptAdInfoTime: (state, action) => {
      state.detailEncryptAdInfo.time = action.payload;
    },
    updateOverviewsFetchStatus: (state, action) => {
      state.overviewsFetchStatus = action.payload;
    },
    updateDetailHistoryController: (state, action) => {
      const oldController = state.detailHistoryController;
      oldController?.abort && oldController?.abort();

      state.detailHistoryController = action.payload;
    },
    updateActiveDateInCampaignId: (state, action) => {
      state.activeDateInCampaignId = action.payload;
    },
    resetDetailInfo: (state, action) => {
      state.adHistoryData = {};
      state.detailEncryptAdInfo = {};
    },
    updateDrawModalOpen: (state, action) => {
      state.drawModalOpen = action.payload;
    },
    updateDetailEncryptAdInfoKeyword: (state, action) => {
      state.detailEncryptAdInfo.keyword = action.payload;
    },
    updateDetailSelectAdsData: (state, action) => {
      state.detailSelectAdsData = action.payload;
    },
    updateDetailSelectAdValue: (state, action) => {
      state.detailSelectAdValue = action.payload;
    },
    resetDataSearch: (state, action) => {
      state.dataSearch = defaultDataSearch;
    },
    updateExportCycleKeywordStatus: (state, action) => {
      state.exportCycleKeywordStatus = action.payload;
    },
    updateExportAllKeywordStatus: (state, action) => {
      state.exportAllKeywordStatus = action.payload;
    },
    updateTrafficTime: (state, action) => {
      state.trafficTime = action.payload;
    },
    autoSetTrafficTime: (state, action) => {
      const { startDate, endDate } = action.payload || {};
      const type = state.baseSearch?.[periodKey];
      const formatParams = getFormatParams(type);
      const endFn = state.dateFormatFn.endFn;

      state.trafficTime = `${format(
        startDate,
        formatParams
      )} ${connectSymbol} ${format(endDate || endFn(startDate), formatParams)}`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdHistoryThunk.fulfilled, (state, action) => {
      const responseData = action.payload;
      state.adHistoryData = responseData;
    });
    builder.addCase(getOverviewsThunk.fulfilled, (state, action) => {
      const responseData = action.payload;
      state.overviewData = responseData;
    });
    builder.addCase(getSelectOptionThunk.fulfilled, (state, action) => {
      const responseData = action.payload;
      console.log("responseData", responseData);
      // TODO需要改为responseData
      state.detailSelectAdsData = responseData?.list;
    });
  },
});

export const {
  resetStore,
  updateTrafficTime,
  updateBasePeriod,
  updateDataSearch,
  resetDataSearch,
  syncBaseSearch,
  resetDetailInfo,
  updateDrawModalOpen,
  updateDetailSelectAdValue,
  updateDetailSelectAdsData,
  updateExportAllKeywordStatus,
  updateDetailEncryptAdInfoTime,
  updateDetailEncryptAdIdInfo,
  updateAdHistoryFetchStatus,
  updateAdHistoryController,
  updateActiveDateInCampaignId,
  updateDetailEncryptAdInfoKeyword,
  updateExportCycleKeywordStatus,
  updateCampaignsAndAsinsOptions,
  updateOverviewsFetchStatus,
  updateActiveOverviewKey,
  updateLastClickActiveInfo,
  autoSetTrafficTime,
} = AdStrategySlice.actions;

export default AdStrategySlice.reducer;

export const getOverviewsThunk = createAsyncThunk(
  "data/adStrategy/getOverviewsThunk",
  async (data, { dispatch, getState }) => {
    const currentState = getState().adStrategy;
    dispatch(updateOverviewsFetchStatus(fetchStatus.loading));

    const response = await getOverviewsApi();
    if (!response) {
      dispatch(updateOverviewsFetchStatus(fetchStatus.error));
    } else {
      dispatch(updateOverviewsFetchStatus(fetchStatus.success));
      dispatch(updateGlobalLoading(false));
    }
    return response;
  }
);

export const getSelectOptionThunk = createAsyncThunk(
  "data/adStrategy/getSelectOptionThunk",
  async (data, { dispatch, getState }) => {
    const currentState = getState().adStrategy;
    const detailEncryptAdInfo = currentState.detailEncryptAdInfo;
    const period = currentState.baseSearch[periodKey];
    const paramsData = {
      encryptCampaignId: detailEncryptAdInfo.encryptAdId,
      period,
      ...(getServiceDateStartAndEnd({
        value: currentState.dataSearch?.time,
        type: period,
      }) || {}),
    };
    const res = await getModalSelectOptionsApi(paramsData);
    return res;
  }
);

export const getAdHistoryThunk = createAsyncThunk(
  "data/adStrategy/getAdHistoryThunk",
  async (data, { dispatch, getState }) => {
    const currentState = getState().adStrategy;

    dispatch(updateAdHistoryFetchStatus(fetchStatus.loading));
    const controller = new AbortController();
    dispatch(updateAdHistoryFetchStatus(fetchStatus.loading));

    dispatch(updateAdHistoryController(controller));

    const response = await getAdHistoryApi({
      data: {
        encryptAdId: currentState.detailEncryptAdInfo?.encryptAdId,
        period: currentState.baseSearch[periodKey],
      },
      signal: controller?.signal,
    });

    console.log("responseData11111", response);
    if (!response) {
      dispatch(updateAdHistoryFetchStatus(fetchStatus.error));
    } else {
      dispatch(updateAdHistoryFetchStatus(fetchStatus.success));
    }

    return response;
  }
);

export const submitExportTaskThunk = createAsyncThunk(
  "data/adStrategy/submitExportTaskThunk",
  async (data, { dispatch, getState }) => {
    const { type } = data;

    const updateStatus =
      type === timeEnum.all
        ? updateExportAllKeywordStatus
        : updateExportCycleKeywordStatus;
    function getResolve(resolve) {
      type === timeEnum.all
        ? (allResolvePromise = resolve)
        : (cycleResolvePromise = resolve);
    }

    function getReject(reject) {
      type === timeEnum.all
        ? (allCancelReject = reject)
        : (cycleCancelReject = reject);
    }

    function successFn() {
      type === timeEnum.all
        ? allResolvePromise && allResolvePromise()
        : cycleResolvePromise && cycleResolvePromise();
    }

    function catchFn() {
      type === timeEnum.all
        ? allCancelReject && allCancelReject()
        : cycleCancelReject && cycleCancelReject();
    }

    const url = {
      all: {
        url: "/api/web/export/submit/ad_strategy_detail_all",
        taskUrl: "/api/web/export/progress/ad_strategy_detail_al",
      },
      cycle: {
        url: "/api/web/export/submit/ad_strategy_detail",
        taskUrl: "/api/web/export/progress/ad_strategy_detail",
      },
    };
    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updateStatus,
      successFn,
      catchFn,
    });
    const currentState = getState().adStrategy;

    let serviceData = {
      encryptAdId: currentState.detailEncryptAdInfo.encryptAdId,
      keyword: currentState.detailEncryptAdInfo.keyword,
    };
    if (type === timeEnum.cycle) {
      serviceData.time = currentState.detailEncryptAdInfo.time;
    }

    exportFn({
      downLoadService: () => {
        return downloadKeywordsApi({ url: url[type].url, data: serviceData });
      },
      taskUrl: url[type].taskUrl,
    });
  }
);
