import { memo } from "react";
import { useTranslation } from "react-i18next";
import CustomSelect from "src/components/customSelect";
import customWithErrorBoundary from "src/components/errorBoundary";

function CommonAction({ options = [], children }) {
  const { t } = useTranslation();
  return (
    <>
      <div style={{ marginBottom: "20px", cursor: "pointer" }}>{children}</div>

      <div>
        <CustomSelect
          click
          defaultConfig={
            <div style={{ fontSize: "15px", padding: "0 10px" }}>
              <span className="icon-more-icon"></span>
            </div>
          }
          options={options.map((item) => ({
            ...item,
            title: t(`router.${item.title}`),
          }))}
        ></CustomSelect>
      </div>
    </>
  );
}

export default customWithErrorBoundary(memo(CommonAction));
