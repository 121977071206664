import { findInterval, getPercentage } from "src/utils";
import style from "src/utils/chart/plugin/index.module.scss";

function getText({ ctx, text, maxWidth }) {
  const measuredText = ctx.measureText(text);
  if (measuredText.width > maxWidth) {
    // 超出画布宽度，将文本分割成多个部分
    const words = text.split("");
    let line = "";
    let ellipsis = "...";

    for (let word of words) {
      if (ctx.measureText(line + word + ellipsis).width <= maxWidth) {
        line += word;
      } else {
        break;
      }
    }

    return line ? line + ellipsis : "";
  }

  return text;
}

export function draw(ctx, arr = [], chart) {
  const halfW = chart.width / 2;

  for (let i = 0; i < arr.length; i++) {
    ctx.save();
    const {
      junction,
      start,
      end,
      color,
      center,
      textPositionAlign,
      textContent,
    } = arr[i] || {};
    ctx.translate(center.x || 0, center.y || 0);
    ctx.beginPath();
    ctx.moveTo(junction.x, junction.y);

    ctx.strokeStyle = color;

    ctx.lineTo(start.x, start.y);

    ctx.lineTo(end.x, end.y);

    ctx.stroke();
    ctx.closePath();

    ctx.beginPath();
    ctx.fillStyle = color;
    ctx.arc(end.x, end.y, 4, 0, 2 * Math.PI);
    ctx.fill();
    ctx.closePath();

    ctx.beginPath();

    ctx.baseLine = "middle";
    ctx.textAlign = textPositionAlign;
    ctx.fillStyle = "#181818";
    const lineHeight = 20;
    textContent.forEach((label, idx) => {
      const { content, fontFamily } = label;
      ctx.font = `12px ${fontFamily}`;
      // 计算文本的长度
      const x = end.x + 10 * (end.x > 0 ? 1 : -1);

      ctx.fillText(content, x, end.y + idx * lineHeight);
    });

    ctx.closePath();
    ctx.restore();
  }
}

export function inCircle(x, y, outerRadius) {
  // 判断一个点是否在圆内
  return Math.pow(x, 2) + Math.pow(y, 2) < Math.pow(outerRadius, 2);
}
export function adjust(arr = [], isRight = true, { chart, height, ctx } = {}) {
  if (!chart) {
    return;
  }
  const halfW = chart.width / 2;

  let newArr = [...arr].map((item, idx) => ({ ...item, idx }));
  newArr.sort((a, b) => b.ratio - a.ratio);

  const dirctionVer = isRight ? 1 : -1;

  let positionY = [{ y: -chart.height / 2 }, { y: chart.height / 2 }];

  function sortHeightByIdx() {
    const ys = positionY.map((item) => item.y);
    ys.pop();
    ys.shift();
    const currentS = positionY
      .filter((item) => item.current)
      .map((item) => item.current);

    currentS.sort((a, b) => (a.idx - b.idx) * dirctionVer);

    currentS.forEach((item, idx) => {
      if (item) {
        item.end.y = ys[idx];
      }
    });
  }
  function commonFormat(current, left, right) {
    const newPositionY = [...positionY].map((item) => ({ ...item }));

    const leftY = newPositionY[left].y;
    const rightY = newPositionY[left + 1].y;
    const leftDIs = Math.abs(current.end.y - leftY);

    const rightDIs = Math.abs(current.end.y - rightY);

    const near = leftDIs < rightDIs ? leftY : rightY;
    const dir = leftDIs < rightDIs ? 1 : -1;
    const nearY = near + height * dir;

    const far = leftDIs > rightDIs ? leftY : rightY;

    const farY = far + height * dir;

    let y;

    if (
      (left === 0 && leftDIs >= height / 2) ||
      (right === positionY.length - 1 && rightDIs >= height / 2)
    ) {
      y = current.end.y;
    } else if (Math.abs(nearY) < chart.height / 2 - height / 2) {
      y = nearY;
    } else if (Math.abs(farY) < chart.height / 2 - height / 2) {
      y = farY;
    }
    if (y === undefined) {
      current.delete = true;
      return false;
    }

    newPositionY.push({ y, current });
    newPositionY.sort((a, b) => a.y - b.y);

    const saveNewPosition = [...newPositionY].map((item) => ({ ...item }));

    const leftX = newPositionY[left]?.current?.end?.x || 0;
    const rightX = newPositionY[right]?.current?.end?.x || halfW;
    const currentX = current.end.x;

    // if (Math.abs(leftX - currentX) > 30 && Math.abs(rightX - currentX) > 30) {
    //   return true;
    // }

    let needMoreAdust = false;

    for (let i = 0; i < newPositionY.length - 1; i++) {
      const curent = newPositionY[i];
      const next = newPositionY[i + 1];
      const needHeight =
        i === 0 || i === newPositionY.length - 2 ? height / 2 : height;

      if (next?.y - curent.y < needHeight) {
        next.y = curent.y + needHeight;
      }

      if (next.y > chart.height / 2) {
        needMoreAdust = true;
        break;
      }
    }

    if (needMoreAdust) {
      for (let i = saveNewPosition.length - 1; i >= 1; i--) {
        const curent = saveNewPosition[i];
        const next = saveNewPosition[i - 1];
        const needHeight =
          i === 0 || i === saveNewPosition.length - 1 ? height / 2 : height;

        if (curent?.y - next?.y < needHeight) {
          next.y = curent.y - needHeight;
        }

        if (Math.abs(next.y) > chart.height / 2) {
          current.delete = true;
          return false;
        }
      }
    }

    positionY = needMoreAdust ? saveNewPosition : newPositionY;
    sortHeightByIdx();

    return false;
  }

  for (let i = 0; i < newArr.length; i++) {
    const previous = newArr[i - 1];
    const current = newArr[i];
    let currentY = current?.end.y;
    if (!previous) {
      positionY.push({ y: currentY, current });
      positionY.sort((a, b) => a.y - b.y);
      continue;
    }

    const res = findInterval(
      positionY.map((item) => item.y),
      currentY
    );
    if (res === -1) {
      current.delete = true;
    } else if (Array.isArray(res)) {
      const [left, right] = res;
      const leftY = positionY[left].y;
      const rightY = positionY[right].y;
      const leftDis = Math.abs(currentY - leftY);
      const rightDIs = Math.abs(currentY - rightY);
      if (
        Math.abs(rightY - leftY) < height * 2 ||
        leftDis < height ||
        rightDIs < height
      ) {
        const res = commonFormat(current, left, right);

        if (!res) continue;
      }
    }

    currentY = current?.end.y;
    positionY.push({ current, y: currentY });
    positionY.sort((a, b) => a.y - b.y);
  }

  sortHeightByIdx();

  const finalArr = newArr.filter((item) => !item.delete);
  finalArr.sort((a, b) => a.idx - b.idx);
  for (let i = 0; i < finalArr.length; i++) {
    const current = finalArr[i];

    let { x, y } = current.end;

    const outerRadius =
      current.datapoint.originOuterRadius || current.datapoint.outerRadius;

    if (inCircle(x, y, outerRadius)) {
      x =
        Math.sqrt(Math.pow(outerRadius + 10, 2) - Math.pow(y, 2)) * dirctionVer;
      current.end.x = x;
    }

    current.textContent.forEach((label, idx) => {
      const { content, fontFamily } = label;
      ctx.font = `12px ${fontFamily}`;
      // 计算文本的长度
      const textX = x + 10 * (x > 0 ? 1 : -1);
      const text = getText({
        ctx,
        text: content,
        maxWidth: Math.abs(halfW - Math.abs(textX)),
      });
      label.content = text;
      label.width = ctx.measureText(text).width;
    });
  }

  const start = isRight ? 1 : 0;
  const end = isRight ? finalArr.length : finalArr.length - 1;
  for (let i = start; i < end; i++) {
    const previous = isRight ? finalArr[i - 1] : finalArr[i + 1];

    const current = finalArr[i];
    if (!current || !previous) {
      break;
    }

    let { y: startY, x: startX } = current.start;
    let { y: previousStartY, x: previousStartX } = previous?.start || {};
    let { y: previousEndY, x: previousEndX } = previous?.end || {};

    const preTextContent = previous?.textContent;
    const textWidth = preTextContent?.[preTextContent?.length - 1]?.width;
    let needAdjustStart = false;

    if (startY - previousEndY < height / 2) {
      if (startX > previousEndX && startX < previousEndX + textWidth) {
        needAdjustStart = true;
      } else if (startX < previousStartX) {
        needAdjustStart = true;
      } else if (startY > previousEndY) {
        needAdjustStart = true;
      }
    }

    if (needAdjustStart) {
      current.start = current.end;
    }
  }

  return newArr.filter((item) => !item.delete);
}

export function drawInfo({ chart }) {
  const { ctx } = chart;
  const left = [];
  const right = [];
  chart.data.datasets.forEach((dataset, i) => {
    chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
      const {
        x: xCenter,
        y: yCenter,
        outerRadius: nowOutRadius,
        innerRadius,
        startAngle,
        endAngle,
        originOuterRadius,
      } = datapoint;
      // 圆环中心的位置
      const { x: originX } = datapoint.tooltipPosition();
      const outerRadius = originOuterRadius || nowOutRadius;
      // 平移坐标系后的坐标
      const x = originX - xCenter;
      const isRight = x >= 0;

      const dirction = isRight ? 1 : -1;

      const labels = chart.data.labels;

      let needHorLine = true;

      let textPositionAlign = isRight ? "left" : "right";

      const centerAngle = (startAngle + endAngle) / 2;

      let key =
        Math.abs(Math.tan(centerAngle)) < 0.1 ? 0 : Math.tan(centerAngle);

      if (key === 0) {
        needHorLine = false;
      }
      //外层扇形的重心
      const junctionX = outerRadius * Math.cos(centerAngle);
      const junctionY = outerRadius * Math.sin(centerAngle);

      const lineDistance =
        ((outerRadius - innerRadius) / 3) * (key === 0 ? 1.4 : 1);
      const extraLine = lineDistance * 1.6 * dirction;

      let xLine = (outerRadius + lineDistance) * Math.cos(centerAngle);
      let yLine = (outerRadius + lineDistance) * Math.sin(centerAngle);

      const horEndX = xLine + (needHorLine ? extraLine : 0);

      const variable = isRight ? right : left;
      const originData = dataset.originData[index];

      const ratio = originData.ratio || (endAngle - startAngle) / (Math.PI * 2);

      variable.push({
        datapoint,
        label: originData.name,
        color: datapoint.options.backgroundColor,
        textContent: [
          {
            content: labels[index],
            fontFamily: "Inter",
          },
          {
            content: getPercentage(ratio),
            fontFamily: "Inter-Bold",
          },
        ],
        ratio,
        index,
        datasetIndex: i,
        textPositionAlign,
        center: { x: xCenter, y: yCenter },
        junction: { x: junctionX, y: junctionY },
        start: { x: xLine, y: yLine },
        end: { x: horEndX, y: yLine },
      });
    });
  });

  const newLeft = adjust(left, false, { chart, height: 40, ctx });
  const newRight = adjust(right, true, { chart, height: 40, ctx });

  chart.assistanceLeft = newLeft;
  chart.assistanceRight = newRight;
  draw(ctx, newLeft, chart);
  draw(ctx, newRight, chart);
}

export function customToolitp(context, data) {
  const { tooltip, chart: myPieChart } = context;
  let tooltipEl = myPieChart.canvas.parentNode.querySelector("div");
  myPieChart.canvas.parentNode.style.position = "relative";
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("tooltip-container");
    tooltipEl.classList.add(style.tooltipEl);
    tooltipEl.classList.add("textFont");
    const container = document.createElement("div");
    container.classList.add("wrapTable");
    tooltipEl.appendChild(container);
    myPieChart.canvas.parentNode.appendChild(tooltipEl);
  }

  if (tooltip?.opacity === 0) {
    tooltipEl.classList.add("hidden");
    return;
  }

  if (
    !(Array.isArray(tooltip?.dataPoints) && tooltip?.dataPoints?.length >= 0)
  ) {
    return;
  }

  const dataPoint = tooltip.dataPoints[0];
  const backgroundColor = dataPoint.element.options.backgroundColor;
  const originData = data.find((item) => item.name === dataPoint.label);

  const showData = [
    {
      name: "Score",
      value: originData.value,
    },
    {
      name: "Proportion",
      value: getPercentage(originData.ratio),
    },
  ];
  // Hide if no tooltip

  tooltipEl.classList.remove("hidden");
  // Set Text
  if (tooltip.body) {
    const head = document.createElement("div");
    head.classList.add(style.title);
    const titleLines = tooltip.title || [];
    titleLines.forEach((title) => {
      const div = document.createElement("div");
      div.innerHTML = `<span style=background:${backgroundColor} class="line-circle"></span><span style=color:var(--color)>${title}</span>`;

      head.appendChild(div);
    });
    const root = tooltipEl.querySelector(".wrapTable");
    // Remove old children
    while (root.firstChild) {
      root.firstChild.remove();
    }
    root.appendChild(head);

    showData.forEach((itemData, i) => {
      const div = document.createElement("div");
      div.innerHTML = `<span class=${style.medium}>${itemData.name}</span><span class=${style.dataSpan}>${itemData.value}</span>`;
      root.appendChild(div);
    });

    // Add new children
  }

  const { offsetLeft: positionX, offsetTop: positionY } = myPieChart.canvas;

  let transform = {
    x: "translateX(0)",
    y: "translateY(0)",
  };
  if (tooltip._eventPosition.x < myPieChart.width / 2) {
    transform.x = "translateX(-100%)";
  }
  if (tooltip._eventPosition.y < myPieChart.height / 2) {
    transform.y = "translateY(-100%)";
  }

  let newTransform =
    (transform?.x?.toString() ?? "") + " " + (transform?.y?.toString() ?? "");

  tooltipEl.style.transform = newTransform;
  tooltipEl.style.left = positionX + tooltip._eventPosition.x + "px";

  const res = tooltipEl.getBoundingClientRect();

  if (res.right + 10 > window.innerWidth) {
    transform.x = "translateX(-100%)";
  } else if (res.left < 0) {
    transform.x = "translateX(0)";
  }

  if (res.bottom + 10 > window.innerHeight) {
    transform.y = "translateY(-100%)";
  } else if (res.top < 0) {
    transform.y = "translateY(0)";
  }

  newTransform =
    (transform?.x?.toString() ?? "") + " " + (transform?.y?.toString() ?? "");
  tooltipEl.style.transform = newTransform;

  tooltipEl.style.top = positionY + tooltip._eventPosition.y + "px";
}

export function reset(chart) {
  chart.data.datasets.forEach((dataset, i) => {
    chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
      const { outerRadius } = datapoint;
      const finalRadius = datapoint.originOuterRadius || outerRadius;
      datapoint.outerRadius = finalRadius;
      datapoint.originOuterRadius = undefined;
    });
  });
}
