export function formatRelatedDataParmas(state, tableSearchData) {
  try {
    const data = {
      keywords: state.wordFreqKeywords,
      pageSize: tableSearchData.pageSize,
      page: tableSearchData.page,
    };

    return data;
  } catch (e) {}
}
