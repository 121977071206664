import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Search from "./components/search";
import customWithErrorBoundary from "src/components/errorBoundary";
import { useDispatch, useSelector } from "react-redux";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import useRegisterTableState from "src/hooksOrClass/registerTableState";

import { KeywordsContext } from "./context";

import { getTableFetchData, getTableSelectOptionsApi } from "./service";
import Table from "./components/table";

import BoxLoading from "src/components/loading/box";
import Info from "./components/info";
import TableSearch from "./components/tableSearch";
import {
  getChartDataThunk,
  resetStore,
  resetTableSearch,
  syncBaseSearch,
  updateEmptyModalOpen,
  updateListingSearch,
  updateRankDrawerOpen,
  updateTableSearchOptions,
  updateTableSearchType,
  updateTrafficDataOfCode,
} from "./store";
import DrawRankTrend from "./components/drawRankTrend";
import DrawWordFrequency from "src/components/drawWordFrequency";
import DrawVolumeTrend from "src/components/drawVolumeTrend";

import RuleModal from "./components/ruleModal";
import {
  resetTable,
  updateActiveData,
  updateGlobalLoading,
} from "src/store/rootReducer";
import { listingAsinType } from "src/constants";
import useTableAndRegister from "src/hooksOrClass/table";
import useResetLoading from "src/hooksOrClass/resetLoading";
import Chart from "./components/chart";
import EmptyModal from "./components/emptyModal";
import { useTop } from "src/hooksOrClass/top";
import { isLoading } from "src/utils";
import { formatFreqTableParmas } from "./util";
import { formatDataTime } from "src/utils/time";

function KeyWords() {
  const [id, name] = useRouterId();
  useTop();
  useResetLoading();
  useRegisterTableState({ sortKey: "trafficRatio" });
  const dispatch = useDispatch();

  const request = ({ getState, tableSearchData, tableController }) => {
    const currentState = getState().keywords;
    return getTableFetchData({
      state: currentState,
      tableSearchData,
      signal: tableController.signal,
    });
  };

  const callback = (tableData) => {
    const { asinType } = tableData;
    dispatch(updateTrafficDataOfCode());
    if (asinType === listingAsinType.SINGLE_ASIN) {
      dispatch(updateListingSearch(false));
    } else if (asinType === listingAsinType.LISTING_PASIN) {
      dispatch(updateListingSearch(true));
    }

    const num =
      asinType === listingAsinType.LISTING_PASIN
        ? tableData.listingTotal
        : tableData.asinTotal;

    if (!num) {
      dispatch(updateEmptyModalOpen(true));
    }
  };

  const [fetchTable] = useTableAndRegister({ name, request, callback });
  const firstRef = useRef(true);
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const chartFetchStatus = useSelector(
    (state) => state.keywords.chartFetchStatus
  );

  const onSubmit = useCallback(async (formatFormValues) => {
    try {
      formatFormValues && dispatch(syncBaseSearch(formatFormValues));
      formatFormValues && dispatch(updateGlobalLoading(true));

      if (formatFormValues?.isListingSearch !== undefined) {
        dispatch(
          updateListingSearch(Boolean(formatFormValues.isListingSearch))
        );
      }
      if (formatFormValues?.keywordType) {
        dispatch(updateTableSearchType(formatFormValues.keywordType));
      }

      if (!firstRef.current) {
        dispatch(resetTableSearch());
      } else {
        firstRef.current = false;
      }

      dispatch(getChartDataThunk());

      fetchTable({ resetSortKey: true });
    } catch (e) {}
  }, []);

  const value = useMemo(
    () => ({
      tableKey: name,
      fetchTable,
      onSubmit,
      openRankDrawer: (rowData) => {
        dispatch(updateActiveData(rowData));
        dispatch(updateRankDrawerOpen(true));
      },
    }),
    [name]
  );

  useEffect(() => {
    return () => {
      dispatch(resetStore());
      dispatch(resetTable(name));
    };
  }, []);

  async function getTableSelectOptionsData() {
    const res = await getTableSelectOptionsApi();

    dispatch(updateTableSearchOptions(res));
  }

  useEffect(() => {
    getTableSelectOptionsData();
  }, []);

  const onGetRequestParams = ({ getState, tableSearchData, activeCode }) => {
    const currentState = getState().keywords;
    const data = formatFreqTableParmas({
      baseSearch: currentState.baseSearch,
      isListingSearch: currentState.isListingSearch,
      activeCode,
      tableSearchData,
    });
    return formatDataTime(data);
  };

  return (
    <KeywordsContext.Provider value={value}>
      <Search />
      <BoxLoading>
        <BoxLoading isLoading={isLoading(tableFetchStatus)}>
          <Info />
        </BoxLoading>

        <BoxLoading isLoading={isLoading(chartFetchStatus)}>
          <Chart />
        </BoxLoading>
        <TableSearch />
        <BoxLoading isLoading={isLoading(tableFetchStatus)} fixed>
          <Table />
        </BoxLoading>
      </BoxLoading>

      <EmptyModal />
      <RuleModal />
      <DrawRankTrend />
      <DrawVolumeTrend />
      <DrawWordFrequency
        onGetRequestParams={onGetRequestParams}
        url="/api/web/traffic/keywords/freq"
        exportUrl="/api/web/export/submit/traffic_keywords_freq"
        taskUrl="/api/web/export/progress/traffic_keywords_freq"
      />
    </KeywordsContext.Provider>
  );
}

export default customWithErrorBoundary(KeyWords);
