import i18next from "i18next";

export const chartDataOptionList = () => {
  const t = i18next.t;

  return [
    t("typeTraffic", { type: t(`terminology.organic`) }),

    t("typeTraffic", { type: t(`terminology.sp`) }),

    t("typeTraffic", { type: t(`terminology.sb`) }),
    t("typeTraffic", { type: t(`terminology.sbv`) }),
    t("typeTraffic", { type: t(`terminology.ac`) }),
    t("typeTraffic", { type: t(`terminology.other`) }),
  ];
};

export const sortByEnum = {
  boughtInPastMonth: "boughtInPastMonth",
  allWords: "allWords",
  organicWords: "organicWords",
  spWords: "spWords",
  sbWords: "sbWords",
  sbvWords: "sbvWords",
  acWords: "acWords",
  otherRecWords: "otherRecWords",
};
