import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import useTable from "src/hooksOrClass/table";
import { getAdDetailListApi } from "../../service";
import FlowTable from "src/components/table";
import { getColumns } from "./column";
import style from "./index.module.scss";
import SearchInput from "src/components/searchInput";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateDetailEncryptAdInfoKeyword } from "../../store";
import WordFreqBtn from "src/components/wordFreqBtn";
import { resetTableData } from "src/store/rootReducer";
import BoxLoading from "src/components/loading/box";
import { isLoading } from "src/utils";
import Empty from "src/components/empty";
import EmptyTips from "../emptyTips";
import classNames from "classnames";
import ExportKeyword from "./exportKeyword";

const name = "DETAIL_MODAL_LIST";

function DetailModalList({ onChangePrevious, onChangeNext }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const encryptAdId = useSelector(
    (state) => state.adStrategy.detailEncryptAdInfo.encryptAdId
  );
  const time = useSelector(
    (state) => state.adStrategy.detailEncryptAdInfo.time
  );
  const tableWrapRef = useRef();
  useRegisterTableState({ key: name, pageSize: 20 });
  const request = ({ getState, tableSearchData, tableController }) => {
    return getAdDetailListApi({
      state: getState().adStrategy,
      tableSearchData,
      signal: tableController?.signal,
    });
  };
  const adHistoryData = useSelector((state) => state.adStrategy.adHistoryData);
  const startIndex = adHistoryData?.wordCounts?.findIndex((item) => item);
  const endIndex = adHistoryData?.wordCounts?.findLastIndex((item) => item);
  const [fetchTable, resetRef] = useTable({ name, request });
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);
  const isLoadingStatus = isLoading(tableFetchStatus);

  const disabled = !tableData?.total || isLoadingStatus;

  useEffect(() => {
    fetchTable({});

    return () => {
      resetRef?.current && resetRef?.current("cancel");
    };
  }, [time, encryptAdId]);

  useEffect(() => {
    return () => {
      dispatch(resetTableData(name));
    };
  }, []);

  useEffect(() => {
    if (tableWrapRef.current && tableWrapRef.current.scrollTop !== 0) {
      tableWrapRef.current.scrollTop = 0;
    }
  }, [tableData]);

  const columns = getColumns({ name });

  const inputRef = useRef();
  const searchText =
    useSelector((state) => state.adStrategy?.detailEncryptAdInfo?.keyword) ??
    "";

  const onBlur = useCallback((value) => {
    dispatch(updateDetailEncryptAdInfoKeyword(value));
  }, []);

  const searchCallBack = useCallback((value) => {
    dispatch(updateDetailEncryptAdInfoKeyword(value));
    fetchTable();
  }, []);

  return (
    <>
      <BoxLoading isLoading={isLoadingStatus} isNotTop>
        <div className={style.tableContainer}>
          <div className={style.tableWrap} ref={tableWrapRef}>
            <FlowTable
              absoluted
              name={name}
              columns={columns}
              uniqId="keyword"
              translationKey="adStrategy.modalList"
            />

            <Empty
              data={tableData?.list}
              tips={<EmptyTips />}
              status={
                tableData?.total === undefined
                  ? tableFetchStatus
                  : memoTableFetchStatus
              }
              className={style.emptyWrap}
            ></Empty>
          </div>
        </div>
        <div className={classNames("textFont", style.btns)}>
          <SearchInput
            ref={inputRef}
            searchText={searchText}
            onBlur={onBlur}
            searchCallBack={searchCallBack}
            placeholder={t(`inputPlaceHolder.keyword`)}
          />
          <div className={classNames("btnsRightWrap", style.right)}>
            <WordFreqBtn disabled={disabled} />
            <ExportKeyword disabled={disabled} />
            <div
              className={classNames("button", {
                disabled: time === adHistoryData?.dates?.[startIndex],
              })}
              onClick={onChangePrevious}
            >
              <span className={classNames("icon-previous")}>
                &nbsp;{t(`viewPreviousChange`)}
              </span>
            </div>
            <div
              className={classNames("button", {
                disabled: time === adHistoryData?.dates?.[endIndex],
              })}
              onClick={onChangeNext}
            >
              <span> {t(`viewNextChange`)}&nbsp;</span>
              <span className={classNames("icon-next")}></span>
            </div>
          </div>
        </div>
      </BoxLoading>
    </>
  );
}

export default customWithErrorBoundary(memo(DetailModalList));
