import { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { fetchTableData } from "src/store/rootReducer";
import useRegisterTableService from "./useRegisterTableService";

function useTable({ name, request, callback }) {
  const resetRef = useRef();
  const dispatch = useDispatch();

  const getReset = (reset) => {
    resetRef.current = reset;
  };

  useRegisterTableService({ name, request, callback, getReset });

  const fetchTable = useCallback((params = {}) => {
    resetRef.current && resetRef.current("cancel");
    dispatch(fetchTableData({ key: name, ...params }));
  }, []);

  return [fetchTable, resetRef];
}

export default useTable;
