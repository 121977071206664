import { findTooltipEl, getTooltipElParent } from "./util";
import style from "./index.module.scss";
import { position } from "src/constants";
import { getPlaceHolderData } from "src/utils";

export const getOrCreateTooltip = (chart, tooltipContainerName) => {
  const parentElement = getTooltipElParent();
  var { tooltipEl } = findTooltipEl(parentElement, tooltipContainerName);

  parentElement.style.position = "relative";

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add(tooltipContainerName);
    tooltipEl.classList.add(style.tooltipEl);
    tooltipEl.classList.add("textFont");

    tooltipEl.style.opacity = 1;

    const wrapTable = document.createElement("div");
    wrapTable.classList.add("wrapTable");

    const arrow = document.createElement("div");
    arrow?.classList.add("arrow");

    tooltipEl.appendChild(wrapTable);
    tooltipEl.appendChild(arrow);
    parentElement.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export function isNeedReverseHor(container, x, tooltip) {
  try {
    return (
      parseInt(container.canvas.style.width) - x < tooltip.offsetWidth + 30
    );
  } catch (e) {
    return false;
  }
}

const externalTooltipHandler = ({
  chart,
  labels,
  tooltipContainer,
  opts = {},
  priorityTop = false,
  args = {},
}) => {
  labels = chart.options.originLabels || labels;
  const { x, y, nativeY, hoverIndex } = chart.corsair;

  const {
    position: optsPosition = position.horizontal,
    format,
    activeElementIdx,
    transform,
  } = opts;

  const tooltipEl = getOrCreateTooltip(chart, tooltipContainer);

  const idx = hoverIndex;
  const originData = chart.data.datasets.filter(
    (item, idx) => chart._metasets[idx].hidden !== true
  );

  let titleLines;
  let showData;
  let titleSpanClass;
  let titleColor;

  if (activeElementIdx !== undefined) {
    const dataset = chart.data.datasets?.[activeElementIdx];
    showData = labels.map((item, idx) => {
      return {
        label: item,
        data: dataset?.data?.[idx],
      };
    });
    titleLines = [dataset?.label];
    titleSpanClass = `${dataset?.type}-${dataset.pointStyle}`;
    titleColor = dataset?.originBackgroundColor || dataset.backgroundColor;
  } else {
    showData = originData.map((item) => ({
      type: item.type || chart?.config?.type,
      pointStyle: item.pointStyle || "circle",
      label: item.label,
      data: item.data[idx],
    }));
    titleLines = [labels[idx]];
  }

  if (showData.length === 0) {
    tooltipEl.classList.add("hidden");
    return;
  }

  const colorsArr = originData.map((item) => {
    if (item.type === "bar") {
      return item.originBackgroundColor || item.backgroundColor;
    }
    return item.borderColor;
  });
  const head = document.createElement("div");
  head.classList.add(style.title);
  titleLines.forEach((title) => {
    const span = document.createElement("span");
    span.classList.add(titleSpanClass);
    span.style.background = titleColor;
    span.style.borderColor = titleColor;

    const spanText = document.createElement("span");
    spanText.innerText = opts.formatTitle ? opts.formatTitle(title) : title;

    head.appendChild(span);
    head.appendChild(spanText);
  });

  const fragment = document.createDocumentFragment();
  showData.forEach((item, i) => {
    const wrap = document.createElement("div");
    const colors = colorsArr[i];
    const span = document.createElement("span");
    span.classList.add(`${item.type}-${item.pointStyle}`);
    span.style.background = colors;
    span.style.borderColor = colors;

    const label = document.createElement("span");
    label.innerText = item.label;

    const dataSpan = document.createElement("span");
    dataSpan.classList.add(style.dataSpan);
    const text = format
      ? format(item.data, item, idx)
      : getPlaceHolderData(item.data);

    dataSpan.innerText = text;

    wrap.appendChild(span);
    wrap.appendChild(label);
    wrap.appendChild(dataSpan);
    fragment.appendChild(wrap);
  });

  const root = tooltipEl.querySelector(".wrapTable");

  // Remove old children
  while (root?.firstChild) {
    root.firstChild.remove();
  }

  // Add new children
  root.appendChild(head);
  root.appendChild(fragment);
  if (opts.tips) {
    const tips = document.createElement("div");
    tips.classList.add(style.tips);
    tips.innerText = opts.tips;
    root.appendChild(tips);
  }

  const { left, top } = chart.canvas.getBoundingClientRect();
  const scrollX = window.scrollX || window.pageXOffset;

  const newX = parseInt(x + left + scrollX);
  const scrollY = window.scrollY || window.pageYOffset;

  const newY = parseInt(parseInt(chart.canvas.style.height) + top);
  const arrow = tooltipEl.querySelector(".arrow");
  if (optsPosition === position.horizontal) {
    if (isNeedReverseHor(chart, x, tooltipEl)) {
      tooltipEl.style.left = newX - 10 + "px";
      tooltipEl.style.transform = "translateX(-100%) translateY(-55%)";
    } else {
      tooltipEl.style.left = newX + 10 + "px";
      tooltipEl.style.transform = "translateY(-55%)";
    }
    if (transform) {
      tooltipEl.style.transform = transform;
    }

    arrow?.classList.add("hidden");
    tooltipEl.style.top =
      top + scrollY + parseInt(chart.canvas.style.height) / 2 + "px";
  } else if (optsPosition === position.vertical) {
    arrow.classList.remove("hidden");
    var viewHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight
    );

    tooltipEl.style.top = newY + scrollY + "px";

    let transformY = "";
    arrow.style.transform = "";
    let isBottomPosition;
    if (viewHeight - nativeY >= tooltipEl.offsetHeight + 50 && !priorityTop) {
      tooltipEl.style.transform = "translateX(-50%)";
      arrow?.setAttribute("placement", "bottom");
      isBottomPosition = true;
    } else {
      tooltipEl.style.top = top + scrollY + "px";
      tooltipEl.style.transform = "translateX(-50%) translateY(-100%)";
      transformY = "translateY(-100%)";
      arrow?.setAttribute("placement", "top");
      isBottomPosition = false;
    }

    tooltipEl.style.left = newX + "px";

    const res = tooltipEl.getBoundingClientRect();

    if (document.body.clientWidth < res.right) {
      tooltipEl.style.transform = transformY;
      let left = document.body.clientWidth - res.width;
      tooltipEl.style.left = left + "px";

      arrow.style.transform = `translate(${
        newX - left - res.width / 2 - 8
      }px, ${isBottomPosition ? "-" : ""}100%)`;
    } else if (res.left < 0) {
      tooltipEl.style.transform = transformY;
      tooltipEl.style.left = "0px";
      arrow.style.transform = `translate(${newX - res.width / 2 - 8}px, -100%)`;
    }
  } else if (optsPosition === position.mouse) {
    arrow?.classList.add("hidden");
    const argX = args.event.x + left;
    tooltipEl.style.left = argX + scrollX + "px";
    tooltipEl.style.top = scrollY + y + top + 10 + "px";

    if (isNeedReverseHor(chart, args.event.x, tooltipEl)) {
      tooltipEl.style.transform = "translateX(-100%)";
    } else {
      tooltipEl.style.transform = "translateX(0)";
    }
  }

  tooltipEl.classList.remove("hidden");

  return tooltipEl;
};

export default externalTooltipHandler;
