export const wordFreqTypes = [
  {
    code: "one",
  },
  {
    code: "two",
  },
  {
    code: "three",
  },
];
