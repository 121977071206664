import { memo, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeActiveSelect from "src/components/customSelect/changeActive";
import customWithErrorBoundary from "src/components/errorBoundary";
import { formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";
import {
  getChartDataThunk,
  resetTableSearch,
  updateListingSearch,
} from "../../store";
import { KeywordsContext } from "../../context";
import classNames from "classnames";
import style from "./index.module.scss";
import { listingAsinType } from "src/constants";

function Search() {
  const { tableKey: name } = useContext(KeywordsContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const isListingSearch = useSelector(
    (state) => state.keywords.isListingSearch
  );
  const info = tableData.asinInfo;

  const { asinTotal, listingTotal, asinType } = tableData || {};

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { onSubmit } = useContext(KeywordsContext);
  const data = useMemo(() => {
    if (!asinType) {
      return [{ value: "loading", label: t(`loading`) + "..." }];
    }

    const asinItem = {
      value: "asin",
      label: `${info?.asin}(${formatNumber(asinTotal)})`,
    };
    const listingItem = {
      value: "listing",
      label: `${t(`keywords.asinDetail.wholeListing`)}(${formatNumber(
        listingTotal
      )})`,
    };
    if (asinType === listingAsinType.LISTING_PASIN) {
      return [listingItem];
    }

    if (asinType === listingAsinType.SINGLE_ASIN) {
      return [asinItem];
    }

    return [asinItem, listingItem];
  }, [tableData]);

  const handleChange = useCallback((value) => {
    dispatch(updateListingSearch(value === "listing"));
    onSubmit();
  }, []);

  const value = asinType ? (isListingSearch ? "listing" : "asin") : "loading";

  return (
    <div className="textFont">
      <ChangeActiveSelect
        data={data}
        width="auto"
        needLabelShrink
        onChange={handleChange}
        value={value}
        labelClassName="textFont"
        label={
          <>
            <div> {t(`keywords.asinDetail.searchLabel`)}</div>
            {asinType === listingAsinType.LISTING_PASIN && (
              <div className={classNames("helperFont", "extraLine", style.tip)}>
                (*{t(`terminology.parentAsin`)})
              </div>
            )}
          </>
        }
      ></ChangeActiveSelect>
    </div>
  );
}

export default customWithErrorBoundary(memo(Search));
