import { MenuItem, Select } from "@mui/material";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { countryList } from "src/components/countrySelect/constant";
import style from "./index.module.scss";
import ImgWrap from "../imgWrap";

const CountrySelect = forwardRef((props, ref) => {
  let { t } = useTranslation();
  const { className } = props;
  return (
    <Select
      {...props}
      renderValue={(value) => {
        const currentCountry = countryList.find((item) => item.code === value);

        return (
          <div>
            <div className={style.miniFlag}>
              <img src={currentCountry.flag} alt="" />
            </div>
            <span>{t(`shortCountry.${currentCountry.code}`)}</span>
          </div>
        );
      }}
    >
      {countryList.map((country) => {
        return (
          <MenuItem value={country.code} key={country.code} variant="search">
            <span className={style.flag}>
              <ImgWrap src={country.flag} />
            </span>
            <span className={className}>{t(`country.${country.code}`)}</span>
          </MenuItem>
        );
      })}
    </Select>
  );
});

export default CountrySelect;
