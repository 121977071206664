import { memo, useContext, useMemo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import FlowTable from "src/components/table";
import { KeywordsGapContext } from "../../context";
import { getColumns } from "./column";
import { useSelector } from "react-redux";
import classNames from "classnames";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
function KeywordsGapTable() {
  const { t } = useTranslation();
  const { tableKey: name, fetchTable } = useContext(KeywordsGapContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const dataKey = "keywords";
  const columns = useMemo(() => {
    return getColumns({ data: tableData[dataKey] });
  }, [tableData]);

  return (
    <div className={classNames("wrap")}>
      <div>{t(`hha.lala`)}</div>
      <FlowTable
        columns={columns}
        name={name}
        fetchTable={fetchTable}
        dataKey={dataKey}
        uniqId="keyword"
      />
    </div>
  );
}

export default customWithErrorBoundary(memo(KeywordsGapTable));
