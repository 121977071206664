import { TableCell } from "@mui/material";
import classNames from "classnames";
import { memo } from "react";

function CustomCell({
  column,
  onClick,
  children,
  sortActive,
  style = {},
  type = "body",
}) {
  const isHead = type === "head";

  return (
    <TableCell
      onClick={onClick}
      key={column.id}
      id={column.id}
      className={classNames(column.id, {
        [column.className]: column.className,
      })}
      align={column.align}
      style={{
        minWidth: column.minWidth,
        maxWidth: column.maxWidth,
        color:
          column.needSort && sortActive && isHead
            ? "#000000"
            : isHead
            ? "#68646B"
            : "",
        width: column.width,
        backgroundColor: isHead ? "var(--hover-background)" : "#FFF",
        position: column.sticky || isHead ? "sticky" : "initial",
        lineHeight: isHead ? "1.5rem" : "initial",
        zIndex: column.sticky && isHead ? 3 : column.sticky ? 1 : "",
        cursor: column.needSort && isHead ? "pointer" : "initial",
        ...column.stickyStyle,
        ...column.style,
        ...style,
      }}
    >
      {children}
    </TableCell>
  );
}

export default memo(CustomCell);
