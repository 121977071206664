import React, { memo, useState } from "react";
import classNames from "classnames";
import { TooltipProps } from "@mui/material";

import SimpleTooltip from "../tooltip/simpleTooltip";
import style from "./index.module.scss";

interface Props {
  src: string;
  alt: string;
  // 是否需要hover上去放大
  hoverZoom: boolean;
  // hover放大的图片src，如果需要hover放大确没有hoverImgSrc属性，用src图片展示
  hoverImgSrc: string;
  wrapStyle?: React.CSSProperties;
  hoverWrapStyle: React.CSSProperties;
  placement: TooltipProps["placement"];
  imgClass: String;
}

function ImgWrap({
  src,
  alt = "",
  hoverZoom = false,
  hoverImgSrc,
  hoverWrapStyle,
  placement,
  imgClass = "",
  ...otherProps
}: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isHoverSrcLoaded, setHoverSrcLoaded] = useState(false);

  return (

    <SimpleTooltip
      title={
        hoverZoom && (
          <div style={hoverWrapStyle} className={classNames(style.toolWrap, { [style.loaded]: isHoverSrcLoaded })}>
            <img src={hoverImgSrc || src} alt={alt} onLoad={() => {
              setHoverSrcLoaded(true);
            }} />
          </div>
        )
      }
      placement={placement || "right"}
    >
      <div
        className={classNames(style.imgWrap, imgClass, { [style.loaded]: isLoaded, [style.hoverZoom]: hoverZoom })}
        {...otherProps}
      >
        <img
          src={src}
          alt={alt}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      </div>
    </SimpleTooltip >
  );

}

export default memo(ImgWrap);
