import { memo } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";
import HoverImg from "src/components/imgWrap/hoverImg";
import style from "./index.module.scss";
import { formatNumber, getPercentage, getPlaceHolderData } from "src/utils";
import { Tooltip } from "@mui/material";
import { dimensionEnum } from "src/constants";
import classNames from "classnames";
function Info({ info = {}, serialInfo, memoActiveCode }) {
  const { t } = useTranslation();
  const activeCode = memoActiveCode;

  const isAsinType = activeCode === dimensionEnum.asin;

  const features = info?.featureValues?.map((item) => item?.value)?.join(" | ");

  return (
    <div className="textFont">
      {isAsinType && (
        <div className={style.imgWrap}>
          {info?.img ? <HoverImg img={info?.img} /> : null}
        </div>
      )}

      <div className={style.contentContainer}>
        <div className={classNames(style.asin, "stressListFont")}>
          {serialInfo}
        </div>
        {isAsinType && (
          <div className={style.features}>
            <Tooltip title={isAsinType && features}>
              {getPlaceHolderData(features)}
            </Tooltip>
          </div>
        )}
        <div>
          {t(`structure.chart.score`, {
            score: getPlaceHolderData(formatNumber(info?.totalScore)),
          })}
        </div>
        <div>
          {t(`structure.chart.ratio`, {
            ratio: getPlaceHolderData(getPercentage(info?.ratio)),
          })}
        </div>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(Info));
