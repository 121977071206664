import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import { KeywordsGapContext } from "./context";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { useTop } from "src/hooksOrClass/top";
import useResetLoading from "src/hooksOrClass/resetLoading";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import useTable from "src/hooksOrClass/table";
import Search from "./components/search";
import { useDispatch, useSelector } from "react-redux";
import { resetStore, resetTableSearch, syncBaseSearch } from "./store";
import { resetTable, updateGlobalLoading } from "src/store/rootReducer";
import { getGapTableFetchDataApi } from "./service";
import Table from "./components/table";
import DrawVolumeTrend from "src/components/drawVolumeTrend";
import BoxLoading from "src/components/loading/box";
import { isLoading } from "src/utils";

function KeywordsGap() {
  const [id, name] = useRouterId();
  useTop();
  useResetLoading();
  useRegisterTableState({ sortKey: "" });
  const dispatch = useDispatch();
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const request = ({ getState, tableSearchData, tableController }) => {
    const currentState = getState().KeywordsGap;

    return getGapTableFetchDataApi({
      state: currentState,
      tableSearchData,
      signal: tableController.signal,
    });
  };

  const callback = () => {};

  const onSubmit = useCallback(async (formatFormValues) => {
    formatFormValues && dispatch(syncBaseSearch(formatFormValues));
    formatFormValues && dispatch(updateGlobalLoading(true));

    dispatch(resetTableSearch());
    fetchTable({ resetSortKey: true });
  }, []);

  const [fetchTable] = useTable({ name, request, callback });
  const value = useMemo(
    () => ({ tableKey: name, fetchTable, onSubmit }),
    [name]
  );

  useEffect(() => {
    return () => {
      dispatch(resetStore());
      dispatch(resetTable(name));
    };
  }, []);

  return (
    <KeywordsGapContext.Provider value={value}>
      <Search />
      <BoxLoading>
        <BoxLoading isLoading={isLoading(tableFetchStatus)} fixed>
          <Table />
        </BoxLoading>
      </BoxLoading>
      <DrawVolumeTrend />
    </KeywordsGapContext.Provider>
  );
}

// i18next.t;
export default customWithErrorBoundary(memo(KeywordsGap));
