import customWithErrorBoundary from "src/components/errorBoundary";

import { useDispatch, useSelector } from "react-redux";
import Search from "src/components/search";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { updateBasePeriod } from "../../store";
import { useTranslation } from "react-i18next";
import { periodKey } from "src/constants/form";
import getWeekMonthComponent from "src/components/search/components/time/weekMonthly";
import { HistoryContext } from "../../context";

function HistorySearch() {
  const { t } = useTranslation();
  const baseSearchValues = useSelector((state) => state.history.baseSearch);

  const [routerId, name] = useRouterId();
  const { onSubmit } = useContext(HistoryContext) || {};
  const dispatch = useDispatch();
  const defaultActionType = "period";

  const fetchNames = useMemo(() => {
    return [{ key: `${name}`, formatKey: defaultActionType }];
  }, [name]);

  const handleSubmit = async (formatFormValues) => {
    onSubmit && onSubmit(formatFormValues);
  };

  const MiddleChildren = getWeekMonthComponent({
    baseSearchValues,
    callback: (time) => {
      dispatch(updateBasePeriod(time));
    },
  });

  return (
    <Search
      multipleAsin={false}
      defaultActionType={defaultActionType}
      baseSearchValues={baseSearchValues}
      fetchNames={fetchNames}
      onSubmit={handleSubmit}
      MiddleChildren={MiddleChildren}
      MiddleName={periodKey}
      placeholder={t(`inputPlaceHolder.asinSingle`)}
    />
  );
}

export default customWithErrorBoundary(memo(HistorySearch));
