import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Search from "./components/search";
import History from "./components/history";
import SalesTable from "./components/table";
import customWithErrorBoundary from "src/components/errorBoundary";
import BoxLoading from "src/components/loading/box";
import { useSelector, useDispatch } from "react-redux";
import { actionTypeEnum, fetchStatus } from "src/constants";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import useRegisterTableService from "src/hooksOrClass/useRegisterTableService";

import { getTableFetchData } from "./service";
import { resetStore, updateColumnType } from "./store";
import { SalesContext } from "./context";
import { resetTable } from "src/store/rootReducer";
import useTable from "src/hooksOrClass/table";
import useResetLoading from "src/hooksOrClass/resetLoading";
import { useTop } from "src/hooksOrClass/top";
import { isLoading } from "src/utils";
import Slider from "src/components/slider";

function Sales() {
  const [id, name] = useRouterId();

  const dispatch = useDispatch();
  useTop();
  useResetLoading();
  useRegisterTableState({ sortKey: "" });
  const chartFetchStatus = useSelector((state) => state.sales.chartFetchStatus);
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const showChart = useSelector((state) => state.sales.showChart);
  const request = ({ getState, tableSearchData, tableController }) => {
    const currentState = getState().sales;
    const isAsin = currentState.baseSearch.actionType === actionTypeEnum.asin;

    // 使用token来发起请求
    return getTableFetchData(
      currentState,
      tableController.signal,
      isAsin,
      tableSearchData
    );
  };

  const callback = () => {
    dispatch(updateColumnType());
  };

  const [fetchTable] = useTable({ name, request, callback });

  const value = useMemo(() => ({ tableKey: name, fetchTable }), [name]);

  useEffect(() => {
    return () => {
      dispatch(resetStore());
      dispatch(resetTable(name));
    };
  }, []);

  return (
    <SalesContext.Provider value={value}>
      <Search />

      <BoxLoading>
        {showChart && (
          <BoxLoading isLoading={isLoading(chartFetchStatus)}>
            <History />
          </BoxLoading>
        )}
        <BoxLoading isLoading={isLoading(tableFetchStatus)} fixed>
          <SalesTable />
        </BoxLoading>
      </BoxLoading>
    </SalesContext.Provider>
  );
}

export default customWithErrorBoundary(Sales);
