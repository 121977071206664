import { memo } from "react";
import customWithErrorBoundary from "../errorBoundary";
import SimpleTooltip from "./simpleTooltip";

import style from "./index.module.scss";
import classNames from "classnames";

function Tip({
  placement = "bottom",
  title,
  children,
  otherContent,
  maxWidth,
}) {
  const isString = typeof title === "string";
  const titleClass = classNames(style.tipHTMLWrap, {
    [style.other]: otherContent,
  });

  return (
    <SimpleTooltip
      tooltipSx={{ maxWidth }}
      title={
        <div className={style.wrap}>
          {isString ? (
            <div
              className={titleClass}
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          ) : (
            <div className={titleClass}>{title}</div>
          )}

          {otherContent}
        </div>
      }
      placement={placement}
    >
      {children}
    </SimpleTooltip>
  );
}

export default customWithErrorBoundary(memo(Tip));
