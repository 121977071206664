import { TableHead, Table, TableBody } from "@mui/material";

import customWithErrorBoundary from "../errorBoundary";
import { useStorageShrinked } from "src/hooksOrClass/localstorge";
import { useSyncPosition } from "src/hooksOrClass/syncPosition";

import { useChangeStickyShadow } from "./hooks/stickyShadow";
import { useSyncHeadFixedPositionAndStyle } from "./hooks/syncHeadFixedPositionAndStyle";

import { useContext, useEffect, useRef } from "react";
import { TableContext } from "./context";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import TablePagination from "./pagination";
import classNames from "classnames";

import style from "./index.module.scss";

function TableHeadSticky({ head = [], body = [] }) {
  const {
    name,
    needSticky,
    needExtraHead,
    stickyClass,
    absoluted = false,
  } = useContext(TableContext);
  const tableData = useSelector((state) => state.root.table[name].tableData);

  const [shrinked, { horizontalLeft, horizontalRight }] = useStorageShrinked();
  const ref = useRef(uuidv4());
  const containerId = `tableContainer-${ref.current}`;
  const fixedHeadId = `tableHeadFixed-${ref.current}`;
  const headId = `tableHead-${ref.current}`;
  // 在没有发生折叠时，不需要自定义的FixedBar优先使用原生的
  const [hasFoldRef] = useChangeStickyShadow([shrinked], {
    containerId,
    fixedHeadId,
    headId,
  });

  useSyncPosition(`#${containerId}`, `#${fixedHeadId}`, needExtraHead);
  useSyncHeadFixedPositionAndStyle([shrinked], hasFoldRef, {
    containerId,
    fixedHeadId,
    headId,
    needExtraHead,
  });

  useEffect(() => {
    try {
      const wrap = document.getElementById(containerId);
      const res = wrap?.getBoundingClientRect();
      if (res?.top < 0) {
        window.scrollTo(0, window.scrollY + res.top);
      }
      if (wrap.scrollTop !== 0) {
        wrap.scrollTop = 0;
      }
    } catch (e) {}
  }, [tableData]);

  return (
    <div className={style.tableContainer} id={containerId}>
      {needExtraHead && (
        <div
          id={fixedHeadId}
          className={classNames(
            style.tableHeadFixed,
            stickyClass,
            "stressTextFont",
            {
              [style.absoluteHeader]: absoluted,
            }
          )}
          style={{ left: horizontalLeft, right: horizontalRight }}
        >
          <Table stickyHeader>
            <TableHead style={{ position: "relative", zIndex: 2 }}>
              {head}
            </TableHead>
          </Table>
        </div>
      )}
      <Table stickyHeader={needSticky} aria-label="sticky table" id="table">
        <TableHead
          id={headId}
          style={{ position: "relative", zIndex: 2 }}
          className="stressTextFont"
        >
          {head}
        </TableHead>

        <TableBody>{body}</TableBody>
      </Table>
    </div>
  );
}

export default customWithErrorBoundary(TableHeadSticky);
