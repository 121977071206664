import { endOfWeek, isAfter, startOfWeek, subMonths } from "date-fns";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { DayPicker } from "react-day-picker";
import head from "./head";
import classNames from "classnames";
const numberOfMonths = 2;

const MuiWeekRange = forwardRef((props, ref) => {
  const { onChange, value, startTime, endTime } = props;
  const [range, setRange] = useState();

  const dateValue = value?.to ?? value?.from;
  const [month, setMonth] = useState(
    subMonths(dateValue ? new Date(dateValue) : new Date(), numberOfMonths - 1)
  );

  useEffect(() => {
    let date = {};
    if (isAfter(value.from, value.to)) {
      date.from = value.to;
      date.to = value.from;
    } else {
      date = value;
    }
    setRange(date);
  }, [value]);

  useImperativeHandle(ref, () => ({
    setMonth: setMonth,
  }));

  const handleSelect = (selectRange, date) => {
    let rangeDate;

    if (range?.from && range?.to) {
      rangeDate = {
        from: startOfWeek(date),
        to: undefined,
      };
    } else {
      rangeDate = {
        from: selectRange.from ? startOfWeek(selectRange.from) : undefined,
        to: selectRange.to ? endOfWeek(selectRange.to) : undefined,
      };
    }

    onChange && onChange(rangeDate);
    setRange(rangeDate);
  };

  return (
    <div className={classNames("week-range-picker multiple-week-range-picker")}>
      <DayPicker
        showOutsideDays
        mode="range"
        onMonthChange={setMonth}
        month={month}
        numberOfMonths={numberOfMonths}
        captionLayout="dropdown-buttons"
        fromDate={startTime}
        toDate={endTime ?? endOfWeek(new Date())}
        components={{
          Caption: head,
        }}
        disabled={{ after: endTime ?? endOfWeek(new Date()) }}
        modifiers={{ booked: new Date() }}
        modifiersClassNames={{ booked: "booked" }}
        selected={range}
        onSelect={handleSelect}
      />
    </div>
  );
});

export default MuiWeekRange;
