import i18next from "i18next";
import { actionTypeEnum } from "src/constants";
import { asinsKey, timeKey } from "src/constants/form";
import { addLinkUrl, pageAction } from "src/constants/page";

export function getOption({ needDefaultOption = false, data = {} } = {}) {
  const options = [
    addLinkUrl(pageAction.adStrategy, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    addLinkUrl(pageAction.productSales, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    addLinkUrl(pageAction.trafficStructure, {
      baseParamsKeys: [asinsKey, timeKey],
      data,
    }),
  ];

  if (needDefaultOption) {
    return [options.shift(), options];
  }

  return options;
}

export function getActionOption({ rowData } = {}) {
  const options = [
    addLinkUrl(pageAction.productSales, {
      params: {
        [asinsKey]: rowData.keyword,
        actionType: actionTypeEnum.keyword,
      },
    }),
  ];

  return options;
}

export function formatFreqTableParmas({
  baseSearch = {},
  isListingSearch,
  activeCode,
  tableSearchData,
}) {
  try {
    const data = {
      asin: baseSearch.asins,
      listing: isListingSearch,
      time: baseSearch.time,
      wordModel: activeCode?.toLocaleUpperCase(),
      page: tableSearchData.page,
      pageSize: tableSearchData.pageSize,
    };
    return data;
  } catch (e) {}
}

export const getEmptyReasons = () => [
  i18next.t(`emptyTips.reason1`),
  i18next.t(`keywords.emptyTips.reason2`),
  i18next.t(`keywords.emptyTips.reason3`),
  i18next.t(`keywords.emptyTips.reason4`),
];
