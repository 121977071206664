// import Nouislider from "nouislider-react";
import "nouislider/dist/nouislider.css";
import { useEffect, useRef, useState } from "react";
import noUiSlider from "nouislider";
import style from "./index.module.scss";
import { linearMap } from "./util";

function Slider({ data = [], update }) {
  function getIdx(val) {
    return Math.round(linearMap(val, [0, 100], [0, data.length - 1]));
  }
  const sliderRef = useRef();
  const [slider, setSlider] = useState(null);
  const [range, setRange] = useState([0, 100]);
  const firstRef = useRef(false);

  const [startIdx, setStartIdx] = useState(getIdx(range[0]));
  const [endIdx, setEndIdx] = useState(getIdx(range[1]));

  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (sliderElement && !slider) {
      noUiSlider.create(sliderElement, {
        start: range,
        connect: true,
        behaviour: "unconstrained-tap-drag",
        range: {
          min: range[0],
          max: range[1],
        },
      });
      setSlider(sliderElement.noUiSlider);
    }
    firstRef.current = true;
  }, []);

  useEffect(() => {
    if (slider) {
      slider.on("update", (values) => {
        if (Number(values[1]) < Number(values[0])) {
          setRange([values[1], values[0]]);
        } else {
          setRange(values);
        }
      });
    }
  }, [slider]);

  function getNewIdx() {
    const startIdx = getIdx(range[0]);
    const endIdx = getIdx(range[1]);
    setStartIdx(startIdx);
    setEndIdx(endIdx);

    return [startIdx, endIdx];
  }
  useEffect(() => {
    if (firstRef.current) {
      getNewIdx();
    }
  }, [data]);

  useEffect(() => {
    if (firstRef.current) {
      const values = getNewIdx();
      update && update(values);
    }
  }, [range]);

  return (
    <div ref={sliderRef} className={style.sliderWrap}>
      <div className={style.leftText} style={{ left: range[0] + "%" }}>
        {data[startIdx]}
      </div>
      <div className={style.rightText} style={{ left: range[1] + "%" }}>
        {data[endIdx]}
      </div>
    </div>
  );
}

export default Slider;
