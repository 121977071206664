import LanguageDetector from "i18next-browser-languagedetector";
import Cookies from "js-cookie";
import { getQueryValue } from "src/utils";
import { options } from "./constant";
// 当前支持的语言以及对应
const support = ["en", "jp"];

function getStartStr(lng) {
  if (!lng) return "";

  const strs = lng.split("-") || [];
  const startStr = strs.length > 0 ? strs[0] : "";
  return startStr;
}

class CustomLanguageDetector extends LanguageDetector {
  detect() {
    const queryLng = getQueryValue(options.lookupQuerystring);
    const startQueryLng = getStartStr(queryLng);

    let lng;
    if (support.includes(startQueryLng)) {
      lng = startQueryLng;
    }

    let langs = super.detect();
    const newLangs = [...new Set(langs)];

    let lang;

    // 尝试从 cookie 中获取语言
    lang = lng || Cookies.get(options.lookupCookie);
    // 如果 cookie 中没有语言，那么使用 fallbackLng 作为回退语言
    if (!lang) {
      for (let i = 0; i < newLangs.length; i++) {
        const startStr = getStartStr(newLangs[i]);
        if (support.includes(startStr)) {
          lang = startStr;
          break;
        }
      }
    }

    return lang || this.options.fallbackLng;
  }
}

const languageDetector = new CustomLanguageDetector(null, options);

export default languageDetector;
