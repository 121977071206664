import { useContext, useEffect, useMemo } from "react";
import FlowTable from "src/components/table";
import Empty from "src/components/empty";
import { getColumns } from "./column";
import { useDispatch, useSelector } from "react-redux";
import { KeywordsContext } from "../../context";

import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";
import style from "./index.module.scss";
import Export from "src/components/export";
import { fetchStatus, listingAsinType } from "src/constants";
import { exportKeywordsTable, updateWordFreqDrawerOpen } from "../../store";
import { getOption } from "../../util";
import CustomSelect from "src/components/customSelect";
import classNames from "classnames";
import EmptyTips from "../tips";
import WordFreqBtn from "src/components/wordFreqBtn";

function Table() {
  const dispatch = useDispatch();
  const { tableKey: name, fetchTable } = useContext(KeywordsContext);

  const baseSearch = useSelector((state) => state.keywords.baseSearch);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const isListingSearch = useSelector(
    (state) => state.keywords.isListingSearch
  );

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const tableDownLoadStatus = useSelector(
    (state) => state.keywords.tableDownLoadStatus
  );
  const time = baseSearch.time;
  const sortKeys = useSelector((state) => state.keywords.trafficSortKeys);

  const [defaultOption, options] = getOption({
    needDefaultOption: true,
    data: baseSearch,
  });

  const { t } = useTranslation();

  const columns = useMemo(() => {
    return getColumns({
      isListingSearch,
      t,
      time,
      sortKeys,
      name,
    });
  }, [tableData]);

  const disabled = !tableData?.total;

  return (
    <div className={classNames("wrap", "wrapMini", style.tableWrap)}>
      <div
        className={classNames(
          "wrapContentInfo",
          "titleSummary",
          style.tableBtns
        )}
      >
        <div className={style.tip}>
          {t(`keywords.keywordFirst3Page`, {
            total: formatNumber(tableData?.total),
            historyTotal: formatNumber(tableData?.historyTotal),
          })}
        </div>
        <div className="btnsRightWrap">
          <WordFreqBtn disabled={disabled} />
          <Export
            disabled={disabled}
            loading={tableDownLoadStatus === fetchStatus.loading}
            onDownload={() => {
              dispatch(exportKeywordsTable({ key: name }));
            }}
          />

          <CustomSelect
            disabled={tableData?.asinType === listingAsinType.LISTING_PASIN}
            defaultConfig={{
              ...defaultOption,
              title: t(`router.${defaultOption.title}`),
              style: {
                color: "var(--link-color)",
              },
            }}
            options={options.map((item) => ({
              ...item,
              title: t(`router.${item.title}`),
            }))}
          />
        </div>
      </div>
      <FlowTable
        columns={columns}
        translationKey="keywords"
        name={name}
        fetchTable={fetchTable}
        uniqId="keyword"
        hasAddProductBtn={false}
      />
      <Empty
        data={tableData?.list}
        tips={<EmptyTips />}
        status={
          tableData?.total === undefined
            ? tableFetchStatus
            : memoTableFetchStatus
        }
        className={style.emptyWrap}
      ></Empty>
    </div>
  );
}

export default Table;
