import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import customWithErrorBoundary from "src/components/errorBoundary";
import classNames from "classnames";
import Export from "src/components/export";
import Empty from "src/components/empty";
import { dimensionEnum, fetchStatus } from "src/constants";
import FlowPagination from "src/components/pagination";
import SalesChart from "../chart";
import {
  changeDimension,
  downloadTrendsDataThunk,
  fetchChartData,
} from "../../store";
import EmptyTips from "../tips";
import style from "./index.module.scss";
import { updateListing } from "src/service";
import UpdateVariants from "src/components/updateVariants";
import ButtonTab from "src/components/buttonTab";
import { getAllFeatures } from "src/utils/chart/util";
import { isAsinMultiple } from "src/utils";

function History() {
  const dispatch = useDispatch();

  const chartSearch = useSelector((state) => state.sales.chartSearch);
  const chartFetchStatus = useSelector((state) => state.sales.chartFetchStatus);

  const chartData = useSelector((state) => state.sales.chartData);

  const memoChartFetchStatus = useMemo(() => {
    return chartFetchStatus;
  }, [chartData]);

  const chartDownLoadStatus = useSelector(
    (state) => state.sales.chartDownLoadStatus
  );

  const asins = useSelector((state) => state.sales.baseSearch.asins);
  useEffect(() => {}, [chartData]);

  let { t } = useTranslation();

  const handleChangePageNumAndFetch = useCallback((e, page) => {
    dispatch(fetchChartData({ pageNum: page }));
  }, []);

  const handleChangeDimension = (value) => {
    dispatch(changeDimension(value));
    dispatch(fetchChartData());
  };

  const allFeatures = getAllFeatures(chartData?.features);

  const handleDownload = () => {
    dispatch(downloadTrendsDataThunk());
  };

  const handleUpdateListing = async () => {
    await updateListing(asins);
  };

  return (
    <div className={classNames(style.history, "wrap")}>
      <div className={classNames("titleSummary", "wrapContentInfo")}>
        <ButtonTab
          data={allFeatures}
          activeCode={chartSearch.dimension}
          onChangeActive={handleChangeDimension}
        />

        <div className={classNames("btnsRightWrap", style.upAndEx)}>
          {!isAsinMultiple(asins) && (
            <UpdateVariants asins={asins} className={style.btn} />
          )}

          <Export
            className={style.btn}
            disabled={!chartData?.total}
            onDownload={handleDownload}
            loading={chartDownLoadStatus === fetchStatus.loading}
          />
        </div>
      </div>
      <div className={classNames(style.chartWrap)}>
        <Empty
          data={chartData}
          tips={<EmptyTips />}
          status={
            chartData?.total === undefined
              ? chartFetchStatus
              : memoChartFetchStatus
          }
        >
          <SalesChart />

          <div className="pageContentWrap">
            <FlowPagination
              onChange={handleChangePageNumAndFetch}
              page={chartSearch.pageNum}
              count={Math.ceil((chartData?.total || 0) / chartSearch.pageSize)}
            />
          </div>
        </Empty>
      </div>
    </div>
  );
}

export default memo(customWithErrorBoundary(History));
