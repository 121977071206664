import buildRequest from "src/request/axios";

export const getWrodRelatedData = async ({ data, signal } = {}) => {
  const url = `/api/web/keywords/aba_base`;

  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downLoadRelatedFile = async (data) => {
  const res = await buildRequest({
    url: "/api/web/export/submit/traffic_keywords_freq_keywords",
    method: "post",
    data,
    extraConfig: {
      showErrorMessage: true,
    },
  });

  return res;
};
