export function dotProduct(v1, v2) {
  return v1[0] * v2[0] + v1[1] * v2[1]; // 对于2D向量
}

export function magnitude(v) {
  return Math.sqrt(v[0] * v[0] + v[1] * v[1]); // 对于2D向量
}

export function angleBetweenVectors(v1, v2) {
  var cosTheta = dotProduct(v1, v2) / (magnitude(v1) * magnitude(v2));
  var theta = Math.acos(cosTheta);
  return theta * (180 / Math.PI); // 转换为度
}

export function ceilRounding({ max, min = 0, count } = {}) {
  try {
    if (count < 1) {
      return { max, min, count };
    }
    const range = Math.ceil((max - min) / (count - 1));

    const length = range.toString().length;
    const divisor = Math.pow(10, length - 1);
    const rangeCeil = Math.ceil(range / divisor) * divisor;
    const res = rangeCeil * (count - 1) + min;

    return {
      max: res,
      count,
      spaceing: rangeCeil,
    };
  } catch (e) {
    return {};
  }
}
