import { useMemo } from "react";
import Tips from "src/components/empty/tips";
import { getEmptyReasons } from "../../util";

function EmptyTips() {
  const reasons = useMemo(() => {
    return getEmptyReasons();
  });

  return <Tips reasons={reasons} num="num.two" />;
}

export default EmptyTips;
