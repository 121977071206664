import customWithErrorBoundary from "../errorBoundary";
import style from "./index.module.scss";

function Loading() {
  return (
    <div className={style["loadingio-spinner-pulse-q8hv0b87789"]}>
      <div className={style["ldio-ccffhultovm"]}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(Loading);
