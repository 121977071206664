// koa 经典洋葱模型
class Onion {
  middlewares = [];
  constructor() {
    this.middlewares = [];
  }

  use(middleware) {
    this.middlewares.push(middleware);
  }

  remove(middleware) {
    const index = this.middlewares.findIndex((it) => it === middleware);
    index > -1 && this.stack.splice(index, 1);
  }

  dispatch() {
    const that = this;
    let index = -1;
    return dispatch(0);

    function dispatch(i) {
      if (i <= index)
        return Promise.reject(new Error("next() called multiple times"));
      index = i;
      let fn = that.middlewares[i];
      if (i === that.middlewares.length || !fn) return Promise.resolve();

      try {
        return Promise.resolve(fn(that, dispatch.bind(null, i + 1)));
      } catch (err) {
        return Promise.reject(err);
      }
    }
  }
}

export default Onion;
