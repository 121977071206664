import i18next from "i18next";
import { dimensionEnum } from "src/constants";
import variables from "src/styles/_chart.scss";
export const chartColors = variables.colors
  .split(",")
  .map((item) => item.trim());

export const getAllFeatures = (features, key = "dimensionEnum") => {
  const t = i18next.t;
  const allFeatures = [
    {
      code: dimensionEnum.asin,
    },
  ]
    .concat(features || [])
    ?.map((item) => ({
      code: item.code,
      name: t(`${key}`, {
        type: t(`trend.${item.name || item.code}`),
      }),
    }));

  return allFeatures;
};
