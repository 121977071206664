import { useState, useEffect } from "react";
import { storageShrinkedKey } from "src/constants";
import variables from "src/styles/_var.scss";
import { calcScssVariables, getStorageValue } from "src/utils";

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    const handleStorage = (event) => {
      // 监测本页面
      if (event?.detail?.key === key) {
        const newValue = JSON.parse(event?.detail?.newValue);
        if (value !== newValue && newValue !== undefined) {
          setValue(newValue);
        }
      } else if (event.key === key) {
        //监测其他页面
        const newValue = JSON.parse(event?.newValue);
        if (value !== newValue && newValue !== undefined) {
          setValue(newValue);
        }
      }
    };

    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, [value]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(
      new CustomEvent("storage", {
        detail: { key, newValue: JSON.stringify(value) },
      })
    );
  }, [key, value]);

  return [value, setValue];
};

export const useStorageShrinked = () => {
  const [value, setValue] = useLocalStorage(storageShrinkedKey, false);
  const barWidth = calcScssVariables(variables.barWidth);

  return [
    value,
    setValue,
    {
      horizontalLeft: value
        ? parseInt(variables.noBarHorizontal) / 2 + "px"
        : variables.hasBarHorizontal,
      horizontalRight: value
        ? variables.noBarHorizontal
        : variables.hasBarHorizontal,
      left: value ? "0px" : barWidth,
    },
  ];
};
