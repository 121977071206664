import buildRequest from "src/request/axios";

export const searchesNumHistory = async ({ data, signal } = {}) => {
  const url = `/api/web/keyword/aba_trend`;
  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
  return response;
};
