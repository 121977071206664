import buildRequest from "src/request/axios";

export const getGapTableFetchDataApi = async ({ signal, data } = {}) => {
  const url = `/api/web/traffic/keyword/rank/gap`;

  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });

  console.log("response", response);
  return response;
};
