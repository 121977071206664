import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchStatus, sortByEnum } from "src/constants";
import { exportPureFn } from "src/hooksOrClass/export";
import { downLoadWordFile } from "src/components/drawWordFrequency/service";
import buildRequest from "src/request/axios";
import tableService, { formatTableSearch } from "src/utils/table";
import { formatDataTime } from "src/utils/time";
import { formatRelatedDataParmas } from "src/components/drawRelated/util";
import { downLoadRelatedFile } from "src/components/drawRelated/service";
import { isBefore } from "date-fns";
const defaultMustReadInfo = {
  title: "",
  content: "",
  imgs: [],
};

function getDefaultTableInfo({ sortKey, pageSize = 100, keywordsExport }) {
  return {
    tableSearch: {
      pageSize,
      pageNum: 1,
      sort: {
        key: sortKey,
        by: sortByEnum.desc,
      },
    },
    keywordsExport,
    defaultSortKey: sortKey,
    selected: [],
    tableData: {},
    // tableData: tableData.data,
    tableFetchStatus: fetchStatus.initial,
    downloadKeywordsStatus: fetchStatus.initial,
    hoverRowAsin: "",
    tableController: null,
  };
}

const initialState = {
  isOnceSet: false,
  globalLoading: false,

  message: [],
  mustRead: defaultMustReadInfo,

  table: {},
  volumeDrawerOpen: false,
  wordFreqDrawerOpen: false,
  wordRelatedDrawerOpen: false,
  wordFreqKeywords: [],
  activeData: {},
};

export const RootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    resetRootLoading: (state, action) => {
      state.isOnceSet = false;
      state.globalLoading = false;
    },
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
    updateIsOnceSet: (state, action) => {
      state.isOnceSet = action.payload;
    },
    updateGlobalLoading: (state, action) => {
      if (state.isOnceSet && !state.globalLoading) {
        return;
      }
      state.globalLoading = action.payload;
      state.isOnceSet = true;
    },
    resetMustRead: (state) => {
      state.mustRead = defaultMustReadInfo;
    },
    updateMustRead: (state, action) => {
      state.mustRead = action.payload;
    },
    resetTable: (state, action) => {
      const key = action.payload;
      state.table[key] = {};
    },
    resetTableData: (state, action) => {
      const key = action.payload;
      state.table[key].tableData = {};
    },
    resetIncludeKeyTableData: (state, action) => {
      const key = action.payload;
      const allTablesKeys = Object.keys(state.table);

      allTablesKeys.forEach((initKey) => {
        if (initKey.includes(key)) {
          state.table[initKey].tableData = {};
        }
      });
    },
    initializeTabledata: (state, action) => {
      const { key, sortKey, pageSize, keywordsExport } = action.payload;
      state.table[key] = getDefaultTableInfo({
        sortKey,
        pageSize,
        keywordsExport,
      });
    },

    updateHoverRowAsin: (state, action) => {
      const { key, value } = action.payload || {};
      state.table[key].hoverRowAsin = value;
    },
    updateWordRelatedDrawerOpen: (state, action) => {
      state.wordRelatedDrawerOpen = action.payload;
    },
    updateSelected: (state, action) => {
      const { key, value } = action.payload || {};
      state.table[key].selected = value;

      state.table[key].downloadKeywordsStatus = fetchStatus.initial;
    },
    updateShowAllChecked: (state, action) => {
      const { key, value } = action.payload || {};
      state.table[key].showAllChecked = value;
    },
    changeTableSearchPageNum: (state, action) => {
      const { key, value } = action.payload || {};

      state.table[key].tableSearch.pageNum = value;
    },

    changeTableFetchStatus: (state, action) => {
      const { key, value } = action.payload || {};

      state.table[key].tableFetchStatus = value;
    },

    resetTableSearchSortKey: (state, action) => {
      const { key } = action.payload || {};

      state.table[key].tableSearch.sort.key = state.table[key]?.defaultSortKey;
    },
    changeTableSearchSort: (state, action) => {
      const { key, value } = action.payload || {};

      state.table[key].tableSearch.sort = value;
    },
    updateDownloadKeywordsStatus: (state, action) => {
      const { key, value } = action.payload || {};
      state.table[key].downloadKeywordsStatus = value;
    },
    updateAndCancelController: (state, action) => {
      const { key, tableController } = action.payload || {};
      const oldController = state.table[key]?.tableController;
      if (oldController) {
        oldController?.abort();
      }

      state.table[key].tableController = tableController;
    },
    updateVolumeDrawerOpen: (state, action) => {
      state.volumeDrawerOpen = action.payload;
    },
    updateActiveData: (state, action) => {
      state.activeData = action.payload;
    },
    updateWordFreqDrawerOpen: (state, action) => {
      state.wordFreqDrawerOpen = action.payload;
    },
    updateWordFreqKeywords: (state, action) => {
      state.wordFreqKeywords = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTableData.fulfilled, (state, action) => {
        const { key, value } = action.payload || {};

        if (!value) {
          state.table[key].tableFetchStatus = fetchStatus.error;
        } else {
          state.table[key].tableFetchStatus = fetchStatus.success;
          state.globalLoading = false;
        }
        state.table[key].selected = [];
        state.table[key].showAllChecked = false;
        state.table[key].tableData = value;
        state.table[key].hoverRowAsin = "";
      })
      .addCase(fetchTableData.rejected, (state, action) => {
        console.log("rejected", action);
        if (action.error.name === "AbortError") {
          // 处理请求被取消的情况
        } else {
          // 处理其他错误情况
        }
      });
  },
});

export const {
  updateGlobalLoading,
  updateIsOnceSet,
  resetRootLoading,
  resetMustRead,
  updateMustRead,
  resetTable,
  updateActiveData,
  resetTableData,
  initializeTabledata,
  updateWordRelatedDrawerOpen,
  changeTableSearchPageNum,
  changeTableFetchStatus,
  updateAndCancelController,
  updateHoverRowAsin,
  updateShowAllChecked,
  updateSelected,
  updateVolumeDrawerOpen,
  updateWordFreqDrawerOpen,
  changeTableSearchSort,
  resetTableSearchSortKey,
  updateWordFreqKeywords,
  resetIncludeKeyTableData,
  updateDownloadKeywordsStatus,
} = RootSlice.actions;

export const fetchTableData = createAsyncThunk(
  "data/fetchTableData",
  async (data, { getState, dispatch, rejectWithValue }) => {
    try {
      return new Promise(async (resolve, reject) => {
        const pageNum = data?.pageNum || 1;
        const resetSortKey = data?.resetSortKey || false;
        const key = data?.key;

        if (!key) {
          reject && reject("error");
          return;
        }

        if (resetSortKey) {
          dispatch(resetTableSearchSortKey({ key }));
        }

        function reset(reason) {
          const oldController = getState().root?.table?.[key]?.tableController;
          oldController?.abort && oldController?.abort();
          dispatch(changeTableFetchStatus({ key, value: fetchStatus.initial }));
          reject && reject(reason);
        }

        dispatch(changeTableSearchPageNum({ key, value: pageNum }));
        dispatch(changeTableFetchStatus({ key, value: fetchStatus.loading }));

        const tableController = new AbortController(); //
        dispatch(
          updateAndCancelController({
            key,
            tableController: tableController,
          })
        );

        const state = getState().root;
        const tableState = state.table[key];
        const {
          request = () => {},
          callback,
          getReset,
        } = tableService[key] || {};

        getReset && getReset(reset);
        const tableSearchData = formatTableSearch(tableState.tableSearch);

        // 使用token来发起请求
        const response =
          (await request({
            getState,
            tableSearchData,
            tableController,
          })) || {};

        response?.list.forEach((item) => {
          item.chartData = response?.dates?.map((item1) => {
            if (isBefore(item1, Object.keys(item?.ads)?.[0])) {
              return null;
            }
            return Math.random() * 1000 + 200;
          });
        });
        console.log("responseData11111", response);

        callback && callback(response, getState);

        resolve({ key, value: response });
      });
    } catch (e) {
      return rejectWithValue("error");
    }
  }
);

export const downLoadKeywordsThunk = createAsyncThunk(
  "data/downloadKeywordsThunk",
  async (data, { getState, dispatch }) => {
    const { key, pageKey } = data || {};
    if (!key || !pageKey) {
      return;
    }

    const allState = getState();

    const tableInfo = allState?.root?.table[key];
    const keywordsExport = tableInfo?.keywordsExport;
    const selected = tableInfo.selected;
    const pageState = allState[pageKey];

    if (!keywordsExport) {
      return;
    }

    const downloadData = {
      asins: selected,
      time: pageState?.baseSearch?.time,
    };

    const update = (status) => {
      dispatch(updateDownloadKeywordsStatus({ key, value: status }));
    };

    const exportFn = exportPureFn({
      catchFn: update.bind(null, fetchStatus.initial),
      successFn: update.bind(null, fetchStatus.success),
      changeStatus: (status) => {
        update(status);
      },
    });

    exportFn({
      downLoadService: async () => {
        return buildRequest({
          url: keywordsExport.apiUrl,
          method: "post",
          data: formatDataTime(downloadData),
          extraConfig: {
            showErrorMessage: true,
          },
        });
      },
      taskUrl: keywordsExport?.taskUrl,
    });
  }
);

export const downLoadWordFileThunk = createAsyncThunk(
  "data/downLoadWordFileData",
  async (data, { getState, dispatch }) => {
    const allState = getState();

    const {
      key,
      activeCode,
      exportFn,
      onGetRequestParams,
      taskUrl,
      exportUrl,
    } = data || {};

    const tableSearchData = allState?.root?.table[key]?.tableSearch;
    const paramsData =
      onGetRequestParams &&
      onGetRequestParams({
        getState,
        tableSearchData: formatTableSearch(tableSearchData),
        activeCode,
      });

    exportFn &&
      exportFn({
        downLoadService: () => {
          return downLoadWordFile({ exportUrl, data: paramsData });
        },
        taskUrl,
      });
  }
);

export const downLoadRelatedFileThunk = createAsyncThunk(
  "data/dDownLoadRelatedFileData",
  async (data, { getState }) => {
    const allState = getState();
    const { key, exportFn } = data || {};
    const state = allState.root;
    const tableSearchData = allState?.root?.table[key]?.tableSearch;
    const paramsData = formatRelatedDataParmas(
      state,
      formatTableSearch(tableSearchData)
    );
    exportFn &&
      exportFn({
        downLoadService: () => {
          return downLoadRelatedFile({
            ...paramsData,
            asin: state?.baseSearch?.asins,
          });
        },
        taskUrl: "/api/web/export/progress/traffic_keywords_freq_keywords",
      });
  }
);

export default RootSlice.reducer;
