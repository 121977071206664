import { Doughnut } from "react-chartjs-2";
import { customToolitp, drawInfo, reset } from "./util";
import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import PlaceHolder from "./placeholderChart.png";
import pieStyle from "./index.module.scss";
import classNames from "classnames";
const doughnutLabelsLine = {
  id: "doughnutLabelsLine",
  defaults: {
    drawTip: false,
    enlarge: {
      ratio: 0.2,
    },
  },
  afterEvent: function (chart, args, options) {
    if (!options.enlarge) {
      return;
    }

    let needUpdate = false;
    if (args.event.type === "mouseout") {
      reset(chart);
      customToolitp({ chart, tooltip: { opacity: 0 } });
      return;
    }
    const originData = chart.config.options.originData;

    var activePoints = chart.getElementsAtEventForMode(
      args.event,
      "nearest",
      { intersect: true },
      false
    );

    if (!activePoints?.length) {
      needUpdate = true;
      const { x, y } = args.event;
      const halfW = chart.width / 2;
      const halfH = chart.height / 2;

      const newX = x - halfW;
      const newY = y - halfH;
      const assistanceArr =
        newX > 0 ? chart.assistanceRight : chart.assistanceLeft;

      let needHide = true;
      for (let i = 0; i < assistanceArr.length; i++) {
        const value = assistanceArr[i];

        const { datasetIndex, index, datapoint, label } = value || {};

        const { x: endX, y: endY } = value?.end || {};
        if (Math.abs(newX - endX) < 4 && Math.abs(newY - endY) < 4) {
          needHide = false;
          datapoint.needUpdate = true;
          datapoint.active = true;
          chart.setActiveElements([
            {
              datasetIndex,
              index,
            },
          ]);
          customToolitp(
            {
              chart,
              tooltip: {
                opacity: 1,
                title: [label],
                body: true,
                needPosition: false,
                _eventPosition: {
                  x,
                  y,
                },
                dataPoints: [
                  {
                    label: label,
                    element: datapoint,
                  },
                ],
              },
            },
            originData
          );
        } else {
          datapoint.needUpdate = false;
          datapoint.active = false;
        }
      }

      if (needHide) {
        customToolitp({ chart, tooltip: { opacity: 0 } });
        chart.setActiveElements([
          {
            datasetIndex: -1,
            index: -1,
          },
        ]);
      }
    }

    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const { outerRadius, innerRadius, originOuterRadius } = datapoint;
        const finalRadius = originOuterRadius || outerRadius;
        if (
          (datapoint.active && activePoints.length > 0) ||
          datapoint.needUpdate
        ) {
          datapoint.active = true;
          datapoint.originOuterRadius = finalRadius;
          datapoint.outerRadius =
            finalRadius + (finalRadius - innerRadius) * options.enlarge?.ratio;
        } else {
          datapoint.outerRadius = finalRadius;
          datapoint.active = false;
        }
      });
    });

    if (needUpdate) {
      chart.render();
    }
  },
  afterUpdate: function (chart, args, options) {
    if (!options.enlarge) {
      return;
    }
    //重置
    reset(chart);
  },
  afterDatasetsDraw: function (chart, args, options) {
    const { ctx } = chart;
    drawInfo({ chart });
    ctx.save();
    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
        const {
          x: xCenter,
          y: yCenter,
          innerRadius,
          outerRadius,
          startAngle,
          endAngle,
          circumference,
        } = datapoint;
        const offset = datapoint.options.offset;

        ctx.save();
        // 平移坐标系
        ctx.translate(xCenter, yCenter);
        const centerAngle = (startAngle + endAngle) / 2;

        ctx.beginPath();
        const fix = 1 - Math.sin(Math.min(Math.PI, circumference || 0));

        const centerOffet = offset / 4;

        const center = {
          x: centerOffet * Math.cos(centerAngle),
          y: centerOffet * Math.sin(centerAngle),
        };

        const lineWidth = (outerRadius - innerRadius) / 3;
        ctx.beginPath();

        ctx.lineWidth = lineWidth;

        ctx.arc(
          center.x,
          center.y,
          innerRadius + centerOffet * fix + lineWidth / 2,
          startAngle,
          endAngle
        );

        ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
        ctx.stroke();
        ctx.closePath();

        ctx.restore();
      });
    });

    ctx.restore();
  },
};

function Pie1({ data = [], backgroundColors, emptyTip = "" }) {
  const sliceData = [...data]?.map((item) => item?.value);
  const sliceLabels = [...data]?.map((item) => item?.name);
  const pieData = {
    labels: sliceLabels,
    originData: data,
    datasets: [
      {
        data: sliceData,
        borderWidth: sliceData.length === 1 ? 0 : 2,
        backgroundColor: backgroundColors,
        outerRadius: [1, 2, 3],
        originData: data,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    transitions: false,
    originData: data,
    plugins: {
      legend: false,
      hoverCrosshair: false,
      p1: false,
      doughnutLabelsLine: {},
      tooltip: {
        enabled: false,
        position: "nearest",
        external: (context, args) => {
          customToolitp(context, data);
        },
      },
    },

    layout: {
      padding: {
        top: 40,
        bottom: 40,
        left: 30,
        right: 30,
      },
    },
  };

  if (!data || (data && data?.length === 0)) {
    return (
      <div className={classNames(pieStyle.emptyWrap, "empty-chart")}>
        <img src={PlaceHolder} alt=""></img>
        <div className={classNames("emptyTextFont", pieStyle.tipText)}>
          {emptyTip}
        </div>
      </div>
    );
  }
  return (
    <Doughnut options={options} data={pieData} plugins={[doughnutLabelsLine]} />
  );
}

export default customWithErrorBoundary(memo(Pie1));
