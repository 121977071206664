import { Box, MenuItem, Select } from "@mui/material";
import { memo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";
import { actionTypes } from "src/constants";
import { actionTypeKey } from "src/constants/form";

function MiddleChildren({
  name,
  className,
  control,
  menuClassName,
  ...otherPorps
}) {
  const { t } = useTranslation();

  return (
    <Box className={className}>
      <Controller
        name={actionTypeKey}
        control={control}
        render={({ field }) => {
          return (
            <Select {...field} {...otherPorps}>
              {actionTypes.map((actionType) => {
                return (
                  <MenuItem
                    value={actionType.value}
                    key={actionType.value}
                    variant="search"
                  >
                    <span className={menuClassName}>
                      {t(`sales.actionType.${actionType.value}`)}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          );
        }}
      />
    </Box>
  );
}

export default customWithErrorBoundary(memo(MiddleChildren));
