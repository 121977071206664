import { memo } from "react";
import customWithErrorBoundary from "../errorBoundary";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateWordFreqDrawerOpen } from "src/store/rootReducer";

function WprdFreqBtn({ disabled = false }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleOpenWordFreq = () => {
    dispatch(updateWordFreqDrawerOpen(true));
  };
  return (
    <div
      className={classNames("button", {
        disabled,
      })}
      onClick={!disabled && handleOpenWordFreq}
    >
      <span className="icon-word-freq">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
      </span>
      <span>{t("terminology.wordFrequency")}</span>
    </div>
  );
}

export default customWithErrorBoundary(memo(WprdFreqBtn));
