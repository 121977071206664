import { useEffect, useRef, useState } from "react";
import { getNumValue } from "src/utils";
import { formatDateToOnlyShowStartYear } from "src/utils/time";

export function usePosition({ nodeRef, data, callback, needLast } = {}) {
  const [showDates, setShowDates] = useState([]);
  const shareNum = data?.length - 1;

  const formatDates = () => {
    const width = nodeRef.current?.offsetWidth;
    const num = Math.floor(width / 100);
    const res = getNumValue({ num, data: data, needLast });
    const showDates = [];
    res?.forEach((date) => {
      const idx = data?.findIndex((item) => item === date);
      const position = idx / shareNum;
      showDates.push({ date, position: position * 100 + "%" });
    });

    const newDates = formatDateToOnlyShowStartYear(showDates);
    callback && callback(newDates);
    setShowDates(newDates);
  };

  useEffect(() => {
    try {
      formatDates();
      window.addEventListener("resize", formatDates, {
        passive: true,
      });

      return () => {
        window.removeEventListener("resize", formatDates);
      };
    } catch (e) {}
  }, [data]);

  return [showDates, setShowDates];
}
