import { MenuItem, Select } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./index.module.scss";
import { times } from "src/constants/time";
import Month from "../picker/month";
import Range from "../picker/day";
import classNames from "classnames";
import useLanguage from "src/hooksOrClass/language";
import { firstLetterUp } from "src/utils";
import { BaseMenuProps } from "src/styles/theme";

const TimeSelect = forwardRef((props, ref) => {
  const { value: PropsValue, onChange, menuClassName } = props;

  const type = PropsValue?.type
    ? `custom${firstLetterUp(PropsValue?.type)}`
    : PropsValue;
  let { t } = useTranslation();
  const { formatLocale } = useLanguage();
  const [open, setIsOpen] = useState(false);
  const [value, setValue] = useState(PropsValue ?? times.week);
  const [activeKey, setActiveKey] = useState(times[type] || times.week);
  const [component, setComponent] = useState(PropsValue?.type ? type : false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = (e) => {
    if (
      e.target.classList.contains("MuiBackdrop-root") &&
      !e.currentTarget.classList.contains("time-select-wrap")
    ) {
      setIsOpen(false);
      // 重制状态
      if (value?.type === "week") {
        setComponent(times.customWeek);
        setActiveKey(times.customWeek);
      } else if (value?.type === "month") {
        setComponent(times.customMonth);
        setActiveKey(times.customMonth);
      } else {
        setActiveKey(value);
        setComponent(false);
      }
    }
  };

  const handleSetValue = (value) => {
    setActiveKey(value);

    if (value === times.customMonth || value === times.customWeek) {
      setComponent(value);
      return;
    }

    setValue(value);
    setComponent(false);
    setIsOpen(false);
  };
  const handleChangeDate = (value) => {
    setIsOpen(false);
    setValue(value);
  };

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  return (
    <div className={classNames(style.timeWrap, "iconCalendarWrap")}>
      <Select
        {...props}
        open={open}
        onOpen={handleClick}
        onClose={handleClose}
        value={value}
        MenuProps={{
          ...BaseMenuProps,
          classes: {
            paper: style.menu, // 自定义类名
          },
        }}
        renderValue={(value) => {
          if (value?.date) {
            return (
              formatLocale(value?.date, { getMonth: value?.type === "month" }) +
              " " +
              t(`time.${value.type}`)
            );
          }
          return t(`timeSelect.${value}`);
        }}
      >
        <div
          className={classNames(style.wrap, menuClassName, "time-select-wrap")}
        >
          <div>
            {Object.keys(times).map((time) => {
              return (
                <MenuItem
                  value={time}
                  key={time}
                  selected={activeKey === time}
                  variant="search"
                  onClick={() => handleSetValue(times[time])}
                >
                  <span>{t(`timeSelect.${time}`)}</span>
                </MenuItem>
              );
            })}
          </div>
          {component && (
            <div className={style.right}>
              <MenuItem
                variant="search"
                sx={{
                  "&:hover": {
                    background: "transparent",
                  },
                }}
              >
                {component === times.customWeek ? (
                  <Range onChange={handleChangeDate} value={value} />
                ) : (
                  <Month onChange={handleChangeDate} value={value} />
                )}
              </MenuItem>
            </div>
          )}
        </div>
      </Select>
      <span className="icon-calendar"></span>
    </div>
  );
});

export default TimeSelect;
