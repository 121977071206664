import { TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { memo, useMemo, useContext } from "react";

import customWithErrorBoundary from "src/components/errorBoundary";

import TableCustomCell from "./memoCell";
import { TableContext } from "./context";

function CustomTableRow({ row, idx, checked, hover }) {
  const { name, columns, handleMouseEnter, handleMouseLeave, dataKey, uniqId } =
    useContext(TableContext);
  const tableData = useSelector((state) => state.root.table[name].tableData);
  const style = useMemo(() => {
    return {
      backgroundColor: hover ? "var(--hover-background)" : "#fff",
    };
  }, [hover]);

  const rowData = useMemo(() => {
    return row;
  }, [tableData]);

  return (
    <TableRow
      role="checkbox"
      tabIndex={-1}
      sx={{
        backgroundColor: hover ? "var(--hover-background)" : "",
      }}
      key={row[uniqId]}
      onMouseEnter={() => {
        handleMouseEnter(row);
      }}
      onMouseLeave={handleMouseLeave}
    >
      {columns.map((column) => {
        return (
          <TableCustomCell
            column={column}
            dataKey={dataKey}
            key={column.id}
            style={style}
            rowData={rowData}
            checked={checked}
            hover={hover}
            idx={idx}
          />
        );
      })}
    </TableRow>
  );
}

export default customWithErrorBoundary(memo(CustomTableRow));
