import { forwardRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
function FlowErrorBoundary(prop) {
  const { fallback, children } = prop;

  const onError = (error) => {};

  return (
    <ErrorBoundary
      fallback={fallback || <div>ErrorBoundary</div>}
      onError={onError}
    >
      {children}
    </ErrorBoundary>
  );
}

export default function customWithErrorBoundary(Compont, { fallback } = {}) {
  try {
    if (Compont?.type?.$$typeof?.toString().includes("forward_ref")) {
      return forwardRef((props, ref) => {
        return (
          <FlowErrorBoundary fallback={props.fallback || fallback}>
            <Compont {...props} ref={ref} />
          </FlowErrorBoundary>
        );
      });
    }
  } catch (e) {}
  return function (props) {
    return (
      <FlowErrorBoundary fallback={props.fallback || fallback}>
        <Compont {...props} />
      </FlowErrorBoundary>
    );
  };
}
