import buildRequest from "src/request/axios";
import { formatAsinArray } from "src/utils";

function formatAsinTrendsData(state) {
  return {
    asins: formatAsinArray(state.baseSearch.asins),
    dimension: state.chartSearch.dimension,
    page: state.chartSearch.pageNum,
    pageSize: state.chartSearch.pageSize,
  };
}

function formatTableData(state, isAsin = true, tableSearchData) {
  const dataPayload = isAsin
    ? {
        asins: formatAsinArray(state.baseSearch.asins),
      }
    : {
        keyword: state.baseSearch.asins,
      };

  return {
    ...dataPayload,
    ...(tableSearchData || {}),
  };
}

export const getTrendsChartData = async (state, signal) => {
  const response = await buildRequest({
    url: `/api/web/bought/listing/trends`,
    method: "post",
    data: formatAsinTrendsData(state),
    extraConfig: {
      signal,
    },
  });

  return response;
};

export const getTableFetchData = async (
  state,
  signal,
  isAsin = true,
  tableSearchData
) => {
  const url = isAsin ? `/api/web/bought/listing` : `/api/web/bought/keyword`;

  const response = await buildRequest({
    url,
    method: "post",
    data: formatTableData(state, isAsin, tableSearchData),
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downloadTrendsData = async (state) => {
  const response = await buildRequest({
    url: `/api/web/export/submit/product_sales_trend_asin`,
    method: "post",
    data: formatAsinTrendsData(state),
    extraConfig: {
      showErrorMessage: true,
    },
  });

  return response;
};

export const downloadTableData = (state, isAsin = true, tableSearchData) => {
  const url = isAsin
    ? `/api/web/export/submit/product_sales_asin`
    : `/api/web/export/submit/product_sales_keyword`;

  return buildRequest({
    url,
    method: "post",
    data: formatTableData(state, isAsin, tableSearchData),
    extraConfig: {
      showErrorMessage: true,
    },
  });
};
