import { memo } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import useLanguage from "src/hooksOrClass/language";
import ChartWrap from "src/components/chart/wrap";
import { getPlaceHolderData } from "src/utils";
import { getFormatWeek } from "src/utils/time";
import { position } from "src/constants";

const colors = ["#222222", "#C3A7D0", "#714C83"];

const getOptions = ({ originData, format }) => ({
  maintainAspectRatio: false,

  responsive: false,
  aspectRatio: 1,
  animation: false,
  plugins: {
    legend: false,
    tooltip: {
      enabled: false,
      position: "nearest",
    },
    hoverCrosshair: {
      position: position.vertical,
      format: (value, data, idx) => {
        console.log("value", value, data);
        if (!value) {
          return getPlaceHolderData(value);
        }
        const asin = data?.label;
        const currenAllData = originData?.[asin];
        const currentData = currenAllData[idx];
        return `${currentData?.rank} (${currentData?.position})`;
      },
      formatTitle: format,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      reverse: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
      border: {
        display: false,
        dash: [4, 4],
      },
    },
  },
  layout: {
    padding: {
      bottom: 18,
    },
  },
});

function RankChart({
  rowData,
  name,
  code,
  baseConfig = {},
  width = 100,
  height = 104,
}) {
  const ranks = rowData?.[code];
  const { autoFormatLocale } = useLanguage();
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  if (!ranks) {
    return <CellEmpty value={ranks} />;
  }

  const options = getOptions({ originData: ranks, format: autoFormatLocale });

  const lineData = {
    labels: tableData?.ogSpRankDates,

    datasets: Object.keys(ranks)?.map((key, idx) => {
      const color = colors[idx % colors.length];
      return {
        data: ranks[key]?.map((item) => item?.rank || item),
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        pointHoverRadius: 3,
        originPointRadius: 1,
        pointStyle: "circle",
        label: key,
        borderColor: color,
        showLine: true,
        ...baseConfig,
      };
    }),
  };

  return (
    <>
      <ChartWrap>
        <Line options={options} data={lineData} width={width} height={height} />
      </ChartWrap>
    </>
  );
}

export default customWithErrorBoundary(memo(RankChart));
