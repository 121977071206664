import { Link, redirect, useRouteError } from "react-router-dom";
import Layouts from "../layouts";
import Sales from "../pages/sales";

import classNames from "classnames";
import Structure from "src/pages/structure";
import Keywords from "src/pages/keywords";
import AdStrategy from "src/pages/adStrategy";
import History from "src/pages/history";
import KeywordsGap from "src/pages/keywordsGap";
function ErrorFallback() {
  let error = useRouteError();

  return (
    <div role="alert">
      <p>
        Something
        <hr />
        1
        <hr />2
      </p>
    </div>
  );
}
export const routes = [
  {
    element: <Layouts />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "/",
        loader: async () => redirect("/sales"),
      },
      {
        path: "/sales",
        showInSidebar: "sales",
        id: "sales",
        title: "sales",
        children: [
          {
            showInSidebar: "productSales",
            index: true,
            element: <Sales />,
            id: "productSales",
          },
        ],
      },
      {
        path: "/traffic",
        showInSidebar: "traffic",
        id: "traffic",
        title: "traffic",
        children: [
          {
            path: "structure",
            showInSidebar: "trafficStructure",
            index: true,
            element: <Structure />,
            id: "trafficStructure",
          },
          {
            path: "keywords",
            showInSidebar: "trafficKeywords",
            element: <Keywords />,
            id: "trafficKeywords",
          },
          {
            path: "keywordsGap",
            showInSidebar: "trafficKeywordsGap",
            element: <KeywordsGap />,
            id: "trafficKeywordsGap",
          },
        ],
      },
      {
        path: "/ad",
        showInSidebar: "ad",
        id: "ad",
        title: "ad",
        children: [
          {
            path: "",
            showInSidebar: "adStrategy",
            index: true,
            element: <AdStrategy />,
            id: "adStrategy",
          },
        ],
      },
      // {
      //   path: "/trafficHistory",
      //   showInSidebar: "trafficHistory",
      //   id: "history",
      //   title: "trafficHistory",
      //   children: [
      //     {
      //       path: "",
      //       showInSidebar: "trafficHistory",
      //       index: true,
      //       element: <History />,
      //       id: "trafficHistory",
      //     },
      //   ],
      // },
      // {
      //   path: "about",
      //   showInSidebar: "关于我们",
      //   title: "关于我们",
      //   loader: function () {
      //     const promise = new Promise((reslove, reject) => {
      //       setTimeout(() => {
      //         reslove("哈喽");
      //       }, 4000);
      //     });

      //     return defer({
      //       data: promise,
      //     });
      //   },
      //   element: <About />,
      // },
    ],
  },
  {
    element: <Layouts />,
    errorElement: <ErrorFallback />,
    children: [
      {
        path: "/upgrade",

        icon: function Icon({ className }) {
          return (
            <span className={classNames(className, "icon-upgrade-new")}></span>
          );
        },
        showInSidebar: "upgrade",
        title: "upgrade",
        id: "upgrade",
        element: <div>upgrade</div>,
      },
      {
        path: "/resources",
        icon: function Icon({ className }) {
          return (
            <span className={classNames(className, "icon-resources")}></span>
          );
        },
        showInSidebar: "resources",
        title: "resources",
        id: "resources",
        element: <div>Resources</div>,
      },
      {
        path: "/extension",
        icon: function Icon({ className }) {
          return (
            <span
              className={classNames(className, "icon-extensionsvg")}
              style={{ fontSize: "18px" }}
            ></span>
          );
        },
        showInSidebar: "extension",
        title: "extension",
        id: "extension",
        element: <div>Extension</div>,
      },
    ],
  },
  {
    path: "*",
    element: (
      <div>
        你似乎迷路了吧
        <Link to="/" replace>
          回到主页
        </Link>
        <div></div>
      </div>
    ),
  },
];
