export const color = "green";

export const fetchStatus = {
  initial: "initial",
  loading: "loading",
  success: "success",
  error: "error",
};

export const startTime = "2020-12-01";

export const position = {
  horizontal: "horizontal",
  vertical: "vertical",
  mouse: "mouse",
};

export const storageShrinkedKey = "shrinked";
export const storageViewAdModalKey = "viewAdModal";

export const actionTypeEnum = {
  asin: "asin",
  keyword: "keyword",
};

export const actionTypes = [
  {
    value: actionTypeEnum.asin,
  },
  {
    value: actionTypeEnum.keyword,
  },
];

export const sortByEnum = {
  // 升序
  asc: "asc",
  // 将序
  desc: "desc",
};

export const dimensionEnum = {
  asin: "asin",
};

export const listingAsinType = {
  LISTING_ASIN: "LISTING_ASIN",
  LISTING_PASIN: "LISTING_PASIN",
  SINGLE_ASIN: "SINGLE_ASIN",
};
