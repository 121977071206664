import { useSelector } from "react-redux";
import { memo, useCallback, useMemo, useContext } from "react";

import CustomCheckbox from "src/components/customCheckbox";
import CellEmpty from "src/components/table/cellEmpty";
import CustomCell from "src/components/table/cell";
import classNames from "classnames";
import { TableContext } from "./context";

function Children({ column, row, checked, hover, idx, dataKey }) {
  // 这里使用memoPage 而不是直接使用page是因为直接从仓库里面取数据时候，只要页码切换，数据还没有请求回来就会导致重新渲染
  const { name, handleSelectClick, memoPage } = useContext(TableContext) || {};
  const tableData = useSelector((state) => state.root.table[name].tableData);
  const showAllChecked = useSelector(
    (state) => state.root.table[name].showAllChecked
  );

  const pageSize = useSelector(
    (state) => state.root.table[name].tableSearch.pageSize
  );
  const checkStyle = useMemo(() => {
    return { "& .MuiSvgIcon-root": { fontSize: 20 } };
  }, []);

  const handleSelectAllClickCheckBox = useCallback(
    (e) => {
      handleSelectClick(e, row);
    },
    [row]
  );

  const showCheckbox = showAllChecked || hover;

  if (column.id === "checkbox") {
    return (
      <div className={classNames({ hide: !showCheckbox })}>
        <div className={classNames({ hide: checked })}>
          <CustomCheckbox
            color="secondary"
            sx={checkStyle}
            onChange={handleSelectAllClickCheckBox}
            checked={false}
          />
        </div>
        <div className={classNames({ hide: !checked })}>
          <CustomCheckbox
            color="secondary"
            sx={checkStyle}
            onChange={handleSelectAllClickCheckBox}
            checked={true}
          />
        </div>
      </div>
    );
  }

  if (column.render) {
    return column.render({
      row,
      idx,
      hover,
      last: idx + 1 === tableData?.[dataKey].length,
      page: memoPage,
      pageSize,
    });
  }
  const value = row[column.id];

  return (
    <CellEmpty
      value={
        column.format && typeof value === "number"
          ? column.format(value)
          : value
      }
    />
  );
}

export const MemoChildren = memo(Children);

function TableCustomCell({
  column,
  style,
  rowData,
  checked,
  hover,
  idx,
  dataKey,
}) {
  const children = useMemo(() => {
    return (
      <MemoChildren
        column={column}
        row={rowData}
        checked={checked}
        hover={hover}
        idx={idx}
        dataKey={dataKey}
      />
    );
  }, [column, rowData, hover, idx, dataKey].concat(column.id === "checkbox" ? [checked] : []));

  return (
    <CustomCell column={column} style={style} key={column.id} row={rowData}>
      {children}
    </CustomCell>
  );
}

export default TableCustomCell;
