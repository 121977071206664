import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";

function Start({ data }) {
  return (
    <div>
      <div>{data.date}开启广告活动</div>
      <div>投放：多广告组，多变体</div>
    </div>
  );
}

export default customWithErrorBoundary(memo(Start));
