import { memo } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import { Bar } from "react-chartjs-2";
import { getOptions } from "./options";

function TimeHistoryChart() {
  const chartData = useSelector((state) => state.history.chartData);
  const data = {
    labels: chartData.date,

    datasets: Object.keys(chartData)
      ?.filter((key) => key !== "date" && key !== "totalScore")
      ?.map((key) => {
        return {
          label: key,
          type: "bar",
          data: chartData[key]?.map((item) => item.value),
          stack: "stack-0",
        };
      }),
  };

  function onClick(event, elements) {
    console.log("evnet", event, elements);
    if (elements.length > 0) {
      const clickedElement = elements[0];
      const index = clickedElement?.index;
      const date = chartData.date[index];
      console.log("date", date);
    }
  }

  const options = getOptions({ onClick });

  return (
    <div style={{ height: "400px" }}>
      <Bar data={data} options={options} />
    </div>
  );
}

export default customWithErrorBoundary(memo(TimeHistoryChart));
