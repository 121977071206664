import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import AdList from "./components/list";
import { adFormatFreqTableParmas } from "./util";
import Search from "./components/search";
import { AdStrategyContext } from "./context";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdListApi,
  getAdOverviewApi,
  getCampaignsAndAsinsOptionsApi,
} from "./service";
import { asinsKey } from "src/constants/form";
import {
  autoSetTrafficTime,
  getOverviewsThunk,
  resetDataSearch,
  resetStore,
  syncBaseSearch,
  updateCampaignsAndAsinsOptions,
  updateTrafficTime,
} from "./store";
import DataSearch from "./components/dataSearch";
import useTableAndRegister from "src/hooksOrClass/table";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import DrawWordFrequency from "src/components/drawWordFrequency";
import BoxLoading from "src/components/loading/box";

import {
  resetIncludeKeyTableData,
  updateGlobalLoading,
} from "src/store/rootReducer";
import DrawDetailModal from "./components/drawDetailModal";
import DrawVolumeTrend from "src/components/drawVolumeTrend";
import { isLoading } from "src/utils";
import { useTop } from "src/hooksOrClass/top";
import useResetLoading from "src/hooksOrClass/resetLoading";
import Timeline from "./components/timeline";

function AdStrategy() {
  const [id, name] = useRouterId();
  useTop();
  useResetLoading();
  useRegisterTableState({ key: name });
  const timeLineStatus = useSelector(
    (state) => state.adStrategy.overviewsFetchStatus
  );
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );
  const firstRef = useRef(true);
  const optionsControllerRef = useRef();

  const request = ({ getState, tableSearchData, tableController }) => {
    const currentState = getState().adStrategy;

    return getAdListApi({
      state: currentState,
      tableSearchData,
      signal: tableController.signal,
    });
  };

  const callback = (tableData) => {
    const startDate = tableData.dates?.[tableData.dates?.length - 1];
    dispatch(autoSetTrafficTime({ startDate }));
  };

  const [fetchTable] = useTableAndRegister({ name, request, callback });
  const dispatch = useDispatch();
  const onSubmit = useCallback(async (formatFormValues) => {
    try {
      formatFormValues && dispatch(syncBaseSearch(formatFormValues));
      formatFormValues && dispatch(updateGlobalLoading(true));
      const controller = optionsControllerRef.current;
      if (controller && controller.abort) {
        controller.abort();
      }
      const newController = new AbortController();
      optionsControllerRef.current = newController;

      if (!firstRef.current) {
        dispatch(resetDataSearch());
      } else {
        firstRef.current = false;
      }

      formatFormValues[asinsKey] &&
        getCampaignsAndAsinsOptionsApi({
          data: {
            asin: formatFormValues[asinsKey],
          },
          signal: newController.signal,
        }).then((res) => {
          dispatch(updateCampaignsAndAsinsOptions(res));
        });

      dispatch(getOverviewsThunk());
      fetchTable({ resetSortKey: true });
    } catch (e) {}
  }, []);

  const value = useMemo(
    () => ({
      tableKey: name,
      onSubmit,
      fetchTable,
    }),
    [onSubmit, fetchTable, name]
  );

  useEffect(() => {
    // onSubmit && onSubmit({});

    return () => {
      dispatch(resetStore());
      dispatch(resetIncludeKeyTableData(name));
    };
  }, []);

  const onGetRequestParams = useCallback(
    ({ getState, tableSearchData, activeCode }) => {
      return adFormatFreqTableParmas({
        state: getState()?.adStrategy,
        tableSearchData,
        activeCode,
      });
    },

    []
  );

  return (
    <AdStrategyContext.Provider value={value}>
      <Search />

      <BoxLoading>
        <BoxLoading isLoading={isLoading(timeLineStatus)}>
          <Timeline />
        </BoxLoading>
        <DataSearch />
        <BoxLoading isLoading={isLoading(tableFetchStatus)} fixed>
          <AdList />
        </BoxLoading>
      </BoxLoading>
      {/* <AdList /> */}
      <DrawDetailModal />
      <DrawVolumeTrend />
      <DrawWordFrequency
        onGetRequestParams={onGetRequestParams}
        url="/api/web/ad_strategy/detail/freq"
        exportUrl="/api/web/export/submit/ad_strategy_freq_keywords"
        taskUrl="/api/web/export/progress/ad_strategy_freq_keywords"
      />
    </AdStrategyContext.Provider>
  );
}

export default customWithErrorBoundary(memo(AdStrategy));
