import { Popover } from "@mui/material";
import { memo, useContext, useEffect, useState } from "react";
import { IconDropdown, useDayPicker } from "react-day-picker";
import customWithErrorBoundary from "src/components/errorBoundary";
import { HeadContext } from "./context";
import classNames from "classnames";

function Dropdown({
  children,
  name,
  value,
  className,
  style,
  onChange,
  caption,
  containerId,
  month,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { activeKey, setActiveKey } = useContext(HeadContext);
  const open = Boolean(anchorEl);
  const dayPicker = useDayPicker();

  const id = "simple-popover";

  const reset = () => {
    if (activeKey === name) {
      setActiveKey("");
    }
    setAnchorEl(null);
  };

  const handleClick = (e, a) => {
    if (e.target.value) {
      onChange(e);
      reset();
    }
  };
  const handleOpen = (e) => {
    if (!anchorEl) {
      setAnchorEl(e.currentTarget);
      setActiveKey(name);
    } else {
      reset();
    }
  };

  useEffect(() => {
    if (activeKey !== name && anchorEl) {
      setAnchorEl(null);
    }
  }, [activeKey, anchorEl]);

  useEffect(() => {
    anchorEl && setAnchorEl(null);
  }, [month.getTime()]);

  return (
    <div className={className} style={style}>
      <div
        className={classNames(dayPicker.classNames.caption_label, {
          active: open,
        })}
        style={dayPicker.styles.caption_label}
        aria-hidden="true"
        onClick={handleOpen}
      >
        {caption}
        <span
          className={classNames(dayPicker.classNames.dropdown_icon, {
            "icon-pull-down": !open,
            "icon-pull-up": open,
          })}
          style={{
            ...(dayPicker.styles.dropdown_icon || {}),
            fontSize: "10px",
          }}
        ></span>
      </div>
      <Popover
        id={id}
        open={open}
        onClick={handleClick}
        anchorEl={anchorEl}
        container={() => {
          return document.querySelector(`[aria-labelledby="${containerId}"]`);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className={`popWrap-${name}`} tabIndex={0}>
          {children}
        </div>
      </Popover>
    </div>
  );
}

export default customWithErrorBoundary(memo(Dropdown));
