import { memo } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import Keyword from "src/components/table/components/keyword";

function PageKeyword({ rowData }) {
  const time = useSelector((state) => state.keywords?.baseSearch?.time);

  return <Keyword rowData={rowData} time={time} />;
}

export default customWithErrorBoundary(memo(PageKeyword));
