import { Checkbox } from "@mui/material";
import { ReactNode, memo } from "react";
import { ReactComponent as UncheckedIcon } from "./Unchecked.svg";
import { ReactComponent as CheckedIcon } from "./checked.svg";
import { ReactComponent as IndeterminateIcon } from "./indeterminateIcon.svg";
import customWithErrorBoundary from "../errorBoundary";
interface Props {
    icon: ReactNode
    sx?: React.CSSProperties;
}

function CustomCheckbox(props: Props) {
    return <Checkbox {...props}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        indeterminateIcon={<IndeterminateIcon />}
        sx={{
            '&:hover': {
                backgroundColor: 'transparent',  // 覆盖默认的hover样式
            },
            ...(props.sx || {})
        }}
    />
}

export default customWithErrorBoundary(memo(CustomCheckbox));