import CellEmpty from "src/components/table/cellEmpty";
import SerialNumber from "src/components/table/components/serialNumber";
import ViewDetail from "./viewDetail";

export const getColumns = () => {
  return [
    {
      id: "#",
      width: 30,
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "adId",
      width: 90,
      align: "center",
      render: function ({ row }) {
        return <ViewDetail value={row?.displayAdId} rowData={row} />;
      },
    },
    {
      id: "targetVariant",
      width: 100,
      align: "left",
      render: function ({ row }) {
        return <CellEmpty value={row?.asin} />;
      },
    },
    {
      id: "target",
      width: 100,
      align: "left",
      render: function ({ row }) {
        return <CellEmpty value={row?.targetKeyword} />;
      },
    },
    {
      id: "matchType",
      width: 80,
      align: "left",
      render: function ({ row }) {
        return <CellEmpty value={row?.matchType} />;
      },
    },
    {
      id: "searchTerms",
      width: 100,
      align: "right",
      render: function ({ row }) {
        return <ViewDetail value={row?.keywordCount} rowData={row} />;
      },
    },
  ];
};
