import {
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import customWithErrorBoundary from "../errorBoundary";
import style from "./index.module.scss";
import SimpleTooltip from "../tooltip/simpleTooltip";
import React, { ReactNode, useState } from "react";
import classNames from 'classnames';
import { openNewUrl } from "src/utils";


interface Option {
  title: string;
  getParamsLink: () => string;
  linkUrl?: string;
  action?: () => void;
  style?: React.CSSProperties;
}

interface Props {
  defaultConfig: ReactNode | Option;
  options: Option[];
  headIcon: ReactNode;
  click: boolean;
  disabled: boolean;
  noNeedMoreIcon?: boolean;
}

function CustomSelect({ defaultConfig, options = [], headIcon = <span className="icon-skip"></span>, click = false, disabled = false, noNeedMoreIcon = false }: Props) {
  // 这个值只会在点击触发的场景下进行应用
  const [open, setOpen] = useState(false);
  const disabledListen = (click && !open) || disabled;

  const onOpen = () => {
    if (!click) return;
    setOpen(true);
  };

  const onClose = () => {
    if (!click) return;
    setOpen(false);
  };

  const openProps = click
    ? {
      open,
      PopperProp: {
        disablePortal: true,
      },
      onClose: onClose,
    }
    : {};

  const WrapCompent = ({ children }: any) => {
    if (click) {
      return <ClickAwayListener onClickAway={onClose}>
        {children}
      </ClickAwayListener>
    }

    return <div>{children}</div>
  }



  const handleClick = (option: Option, cb: () => void) => {
    cb && cb();
    openNewUrl(option?.linkUrl)

    option.action && option.action();
  }

  const handleWrapClick = () => {
    if (disabled) {
      return;
    }

    if (click) {
      onOpen();
      return;
    };
    handleClick(defaultConfig as Option, onOpen)
  }

  return (
    <WrapCompent>
      <div style={{ cursor: "pointer" }} className="textFont">
        <SimpleTooltip
          {...openProps}
          disableFocusListener={disabledListen}
          disableHoverListener={disabledListen}
          disableTouchListener={disabledListen}
          tooltipSx={{ padding: 0 }}
          title={
            <Paper className={style.option} sx={{ marginTop: 0 }}>
              <MenuList>
                {options.map((item) => {
                  return (
                    <MenuItem
                      value={item.title}
                      key={item.title}
                      onClick={() => handleClick(item, onClose)}
                    >
                      {headIcon}
                      <div className={classNames("textFont", style.title)}>{item.title}</div>
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          }
        >
          <div className={classNames(style.selectWrap, { "disabled": disabled })} onClick={handleWrapClick} style={(defaultConfig as Option).style || {}}>
            {React.isValidElement(defaultConfig) ? defaultConfig : <>
              {headIcon}
              <div className={style.content}>{(defaultConfig as Option).title}</div>
              {!noNeedMoreIcon && (
                <span className="icon-more-icon-1"></span>
              )}

            </>}

          </div>
        </SimpleTooltip>
      </div>
    </WrapCompent>
  );
}

export default customWithErrorBoundary(CustomSelect);
