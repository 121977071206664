import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import { OutlinedInput, Box, Tooltip } from "@mui/material";

import CountrySelect from "src/components/countrySelect";

import style from "./index.module.scss";
import Message from "../message";
import { getCountry, getDefaultLinkInfo, linkToNewUrl } from "src/utils";
import { countryKey } from "src/constants/country";
import variables from "src/styles/_var.scss";
import { actionTypeEnum } from "src/constants";
import customWithErrorBoundary from "../errorBoundary";
import Example from "./components/example";
import { getExamplesData, getSearchRecord } from "src/service";
import Record from "./components/record";
import AsinTip from "./components/asinTip";
import { validatePasinApi } from "./service";
import { searchWrapClass } from "./constant";
import { actionTypeKey, asinsKey } from "src/constants/form";

function Search({
  // 默认支持多个asin以为逗号分隔
  multipleAsin = true,
  baseSearchValues = {},
  onSubmit,
  MiddleChildren,
  MiddleName,
  validateRule,
  placeholder,
  fetchNames = [],
  defaultActionType,
  minCount = 1,
  maxCount = 10,
}) {
  const [records, setRecords] = useState({});
  const [amazonExample, setAmazonExample] = useState({});
  const [isFocused, setIsFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [tipAsins, setTipAsins] = useState("");
  const exampleAbortControllerRef = useRef({});
  const recordAbortControllerRef = useRef({});

  const { t } = useTranslation();
  const ref = useRef(false);

  const info = getDefaultLinkInfo();

  const defaultValues = {
    ...baseSearchValues,
    ...info,
    [countryKey]: getCountry(),
  };

  const { control, watch, getValues, setValue } = useForm({
    defaultValues,
  });

  const actionTypeValue = watch(actionTypeKey) || defaultActionType;

  useEffect(() => {
    if (!ref.current) {
      ref.current = true;
      return;
    }
    setValue(asinsKey, "");
  }, [actionTypeValue]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const asinValue = watch(asinsKey);
  const country = watch(countryKey);
  const countryChangeRef = useRef(false);
  const asinInputRef = useRef();

  useEffect(() => {
    if (countryChangeRef.current) {
      linkToNewUrl(countryKey, country);
    }

    countryChangeRef.current = true;
  }, [country]);

  const inputPlaceholder =
    placeholder ||
    (actionTypeValue === actionTypeEnum.keyword
      ? t("inputPlaceHolder.keyword")
      : t("inputPlaceHolder.asin"));

  useEffect(() => {
    if (asinInputRef.current) {
      // 如果输入框有值，则title为value，否则为placeholder
      const titleValue = asinValue ? asinValue : inputPlaceholder;
      asinInputRef.current.setAttribute("title", titleValue);
    }
  }, [inputPlaceholder, asinValue]);

  const handleSubmit = async (e) => {
    // 为tru表示表单验证通过
    const formValues = getValues();
    //匹配零宽度字符
    const regex = /[\u200B-\u200D\uFEFF]/g;
    //匹配空格
    // const emptyRegex = /[\u00A0\u1680\u180E\u2000-\u200A\u202F\u205F\u3000]/g;

    const asins = [
      ...new Set(
        formValues.asins
          .trim()
          .replaceAll(regex, "")
          .replaceAll(/\s+/g, " ")
          .replaceAll("，", ",")
          .split(",")
          .map((item) => item.trim().toLocaleUpperCase())
          .filter((item) => item)
      ),
    ];
    let needNextValid = true;

    if (!multipleAsin) {
      if (asins.length > 1) {
        Message.warning(t(`rulesTip.asinMaxCount`));
        return;
      }
    }

    if (validateRule) {
      const { result, nextVailde } = validateRule(formValues, asins) || {};

      if (nextVailde !== undefined) {
        needNextValid = nextVailde;
      }

      if (!result) {
        return;
      }
    }

    if (needNextValid) {
      const asinValue = asins.join(",");

      setValue(asinsKey, asinValue);

      if (asins.length === 0) {
        Message.warning(t("emptyTip.asin"));
        return;
      }

      const reg = /^[A-Z0-9a-z]{10}$/;

      if (asins.length > 0 && asins.some((item) => !reg.test(item))) {
        Message.warning(t("rulesTip.asin"));

        return;
      }

      if (asins.length < minCount) {
        Message.warning(t("rulesTip.asinsMinCount", { count: minCount }));

        return;
      }

      if (asins.length > maxCount) {
        Message.warning(t("rulesTip.asinsMaxCount", { num: maxCount }));
        return;
      }
    }

    const formatFormValues = getValues();
    setTipAsins("");

    if (asins.length > 1) {
      validatePasinApi(asins)
        .then((data) => {
          if (Array.isArray(data)) {
            setTipAsins(data?.join(","));
          }
        })
        .catch(() => {
          setTipAsins("");
        });
    }

    onSubmit && onSubmit(formatFormValues);
  };

  useEffect(() => {
    // asin must be existed
    if (info && asinValue) {
      handleSubmit();
    }
  }, []);

  const handleReset = () => {
    setValue(asinsKey, "");
  };

  const handleSelectAsin = useCallback((info) => {
    const content = info?.searchVal;
    setValue(asinsKey, content);

    handleSubmit();
  }, []);

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setIsFocused(false);
      handleSubmit();
      asinInputRef.current?.blur();
    }
  };

  const handleExample = useCallback((asin) => {
    setValue(asinsKey, asin);
    handleSubmit();
  }, []);

  const getPageSearchRecord = async () => {
    try {
      const res = await Promise.allSettled(
        fetchNames?.map((item) => {
          try {
            const controller = new AbortController();
            recordAbortControllerRef.current[item.key] = controller;
            return getSearchRecord(item?.key, controller.signal);
          } catch (e) {
            return item;
          }
        })
      );
      const records = {};

      res.forEach((item, idx) => {
        records[fetchNames[idx]?.formatKey] = item?.value;
      });
      setRecords(records);
    } catch (e) {}
  };

  const getAmazonExample = async () => {
    try {
      const res = await Promise.allSettled(
        fetchNames?.map((item) => {
          try {
            const controller = new AbortController();
            exampleAbortControllerRef.current[item.key] = controller;
            return getExamplesData(item.key, controller.signal);
          } catch (e) {
            return item;
          }
        })
      );

      const example = {};
      res.forEach((item, idx) => {
        example[fetchNames[idx]?.formatKey] = item.value?.examples;
      });

      setAmazonExample(example);
    } catch (e) {}
  };

  useEffect(() => {
    getAmazonExample();
    getPageSearchRecord();
    return () => {
      try {
        Object.keys(exampleAbortControllerRef.current ?? {})?.forEach((key) => {
          const controller = exampleAbortControllerRef.current?.[key];
          controller?.abort();
        });
        Object.keys(recordAbortControllerRef.current ?? {})?.forEach((key) => {
          const controller = recordAbortControllerRef.current?.[key];
          controller?.abort();
        });
      } catch (e) {}
    };
  }, []);

  const handleClose = useCallback(() => {
    setTipAsins("");
  }, []);

  return (
    <>
      <Box
        className={classNames(style.searchForm, "searchTextFont")}
        boxShadow={
          isFocused || open ? "0px 2px 8px 2px rgba(218,218,218,0.6);" : "none"
        }
      >
        <form>
          <Box className={style.countryWrap}>
            <Controller
              name={countryKey}
              control={control}
              render={({ field }) => {
                return (
                  <CountrySelect
                    {...field}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    className="searchTextFont"
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                );
              }}
            />
          </Box>
          {MiddleChildren && (
            <MiddleChildren
              className={classNames(style[MiddleName])}
              menuClassName="searchTextFont"
              control={control}
              watch={watch}
              handleSubmit={handleSubmit}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              sx={{
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "secondary.main",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderLeft: "1px solid",
                  borderRadius: 0,
                  borderColor: "secondary.main",
                },
                "& .MuiList-root": {},
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderWidth: 1,
                  borderColor: "secondary.main",
                },
              }}
            />
          )}

          <Box
            className={classNames(style.asinWrap, searchWrapClass, {
              [style.canReset]: true,
            })}
          >
            <Controller
              name={asinsKey}
              control={control}
              render={({ field }) => (
                <Fragment>
                  <OutlinedInput
                    {...field}
                    autoComplete="off"
                    onKeyDown={handleSearch}
                    inputRef={asinInputRef}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiInputBase-input": {
                        padding: `16.5px  ${variables.selectMenuHor}`,
                      },
                    }}
                    onFocus={handleFocus}
                    onBlur={() => {
                      setTimeout(() => {
                        setIsFocused(false);
                      }, 200);
                    }}
                    placeholder={inputPlaceholder}
                  />

                  <span
                    className={classNames(style.icon, {
                      "icon-delete": asinValue,
                    })}
                    onClick={handleReset}
                  ></span>
                </Fragment>
              )}
            />

            {isFocused && records?.[actionTypeValue]?.length > 0 && (
              <div className={style.recodWrap}>
                <Record
                  records={records}
                  actionTypeValue={actionTypeValue}
                  handleSelectAsin={handleSelectAsin}
                />
              </div>
            )}
          </Box>
          <div
            className={classNames(style.searchIcon, "searchBtnFont")}
            onClick={handleSubmit}
          >
            {t("globalBtnType.search")}
          </div>
        </form>
      </Box>
      {tipAsins && <AsinTip tipAsins={tipAsins} onClose={handleClose} />}
      {amazonExample?.[actionTypeValue] && (
        <Example
          amazonExample={amazonExample?.[actionTypeValue]}
          onClick={handleExample}
        />
      )}
    </>
  );
}

export default customWithErrorBoundary(Search);
