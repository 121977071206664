import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "../../index.module.scss";
import classNames from "classnames";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import Title from "./title";
import { formatDateToOnlyShowStartYear } from "src/utils/time";
import variables from "../../_var.scss";

function BubbleChart({
  data = {},
  activeDate = "",
  onCircleClick,
  displayAdId,
}) {
  let max = Math.max(...(data?.wordCounts || []));
  let min = Math.min(...(data?.wordCounts || []));
  const newDates = formatDateToOnlyShowStartYear(data?.dates);

  return (
    <div className={style.bubble}>
      <div className={style.pointAt}>
        <span className="icon-pointAt"></span>
      </div>
      {newDates?.map((dateInfo, index) => {
        const { date, showDate, year } = dateInfo;
        const value = data?.wordCounts[index];
        let size = 0;
        if (value === 0) {
          size = 0;
        } else {
          const gap = max - min;
          size = (gap === 0 ? 1 : (value - min) / (max - min)) * 100 + 3;
          const maxSize = parseInt(variables.baseBubbleWrapSize);
          const coefficient = (maxSize - 10) / 103;
          size = coefficient * size + 4;
        }
        const active = activeDate === date;

        return (
          <div
            className={style.baseWrap}
            key={dateInfo.date}
            style={{ zIndex: parseInt(100 - size) }}
          >
            <div
              className={classNames(style.circleWrap, {
                [style.active]: active,
              })}
            >
              <div className={classNames(style.dashedLine)}></div>
              {size ? (
                <SimpleTooltip
                  tooltipClass={style.circleTooltip}
                  title={
                    <Title
                      value={value}
                      date={date}
                      displayAdId={displayAdId}
                      active={active}
                    />
                  }
                >
                  <div
                    onClick={() => {
                      onCircleClick && onCircleClick(date);
                    }}
                    className={classNames(style.circle)}
                    style={{
                      width: `${size}px`,
                      height: `${size}px`,
                    }}
                  ></div>
                </SimpleTooltip>
              ) : null}
            </div>
            <div className={classNames(style.line)}></div>

            <div className={classNames(style.sawtooth)}></div>
            <div className={style.axisInfoHorText}>
              <div>{showDate}</div>
              <div>{year}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default customWithErrorBoundary(memo(BubbleChart));
