import { useDispatch, useSelector } from "react-redux";
import Search from "src/components/search";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { useContext, useEffect, useMemo, memo } from "react";

import { useTranslation } from "react-i18next";
import getTimeComponent from "src/components/search/components/time";
import customWithErrorBoundary from "src/components/errorBoundary";

import { updateBaseTime } from "../../store";
import { StructureContext } from "../../context";
function StructureSearch() {
  const { t } = useTranslation();
  const baseSearchValues = useSelector((state) => state.structure.baseSearch);
  const [routerId, name] = useRouterId();
  const { onSubmit } = useContext(StructureContext);
  const dispatch = useDispatch();

  const defaultActionType = "structure";
  const fetchNames = useMemo(() => {
    return [{ key: `${name}`, formatKey: defaultActionType }];
  }, [name]);

  const handleSubmit = async (formatFormValues) => {
    if (formatFormValues?.asins !== baseSearchValues.asins) {
    }

    onSubmit && onSubmit(formatFormValues);
  };

  const middleChildren = getTimeComponent({
    baseSearchValues,
    callback: (time) => {
      dispatch(updateBaseTime(time));
    },
  });

  return (
    <Search
      baseSearchValues={baseSearchValues}
      fetchNames={fetchNames}
      defaultActionType={defaultActionType}
      onSubmit={handleSubmit}
      MiddleChildren={middleChildren}
      MiddleName="time"
    />
  );
}

export default customWithErrorBoundary(memo(StructureSearch));
