import { columnTypeEnum } from "../../constant";
import CellEmpty from "src/components/table/cellEmpty";
import SerialNumber from "src/components/table/components/serialNumber";

import tableVariables from "src/styles/_table.scss";

import { formatNumber, formatSalesCount } from "src/utils";

import AsinInfo from "./asinInfo";
import Action from "./action";
import SalesChart from "src/components/table/components/salesChart";

import { getPrice } from "src/utils/amazon";
import Attributes from "./attributes";
import VAImage from "src/components/table/components/VAImage";

const keyWordOrAsinsMidColumns = [
  {
    id: "attributes",
    width: 80,
    render: function Render({ row, idx }) {
      return <Attributes row={row} />;
    },
  },
  {
    id: "reviewCnt",
    width: 80,
    align: "right",
    needSort: true,
    render: function ({ row, idx }) {
      return <CellEmpty value={formatNumber(row.reviewCnt)} />;
    },
  },
  {
    id: "score",
    align: "right",
    needSort: true,
    width: 50,
  },
];

export const getColumns = (features = [], type = columnTypeEnum.asin) => {
  const showSingleAsinStyle = type === columnTypeEnum.asin;

  const middleColumns =
    (showSingleAsinStyle
      ? features?.map((feature) => {
          return {
            id: feature?.code,
            label: feature?.name,
            width: 70,
            needSort: feature?.needSort,
            align: feature?.align || "left",
            render: function ({ row }) {
              const ele = row?.featureValues?.find(
                (item) => item?.code === feature.code
              );
              return <CellEmpty value={ele?.value} />;
            },
          };
        })
      : keyWordOrAsinsMidColumns) || [];
  return [
    {
      id: "checkbox",
      width: 20,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
    },
    {
      id: "#",
      width: 25,
      sticky: true,
      stickyStyle: {
        left: 20 + 16,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "image",
      align: "center",
      width: 80,
      minWidth: 50,
      maxWidth: 80,
      render: function ({ row }) {
        return <VAImage rowData={row} />;
      },
    },
    {
      id: "asinInfo",
      width: 220,
      minWidth: 150,
      sticky: true,
      className: tableVariables.leftLast,
      stickyStyle: {
        left: 20 + 16 + 25 + 16,
      },
      render: function ({ row, hover = false }) {
        return (
          <AsinInfo
            showSingleAsinStyle={showSingleAsinStyle}
            row={row}
            hover={hover}
          />
        );
      },
    },
    ...middleColumns,
    {
      id: "price",
      width: 50,
      needSort: true,
      align: "right",
      render: function ({ row }) {
        return (
          <div style={{ wordBreak: "normal" }}>
            <CellEmpty value={getPrice(row.price)} />
          </div>
        );
      },
    },
    {
      id: "boughtInPastMonth",
      width: 109,
      align: "right",
      tooltip: "boughtInPastMonthHover",
      needSort: true,
      render: function ({ row }) {
        const value = row.boughtInPastMonth;

        return <CellEmpty value={formatSalesCount(value)} />;
      },
    },
    {
      id: "boughtHistory",
      align: "center",
      width: 155,
      maxWidth: 155,
      render: function ({ row, last }) {
        return <SalesChart rowData={row} />;
      },
    },
    {
      id: "action",
      align: "center",
      width: 80,
      sticky: true,
      className: tableVariables.rightLast,
      stickyStyle: {
        right: 0,
      },
      render: function ({ row }) {
        return <Action rowData={row} />;
      },
    },
  ];
};
