import { isWithinInterval, subDays, subMonths, startOfMonth } from "date-fns";
export const times = {
  week: "week",
  month: "month",
  customWeek: "customWeek",
  customMonth: "customMonth",
};

export const weekMonthEnum = {
  weekly: "WEEK",
  monthly: "MONTH",
};

export const weekMonthTimes = [
  {
    code: weekMonthEnum.weekly,
  },
  {
    code: weekMonthEnum.monthly,
  },
];

export const baseFilter = ({ day, month } = {}, date) => {
  const today = new Date();
  let start;
  let end = today;
  if (day !== undefined) {
    start = subDays(today, day);
  } else if (month !== undefined) {
    start = startOfMonth(subMonths(today, month));
  }

  if (!date) {
    return { start, end };
  }

  return isWithinInterval(date, {
    start,
    end,
  });
};

export const getRangeStartAndEnd = () => {};

export const sectionTimes = [
  {
    code: times.week,
    filter: baseFilter.bind(null, { day: 7 }),
  },
  {
    code: times.month,
    filter: baseFilter.bind(null, { day: 30 }),
  },
  {
    code: "3months",
    filter: baseFilter.bind(null, { month: 2 }),
  },
  {
    code: "6months",
    filter: baseFilter.bind(null, { month: 5 }),
  },
  {
    code: "1year",
    filter: baseFilter.bind(null, { month: 11 }),
  },
  {
    code: "all",
    filter: () => {
      return true;
    },
  },
];

export const filterRangeTimes = (startTime, endTime) => [
  sectionTimes[2],
  sectionTimes[3],
  sectionTimes[4],
  {
    code: "2years",
    filter: baseFilter.bind(null, { month: 23 }),
  },
  {
    code: "all",
    filter: () => {
      return {
        start: new Date(startTime),
        end: new Date(endTime),
      };
    },
  },
];
