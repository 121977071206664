import { memo, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";
import useRegisterTableState from "src/hooksOrClass/registerTableState";

import { useDispatch, useSelector } from "react-redux";
import CustomDrawer from "src/components/customDrawer";

import {
  downLoadRelatedFileThunk,
  resetTable,
  resetTableData,
  updateWordFreqKeywords,
  updateWordRelatedDrawerOpen,
} from "src/store/rootReducer";
import FlowTable from "src/components/table";
import BoxLoading from "src/components/loading/box";
import { fetchStatus } from "src/constants";
import { getColumns } from "./column";
import style from "./index.module.scss";
import useTable from "src/hooksOrClass/table";
import Export from "src/components/export";
import useExport from "src/hooksOrClass/export";
import Empty from "src/components/empty";
import classNames from "classnames";
import { isLoading } from "src/utils";
import { formatRelatedDataParmas } from "./util";
import { getWrodRelatedData } from "./service";

const name = "WORD_FREQUENCY_RELATED";
const columns = getColumns();

function DrawRelated(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const { downLoadStatus, exportFn, cancelRef } = useExport();

  useRegisterTableState({ key: name, pageSize: 20 });
  const wordRelatedDrawerOpen = useSelector(
    (state) => state.root.wordRelatedDrawerOpen
  );

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const request = ({ getState, tableSearchData, tableController }) => {
    const state = getState().root;

    const data = formatRelatedDataParmas(state, tableSearchData);
    return getWrodRelatedData({ data, signal: tableController.signal });
  };

  const [fetchTable, resetRef] = useTable({ name, request });

  const onClose = () => {
    resetRef.current && resetRef.current("cancel");
    dispatch(resetTableData(name));
    dispatch(updateWordRelatedDrawerOpen(false));
    dispatch(updateWordFreqKeywords([]));
  };

  const downLoadRelated = () => {
    dispatch(downLoadRelatedFileThunk({ key: name, exportFn }));
  };

  useEffect(() => {
    cancelRef.current && cancelRef.current();
    if (wordRelatedDrawerOpen) {
      fetchTable();
    }
  }, [wordRelatedDrawerOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetTable(name));
    };
  }, []);

  return (
    <CustomDrawer
      open={wordRelatedDrawerOpen}
      onClose={onClose}
      title={t(`relatedKeywords`)}
    >
      <BoxLoading isLoading={isLoading(tableFetchStatus)} isNotTop>
        <div className={style.export}>
          <Export
            loading={downLoadStatus === fetchStatus.loading}
            disabled={!tableData?.total}
            onDownload={() => {
              downLoadRelated();
            }}
          />
        </div>
        <div
          className={classNames(
            "tableLastNoBorder",
            "drawerTableWrap",
            style.tableWrap
          )}
        >
          <FlowTable
            columns={columns}
            translationKey="keywords"
            uniqId="keyword"
            name={name}
          />
          <Empty
            data={tableData?.list}
            status={
              tableData?.total === undefined
                ? tableFetchStatus
                : memoTableFetchStatus
            }
            className="emptyDrawer"
          ></Empty>
        </div>
      </BoxLoading>
    </CustomDrawer>
  );
}

export default customWithErrorBoundary(memo(DrawRelated));
