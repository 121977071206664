import classNames from "classnames";

import LoadingButton from "@mui/lab/LoadingButton";
import customWithErrorBoundary from "../errorBoundary";
import { memo } from "react";

function BaseLoading(props) {
  const {
    className,
    disabled = false,
    onClick,
    loading = false,
    startIcon,
    children,
  } = props;

  return (
    <LoadingButton
      size="small"
      className={classNames(className, "button", {
        disabled: disabled || loading,
        loading: loading,
      })}
      sx={{
        color: "inherit",
        textTransform: "initial",
        borderColor: "var(--secondary)",
        lineHeight: "initial",
        fontSize: "14px",
        "&": {
          ".MuiButton-startIcon": {
            margin: "-2px 6px 0 0",
            display: "inline-block",

            "&>*:nth-of-type(1)": {
              fontSize: "14px",
            },
          },
          ".MuiLoadingButton-loadingIndicator": {
            marginRight: "6px",
          },
          ".Mui-disabled": {
            color: "var(--disabled-text-color)",
            border: "1px solid var(--secondary)",
          },
        },
        "&:hover": {
          backgroundColor: "transparent",
          borderColor: "var(--secondary)",
        },
      }}
      onClick={onClick}
      loading={loading}
      variant="outlined"
      disabled={disabled}
      loadingPosition="start"
      startIcon={startIcon}
    >
      {children}
    </LoadingButton>
  );
}

export default customWithErrorBoundary(memo(BaseLoading));
