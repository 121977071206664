import { useEffect } from "react";
import { searchWrapClass } from "../constant";

export const useSyncPostion = (wrapEle, placeHoldELe) => {
  useEffect(() => {
    const searchWrap = document.querySelector(`.${searchWrapClass}`);

    const wrap = document.querySelector(wrapEle);

    const placeHold = document.querySelector(placeHoldELe);

    const handleResize = () => {
      const res = searchWrap.getBoundingClientRect();
      const res1 = wrap.getBoundingClientRect();

      placeHold.style.width = res.x - res1.x + "px";
    };

    window.addEventListener("resize", handleResize, {
      passive: true,
    });

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize, {
        passive: true,
      });
    };
  }, []);
};
