import { createSlice } from "@reduxjs/toolkit";

import { asinsKey, periodKey } from "src/constants/form";
import { weekMonthEnum } from "src/constants/time";

const initialState = {
  baseSearch: {
    [periodKey]: weekMonthEnum.weekly,
    [asinsKey]: "",
  },
  tableSearch: {
    compareOptionValue: "",
    trafficWordsTypeValue: "",
    effectWordsTypeValue: "",
    //enter keywords
    inputKeywordsValue: "",
  },
};

export const KeywordsGapSlice = createSlice({
  name: "keywordsGap",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    syncBaseSearch: (state, action) => {
      state.baseSearch = action.payload;
    },
    resetTableSearch: (state, action) => {
      state.tableSearch = initialState.tableSearch;
    },
  },
  extraReducers: (builder) => {
    // builder
    //   .addCase(fetchChartData.fulfilled, (state, action) => {
    //     const responseData = action.payload;
    //     state.chartData = responseData;
    //   })
    //   .addCase(fetchChartData.rejected, (state, action) => {
    //     if (action.error.name === "AbortError") {
    //       // 处理请求被取消的情况
    //     } else {
    //       // 处理其他错误情况
    //     }
    //   });
  },
});

export const { resetStore, syncBaseSearch, resetTableSearch } =
  KeywordsGapSlice.actions;

export default KeywordsGapSlice.reducer;
