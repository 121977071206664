import { Fragment, memo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";

import { Tooltip } from "@mui/material";
import Message from "src/components/message";
import LinkToAmazonCom from "src/components/amazon";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";

import style from "./index.module.scss";

function AsinHover({ hover, rowData }) {
  const { t } = useTranslation();

  return (
    <div className={style.infoWrap}>
      <span className="stressListFont">{rowData.asin}</span>
      {hover && (
        <Fragment>
          <span className={classNames(style.infoIcon, "textFont")}>
            <CopyToClipboard
              text={rowData.asin}
              onCopy={() => {
                Message.success(t(`copied`));
              }}
            >
              <Tooltip title={t("copy")} placement="top">
                <span className="icon-copy"></span>
              </Tooltip>
            </CopyToClipboard>
          </span>
          <span className={classNames(style.infoIcon, "textFont")}>
            <LinkToAmazonCom asin={rowData.asin} />
          </span>
        </Fragment>
      )}
    </div>
  );
}

export default customWithErrorBoundary(memo(AsinHover));
