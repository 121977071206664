import SerialNumber from "src/components/table/components/serialNumber";
import AsinInfo from "./asinInfo";
import CellEmpty from "src/components/table/cellEmpty";
import Action from "./action";
import tableVariables from "src/styles/_table.scss";
import TrafficKeyWords from "./keyword";
import OtherContent from "./otherContent";
import { useTranslation } from "react-i18next";
import { memo } from "react";
import i18next from "i18next";
import { sortByEnum } from "../../constant";
import { formatSalesCount } from "src/utils";
import Position from "src/components/table/components/position";
import BoughtHistory from "./boughtHistory";
import VAImage from "src/components/table/components/VAImage";

function BaseTrafficWordHover({ type }) {
  const { t } = useTranslation();
  return (
    <div>
      {t(`structure.tableColumn.trafficKeywordsHover`, {
        type,
      })}
    </div>
  );
}

const TrafficWordHover = memo(BaseTrafficWordHover);

export const getColumn = () => {
  const t = i18next.t;

  return [
    {
      id: "checkbox",
      width: 20,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
    },
    {
      id: "#",
      width: 35,
      sticky: true,
      stickyStyle: {
        left: 20 + 16,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "image",
      align: "center",
      sticky: true,
      stickyStyle: {
        left: 20 + 16 * 2 + 35,
      },
      width: 104,
      render: function ({ row }) {
        return <VAImage rowData={row} />;
      },
    },
    {
      id: "asinInfo",
      align: "center",
      width: 220,
      minWidth: 150,
      sticky: true,
      stickyStyle: {
        left: 20 + 16 * 3 + 35 + 104,
      },
      className: tableVariables.leftLast,
      render: function ({ row, hover }) {
        return (
          <div style={{ paddingLeft: "10px" }}>
            <AsinInfo rowData={row} hover={hover} />
          </div>
        );
      },
    },
    {
      id: "boughtInPastMonth",
      tooltip: "boughtInPastMonthHover",
      align: "right",
      needSort: true,
      width: 120,
      render: function ({ row }) {
        const value = row?.boughtInPastMonth;

        return <CellEmpty value={formatSalesCount(value)} />;
      },
    },
    {
      id: "boughtHistory",
      align: "center",
      width: 175,
      render: function ({ row }) {
        return <BoughtHistory rowData={row} />;
      },
    },
    {
      id: "position",
      width: 128,
      tooltip: "positionHover",
      render: function ({ row }) {
        return <Position rowData={row} />;
      },
    },
    {
      id: "all",
      label: <div>{t(`typeKeywords`, { type: t(`terminology.all`) })}</div>,
      tooltip: "allHover",
      otherContent: <OtherContent />,
      align: "right",
      width: 88,
      needSort: true,
      sortKey: sortByEnum.allWords,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.allWords} />;
      },
    },
    {
      id: "organic",
      tooltip: <TrafficWordHover type={t(`terminology.organic`)} />,
      otherContent: <OtherContent />,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.organic`) })}</div>,
      align: "right",
      width: 122,
      needSort: true,
      sortKey: sortByEnum.organicWords,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.organicWords} />;
      },
    },
    {
      id: "sp",
      align: "right",
      needSort: true,
      sortKey: sortByEnum.spWords,
      otherContent: <OtherContent />,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.sp`) })}</div>,
      tooltip: <TrafficWordHover type={t(`terminology.ppcSp`)} />,
      width: 88,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.spWords} />;
      },
    },
    {
      id: "sb",
      needSort: true,
      sortKey: sortByEnum.sbWords,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.sb`) })}</div>,
      tooltip: <TrafficWordHover type={t(`terminology.ppcSb`)} />,
      otherContent: <OtherContent />,
      align: "right",
      width: 88,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.sbWords} />;
      },
    },
    {
      id: "sbv",
      align: "right",
      tooltip: <TrafficWordHover type={t(`terminology.ppcSbv`)} />,
      otherContent: <OtherContent />,
      needSort: true,
      sortKey: sortByEnum.sbvWords,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.sbv`) })}</div>,
      width: 99,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.sbvWords} />;
      },
    },
    {
      id: "ac",
      align: "right",
      tooltip: "acHover",
      otherContent: <OtherContent />,
      needSort: true,
      sortKey: sortByEnum.acWords,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.ac`) })}</div>,
      width: 88,
      render: function ({ row, hover }) {
        return <TrafficKeyWords rowData={row} type={sortByEnum.acWords} />;
      },
    },
    {
      id: "other",
      align: "right",
      tooltip: "otherHover",
      otherContent: <OtherContent />,
      needSort: true,
      sortKey: sortByEnum.otherRecWords,
      label: <div>{t(`typeKeywords`, { type: t(`terminology.other`) })}</div>,
      width: 135,
      render: function ({ row }) {
        return (
          <TrafficKeyWords rowData={row} type={sortByEnum.otherRecWords} />
        );
      },
    },

    {
      id: "action",
      align: "center",
      width: 80,
      className: tableVariables.rightLast,

      sticky: true,
      stickyStyle: {
        right: 0,
      },
      render: function ({ row, hover }) {
        return <Action rowData={row} />;
      },
    },
  ];
};
