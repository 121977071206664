import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";

import { useTranslation } from "react-i18next";
import classNames from "classnames";
import HoverImg from "src/components/imgWrap/hoverImg";
import ArrangeMentImg from "src/components/imgWrap/arrangement";
import style from "./index.module.scss";
import useLanguage from "src/hooksOrClass/language";
import commonStyle from "../../index.module.scss";

function AdGroupInfo({ data = {}, color = "var(--secondary)", max, min }) {
  const { t } = useTranslation();

  return (
    <div
      className={style.adGroupWrap}
      // style={{ border: `1px solid ${color}` }}
      key={data?.displayCampaignId}
    >
      <div className={style.adContent}>
        <div className={style.variantsNumWrap}>
          <div className={style.imgContainer}>
            <ArrangeMentImg
              list={data?.asins}
              className={style.imgWrap}
              height="80"
            />
          </div>
          <div className={classNames(commonStyle.hatStyle, style.variantsNum)}>
            {t(`adStrategy.targetVariant`, { asinsNum: data?.asins?.length })}
          </div>
        </div>
        <div className={style.numInfo}>
          <div className={style.targetVariantNumWrap}>
            <div className={style.targetVariantNum}>
              <div>
                <div className={style.number}>{data?.adCount}</div>
                <div>{t(`adStrategy.totalAd`)}</div>
              </div>
            </div>
          </div>
          <div
            className={classNames(commonStyle.hatStyle, style.productAdTitle)}
          >
            {t(`adStrategy.productAdTitle`)} -&gt;
          </div>
        </div>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(AdGroupInfo));
