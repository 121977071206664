import React, { memo, useContext, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import customWithErrorBoundary from "src/components/errorBoundary";
import { fetchTableData, updateGlobalLoading } from "src/store/rootReducer";
import Search from "src/components/search";
import Message from "src/components/message";

import { useRouterId } from "src/hooksOrClass/getRouterId";
import { actionTypeEnum, dimensionEnum } from "src/constants";

import MiddleChildren from "./middle";

import { SalesContext } from "../../context";

import { changeDimension, fetchChartData, syncBaseSearch } from "../../store";

function SalesSearch() {
  const baseSearchValues = useSelector((state) => state.sales.baseSearch);
  const [routerId, name] = useRouterId();
  const { fetchTable } = useContext(SalesContext);

  const dispatch = useDispatch();
  let { t } = useTranslation();

  const onSubmit = useCallback(async (formatFormValues) => {
    formatFormValues && dispatch(syncBaseSearch(formatFormValues));
    dispatch(changeDimension(dimensionEnum.asin));
    dispatch(updateGlobalLoading(true));
    if (formatFormValues?.actionType === actionTypeEnum.asin) {
      dispatch(fetchChartData());
    }
    fetchTable({ resetSortKey: true });
  }, []);

  const handleValidate = useCallback((formValues, asins) => {
    if (formValues.actionType === actionTypeEnum.asin) {
      return { result: true, nextVailde: true };
    }
    if (formValues.actionType === actionTypeEnum.keyword) {
      if (asins.length === 0) {
        Message.warning(t("emptyTip.keyword"));
        return { result: false, nextVailde: false };
      }

      if (asins.length > 1) {
        Message.warning(t("rulesTip.keywordMaxCount"));
        return { result: false, nextVailde: false };
      }

      return { result: true, nextVailde: false };
    }

    return { result: true, nextVailde: true };
  }, []);

  const fetchNames = useMemo(() => {
    return [
      { key: `${name}_ASIN`, formatKey: actionTypeEnum.asin },
      { key: `${name}_KEYWORD`, formatKey: actionTypeEnum.keyword },
    ];
  }, [name]);

  return (
    <Search
      baseSearchValues={baseSearchValues}
      fetchNames={fetchNames}
      validateRule={handleValidate}
      MiddleName="actionType"
      MiddleChildren={MiddleChildren}
      onSubmit={onSubmit}
    />
  );
}

export default memo(customWithErrorBoundary(SalesSearch));
