import { memo } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import Empty from "src/components/empty";
import customWithErrorBoundary from "src/components/errorBoundary";
import { position } from "src/constants";
import useLanguage from "src/hooksOrClass/language";
import { ceilRounding } from "src/utils/math";
import { getFormatWeek } from "src/utils/time";
let count = 8;

const getoptions = (
  { yTitle, y1Title, yTicks, y1Ticks } = {},
  { originLabels } = {}
) => ({
  maintainAspectRatio: false,
  animation: false,
  originLabels,
  plugins: {
    legend: false,
    tooltip: {
      enabled: false,
      position: "nearest",
    },
    hoverCrosshair: {
      position: position.horizontal,
      formatTitle: (date) => {
        return getFormatWeek(date);
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      max: yTicks?.max,
      title: {
        display: true,
        text: yTitle,
      },
      ticks: yTicks,
    },
    y1: {
      position: "right",
      reverse: true,
      min: 1,
      max: parseInt(y1Ticks?.max * 1.1),
      ticks: y1Ticks,
      title: {
        display: true,
        text: y1Title,
      },
    },
  },

  layout: {
    padding: {
      bottom: 18,
    },
  },
});

function VolumeChart({ originData }) {
  const { t } = useTranslation();
  const { autoFormatLocale } = useLanguage();
  const searchVolumes = originData?.searchVolumes || [];
  const ranks = originData?.ranks || [];

  if (!originData?.dates || originData.dates?.length === 0) {
    return (
      <Empty
        data={null}
        tips={<div>{t(`keywords.drawVolumeEmpty`)}</div>}
      ></Empty>
    );
  }

  const yInfo = ceilRounding({
    min: 0,
    max: Math.max(...searchVolumes),
    count,
  });

  const y1Info = ceilRounding({ min: 1, max: Math.max(...ranks), count });

  const options = getoptions(
    {
      yTitle: t(`keywords.searchVolume`),
      y1Title: t(`keywords.abaRank`),

      yTicks: yInfo,
      y1Ticks: y1Info,
    },
    { originLabels: originData?.dates }
  );
  const lineData = {
    labels: originData?.dates?.map((item) => autoFormatLocale(item)),

    datasets: [
      {
        type: "bar",
        data: searchVolumes,
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        label: t(`keywords.tableColumn.searchVolume`),
        backgroundColor: "rgba(34,34,34,0.1)",
      },
      {
        data: ranks,
        type: "line",
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        label: t(`keywords.tableColumn.abaRank`),
        borderColor: "#222222",
        yAxisID: "y1",
      },
    ],
  };
  return <Chart options={options} data={lineData} />;
}

export default customWithErrorBoundary(memo(VolumeChart));
