import { memo } from "react";
import style from "./index.module.scss";
import { getPercentage } from "src/utils";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";

function AdsTraffic({ rowData }) {
  const { t } = useTranslation();

  const { adTrafficSpRatio, adTrafficSbRatio, adTrafficSbvRatio } =
    rowData || {};
  const arr = [
    {
      key: "sp",
      value: adTrafficSpRatio,
    },
    {
      key: "sb",
      value: adTrafficSbRatio,
    },
    {
      key: "sbv",
      value: adTrafficSbvRatio,
    },
  ];
  return (
    <div className={classNames(style.adsTrafficWrap, "multLineTdWrap")}>
      {arr.map((item) => (
        <div key={item.key}>
          {t(`terminology.${item.key}`)}: {getPercentage(item.value)}
        </div>
      ))}
    </div>
  );
}

export default customWithErrorBoundary(memo(AdsTraffic));
