import { memo } from "react";
import Dropdown from "./dropDown";
import { useDayPicker } from "react-day-picker";
import { format, isSameYear, setMonth, startOfMonth } from "date-fns";
import customWithErrorBoundary from "src/components/errorBoundary";
import classNames from "classnames";

function Month(props) {
  const {
    fromDate,
    toDate,
    styles,
    locale,
    formatters: { formatMonthCaption },
    classNames: contextClassNames,
    labels: { labelMonthDropdown },
  } = useDayPicker();
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const { onChange, displayMonth, containerId } = props;

  const dropdownMonths = [];

  function formatMonthCaptionShort(month, options) {
    return format(month, "MMM", options);
  }

  if (isSameYear(fromDate, toDate)) {
    // only display the months included in the range
    const date = startOfMonth(fromDate);
    for (let month = fromDate.getMonth(); month <= toDate.getMonth(); month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  } else {
    // display all the 12 months
    const date = startOfMonth(new Date()); // Any date should be OK, as we just need the year
    for (let month = 0; month <= 11; month++) {
      dropdownMonths.push(setMonth(date, month));
    }
  }

  const handleChange = (value) => {
    const newMonth = setMonth(startOfMonth(displayMonth), value);
    onChange && onChange(newMonth);
  };

  return (
    <Dropdown
      name="months"
      containerId={containerId}
      aria-label={labelMonthDropdown()}
      className={contextClassNames.dropdown_month}
      style={styles.dropdown_month}
      value={displayMonth.getMonth()}
      month={displayMonth}
      caption={formatMonthCaption(displayMonth, { locale })}
    >
      {dropdownMonths.map((m) => {
        const value = m.getMonth();

        return (
          <div
            key={value}
            datvalue={value}
            className={classNames("textFont", {
              active: value === displayMonth.getMonth(),
              now:
                value === currentMonth &&
                displayMonth.getFullYear() === currentYear,
            })}
            onClick={() => handleChange(value)}
          >
            {formatMonthCaptionShort(m, { locale })}
          </div>
        );
      })}
    </Dropdown>
  );
}

export default customWithErrorBoundary(memo(Month));
