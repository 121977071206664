export const defaultConfig = {
  title: "trafficStructure",
  link: "",
};

export const columnTypeEnum = {
  asin: "asin",
  asins: "asins",
  keyWord: "keyWord",
};
