export const countryCodeEnum = {
  US: "US",
  UK: "UK",
  DE: "DE",
  JP: "JP",
  CA: "CA",
  FR: "FR",
  ES: "ES",
  IT: "IT",
};

export const defaultCountryCode = countryCodeEnum.US;

export const countryKey = "country";
