import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RankChart from "./chart";

import CustomDrawer from "src/components/customDrawer";
import classNames from "classnames";
import style from "./index.module.scss";
import ButtonTab from "src/components/buttonTab";
import { rankTrend } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { updateRankDrawerOpen } from "../../store";
import { getRankTrendData } from "../../service";
import BoxLoading from "src/components/loading/box";
import SectionTimeSelect from "src/components/timeSelect/section";
import { sectionTimes } from "src/constants/time";
import { updateActiveData } from "src/store/rootReducer";

let controller;
let dataReject;

function DrawRankTrend() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state) => state.keywords.rankDrawerOpen);
  const [isLoading, setIsLoading] = useState(false);
  const [allData, setAllData] = useState({});
  const baseSearchValues = useSelector((state) => state.keywords.baseSearch);
  const isListingSearch = useSelector(
    (state) => state.keywords.isListingSearch
  );
  const activeData = useSelector((state) => state.root.activeData);
  const tabsData = rankTrend.map((item) => ({
    ...item,
    name: t(`keywords.drawRankTabs.${item.code}`),
    tooltip: item.needTooltip
      ? t(`keywords.drawRankTabs.${item.code}Hover`)
      : "",
  }));

  const defaultActive = activeData?.defaultActive ?? tabsData[0];

  const [active, setActive] = useState(defaultActive);
  const defaultTimeCode = sectionTimes[2].code;
  const [timeCode, setTimeCode] = useState(defaultTimeCode);

  useEffect(() => {
    if (activeData?.defaultActive) {
      const code = activeData?.defaultActive?.code;
      const data = tabsData?.find((item) => item.code === code);
      data && setActive(data);
    }
  }, [activeData]);

  const handleChangeTime = useCallback((code) => {
    setTimeCode(code);
  }, []);

  const handleChange = (code, data) => {
    setActive(data);
  };

  function reset() {
    controller?.abort();
    setActive(defaultActive);
    dispatch(updateActiveData({}));
    setAllData({});
    setIsLoading(false);
    dataReject();
    setTimeCode(sectionTimes[2].code);
  }
  const handleCloseRankDrawer = useCallback(() => {
    dispatch(updateRankDrawerOpen(false));
    reset();
  }, []);

  const getData = () => {
    new Promise(async (resolve, reject) => {
      dataReject = reject;
      controller?.abort();
      controller = new AbortController();
      setIsLoading(true);
      const paramsData = {
        listing: isListingSearch,
        // keyword: "gaming chair",
        // asin: "B01MRZ02TL",
        asin: baseSearchValues.asins,
        keyword: activeData?.keyword,
      };
      const data = await getRankTrendData({
        data: paramsData,
        signal: controller.signal,
      });
      resolve(data);
    }).then((data) => {
      setAllData(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const activeTimeData = sectionTimes.find((item) => item.code === timeCode);
  let originData;
  if (activeTimeData.filter && typeof allData[active.type] === "object") {
    let obj = {};
    Object.keys(allData[active.type])
      .filter((item) => activeTimeData.filter(item))
      .forEach((key) => {
        obj[key] = allData[active.type][key];
      });
    originData = obj;
  } else {
    originData = allData[active.type];
  }

  return (
    <CustomDrawer
      open={open}
      title={t(`keywords.drawTitle.rankTrend`, {
        keyword: activeData.keyword,
      })}
      onClose={handleCloseRankDrawer}
    >
      <div className={style.container}>
        <BoxLoading isLoading={isLoading} isNotTop={true}>
          <div className={classNames(style.actionsWrap, "textFont")}>
            <ButtonTab
              data={tabsData}
              activeCode={active.code}
              onChangeActive={handleChange}
            />
            <SectionTimeSelect value={timeCode} onChange={handleChangeTime} />
          </div>

          <div className={style.chartWrap}>
            {allData[active.type] && (
              <RankChart originData={originData} type={active.type} />
            )}
          </div>
        </BoxLoading>

        <div className={classNames(style.tip, "helperFont")}>
          {t(`keywords.drawRankTip`)}
        </div>
      </div>
    </CustomDrawer>
  );
}

export default DrawRankTrend;
