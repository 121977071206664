import i18next from "i18next";
import { fetchStatus } from "src/constants";
import { countryCodeEnum, countryKey } from "src/constants/country";
import { options } from "src/i18n/constant";
import languageDetector from "src/i18n/detector";

export function linkToNewUrl(key, value) {
  const newHref = setQueryValue(window.location.href, key, value);
  window.location.href = newHref;
}

const isValidUrl = (url = "") =>
  ["http://", "https://"].some((protocol) => url.startsWith(protocol));

export function setQueryValue(url, key, value) {
  const urlString = url || window.location.href;
  const newUrl = new URL(urlString);
  newUrl.searchParams.set(key, value);

  return newUrl;
}

export function setQueryValues(url, parmas = {}) {
  let newUrl = isValidUrl(url) ? url : window.location.origin + url;

  Object.keys(parmas).forEach((item) => {
    newUrl = setQueryValue(newUrl, item, parmas[item]);
  });

  return newUrl;
}

export function toRGBA(color, opacity = 1) {
  if (color.indexOf("rgba") > -1) {
    const arr = color.split(",");
    arr[arr.length - 1] = opacity + ")".trim();
    return arr.join(",");
  }

  if (color.indexOf("rgb") > -1) {
    color.replace("rgb", "rgba");
    const arr = color.split(",");
    const lastValue = arr[arr.length - 1];
    arr[arr.length - 1] = lastValue.substr(0, lastValue.length - 1);
    return arr.join(",");
  }

  if (color.indexOf("#") > -1) {
    color = color.replace("#", "");
    var r = parseInt(color.substring(0, 2), 16);
    var g = parseInt(color.substring(2, 4), 16);
    var b = parseInt(color.substring(4, 6), 16);
    return `rgba(${r},${g},${b},${opacity})`;
  }

  return color;
}

export function getQueryValue(key) {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);

  return urlParams.get(key);
}

export const getCountry = () => {
  return getQueryValue(countryKey) || countryCodeEnum.US;
};

// 跳转
export const menuLinkToPath = (path) => {
  return setQueryValues(path, { [countryKey]: getCountry() });
};

export const getDefaultLinkInfo = () => {
  try {
    const search = window.location.search;
    const parseQuery = (query) =>
      Object.fromEntries(new URLSearchParams(query));
    let info = parseQuery(search);

    if (info.time) {
      info.time = JSON.parse(info.time);

      if (info.time?.date) {
        info.time.date = new Date(info.time?.date);
      }
    }

    delete info[countryKey];
    delete info[options.lookupQuerystring];

    if (Object.keys(info).length === 0) {
      return null;
    }

    return info;
  } catch (e) {}
};

export function calcScssVariables(variables) {
  const str = variables
    .replaceAll("min", "Math.min")
    .replaceAll("max", "Math.max")
    .replaceAll("px", "");

  let regex = /(\d+)%/g; // 匹配任意数字后面跟着的百分号
  let newStr = str.replace(regex, (match, p1) => `window.innerWidth * ${p1}`);
  return new Function("return " + newStr)() + "px";
}

export function getVarCssVariables(key) {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(key)
    .trim();
}

export function download(url, filename) {
  let a = document.createElement("a");
  a.href = url;
  //这样不会影响当前页面
  a.setAttribute("target", "_blank");
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function downloadFile(url, filename) {
  if (!filename) {
    let reg = /\/(([^\/]+)\.(\w+))\?/;
    let match = url.match(reg);
    filename = match ? match[1] : filename;
  }

  const isDev = process.env.NODE_ENV === "development";

  if (isDev) {
    download(`https://dev.minusflow.com${url}`, filename);
  } else {
    download(url, filename);
  }
}

export const formatNumber = (num) => {
  return getPlaceHolderData(num?.toLocaleString());
};

export function formatSalesCount(value) {
  return value !== null && value !== undefined && value >= 50
    ? formatNumber(value) + "+"
    : "<50";
}

export function getPercentage(number, decimalPlaces = 2) {
  if (number === undefined || number === null) {
    return;
  }
  number = Number(number);

  if (number < 0.0001) {
    return "<0.01%";
  }

  const precentage = number * 100;
  const value =
    precentage.toFixed(Number.isInteger(precentage) ? 0 : decimalPlaces) + "%";
  return value;
}

export function getStorageValue(key, defaultValue) {
  const saved = localStorage.getItem(key);

  try {
    const initial = JSON.parse(saved);
    return initial || defaultValue;
  } catch (e) {
    return saved;
  }
}

export function isDateValid(dateStr) {
  return !isNaN(new Date(dateStr));
}

export function findInterval(arrs, num) {
  if (num <= arrs[0] || num >= arrs[arrs.length - 1]) {
    return -1;
  }

  for (let i = 0; i < arrs.length - 1; i++) {
    if (arrs[i] < num && arrs[i + 1] > num) {
      return [i, i + 1];
    }
  }

  return null;
}

export function openNewUrl(linkUrl) {
  if (!linkUrl) {
    return;
  }
  const newUrl = setQueryValues(linkUrl?.href || linkUrl, {
    [countryKey]: getCountry(),
  });
  window.open(newUrl);
}

export function dataEmpty(data) {
  try {
    if (!Array.isArray(data)) {
      return true;
    }
    if (data?.length === 0 || data?.filter((item) => item)?.length === 0) {
      return true;
    }

    return false;
  } catch (e) {
    console.log("e");
  }
}

export const isLoading = (serviceFetchStatus) => {
  return (
    serviceFetchStatus === fetchStatus.loading ||
    serviceFetchStatus === fetchStatus.error
  );
};

export const getPlaceHolderData = (data) => {
  return data ?? "-";
};

export const isMissingKeyHandler = ({ key, value }) => {
  try {
    if (value.indexOf("returned an object instead of string") > -1) {
      return true;
    }
    const lastPart = key?.split(".").pop();

    if (value === lastPart) {
      return true;
    }

    return false;
  } catch (e) {
    return e;
  }
};

export const getOnionI18nText = (keys) => {
  const t = i18next.t;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = t(key);

    if (isMissingKeyHandler({ key, value })) {
      continue;
    } else {
      return value;
    }
  }

  const lastKey = keys[keys.length - 1];
  return t(lastKey);
};

export const formatAsinArray = (asins) => {
  try {
    return asins?.split(",");
  } catch (e) {
    return [];
  }
};

export const isAsinMultiple = (asins) => {
  try {
    if (Array.isArray(asins)) {
      return asins.length > 1;
    }

    return asins?.split(",")?.length > 1;
  } catch (e) {
    return false;
  }
};

export const firstLetterUp = (word) => {
  try {
    const firstLetter = word.charAt(0);

    const firstLetterCap = firstLetter.toUpperCase();

    const remainingLetters = word.slice(1);

    return firstLetterCap + remainingLetters;
  } catch (e) {
    return word;
  }
};

export function getNumValue({ num = 1, data = [], needLast = true }) {
  try {
    num = Number(num);

    if (!Array.isArray(data)) {
      return data;
    }

    const length = data.length;
    const step = Math.ceil(length / num);

    const result = [];
    let left = 0;
    let lastPush = left;

    while (left < length) {
      result.push(data[left]);
      lastPush = left;
      left = left + step;
    }

    if (needLast) {
      if (lastPush < data.length - 1) {
        result.push(data[data.length - 1]);
      }
    }

    return result;
  } catch (e) {
    console.log("e");
  }
}
