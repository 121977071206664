import { memo, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import FlowTable from "src/components/table";
import customWithErrorBoundary from "src/components/errorBoundary";

import { StructureContext } from "../../context";
import { getColumn } from "./column";
import { useTranslation } from "react-i18next";
import Tip from "src/components/tooltip/tip";
import Info from "./info";

import Btns from "./btns";

import style from "./index.module.scss";
import classNames from "classnames";
import { getPlaceHolderData } from "src/utils";
import Empty from "src/components/empty";
import { fetchStatus, listingAsinType } from "src/constants";
import EmptyTips from "../tips";

function StructureTable() {
  const { t } = useTranslation();

  const { tableKey: name } = useContext(StructureContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const isTableDataMultipleAsin = useSelector(
    (state) => state.structure.isTableDataMultipleAsin
  );
  const { total } = tableData || {};

  const columns = useMemo(() => {
    return getColumn();
  }, []);

  return (
    <div className={classNames("wrap")}>
      <div
        className={classNames(
          "wrapContentInfo",
          "titleSummary",
          style.infoWrap
        )}
      >
        <div className={style.left}>
          <div className={style.leftTip}>
            {tableData?.asinType === listingAsinType.LISTING_PASIN
              ? t(`structure.tableTip.pasinTip`, {
                  total: getPlaceHolderData(total),
                })
              : t(`structure.tableTip.asinTip`, {
                  total: getPlaceHolderData(total),
                })}
          </div>
        </div>
        <div className="btnsRightWrap">
          <Btns />
        </div>
      </div>
      {!isTableDataMultipleAsin && (
        <div className={classNames(style.infoContainer, "listSummaryFont")}>
          <div className={classNames(style.summaryWrap)}>
            <span>
              <span className={style.summary}>
                {t(`structure.tableTip.listingWordsSummary`)}
              </span>
              <Tip title={t(`structure.tableTip.listingWordsSummaryHover`)}>
                <span className="icon-tip"></span>
              </Tip>
            </span>
          </div>
          <div className={style.types}>
            <Info />
          </div>
        </div>
      )}
      <div
        className={classNames({
          wrapMini:
            tableFetchStatus === fetchStatus.loading ||
            tableFetchStatus === fetchStatus.error,
        })}
      >
        <FlowTable
          columns={columns}
          hasDownloadBtn={true}
          hasAddProductBtn={true}
          pageKey="structure"
          translationKey="structure"
          name={name}
        />
      </div>

      <Empty
        data={tableData}
        status={
          tableData?.total === undefined
            ? tableFetchStatus
            : memoTableFetchStatus
        }
        tips={<EmptyTips />}
      ></Empty>
    </div>
  );
}

export default customWithErrorBoundary(memo(StructureTable));
