import { memo } from "react";
import customWithErrorBoundary from "../errorBoundary";
import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { weekMonthTimes } from "src/constants/time";

function WeekMonthlyTimeSelect({
  otherPorps = {},
  onChange,
  value,
  menuClassName,
}) {
  const { t } = useTranslation();
  const data = weekMonthTimes.map((item) => ({
    ...item,
    value: item.code,
    label: t(`timeSelect.${item.code}`),
  }));

  return (
    <Select onChange={onChange} value={value} {...otherPorps}>
      {data.map((item) => {
        return (
          <MenuItem value={item.code} key={item.code} variant="search">
            <span className={menuClassName}>{item.label}</span>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default customWithErrorBoundary(memo(WeekMonthlyTimeSelect));
