import React, { memo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import classNames from "classnames";

import variables1 from "src/styles/_var.scss";
import style from "./index.module.scss";

function Lengend({ item, color, handleToggleLine }) {
  return (
    <FormControlLabel
      onClick={(e) => handleToggleLine && handleToggleLine(e, item)}
      key={item.text}
      className={classNames(style.control, {
        [style.hide]: item.hide,
      })}
      control={
        <Checkbox
          checked={!item.hide}
          sx={{
            color: color,

            "&": {
              padding: 0,
              paddingRight: variables1.iconDistance,
            },
            "&.Mui-checked": {
              color: color,
            },
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          }}
        />
      }
      sx={{
        "& .MuiFormControlLabel-label": {
          font: "var(--text-font)",
        },
      }}
      label={item.text}
      color={color}
    />
  );
}

export default memo(Lengend);
