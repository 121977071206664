import buildRequest from "src/request/axios";

export const downLoadWordFile = async ({ exportUrl, data }) => {
  const res = await buildRequest({
    url: exportUrl,
    method: "post",
    data,
    extraConfig: {
      showErrorMessage: true,
    },
  });

  return res;
};

export const getWordFreq = async ({ url, data, signal } = {}) => {
  const response = await buildRequest({
    url,
    method: "post",
    data: data,
    extraConfig: {
      signal,
    },
  });
  return response;
};
