import buildRequest from "src/request/axios";

class ErrorMonitor {
    baseUrl: string;
    isAddListener = false;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    addErrorListener(): void {
        if (this.isAddListener) {
            return;
        }

        const self = this;
        window.addEventListener("error", function (event) {
            self.report(event.error);
        })

        window.addEventListener('unhandledrejection', function (event) {
            const error = {
                message: event.reason,
                name: event.type
            }
            self.report(error);
        })

        this.isAddListener = true;

    }

    format(error: any): object {
        let obj: any = {
            route: window.location.href,
            message: error.message,
            name: error.name,
            stack: error.stack
        };


        return obj;
    }


    report(error: object): void { // 方法的返回值类型

        const data = this.format(error);



        // buildRequest({
        //     url: this.baseUrl,
        //     method: 'post',
        //     data: data,
        //     extraConfig: {
        //         noNeedValidate: true,
        //         noSiteRequired: true,
        //     }
        // }).catch((e) => {
        //     console.log("e", e);
        // });

    }

}


const monitorInstance = new ErrorMonitor("http://localhost:3002/getScore?site=US");

export const reportError = (error: object) => {
    monitorInstance.report(error);
}

// regex o match the email
// const emailReg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;






export default monitorInstance;