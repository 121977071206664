import { useTranslation } from "react-i18next";
import style from "src/components/empty/tip.module.scss";
import Tips from "src/components/empty/tips";
import { getEmptyReasons } from "../../util";
import { useMemo } from "react";

function EmptyTips() {
  const reasons = useMemo(() => {
    return getEmptyReasons();
  });

  return <Tips reasons={reasons} num="num.four" />;
}

export default EmptyTips;
