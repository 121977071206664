import i18next from "i18next";

export const all = "all";

export const getDefaultOption = () => {
  return [
    {
      value: all,
      label: i18next.t(`terminology.all`),
    },
  ];
};

export const allFormatEmpty = (value) => {
  if (value === all) {
    return "";
  }

  return value;
};
