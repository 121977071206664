import { memo, useCallback, useContext, useMemo, useTransition } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import Search from "src/components/search";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { KeywordsGapContext } from "../../context";

function KeyWordsGapSearch() {
  const [routerId, name] = useRouterId();
  const { onSubmit } = useContext(KeywordsGapContext);

  const baseSearchValues = useSelector((state) => state.keywordsGap.baseSearch);
  const defaultActionType = "keywordsGap";
  const fetchNames = useMemo(() => {
    return [{ key: `${name}`, formatKey: defaultActionType }];
  }, [name]);

  const handleSubmit = useCallback((formatFormValues) => {
    onSubmit && onSubmit(formatFormValues);
  }, []);

  return (
    <Search
      defaultActionType={defaultActionType}
      baseSearchValues={baseSearchValues}
      fetchNames={fetchNames}
      minCount={2}
      onSubmit={handleSubmit}
    />
  );
}

export default customWithErrorBoundary(memo(KeyWordsGapSearch));
