import React, { useState, useEffect, useRef, useContext } from "react";
import Loading from ".";
import { useSelector } from "react-redux";

import customWithErrorBoundary from "../errorBoundary";
import style from "./index.module.scss";
import { LoadingContext } from "./context";
import classNames from "classnames";

interface Props {
  children: React.ReactNode,
  isLoading: boolean;
  fixed: boolean;
  isNotTop: boolean;
}
// 整体的逻辑
// 当父级别loading的时候子组件不展示
function BoxLoading({ children, isLoading, fixed, isNotTop = false }: Props) {
  const data = useContext(LoadingContext);
  const ref = useRef(null);
  const loadingRef: any = useRef(null);
  const isTopParent = !data && !isNotTop;

  const onceSetRef = useRef<boolean>();
  const [showChildren, changeShowChildren] = useState(false);
  const globalLoading = useSelector((state) => (state as any).root.globalLoading);
  const currentLoading = isTopParent ? globalLoading : isLoading;

  useEffect(() => {
    //  顶层Loading 需要经过false -> true -> false的过程才能展示子元素
    //  其他loading 只需要占位展示loading 效果
    if (isTopParent) {
      if (!onceSetRef.current) {
        onceSetRef.current = true;
      } else {
        !globalLoading && changeShowChildren(true);
      }
    }
  }, [globalLoading]);

  useEffect(() => {
    const scroll = () => {
      const ele: any = ref.current;

      if (!ele?.firstChild || !currentLoading || !fixed) {
        return;
      }

      const { top, height } = ele?.firstChild?.getBoundingClientRect();
      const isVisible = (top > 0 && top < window.innerHeight) || (top < 0 && (top + height) > 0);
      if (height > window.innerHeight && isVisible) {

        if (loadingRef.current) {
          loadingRef.current.style.position = "fixed";
          let fixedTop;
          if (top > 0) {
            fixedTop = top + (window.innerHeight - top) / 2 + 'px';
          } else if (height + top < window.innerHeight) {
            fixedTop = (height + top) / 2 + 'px';
          } else {
            fixedTop = window.innerHeight / 2 + 'px';
          }
          loadingRef.current.style.top = fixedTop;
        }
      } else {
        if (loadingRef.current) {
          loadingRef.current.style.position = "";
          loadingRef.current.style.top = ""
        }

      }
    }

    scroll();

    window.addEventListener("scroll", scroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", scroll)

    }
  }, [currentLoading, fixed])

  return (
    <LoadingContext.Provider value={{ isLoading: currentLoading }}>
      <div className={classNames(style.boxLoading, { [style.topBoxLoading]: isTopParent && currentLoading })} ref={ref} >
        {/* BoxLoading如果嵌套展示的话只有父级别的loading结束后才会展示子元素的loading效果 */}
        {currentLoading && !data?.isLoading ? (
          <div className={classNames(style.animateLoading,)} >
            <div ref={loadingRef}>
              <Loading />
            </div>
          </div>
        ) : null}

        {(isTopParent ? showChildren : true) && children}
      </div>
    </LoadingContext.Provider >
  );
}

export default customWithErrorBoundary(BoxLoading);
