import axios from "axios";
import { vaildateResponseCode, vaildateResponseStatus } from "./vaildate";
import { reportError } from "src/utils/errorMonitor";
import { getCountry, setQueryValue } from "src/utils";
import Message from "src/components/message";

// 与后端的通信协议：https://opeblk6n4y.feishu.cn/docx/XGiqdr2rwoGkGPxJvwzcMYVAnCe

// 配置超时时间
const axiosInstance = axios.create({
  // 超时时间设置为20秒
  timeout: 1000 * 30,
  headers: { "Content-Type": "application/json" },
});

//配置【返回数据格式化】【重试】拦截器
axiosInstance.interceptors.response.use(
  function (response) {
    if (response.config?.noNeedValidate) {
      return response;
    }
    if (vaildateResponseStatus(response)) {
      const config = response.config;
      const showMessage = config.showMessage;
      const showErrorMessage = config.showErrorMessage;
      const vaildateCode = vaildateResponseCode(response.data?.code);

      if (showMessage) {
        Message[vaildateCode ? "success" : "error"](response.data?.message);
      } else if (showErrorMessage && !vaildateCode) {
        Message["error"](response.data?.message);
      }

      if (vaildateCode) {
        if (config.needCode) {
          return response.data;
        }

        return response.data.data;
      }
    }
    return Promise.reject("The status returned for the request is incorrect");
  },
  function (error) {
    const config = error.config;
    //   如果没有设置自动重试的话，直接将错误返回
    if (!config || !config.retry) return Promise.reject(error);

    // 设置变量以跟踪重试次数
    config.__retryCount = config.__retryCount || 0;

    // 判断是否超过总重试次数
    if (config.__retryCount >= config.retry) {
      reportError(error);
      // 返回错误并退出自动重试
      return Promise.reject(error);
    }

    // 增加重试次数
    config.__retryCount += 1;

    console.log(config.url + " 自动重试第" + config.__retryCount + "次");

    const backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // 每间隔retryDelay(单位是毫秒)返回重试请求
    return backoff.then(function () {
      return axiosInstance(config);
    });
  }
);

const buildRequest = async ({ method, url, data, extraConfig = {} }) => {
  try {
    const newUrl = extraConfig.noSiteRequired
      ? url
      : setQueryValue(window.location.origin + url, "site", getCountry());
    return axiosInstance({
      method,
      data,
      url: newUrl,
      ...extraConfig,
    });
  } catch (e) {}
};

export default buildRequest;
