import { MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import style from "./index.module.scss";
import classNames from "classnames";
import CustomCheckbox from "../customCheckbox";
import { BaseMenuProps } from "src/styles/theme";

function ChangeActiveSelect({
  children,
  label = "",
  defaultValue = "",
  value,
  onChange,
  data = [],
  multiple = false,
  labelClassName = "",
  width = "200px",
  height = "100%",
  wrapClass,
  menuWidth = "",
  needLabelShrink = false,
  selected: PropsSelected = [],
  colon = false,
  beforeIcon,
  iconWrapClassName,
  ...otherProps
}) {
  const defaultVal = useMemo(() => {
    return defaultValue || value;
  }, []);

  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState(PropsSelected);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(value);
    if (multiple) {
      if (
        value.length !== defaultVal.length ||
        (value.length === defaultVal.length && value[0] !== defaultVal[0].value)
      ) {
        setActive(true);
      } else {
        setActive(false);
      }
    } else {
      if (value === defaultVal) {
        setActive(false);
      } else {
        setActive(true);
      }
    }
  }, [value]);

  const handleChange = useCallback((e) => {
    if (multiple) {
      return;
    }
    onChange && onChange(e.target.value);
  }, []);

  const handleSelcted = (value) => {
    const newSlected = [...selected];
    const idx = newSlected.indexOf(value);
    if (idx > -1) {
      newSlected.splice(idx, 1);
    } else {
      newSlected.push(value);
    }

    setSelected(newSlected);
  };

  const handleApply = () => {
    onChange && onChange(selected);
    setOpen(false);
  };

  const sx = useMemo(() => {
    return {
      "& .MuiOutlinedInput-input": {
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: "36px",
        height: `${height} !important`,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
      },
      "&.MuiOutlinedInput-root": {
        height,
        width,
      },

      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: active
          ? "var(--color) !important"
          : "var(--secondary) !important",
        borderWidth: "1px !important",
      },
    };
  }, [active]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setSelected(value);
    setOpen(true);
  };

  const renderValue = multiple
    ? {
        open,
        onClose: handleClose,
        onOpen: handleOpen,

        renderValue: () => {
          let labels =
            value
              ?.map((val) => data.find((item) => item.value === val)?.label)
              ?.filter((item) => item)
              .join(",") || defaultVal?.map((item) => item.label).join(",");

          return (
            <div
              className={classNames(style.selectTextWrap, "textOverFlow")}
              title={labels}
            >
              {labels}
            </div>
          );
        },
      }
    : {
        renderValue: (value) => {
          const label = data?.find((item) => item?.value === value)?.label;

          return label || value;
        },
      };

  const memoMenuProps = useMemo(() => {
    return {
      ...BaseMenuProps,
      PaperProps: {
        style: {
          width: menuWidth,
        },
      },
      classes: {
        paper: multiple ? style.menuSelect : style.singleMenu, // 自定义类名
      },
    };
  }, [multiple]);

  return (
    <div
      className={classNames(
        style.activeWrap,
        wrapClass,
        iconWrapClassName,
        "iconCalendarWrap",
        {
          [style.labelShrinkWrap]: needLabelShrink,
          [style.active]: active,
        }
      )}
    >
      {label && (
        <span
          className={classNames(style.label, "stressTextFont")}
          title={label}
        >
          {label}
          {colon ? ":" : ""}
        </span>
      )}
      {beforeIcon}
      <Select
        sx={sx}
        value={value}
        onChange={handleChange}
        defaultValue={
          Array.isArray(defaultVal)
            ? defaultVal?.map((item) => item.value)
            : defaultVal
        }
        multiple={multiple}
        MenuProps={memoMenuProps}
        {...renderValue}
        {...otherProps}
      >
        {data?.map((item) => {
          return (
            <MenuItem
              value={item?.value}
              key={item?.value}
              variant="search"
              onClick={() => multiple && handleSelcted(item?.value)}
            >
              <div
                className={classNames(
                  style.label,
                  labelClassName,
                  "textOverFlow"
                )}
                title={item?.label}
              >
                {multiple && (
                  <CustomCheckbox
                    checked={selected?.indexOf(item.value) > -1}
                    sx={{ padding: "0 6px 0 0" }}
                  />
                )}
                {item?.label}
              </div>
            </MenuItem>
          );
        })}
        {multiple && (
          <MenuItem>
            <div
              className={classNames(style.apply, "button")}
              onTouchStart={handleApply}
              onClick={handleApply}
            >
              Apply
            </div>
          </MenuItem>
        )}
        {children}
      </Select>
    </div>
  );
}

export default ChangeActiveSelect;
