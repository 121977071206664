import { useRouterId } from "src/hooksOrClass/getRouterId";
import TableComponent from "./table";

import { TableContext } from "./context";
import { memo, useMemo, useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TablePagination from "./pagination";
import {
  fetchTableData,
  updateHoverRowAsin,
  updateSelected,
  updateShowAllChecked,
} from "src/store/rootReducer";
import GlobalAction from "./globalAction";
import formatParmars from "./format";

function FlowTable({
  name,
  columns,
  stickyClass,
  dataKey = "list",
  translationKey,
  uniqId = "asin",
  hasAddProductBtn,
  hasDownloadBtn,
  fetchTable,
  pageKey,
  absoluted = false,
  needSticky = true,
  needExtraHead = true,
  showPageCompent = true,
}) {
  const [routerId, routerName] = useRouterId();
  name = name || routerName;

  const tableData = useSelector((state) => state.root.table[name]?.tableData);
  const selected = useSelector((state) => state.root.table[name]?.selected);

  const page = useSelector(
    (state) => state.root.table[name]?.tableSearch?.pageNum
  );

  // 只有当表格数据变化之后，page参数才需要影响UI
  const memoPage = useMemo(() => {
    return page;
  }, [tableData]);

  const selectedRef = useRef(selected);

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);
  const numSelected = selected?.length;

  const tableList = useMemo(() => {
    return tableData?.[dataKey];
  }, [tableData]);

  const dispatch = useDispatch();

  const handleMouseEnter = useCallback((row) => {
    dispatch(updateHoverRowAsin(formatParmars(name, row[uniqId])));
  }, []);

  const handleMouseLeave = useCallback((row) => {
    dispatch(updateHoverRowAsin(formatParmars(name, row[uniqId])));
  }, []);

  const handleSelectClick = useCallback((event, rowData) => {
    let newSelected;
    const selected = selectedRef.current;
    if (event.target.checked) {
      if (selected?.indexOf(rowData[uniqId]) === -1) {
        newSelected = [...selected, rowData[uniqId]];
      } else {
        return;
      }
    } else {
      newSelected = selected.filter((asin) => asin !== rowData[uniqId]);
    }

    dispatch(updateSelected(formatParmars(name, newSelected)));
    if (newSelected.length > 0) {
      dispatch(updateShowAllChecked(formatParmars(name, true)));
    } else {
      dispatch(updateShowAllChecked(formatParmars(name, false)));
    }
  }, []);

  const handleSelectAllClick = useCallback(
    (event) => {
      if (event.target.checked) {
        const newSelected = tableList.map((item) => item[uniqId]);
        dispatch(updateSelected(formatParmars(name, newSelected)));

        dispatch(updateShowAllChecked(formatParmars(name, true)));
        return;
      }
      dispatch(updateSelected(formatParmars(name, [])));
      dispatch(updateShowAllChecked(formatParmars(name, false)));
    },
    [tableList]
  );

  const onChangePageNumAndFetch = useCallback((e, page) => {
    const data = { pageNum: page, key: name };
    if (fetchTable) {
      fetchTable(data);
    } else {
      dispatch(fetchTableData(data));
    }
  }, []);

  const value = useMemo(() => {
    return {
      name,
      absoluted,
      pageKey,
      dataKey,
      translationKey,
      columns,
      memoPage,
      uniqId,
      fetchTable,
      numSelected,
      showPageCompent,
      handleMouseEnter,
      handleMouseLeave,
      handleSelectClick,
      handleSelectAllClick,
      onChangePageNumAndFetch,
      needSticky,
      needExtraHead,
      stickyClass,
    };
  }, [
    name,
    dataKey,
    translationKey,
    columns,
    memoPage,
    uniqId,
    numSelected,
    stickyClass,
    showPageCompent,
    handleMouseEnter,
    handleMouseLeave,
    handleSelectClick,
    handleSelectAllClick,
    onChangePageNumAndFetch,
    needSticky,
    needExtraHead,
    absoluted,
  ]);

  return (
    <div className="textFont">
      <TableContext.Provider value={value}>
        <TableComponent />

        <TablePagination />

        <GlobalAction
          hasAddProductBtn={hasAddProductBtn}
          hasDownloadBtn={hasDownloadBtn}
        />
      </TableContext.Provider>
    </div>
  );
}

export default memo(FlowTable);
