import i18next from "i18next";
import { asinsKey, timeKey } from "src/constants/form";
import { addLinkUrl, pageAction } from "src/constants/page";

export const getListingOption = ({ data } = {}) => {
  const option = addLinkUrl(pageAction.trafficKeywords, {
    baseParamsKeys: [asinsKey, timeKey],
    data,
    params: {
      isListingSearch: true,
    },
  });

  return option;
};

export const getTablePageActions = ({
  data,
  needDefault = false,
  // 这里指的是搜索框内输入的asin是否为多个
  multiple,
} = {}) => {
  const t = i18next.t;

  const baseParams = {
    baseParamsKeys: [asinsKey, timeKey],
    data,
  };

  const options = [
    needDefault && multiple
      ? null
      : addLinkUrl(pageAction.trafficKeywords, baseParams),
    addLinkUrl(pageAction.productSales, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    !needDefault && multiple
      ? addLinkUrl(pageAction.trafficStructure, baseParams)
      : null,
    addLinkUrl(pageAction.trafficHistory, baseParams),
    addLinkUrl(pageAction.adStrategy, {
      baseParamsKeys: [asinsKey],
      data,
    }),
  ]
    ?.filter((item) => item)
    ?.map((item) => ({ ...item, title: t(`router.${item.title}`) }));

  if (needDefault) {
    return [options.shift(), options];
  }

  return options;
};

export const getTypeKeyWordsLink = (data = {}) => {
  const options = addLinkUrl(pageAction.trafficKeywords, {
    baseParamsKeys: [asinsKey, timeKey],
    data,
    params: {
      keywordType: data?.type,
    },
  });

  return options?.linkUrl;
};

export const getEmptyReasons = (isAsin) => [
  i18next.t(`emptyTips.reason1`),
  i18next.t(`structure.emptyTips.reason2`),
];
