import SerialNumber from "src/components/table/components/serialNumber";
import Word from "./word";
import CellEmpty from "src/components/table/cellEmpty";
import { formatNumber } from "src/utils";
import tableVariables from "src/styles/_table.scss";

export const getColumns = () => {
  return [
    {
      id: "#",
      style: { paddingLeft: "30px", paddingRight: "30px" },
      width: 80,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "word",
      width: 200,
      sticky: true,
      stickyStyle: {
        left: 80 + 16,
      },
      className: tableVariables.leftLast,
      render: function ({ row }) {
        return <Word rowData={row} />;
      },
    },
    {
      id: "searchVolume",
      width: 200,
      align: "right",
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.searchWeight)} />;
      },
    },
    {
      id: "wordFrequency",
      align: "right",
      width: 200,

      style: { paddingLeft: "30px", paddingRight: "30px" },
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.freq)} />;
      },
    },
  ];
};
