import { memo } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";
import { getFormatWeek } from "src/utils/time";
import chartStyle from "src/utils/chart/plugin/index.module.scss";

function Title({ date, value, displayAdId, active }) {
  const { t } = useTranslation();
  return (
    <div className={chartStyle.tooltipElNoStyle} style={{ minWidth: "208px" }}>
      <div className="wrapTable">
        <div className={chartStyle.title}>{getFormatWeek(date)}</div>
        <div>
          <span>{t(`adStrategy.tableColumn.adId`)}</span>
          <span className={chartStyle.dataSpan}>{displayAdId}</span>
        </div>
        <div>
          <span>{t(`adStrategy.tableColumn.searchTerms`)}</span>
          <span className={chartStyle.dataSpan}>{value}</span>
        </div>
        {!active && (
          <div className={chartStyle.tips}>*{t(`clickToViewSearchTerms`)}</div>
        )}
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(Title));
