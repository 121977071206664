import classNames from "classnames";
import { memo, useContext } from "react";
import CellEmpty from "src/components/table/cellEmpty";

import style from "./index.module.scss";
import { KeywordsContext } from "../../context";
import { highlightByTime } from "src/utils/time";
import { useTranslation } from "react-i18next";
import RankTooltip from "./rankTooltip";

function OrganicRank({ rowData = {}, isListingSearch = false, time }) {
  const { t } = useTranslation();
  const { openRankDrawer } = useContext(KeywordsContext);
  const res = highlightByTime(rowData?.ogPositionTimeUser, time);

  return (
    <RankTooltip
      localTime={rowData?.ogPositionTimeUser}
      siteTime={rowData?.ogPositionTime}
    >
      <div
        className={classNames("multLineTdWrap", {
          [style.highlight]: res.highlight,
        })}
        style={{ cursor: "pointer" }}
        onClick={() => openRankDrawer(rowData)}
      >
        <div className={style.lastRank}>
          <span>
            <CellEmpty value={rowData?.ogRank}></CellEmpty>
          </span>
        </div>
        <div>
          <CellEmpty value={rowData?.ogPosition}></CellEmpty>
        </div>

        {isListingSearch && (
          <div>
            <CellEmpty value={rowData?.ogAsin}></CellEmpty>
          </div>
        )}
        <div>
          <CellEmpty value={res?.date + " " + t(res.unit)}></CellEmpty>
        </div>
      </div>
    </RankTooltip>
  );
}

export default memo(OrganicRank);
