import ReactDOM from "react-dom/client";
import Alert from "@mui/material/Alert";
import { Stack } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { customTheme } from "src/styles/theme";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useStorageShrinked } from "src/hooksOrClass/localstorge";
import { createPortal } from "react-dom";
import { MessageContext } from "src/hooksOrClass/messageContext";

export const MessageComponent = forwardRef((props, ref) => {
  let timer;
  const [open, setOpen] = useState(true);
  const messageInfo = useContext(MessageContext).info;

  const {
    type = "info",
    content: Content,
    duration = 2000,
    onClose,
    style,
  } = messageInfo || {};

  useEffect(() => {
    setOpen(true);

    timer = setTimeout(() => {
      setOpen(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [messageInfo]);

  const [shrinked, setShrinked, { left }] = useStorageShrinked();

  const handleClose = () => {
    setOpen(false);
    clearTimeout(timer);
    onClose && onClose();
  };

  useImperativeHandle(ref, () => ({
    close: () => {
      clearTimeout(timer);
      setOpen(false);
    },
  }));

  if (!open || !Content) {
    return null;
  }

  return createPortal(
    <Stack
      spacing={2}
      ref={ref}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        width: `calc(100% - ${left} - 200px)`,
        zIndex: 1201,
        margin: "0 100px",
        left: left,
        top: "30px",
        ...style,
      }}
    >
      <Alert severity={type} onClose={handleClose}>
        {typeof Content === "string" || Content.$$typeof ? (
          Content
        ) : (
          <Content />
        )}
      </Alert>
    </Stack>,
    document.body
  );
});

const Message = {};
function format(props) {
  var data = {};

  if (
    typeof props === "string" ||
    typeof props === "function" ||
    props.$$typeof
  ) {
    data.content = props;
  } else {
    data = props;
  }

  return data;
}

export function MessageNotification({ children }) {
  const [info, changeMessageInfo] = useState();
  const ref = useRef();

  const show_message = (props) => {
    changeMessageInfo(props);
  };

  Message.success = (props) => {
    props = format(props);
    show_message({
      ...props,
      type: "success",
    });
  };

  Message.warning = (props) => {
    props = format(props);
    show_message({
      ...props,
      type: "warning",
    });
  };

  Message.default = (props) => {
    props = format(props);
    return show_message({
      ...props,
      type: "info",
    });
  };

  Message.error = (props) => {
    props = format(props);
    show_message({
      ...props,
      type: "error",
    });
  };

  return (
    <MessageContext.Provider value={{ info }}>
      <MessageComponent ref={ref} />
    </MessageContext.Provider>
  );
}

export default Message;
