import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { memo, useEffect } from "react";

import style from "./index.module.scss";
import customWithErrorBoundary from "src/components/errorBoundary";
import { useSyncPostion } from "../../hooks/useInputPosition";

function Example({ amazonExample = [], onClick = () => {} }) {
  const { t } = useTranslation();
  useSyncPostion(`.${style.wrap}`, "#example-placeHold");

  if (!amazonExample.length) {
    return null;
  }

  return (
    <div className={classNames(style.wrap, "stressTextFont")}>
      <div id="example-placeHold"></div>
      <div className={style.example}>
        <span className={style.title}>{t(`examples`)}:</span>
        {amazonExample.map((item) => (
          <span
            key={item}
            className={style.option}
            onClick={() => onClick(item)}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(Example));
