import { memo, useMemo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import Tips from "src/components/empty/tips";
import { getEmptyReasons } from "../util";

function EmptyTips() {
  const reasons = useMemo(() => {
    return getEmptyReasons();
  });

  return <Tips reasons={reasons} num="num.three" />;
}

export default customWithErrorBoundary(memo(EmptyTips));
