import { forwardRef, memo, useImperativeHandle, useState } from "react";
import MonthPicker from "./MonthPicker";
import { startTime } from "src/constants";
import { endOfMonth, endOfWeek } from "date-fns";
import customWithErrorBoundary from "src/components/errorBoundary";

const Month = forwardRef((props, ref) => {
  const {
    onChange,
    value,
    startTime: propStartTime,
    endTime,
    range,
    inputValue,
  } = props;

  const [selectedMonthData, setSelectedMonthData] = useState(
    value?.date instanceof Date && value.type === "month"
      ? {
          month: value?.date?.getMonth(),
          year: value?.date?.getFullYear(),
          maxYear: value?.maxYear,
        }
      : undefined
  );
  const handleOnChange = (value) => {
    setSelectedMonthData(value);
    const { year, month, maxYear } = value || {};

    const monthDate = new Date(year, month);
    onChange &&
      onChange({
        date: monthDate,
        type: "month",
        maxYear,
      });
  };

  return (
    <MonthPicker
      textColor="#535353"
      range={range}
      ref={ref}
      inputValue={inputValue}
      fromDate={propStartTime ?? new Date(startTime)}
      toDate={endTime ?? endOfMonth(new Date())}
      disabled={{ after: endTime ?? new Date() }}
      selected={selectedMonthData}
      onChange={handleOnChange}
    />
  );
});

export default customWithErrorBoundary(memo(Month));
