import { memo } from "react";
import ImgWrap from "src/components/imgWrap";
import { getAmazonFixedWidthImage } from "src/utils/amazon";

function HoverImage({ img, placement }) {
  const hoverImgSrc = getAmazonFixedWidthImage(img, 280, {
    hoverRatio: 2,
  });

  return (
    <ImgWrap
      src={img}
      placement={placement}
      style={{ height: "105px" }}
      hoverImgSrc={hoverImgSrc}
      hoverZoom
      hoverWrapStyle={{ height: 280, width: 280 }}
    />
  );
}

export default memo(HoverImage);
