import { memo } from "react";
import customWithErrorBoundary from "../errorBoundary";
import ChangeActiveSelect from "../customSelect/changeActive";
import { useTranslation } from "react-i18next";
import style from "./index.module.scss";
import { sectionTimes } from "src/constants/time";

function SectionTimeSelect({
  value = sectionTimes[2].code,
  onChange,
  labelClassName = "textFont",
}) {
  const { t } = useTranslation();
  const data = sectionTimes.map((item) => ({
    ...item,
    value: item.code,
    label: t(`timeSelect.${item.code}`),
  }));

  return (
    <ChangeActiveSelect
      data={data}
      width={157}
      iconWrapClassName={style.timeWrap}
      onChange={onChange}
      value={value}
      beforeIcon={<span className="icon-calendar"></span>}
      labelClassName={labelClassName}
    />
  );
}

export default customWithErrorBoundary(memo(SectionTimeSelect));
