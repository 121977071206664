import React, { useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useStorageShrinked } from "src/hooksOrClass/localstorge";
import customWithErrorBoundary from "src/components/errorBoundary";
import { resetMustRead, updateMustRead } from "src/store/rootReducer";

import Sidebar from "./sidebar";
import HorizontalBar from "./horizontalBar";

import style from "./index.module.scss";
import TopAction from "src/components/action";
import buildRequest from "src/request/axios";
import MustRead from "src/components/mustRead";
import classNames from "classnames";
import { useRouterId } from "src/hooksOrClass/getRouterId";

let controller;

function Layouts() {
  const [shrinked, setShrinked, { horizontalLeft, horizontalRight }] =
    useStorageShrinked();
  const location = useLocation();

  const [routerId, name] = useRouterId();

  const mustReadInfo = useSelector((state) => state.root.mustRead);

  const dispatch = useDispatch();

  const fetchMustReadInfo = async () => {
    try {
      controller?.abort();
      controller = new AbortController();

      if (!name) {
        return;
      }
      const data = await buildRequest({
        url: `/api/web/must_read?mustReadType=${name}`,
        extraConfig: {
          signal: controller.signal,
        },
      });

      dispatch(updateMustRead(data));
    } catch (e) {
      console.log("e", e);
    }
  };

  useEffect(() => {
    fetchMustReadInfo();

    return () => {
      dispatch(resetMustRead());
    };
  }, [location]);

  return (
    <div className={style.wrap}>
      <Sidebar />
      <div
        className={classNames(style.contentWrap, name, "pageWrap")}
        style={{
          marginLeft: horizontalLeft,
          marginRight: horizontalRight,
        }}
      >
        <HorizontalBar />
        <Outlet />
        <MustRead info={mustReadInfo} />
      </div>
      <TopAction />
    </div>
  );
}

export default customWithErrorBoundary(Layouts);
