import { useEffect } from "react";
import { throttle } from "loadsh";
export const useSyncPosition = (selector1, selector2, needExtraHead) => {
  useEffect(() => {
    if (!needExtraHead) {
      return;
    }
    const ele1 = document.querySelector(selector1);
    const ele2 = document.querySelector(selector2);

    if (!ele1 || !ele2) {
      return;
    }

    const syncPostion1 = throttle(() => {
      if (!window.getComputedStyle(ele1).getPropertyValue("opacity")) {
        return;
      }
      const scrollLeft = ele1.scrollLeft;

      ele2.scrollLeft = scrollLeft;
    }, 200);

    const syncPostion2 = throttle(() => {
      if (!window.getComputedStyle(ele2).getPropertyValue("opacity")) {
        return;
      }

      const scrollLeft = ele2.scrollLeft;

      ele1.scrollLeft = scrollLeft;
    }, 200);

    ele1.addEventListener("scroll", syncPostion1, { passive: true });
    ele2.addEventListener("scroll", syncPostion2, {
      passive: true,
    });

    return () => {
      ele1.removeEventListener("scroll", syncPostion1);
      ele2.removeEventListener("scroll", syncPostion2);
    };
  }, []);
};
