import customWithErrorBoundary from "src/components/errorBoundary";
import Dropdown from "./dropDown";
import { setYear, startOfMonth, startOfYear } from "date-fns";
import { useDayPicker } from "react-day-picker";
import { memo } from "react";
import classNames from "classnames";

function Year(props) {
  const {
    fromDate,
    toDate,
    locale,
    styles,

    classNames: contextClassNames,
    formatters: { formatYearCaption },
    labels: { labelYearDropdown },
  } = useDayPicker();

  const currentYear = new Date().getFullYear();
  const { displayMonth, onChange, containerId } = props;

  const years = [];

  // Dropdown should appear only when both from/toDate is set
  if (!fromDate) return <></>;
  if (!toDate) return <></>;

  const fromYear = fromDate.getFullYear();
  const toYear = toDate.getFullYear();
  for (let year = fromYear; year <= toYear; year++) {
    years.push(setYear(startOfYear(new Date()), year));
  }

  const handleChange = (value) => {
    const newMonth = setYear(startOfMonth(displayMonth), Number(value));
    onChange && onChange(newMonth);
  };

  return (
    <Dropdown
      name="years"
      containerId={containerId}
      aria-label={labelYearDropdown()}
      className={contextClassNames.dropdown_year}
      style={styles.dropdown_year}
      value={displayMonth.getFullYear()}
      month={displayMonth}
      caption={formatYearCaption(displayMonth, { locale })}
    >
      {years.map((year) => {
        const value = year.getFullYear();
        return (
          <div
            key={value}
            value={value}
            className={classNames("textFont", {
              active: value === displayMonth.getFullYear(),
              now: value === currentYear,
            })}
            onClick={() => handleChange(value)}
          >
            {formatYearCaption(year, { locale })}
          </div>
        );
      })}
    </Dropdown>
  );
}

export default customWithErrorBoundary(memo(Year));
