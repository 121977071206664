import { useRef, useState } from "react";
import Message from "src/components/message";
import { fetchStatus } from "src/constants";
import buildRequest from "src/request/axios";
import { downloadFile } from "src/utils";

const exportCommonFn = async (
  changeStatus,
  successFn,
  catchFn,
  dispatch = () => {},
  { downLoadService, taskUrl } = {}
) => {
  try {
    dispatch(changeStatus(fetchStatus.loading));
    const response = await downLoadService();

    if (!response.id) {
      return;
    }

    let timer;

    timer = setInterval(async () => {
      const fileRes = await buildRequest({
        url: `${taskUrl}?id=${response?.id}`,
      });

      if (Number(fileRes?.status) === 3) {
        clearInterval(timer);
        catchFn && catchFn();
        fileRes.error && Message.error(fileRes.error);
      }

      if (fileRes?.progress === 1 && fileRes.fileUrl) {
        clearInterval(timer);
        successFn && successFn();
        downloadFile(fileRes.fileUrl);
      }
    }, 1000);
  } catch (e) {
    catchFn && catchFn();
  }
};

function useExport() {
  const [downLoadStatus, updateDownLoadStatus] = useState(fetchStatus.initial);
  const cancelRef = useRef();
  const resolveRef = useRef();

  new Promise((resolve, reject) => {
    resolveRef.current = resolve;
    cancelRef.current = reject;
  })
    .then(() => {
      updateDownLoadStatus(fetchStatus.success);
    })
    .catch(() => {
      updateDownLoadStatus(fetchStatus.initial);
    });

  const successFn = () => {
    resolveRef.current && resolveRef.current();
  };

  const catchFn = () => {
    cancelRef.current && cancelRef.current();
  };
  const exportFn = exportCommonFn.bind(
    this,
    updateDownLoadStatus,
    successFn,
    catchFn,
    () => {}
  );

  return { downLoadStatus, exportFn, cancelRef, resolveRef };
}

export function exportPureFn({
  dispatch = () => {},
  getResolve = () => {},
  getReject = () => {},
  changeStatus,
  successFn = () => {},
  catchFn = () => {},
} = {}) {
  new Promise((resolve, reject) => {
    getResolve(resolve);
    getReject(reject);
  })
    .then(() => {
      dispatch(changeStatus(fetchStatus.success));
    })
    .catch(() => {
      dispatch(changeStatus(fetchStatus.initial));
    });

  const exportFn = exportCommonFn.bind(
    this,
    changeStatus,
    successFn,
    catchFn,
    dispatch
  );

  return exportFn;
}

export default useExport;
