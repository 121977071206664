import { Tooltip } from "@mui/material";
import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import AsinHover from "src/components/table/components/asinHover";

import style from "./index.module.scss";
import { formatNumber } from "src/utils";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

function AsinInfo({ hover, rowData }) {
  const { t } = useTranslation();
  const features = rowData?.featureValues
    ?.map((item) => item?.value ?? item)
    ?.join(" | ");

  return (
    <div className={style.asinInfoWrap}>
      <AsinHover rowData={rowData} hover={hover} />

      <Tooltip
        title={
          <div>
            <div>{rowData.title}</div>
            <div className={style.attributeTooltip}>{features}</div>
          </div>
        }
      >
        <div className={style.infoDetail}>
          <CellEmpty value={rowData.title} />
        </div>
        <div className={style.attribute}>
          <CellEmpty value={features} />
        </div>
      </Tooltip>
      <div
        className={classNames(style.rating, {
          [style.ratingShow]: hover,
        })}
      >
        <span>
          <CellEmpty value={formatNumber(rowData.reviewCnt)} />(
          <CellEmpty value={rowData.score} />) {t(`ratings`)}
        </span>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(AsinInfo));
