import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import languageDetector from "./detector";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?${process.env.HASH_ID}`,
    },
    interpolation: {
      escapeValue: false, // React 已经处理了 XSS
    },
    // cookie和localStorage都会同步当前最新的语言字段i18next
    // 回退的语言,备用语言
    fallbackLng: "en",
    // 是否打印帮助信息, 默认就是false
    debug: false,
    parseMissingKeyHandler: (key) => {
      // 如果键在资源文件中找不到，使用键本身作为默认值
      const lastPart = key?.split(".").pop();

      return lastPart;
    },
  });

// 默认会检测用户的浏览器语言
// order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
// LanguageDetector默认会按照上面的顺序进行语言的查找，可以看到navigator的排序是靠后的，在初始状态navigator会生效，但是在用户手动选择了语言之后，会存在localStorage中
i18n.changeLanguage();

export default i18n;
