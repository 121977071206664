import React, { forwardRef, memo, useEffect, useImperativeHandle } from "react";
import { useState } from "react";
import styles from "./MonthPicker.module.scss";
import classNames from "classnames";
import useLanguage from "src/hooksOrClass/language";
import { endOfMonth, isAfter, isBefore, isEqual, startOfMonth } from "date-fns";
import customWithErrorBoundary from "src/components/errorBoundary";
import { isDateValid } from "src/utils";

const MonthPicker = forwardRef((props, ref) => {
  const {
    calendar = 2,
    disabled,
    setIsOpen,
    onChange,
    fromDate,
    toDate,
    range,
    inputValue,
  } = props;
  const currentMonth = new Date().getMonth();
  const currentYear = new Date().getFullYear();

  const fromYear = fromDate?.getFullYear();
  const toYear = toDate?.getFullYear();

  const fromMonth = fromDate?.getMonth();
  const toMonth = toDate?.getMonth();

  const [selected, setSelected] = useState(props.selected ?? undefined);

  const disableAfter = disabled.after;
  const disableAfterMonth = disableAfter.getMonth();
  const disableAfterYear = disableAfter.getFullYear();

  let { language } = useLanguage();

  const rangeYear = range?.to ?? range?.from;

  const [year, setYear] = useState(
    (isDateValid(inputValue) ? inputValue?.getFullYear() : undefined) ??
      (isDateValid(rangeYear) ? rangeYear.getFullYear() : undefined) ??
      selected?.maxYear ??
      selected?.year ??
      currentYear
  );

  const arr = new Array(calendar).fill(year).map((item, idx) => {
    return item - (calendar - 1 - idx);
  });

  useEffect(() => {}, []);

  const changeYear = (year) => {
    setYear(year);
  };

  useImperativeHandle(ref, () => ({
    setMonth: (date) => {
      const year = new Date(date).getFullYear();
      changeYear(year);
    },
  }));

  const getMonthNames = (format = "short") => {
    const formatter = new Intl.DateTimeFormat(language, {
      month: format,
      timeZone: "UTC",
    });

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
      const mm = month < 10 ? `0${month}` : month;
      // 这里的1994只是为了获取month的时间
      return new Date(`1994-${mm}-01T00:00:00+00:00`);
    });
    return months.map((date) => formatter.format(date));
  };

  const changeMonth = (month, year) => {
    setSelected({
      month,
      year,
    });
    setIsOpen && setIsOpen(false);
    onChange &&
      onChange({
        month,
        year,
        maxYear: arr[arr.length - 1],
      });
  };

  return (
    <div className={classNames(styles.pickerContainer, "textFont")}>
      <div className={styles.yearContainer}>
        <button
          className={classNames(styles.button, {
            iconButtonDisabled: arr[0] <= fromYear,
          })}
          aria-label="Previous Year"
          onClick={(e) => {
            if (arr[0] <= fromYear) {
              return;
            }
            changeYear(year - calendar);
          }}
        >
          <span className="icon-previous"></span>
        </button>
        <span className={styles.yearContent}>
          {arr.map((item, idx) => {
            return (
              <span
                aria-description="Year selected"
                className={classNames({
                  [styles.disabeld]: item > toYear || item < fromYear,
                })}
                key={idx}
              >
                {item}
              </span>
            );
          })}
        </span>
        <button
          className={classNames(styles.button, styles.nextButton, {
            iconButtonDisabled: year >= toYear,
          })}
          aria-label="Next Year"
          onClick={(e) => {
            if (year >= toYear) {
              return;
            }
            changeYear(year + calendar);
          }}
        >
          <span className="icon-next"></span>
        </button>
      </div>
      <div className={styles.calendarWrap}>
        {arr.map((itemYear, idx) => {
          return (
            <div className={styles.monthsContainer} key={idx}>
              {getMonthNames().map((monthName, index) => {
                const disabled =
                  (itemYear === disableAfterYear &&
                    index > disableAfterMonth) ||
                  itemYear > disableAfterYear;

                const disabeldDate =
                  itemYear > toYear ||
                  itemYear < fromYear ||
                  (itemYear === toYear && index > toMonth) ||
                  (itemYear === fromYear && index < fromMonth);

                const finalDisabled = disabled || disabeldDate;

                const monthNum = index + 1;
                const currentDate = `${itemYear}-${
                  monthNum >= 10 ? monthNum : `0${monthNum}`
                }-01`;

                let rangeClass = "";
                if (
                  isEqual(
                    startOfMonth(currentDate),
                    startOfMonth(range?.from)
                  ) &&
                  isEqual(startOfMonth(currentDate), startOfMonth(range?.to))
                ) {
                  rangeClass = styles.rangeActive;
                } else if (
                  isEqual(startOfMonth(currentDate), startOfMonth(range?.from))
                ) {
                  rangeClass = range?.to
                    ? styles.rangeStart
                    : styles.rangeActive;
                } else if (
                  isEqual(endOfMonth(currentDate), endOfMonth(range?.to))
                ) {
                  rangeClass = range?.from
                    ? styles.rangeEnd
                    : styles.rangeActive;
                } else if (
                  isAfter(
                    startOfMonth(currentDate),
                    startOfMonth(range?.from)
                  ) &&
                  isBefore(endOfMonth(currentDate), endOfMonth(range?.to))
                ) {
                  rangeClass = styles.rangeMiddle;
                }

                return (
                  <button
                    key={index}
                    className={classNames(styles.month, styles.button, {
                      [styles.active]:
                        index === currentMonth && currentYear === itemYear,
                      [styles.selected]:
                        selected?.month === index &&
                        selected?.year === itemYear &&
                        !range,
                      [styles.disabeld]: finalDisabled,
                      [rangeClass]: rangeClass,
                    })}
                    onClick={(e) =>
                      !finalDisabled && changeMonth(index, itemYear)
                    }
                  >
                    {monthName}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default customWithErrorBoundary(memo(MonthPicker));
