import { memo } from "react";
import CellEmpty from "src/components/table/cellEmpty";
import { getTypeKeyWordsLink } from "../../util";
import { useSelector } from "react-redux";
import { openNewUrl } from "src/utils";
import classNames from "classnames";
import style from "./index.module.scss";

function TrafficKeyWords({ type, rowData }) {
  const baseSearch = useSelector((state) => state.structure.baseSearch);

  const data = rowData[type];

  const { code, count: value } = data;
  const disabled = !value;

  const handleSearchKeyword = () => {
    try {
      if (disabled) {
        return;
      }
      const url = getTypeKeyWordsLink({
        ...rowData,
        time: baseSearch.time,
        type: code,
      });

      openNewUrl(url);
    } catch (e) {}
  };

  return (
    <div
      onClick={handleSearchKeyword}
      className={classNames(style.keywords, {
        underDashLine: value,
        disabled: !value,
      })}
    >
      <CellEmpty value={value} />
    </div>
  );
}

export default memo(TrafficKeyWords);
