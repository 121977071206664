import classNames from "classnames";
import style from "./index.module.scss";
import { ReactComponent as QAIcon } from "./Q&A.svg";
import PreviewModal from "./previewModal";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import customWithErrorBoundary from "../errorBoundary";
const isDev =
  process.env.NODE_ENV === "development" ||
  window.location.origin.includes("dev.minusflow.com");
function MustRead({ info = {} }) {
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const previewRef = useRef({});
  const location = useLocation();
  let { title = "", content = "", imgs = [], userManualUrl, qaUr } = info || {};

  const handleOk = useCallback((ele) => {
    const value = ele?.value;
    previewRef.current.content = value;
    setPreviewModalOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setPreviewModalOpen(false);
  }, []);

  useEffect(() => {
    previewRef.current = {};
  }, [location]);

  if (!title) {
    return null;
  }

  content = previewRef.current?.content || content;

  const btns = [
    {
      icon: "icon-user-manual",
      name: "User manual",
      link: userManualUrl,
    },
    {
      icon: <QAIcon />,
      name: "Q&A",
      link: qaUr,
    },
  ];

  const handleClick = (link) => {
    link && window.open(link);
  };

  const handleEdit = () => {
    isDev && setPreviewModalOpen(true);
  };

  return (
    <>
      <div className={style.wrap}>
        <div className={classNames(style.mustReadWrap, "wrap", "not-content")}>
          <div
            className={classNames(style.title, "faqTitleFont")}
            onClick={handleEdit}
          >
            {title}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className={classNames(style.contet, "mustTextFont")}
          ></div>
          <div className={style.btnsWrap}>
            {btns.map((item) => {
              return (
                <span
                  key={item.name}
                  className={classNames(style.btn, "button", "button-hover")}
                  onClick={() => handleClick(item.link)}
                >
                  {typeof item.icon === "string" ? (
                    <span className={item.icon}></span>
                  ) : (
                    item.icon
                  )}
                  <span>{item.name}</span>
                </span>
              );
            })}
          </div>
        </div>
        {imgs.map((src) => (
          <div
            className={classNames(style.mediaWrap, "wrap", "not-content")}
            key={src}
          >
            <img src={src} />
          </div>
        ))}
      </div>

      {isDev && (
        <PreviewModal
          previewModalOpen={previewModalOpen}
          handleOk={handleOk}
          content={content}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default customWithErrorBoundary(memo(MustRead));
