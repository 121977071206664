import { memo, useContext, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Empty from "src/components/empty";
import Export from "src/components/export";
import FlowTable from "src/components/table";
import CustomSelect from "src/components/customSelect";
import customWithErrorBoundary from "src/components/errorBoundary";

import { actionTypeEnum, fetchStatus } from "src/constants";
import { formatNumber, isAsinMultiple } from "src/utils";
import EmptyTips from "../tips";

import { getColumns } from "./columns";
import { downloadTableDataThunk } from "../../store";

import { SalesContext } from "../../context";
import style from "./index.module.scss";
import { getTrafficOption } from "../../util";

function SalesTable() {
  const { t } = useTranslation();
  const { tableKey: name } = useContext(SalesContext);

  const selected = useSelector((state) => state.sales.selected);
  const selectedRef = useRef(selected);

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const asins = useSelector((state) => state.sales.baseSearch.asins);
  const actionType = useSelector((state) => state.sales.baseSearch.actionType);

  const tableDownLoadStatus = useSelector(
    (state) => state.sales.tableDownLoadStatus
  );

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  const columnType = useSelector((state) => state.sales.columnType);

  const columns = useMemo(() => {
    return getColumns(tableData?.features, columnType);
  }, [tableData, columnType]);

  const [defaultOption, options] = getTrafficOption(
    isAsinMultiple(asins),
    true,
    { asin: asins }
  );

  return (
    <div
      className={classNames("wrap", style.tableWrap, {
        wrapMini:
          tableFetchStatus === fetchStatus.loading ||
          tableFetchStatus === fetchStatus.error,
      })}
    >
      <div className={classNames("titleSummary", style.info)}>
        <div>
          <span className="listSummaryFont">
            {actionType === actionTypeEnum.asin
              ? t("sales.salesGreater50", {
                  count: formatNumber(tableData?.hasBoughtCount) || 0,
                  sum: formatNumber(tableData?.variantCount),
                })
              : t(`sales.keywordFirst3Page`, {
                  sum: formatNumber(tableData?.total),
                })}
          </span>
        </div>
        <div className="btnsRightWrap">
          <Export
            disabled={!tableData?.total}
            loading={tableDownLoadStatus === fetchStatus.loading}
            onDownload={() => {
              dispatch(downloadTableDataThunk({ key: name }));
            }}
          />
          {actionType === actionTypeEnum.asin && (
            <CustomSelect
              defaultConfig={{
                ...defaultOption,
                title: t(`router.${defaultOption.title}`),
                style: {
                  color: "var(--link-color)",
                },
              }}
              options={options.map((item) => ({
                ...item,
                title: t(`router.${item.title}`),
              }))}
            />
          )}
        </div>
      </div>
      <FlowTable columns={columns} translationKey="sales" name={name} />

      <Empty
        data={tableData?.list}
        status={
          tableData?.total === undefined
            ? tableFetchStatus
            : memoTableFetchStatus
        }
        tips={<EmptyTips />}
      ></Empty>
    </div>
  );
}

export default memo(customWithErrorBoundary(SalesTable));
