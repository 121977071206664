import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDrawer from "src/components/customDrawer";
import BoxLoading from "src/components/loading/box";
import ButtonTab from "src/components/buttonTab";
import { useTranslation } from "react-i18next";
import VolumeChart from "./chart";

import { updateVolumeDrawerOpen } from "src/store/rootReducer";
import { volumeTrend } from "./constant";

import style from "./index.module.scss";
import { searchesNumHistory } from "./service";

let controller;
let dataReject;

function DrawVolumeTrend() {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const [data, setData] = useState({});
  const open = useSelector((state) => state.root.volumeDrawerOpen);
  const activeData = useSelector((state) => state.root.activeData);
  const [isLoading, setIsLoading] = useState(false);
  const tabsData = volumeTrend.map((item) => ({
    ...item,
    name: t(`drawVolumeTabs.${item.code}`),
  }));

  const [activeCode, setActiveCode] = useState(volumeTrend[0].code);

  function reset() {
    setIsLoading(false);
    setActiveCode(volumeTrend[0].code);
    setData({});
    controller?.abort();
    dataReject();
  }
  const handleClose = useCallback(() => {
    dispath(updateVolumeDrawerOpen(false));
    reset();
  }, []);

  const handleChange = useCallback((code) => {
    setActiveCode(code);
  }, []);

  async function getVolumeTrendData() {
    new Promise(async (resolve, reject) => {
      controller?.abort();
      controller = new AbortController();
      dataReject = reject;

      setIsLoading(true);
      const paramsData = {
        keyword: activeData.keyword,
        abaType: activeCode?.toLocaleUpperCase(),
      };
      const data = await searchesNumHistory({
        data: paramsData,
        signal: controller.signal,
      });
      resolve(data);
    }).then((data) => {
      setData(data);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (open) {
      getVolumeTrendData();
    }
  }, [open, activeCode]);

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      title={t(`drawVolumeTitle`)}
    >
      <BoxLoading isLoading={isLoading} isNotTop={true}>
        <ButtonTab
          data={tabsData}
          activeCode={activeCode}
          onChangeActive={handleChange}
        />
        <div className={style.chartWrap}>
          {typeof data === "object" && Object.keys(data)?.length > 0 && (
            <VolumeChart originData={data} />
          )}
        </div>
      </BoxLoading>
    </CustomDrawer>
  );
}

export default DrawVolumeTrend;
