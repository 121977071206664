import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { times } from "src/constants/time";
import { dimensionEnum, fetchStatus } from "src/constants";
import { exportPureFn } from "src/hooksOrClass/export";
import { updateGlobalLoading } from "src/store/rootReducer";
import {
  downloadStructureChartApi,
  downloadStructureTableDataApi,
  getChartDataApi,
  getWordsTotalApi,
} from "./service";
import { asinsKey, timeKey } from "src/constants/form";
import { formatAsinArray } from "src/utils";
import { chartOriginData } from "./constant";
import { formatTableSearch } from "src/utils/table";

let tableCancelReject;
let tableResolvePromise;

let chartCancelReject;
let chartResolvePromise;
let chartController;

let wordsController;

const initialState = {
  baseSearch: {
    [timeKey]: times.week,
    [asinsKey]: "",
  },
  chartSearch: {
    dimension: dimensionEnum.asin,
    pageNum: 1,
    pageSize: 5,
  },
  chartData: {},
  wordsTotal: {},
  chartFetchStatus: fetchStatus.initial,
  chartDownloadStatus: fetchStatus.initial,

  tableDownloadStatus: fetchStatus.initial,

  isTableDataMultipleAsin: false,
  memoBaseSearch: {},
};

export const StructureSlice = createSlice({
  name: "structure",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    syncBaseSearch: (state, action) => {
      state.baseSearch = action.payload;
      chartCancelReject && chartCancelReject();
      state.chartDownLoadStatus = fetchStatus.initial;

      tableCancelReject && tableCancelReject();
      state.tableDownLoadStatus = fetchStatus.initial;
    },
    updateBaseTime: (state, action) => {
      state.baseSearch.time = action.payload;
    },
    updateChartFetchStatus: (state, action) => {
      state.chartFetchStatus = action.payload;
    },
    updateIsTableDataMultipleAsin: (state, action) => {
      state.isTableDataMultipleAsin = action.payload;
    },
    updateChartDownLoadStatus: (state, action) => {
      state.chartDownloadStatus = action.payload;
    },
    updateChangeDimension: (state, action) => {
      state.chartSearch.dimension = action.payload;
    },
    updateChartSearchPageNum: (state, action) => {
      state.chartSearch.pageNum = action.payload;
    },
    updateMemoBaseSearch: (state, action) => {
      state.memoBaseSearch = state.baseSearch;
    },
    updatTableDownLoadStatus: (state, action) => {
      state.tableDownloadStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartDataThunk.fulfilled, (state, action) => {
      const responseData = action.payload;
      state.chartData = responseData;
    });
    builder.addCase(getWordsTotalThunk.fulfilled, (state, action) => {
      state.wordsTotal = action.payload;
    });
  },
});

export const {
  resetStore,
  syncBaseSearch,
  updateBaseTime,
  updateMemoBaseSearch,
  updateChartFetchStatus,
  updateChartDownLoadStatus,
  updateChangeDimension,
  updateChartSearchPageNum,
  updatTableDownLoadStatus,
  updateIsTableDataMultipleAsin,
} = StructureSlice.actions;

export const downloadStructureChartDataThunk = createAsyncThunk(
  "data/exportStructureChart",
  async (data, { dispatch, getState }) => {
    function getResolve(resolve) {
      chartResolvePromise = resolve;
    }

    function getReject(reject) {
      chartCancelReject = reject;
    }

    function successFn() {
      chartResolvePromise && chartResolvePromise();
    }

    function catchFn() {
      chartCancelReject && chartCancelReject();
    }

    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updateChartDownLoadStatus,
      successFn,
      catchFn,
    });

    const currentState = getState().structure;
    const taskUrl = "/api/web/export/progress/traffic_structure";

    exportFn({
      downLoadService: () => {
        return downloadStructureChartApi(currentState);
      },
      taskUrl,
    });
  }
);

export const exportStructureTableThunk = createAsyncThunk(
  "data/exportStructureTable",
  async (data, { dispatch, getState }) => {
    const key = data?.key;

    if (!key) {
      return;
    }
    function getResolve(resolve) {
      tableResolvePromise = resolve;
    }

    function getReject(reject) {
      tableCancelReject = reject;
    }

    function successFn() {
      tableResolvePromise && tableResolvePromise();
    }

    function catchFn() {
      tableCancelReject && tableCancelReject();
    }

    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updatTableDownLoadStatus,
      successFn,
      catchFn,
    });

    const allState = getState();
    const currentState = allState.structure;
    const tableSearchData = allState?.root?.table[key]?.tableSearch;

    exportFn({
      downLoadService: () => {
        return downloadStructureTableDataApi(
          currentState,
          formatTableSearch(tableSearchData)
        );
      },
      taskUrl: "/api/web/export/progress/traffic_structure_chart",
    });
  }
);

export const getChartDataThunk = createAsyncThunk(
  "data/structureGetChartData",
  async (data, { getState, dispatch }) => {
    chartCancelReject && chartCancelReject();
    const pageNum = data?.pageNum || 1;
    dispatch(updateChartFetchStatus(fetchStatus.loading));

    dispatch(updateChartSearchPageNum(pageNum));
    chartController?.abort();
    chartController = new AbortController();

    const currentState = getState().structure;

    const response = await getChartDataApi(
      currentState,
      chartController.signal
    );

    if (!response) {
      dispatch(updateChartFetchStatus(fetchStatus.error));
    } else {
      dispatch(updateChartFetchStatus(fetchStatus.success));
      dispatch(updateGlobalLoading(false));
    }

    return response;
  }
);

export const getWordsTotalThunk = createAsyncThunk(
  "data/structureGetWordsTotal",
  async (data, { getState, dispatch }) => {
    const currentState = getState().structure;

    if (wordsController) {
      wordsController.abort();
    }
    wordsController = new AbortController();
    const response = await getWordsTotalApi(
      {
        asins: formatAsinArray(currentState.baseSearch.asins),
        time: currentState.baseSearch.time,
      },
      wordsController.signal
    );

    return response;
  }
);

export default StructureSlice.reducer;
