import classNames from "classnames";
import { memo, useContext } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TableContext } from "./context";

import style from "./index.module.scss";
import { downLoadKeywordsThunk } from "src/store/rootReducer";
import BaseLoading from "../baseLoading";
import { fetchStatus } from "src/constants";

function DownloadKeyword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { name, pageKey } = useContext(TableContext);

  const downloadKeywordsStatus = useSelector(
    (state) => state.root.table[name].downloadKeywordsStatus
  );

  const selected = useSelector((state) => state.root.table[name].selected);

  const numSelected = selected?.length;

  const handleDownLoad = () => {
    dispatch(downLoadKeywordsThunk({ key: name, pageKey }));
  };

  const maxNum = 10;

  return (
    <BaseLoading
      disabled={numSelected > maxNum}
      className={classNames(style.globalBtn)}
      loading={downloadKeywordsStatus === fetchStatus.loading}
      onClick={handleDownLoad}
    >
      {`${t("globalBtn.downloadKeywords")} (${numSelected}/${maxNum})`}
    </BaseLoading>
  );
}

export default customWithErrorBoundary(memo(DownloadKeyword));
