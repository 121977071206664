import React, { memo, useMemo, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";

import customWithErrorBoundary from "src/components/errorBoundary";
import ChartDataLabels from "chartjs-plugin-datalabels";
import style from "./index.module.scss";

import { getPlaceHolderData, toRGBA } from "src/utils";

import { chartColors } from "src/utils/chart/util";
import { useSelector } from "react-redux";

import Info from "./info";
import { getSerialNumber } from "src/utils/table";
import Nav from "./nav";
import { getOptions } from "./options";
import { chartDataOptionList } from "../../constant";

function BarChart({ memoChartSearch = {} }) {
  const chartRef = useRef();
  const barRef = useRef();
  const chartData = useSelector((state) => state.structure.chartData);
  const activeCode = useSelector(
    (state) => state.structure.chartSearch.dimension
  );
  const updateLengedRef = useRef();

  const memoActiveCode = useMemo(() => {
    return activeCode;
  }, [chartData]);

  if (!chartData?.total) {
    return <div style={{ height: "300px" }}></div>;
  }
  const chartSearch = memoChartSearch;

  const stack = "stack-0";

  const datasets = [
    {
      data: chartData?.ogScores,
    },
    {
      data: chartData?.spScores,
    },
    {
      data: chartData?.sbScores,
    },
    {
      data: chartData?.sbvScores,
    },
    {
      data: chartData?.acScores,
    },
    {
      data: chartData?.otherRecScores,
    },
  ]?.map((item, idx) => ({
    ...item,
    label: chartDataOptionList()?.[idx],
  }));

  const options = getOptions();

  const data = {
    labels: chartData?.list?.map((item, idx) => {
      const serialNumber = getSerialNumber({
        page: chartSearch.pageNum,
        pageSize: chartSearch.pageSize,
        idx,
      });

      return `#${serialNumber}-${item?.dimVal}`;
    }),

    datasets: datasets?.map((item, idx) => {
      const last = idx === datasets.length - 1;
      idx = last ? idx + 1 : idx;
      return {
        ...item,
        type: "bar",
        backgroundColor: chartColors[idx].trim(),
        noHoverColor: toRGBA(chartColors[idx].trim(), 0.1),
        originBackgroundColor: chartColors[idx].trim(),
        textColor: "rgba(0,0, 0, 1)",
        originTextColor: "rgba(0,0, 0, 1)",
        stack: stack,
      };
    }),
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      if (updateLengedRef.current) {
        return;
      }

      updateLengedRef.current = true;
      chartRef.current = chart;
      // 下面这句话不能删，不然会导致图例点击不能切换图表的展示与隐藏
      chart.options.plugins.legend.labels.generateLabels(chart);
    },
  };

  const length = chartData?.list?.length;

  return (
    <div>
      <Nav chartRef={chartRef} />
      <div className={style.barAndInfo}>
        <div className={style.info}>
          {chartData?.list?.map((item, idx) => {
            const serialNumber = getSerialNumber({
              page: chartSearch.pageNum,
              pageSize: chartSearch.pageSize,
              idx,
            });
            return (
              <Info
                info={item}
                memoActiveCode={memoActiveCode}
                key={item.value}
                serialInfo={`#${serialNumber}-${getPlaceHolderData(
                  item.dimVal
                )}`}
              />
            );
          })}
        </div>
        <div
          className={style.bar}
          style={{ height: `${length * (104 + 16) + 12}px` }}
        >
          <Bar
            ref={barRef}
            data={data}
            options={options}
            plugins={[ChartDataLabels, htmlLegendPlugin]}
          />
        </div>
      </div>
    </div>
  );
}
export default customWithErrorBoundary(memo(BarChart));
