import { useState } from "react";
import { endOfWeek, startOfWeek } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { startTime } from "src/constants";
import head from "./head";
import "./index.scss";

function SingleWeekRange(props) {
  const { onChange, value } = props;
  const handleSelect = (range, date) => {
    const startOfWeekDate = startOfWeek(date);
    const endOfWeekDate = endOfWeek(date);
    setRange({
      from: startOfWeekDate,
      to: endOfWeekDate,
    });

    onChange &&
      onChange({
        type: "week",
        date: startOfWeekDate,
      });
  };
  const [range, setRange] = useState(
    value?.date instanceof Date && value?.type === "week"
      ? {
          from: startOfWeek(value?.date),
          to: endOfWeek(value?.date),
        }
      : undefined
  );

  return (
    <div className="week-range-picker">
      <DayPicker
        showOutsideDays
        mode="range"
        defaultMonth={range?.from}
        captionLayout="dropdown-buttons"
        fromDate={new Date(startTime)}
        toDate={endOfWeek(new Date())}
        components={{
          Caption: head,
        }}
        disabled={{ after: new Date() }}
        modifiers={{ booked: new Date() }}
        modifiersClassNames={{ booked: "booked" }}
        selected={range}
        onSelect={handleSelect}
      />
    </div>
  );
}

export default SingleWeekRange;
