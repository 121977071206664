import { useTranslation } from "react-i18next";
import { useMatches } from "react-router";
import classNames from "classnames";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "./index.module.scss";

import { useRouterId } from "src/hooksOrClass/getRouterId";

const actionArr = [
  {
    icon: "icon-user-manual",
    id: "userMannul",
  },
  {
    icon: "icon-Feedback",
    id: "feedback",
  },
  {
    icon: "icon-duplicate-page",
    id: "duplicatePage",
  },
  {
    icon: "icon-new",
    id: "new",
  },
];

function HorizontalBar() {
  const { t } = useTranslation();
  const [routerId] = useRouterId();

  return (
    <>
      <div className={classNames(style.horizontalBar, "topTextFont")}>
        <div className={style.routerName}>{t(`router.${routerId}`)}</div>
        <div className={style.actionWrap}>
          {actionArr.map((item) => (
            <div
              key={item.id}
              className={classNames("button", "button-hover", style.actionItem)}
            >
              {typeof item.icon === "string" ? (
                <span className={item.icon}></span>
              ) : (
                item.icon
              )}
              <span>{t(`router.${item.id}`)}</span>
            </div>
          ))}
        </div>
      </div>
      <div>
        <span className="icon-Resources"></span>
      </div>
    </>
  );
}

export default customWithErrorBoundary(HorizontalBar);
