import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import Tip from "src/components/tooltip/tip";
import { getPercentage, getPlaceHolderData } from "src/utils";

function Traffic({ rowData }) {
  const { t } = useTranslation();
  const trafficDataOfCode = useSelector(
    (state) => state.keywords.trafficDataOfCode
  );

  const trafficRatioDetail = rowData?.trafficRatioDetail;
  const tableSearchOptions = useSelector(
    (state) => state.keywords.tableSearchOptions
  );

  const { trafficTypes } = tableSearchOptions || {};

  let content = "";
  trafficTypes?.forEach((item) => {
    content += `<li>${item.name}: ${getPlaceHolderData(
      getPercentage(trafficRatioDetail?.[item.code])
    )}</li>`;
  });

  return (
    <Tip
      title={t(`keywords.tableTip.traffic`, {
        keyword: rowData?.keyword,
        content,
      })}
    >
      <div>
        <CellEmpty
          value={getPercentage(
            trafficDataOfCode === "all"
              ? rowData.trafficRatio
              : trafficRatioDetail?.[trafficDataOfCode]
          )}
        />
      </div>
    </Tip>
  );
}

export default customWithErrorBoundary(memo(Traffic));
