import { weekMonthEnum } from "src/constants/time";

export const formatParamsEnum = {
  en: {
    [weekMonthEnum.weekly]: "MM/dd/yyyy",
    [weekMonthEnum.monthly]: "MM/yyyy",
  },
  jp: {
    [weekMonthEnum.weekly]: "MM/dd/yyyy",
    [weekMonthEnum.monthly]: "MM/yyyy",
  },
};

export const connectSymbol = "-";

export const standardFormatParams = {
  [weekMonthEnum.weekly]: "yyyy-MM-dd",
  [weekMonthEnum.monthly]: "yyyy-MM",
};
