import { Tooltip } from "@mui/material";
import classNames from "classnames";

import { memo } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";

function Collect({
  inLibrary,
  placement = "top",
  isBtn = false,
  disabled = false,
  size = 16,
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={!inLibrary ? t(`addProduct`) : t(`editProduct`)}
      placement={placement}
    >
      <div
        className={classNames({
          button: isBtn,
          disabled: disabled,
        })}
      >
        <span
          style={{ fontSize: size + "px" }}
          className={!inLibrary ? "icon-add-to-my-list" : "icon-edit-my-list"}
        ></span>
      </div>
    </Tooltip>
  );
}

export default customWithErrorBoundary(memo(Collect));
