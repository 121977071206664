import { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { ReactComponent as UpIcon } from "./up.svg";

function TopAction() {
  const [showPlace, setShowPlace] = useState(false);
  const showPlaceRef = useRef();
  const [showAction, setShowAction] = useState(false);
  const placeRef = useRef(null);
  const distanceCoefficient = 1.01;

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (
          entry.contentRect.height >
          distanceCoefficient * window.innerHeight
        ) {
          setShowPlace(true);
          showPlaceRef.current = true;
        } else {
          setShowPlace(false);
          showPlaceRef.current = false;
        }
      }
    });

    // 监听整个页面的高度变化
    resizeObserver.observe(document.documentElement);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // 根据交叉状态做出响应
        if (
          entry.isIntersecting ||
          (entry.boundingClientRect.y < 0 && showPlaceRef.current)
        ) {
          setShowAction(true);
          // 元素进入视口
        } else {
          setShowAction(false);
        }
      });
    });

    observer.observe(placeRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {/* 该按钮只有在用户滚动距离超过一屏之后才会触发 */}
      {showAction ? (
        <div className={style.topAction} onClick={handleScrollToTop}>
          <UpIcon />
        </div>
      ) : null}
      <div
        ref={placeRef}
        className={style.palceHolder}
        style={{ top: showPlace ? `${100 * distanceCoefficient}vh` : "" }}
      ></div>
    </>
  );
}

export default TopAction;
