import { memo } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "../errorBoundary";
import style from "./tip.module.scss";
import classNames from "classnames";

function Tips({ num, reasons }) {
  const { t } = useTranslation();

  return (
    <div className={style.wrap}>
      <div className={classNames(style.title)}>
        {t(`emptyTips.title`, { num: t(num || reasons.length) })}
      </div>
      <ol>
        {reasons.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ol>
    </div>
  );
}

export default customWithErrorBoundary(memo(Tips));
