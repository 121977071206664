import { Tooltip, TooltipProps } from "@mui/material";

interface Props extends TooltipProps {
  children: React.ReactElement,
  tooltipSx?: React.CSSProperties,
  tooltipClass?: string
  paddingY?: string
  popper?: any;
}


function SimpleTooltip({ children, tooltipSx = {}, tooltipClass, paddingY, ...otherProps }: Props) {
  return (
    <Tooltip
      {...otherProps}
      classes={{ tooltip: tooltipClass }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#FFF", // 设置背景色为黑色
            color: "var(--color)",
            "& .MuiTooltip-arrow": {
              color: "#FFF",
            },
            paddingY: paddingY || "20px",
            ...tooltipSx,
          },
        },
      }}
    >
      {children}

    </Tooltip>
  );
}

export default SimpleTooltip;
