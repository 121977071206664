import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";

import style from "./index.module.scss";
import ActionViewChart from "./chart";
import { getPlaceHolderData } from "src/utils";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import HoverAdOverview from "../hoverAdOverview";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveDateInCampaignId } from "../../store";
import classNames from "classnames";
import commonStyle from "../../index.module.scss";
import { usePosition } from "../../hook";
import Start from "./start";
import { getPosition } from "../../util";
import {
  connectSymbol,
  standardFormatParams,
} from "src/components/picker/inputRange/constant";
import { endOfDay, format } from "date-fns";
import { formatStandardDate } from "src/components/picker/inputRange/util";
import { periodKey } from "src/constants/form";
function ActionView({ data, dates, trafficTime, length }) {
  console.log("渲染了多少次");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timerRef = useRef();
  const nodeRef = useRef();
  const [showDates] = usePosition({
    nodeRef: nodeRef,
    data: dates,
  });

  const ads = data?.ads;
  const newAds = [];
  const activeDateInCampaignId = useSelector(
    (state) => state.adStrategy.activeDateInCampaignId
  );

  Object.keys(ads)?.forEach((key) => {
    const newObj = { ...ads[key], position: getPosition({ dates, key }) };

    newAds.push(newObj);
  });

  const handleMouseEnter = (item) => {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      const date = item?.date;
      dispatch(
        updateActiveDateInCampaignId(`${date}In${data?.displayCampaignId}`)
      );
    }, 300);
  };

  const tooltipSx = useMemo(() => {
    return {
      maxWidth: "none",
      width: "600px",
    };
  }, []);

  const popperProps = useMemo(() => {
    return {
      style: { zIndex: 1100 },
    };
  }, []);

  const tipsPopperProps = useMemo(() => {
    return {
      style: { zIndex: 2 },
      modifiers: [
        {
          name: "flip",
          enabled: false, // 禁用翻转
        },
      ],
    };
  }, []);

  const handleClickAway = useCallback((e) => {
    dispatch(updateActiveDateInCampaignId(""));
  }, []);

  const handleEnterHover = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  const handleCancelClickAway = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);
  const type = useSelector(
    (state) => state.adStrategy?.baseSearch?.[periodKey]
  );

  const { startFn, endFn } = useSelector(
    (state) => state.adStrategy.dateFormatFn
  );

  let left, right, isLine;
  if (trafficTime) {
    const [startDate, endDate] = trafficTime?.split(connectSymbol) || [];
    isLine =
      endOfDay(endFn(startDate)).getTime() === endOfDay(endDate).getTime();
    left = getPosition({ dates, key: formatStandardDate(type, startDate) });
    if (!isLine) {
      right = getPosition({
        dates,
        key: format(startFn(endDate), standardFormatParams[type]),
      });
    }
  }

  return (
    <div className={style.actionViewWrap} ref={nodeRef}>
      {trafficTime && (
        <div
          className={classNames(style.mark, { [style.line]: isLine })}
          style={{
            left,
            width: 100 - (100 - parseFloat(right)) - parseFloat(left) + "%",
            height: `calc(${100 * length + "%"} + ${
              90 * (length - 1) - 20
            }px )`,
          }}
        ></div>
      )}

      <div className={style.canvasWrap}>
        <ActionViewChart data={data.chartData} dates={dates} />
      </div>

      {newAds?.map((item, idx) => {
        const num = getPlaceHolderData(item?.encryptAdIds?.length);
        const key = `${item.date}In${data?.displayCampaignId}`;
        const open = activeDateInCampaignId === key;
        const showStart = idx == 0;
        const element = (
          <div
            onClick={handleCancelClickAway}
            className={classNames(style.encryptAdIdsWrap, {
              [style.active]: open,
              [style.start]: showStart,
            })}
            onMouseEnter={() => handleMouseEnter(item)}
          >
            <span>+{num}</span>
          </div>
        );

        return (
          <div key={key}>
            <SimpleTooltip
              leaveDelay={500}
              placement="top"
              classes={style.tooltipWrap}
              tooltipSx={tooltipSx}
              PopperProps={popperProps}
              key={item?.date}
              onClose={handleClickAway}
              open={open}
              title={
                <HoverAdOverview
                  onMouseEnter={handleEnterHover}
                  data={item}
                  displayCampaignId={data?.displayCampaignId}
                />
              }
            >
              <div className={style.whiteBack} style={{ left: item.position }}>
                {showStart ? (
                  <SimpleTooltip
                    open={idx === 0}
                    tooltipClass={style.startTooltip}
                    title={<Start data={item} />}
                    placement="top"
                    PopperProps={tipsPopperProps}
                  >
                    {element}
                  </SimpleTooltip>
                ) : (
                  element
                )}
              </div>
            </SimpleTooltip>
          </div>
        );
      })}
      <div className={style.line}></div>
      {showDates?.map((item) => {
        return (
          <div key={item.date}>
            <span
              className={style.verticalLine}
              style={{ position: "absolute", left: item.position }}
            ></span>
            <span
              className={classNames(commonStyle.sawtooth, style.shortLine)}
              style={{ position: "absolute", left: item.position }}
            ></span>
            <span
              className={classNames(
                commonStyle.axisInfoHorText,
                commonStyle.dateText,
                style.dateText
              )}
              style={{ position: "absolute", left: item.position }}
            >
              <div>{item?.showDate}</div>
              <div>{item?.year}&nbsp;</div>
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default customWithErrorBoundary(memo(ActionView));
