import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import Keyword from "src/components/table/components/keyword";
import { periodKey } from "src/constants/form";

function PageKeyword({ rowData }) {
  const time = useSelector(
    (state) => state.keywordsGap?.baseSearch?.[periodKey]
  );

  return <Keyword rowData={rowData} time={time?.toLocaleLowerCase()} />;
}

export default customWithErrorBoundary(memo(PageKeyword));
