import { Box } from "@mui/material";
import { Controller } from "react-hook-form";

import { useEffect } from "react";
import WeekMonthlyTimeSelect from "src/components/timeSelect/weekMonth";
import style from "./index.module.scss";
import { periodKey } from "src/constants/form";

function getWeekMonthComponent({ callback, baseSearchValues }) {
  return function MiddleChildren({
    className,
    control,
    watch,
    handleSubmit,
    menuClassName,
    ...otherPorps
  }) {
    const period = watch(periodKey);

    useEffect(() => {
      if (period && period !== baseSearchValues[periodKey]) {
        callback && callback(period);
        baseSearchValues.asins && handleSubmit();
      }
    }, [period]);

    return (
      <div className={style.middlely}>
        <Box className={className}>
          <Controller
            name={periodKey}
            control={control}
            render={({ field }) => {
              return (
                <WeekMonthlyTimeSelect
                  otherPorps={otherPorps}
                  menuClassName={menuClassName}
                  {...field}
                ></WeekMonthlyTimeSelect>
              );
            }}
          />
        </Box>
      </div>
    );
  };
}

export default getWeekMonthComponent;
