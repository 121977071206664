import { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import Keyword from "src/components/table/components/keyword";
import { periodKey } from "src/constants/form";
import { weekMonthEnum } from "src/constants/time";

function PageKeyword({ rowData }) {
  const time = useSelector(
    (state) => state.adStrategy?.detailEncryptAdInfo?.time
  );

  const type = useSelector(
    (state) => state.adStrategy?.baseSearch?.[periodKey]
  );

  const newTime = useMemo(() => {
    return {
      type: type === weekMonthEnum.weekly ? "week" : "month",
      date: time,
    };
  });
  return (
    <div>
      <Keyword rowData={rowData} time={newTime} placement="right" />
    </div>
  );
}

export default customWithErrorBoundary(memo(PageKeyword));
