import { Modal } from "@mui/material";
import customWithErrorBoundary from "../errorBoundary";
import { memo } from "react";
import style from "./index.module.scss";
import classNames from "classnames";

function CustomModal({ open, children, onClose, title, btns, content }) {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={style.modalWrap}>
        {title && (
          <div className={classNames(style.title, "dialogTitleFont")}>
            {title}
          </div>
        )}
        <div className={classNames(style.contentWrap)}>
          {content && (
            <div
              className={classNames(style.content, "textFont")}
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          )}
          {children}
        </div>
        {btns}
      </div>
    </Modal>
  );
}

export default customWithErrorBoundary(memo(CustomModal));
