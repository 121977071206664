import buildRequest from "src/request/axios";

export const getExamplesData = (modelName, signal) => {
  return buildRequest({
    url: `/api/web/examples?model=${modelName}`,
    extraConfig: {
      signal,
    },
  });
};

export const getSearchRecord = (modelName, signal) => {
  return buildRequest({
    url: `/api/web/user/search_record?model=${modelName}`,
    extraConfig: {
      signal,
    },
  });
};

export const updateListing = (asin) => {
  return buildRequest({
    url: `/api/web/listing/update?asin=${asin}`,
    method: "post",
    extraConfig: {
      showMessage: true,
    },
  });
};
