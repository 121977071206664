import { SwipeableDrawer } from "@mui/material";
import { memo, useEffect, useState } from "react";
import style from "./index.module.scss";
import classNames from "classnames";
function CustomDraw({
  anchor = "bottom",
  children,
  title = "",
  open: PropsOpen = true,
  onClose,
  titleClassName,
  contentClassName,
  onOpen = () => {},
}) {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose(false);
  };

  const [open, setOpen] = useState(PropsOpen);

  useEffect(() => {
    setOpen(PropsOpen);
  }, [PropsOpen]);

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={handleClose}
      onOpen={onOpen}
    >
      <div className={style.titleWrap}>
        <span
          className={classNames(style.title, titleClassName, "dialogTitleFont")}
        >
          {title}
        </span>

        <span className="icon-delete" onClick={handleClose}></span>
      </div>
      <div className={classNames(style.contentWrap, contentClassName)}>
        {children}
      </div>
    </SwipeableDrawer>
  );
}

export default memo(CustomDraw);
