import i18next from "i18next";

export const detailOverId = "overXBubbleWrapId";

export const sortByEnum = {
  ad: "ad",
  campaign: "campaign",
};

export const timeEnum = {
  cycle: "cycle",
  all: "all",
};

export const overCodes = ["sp", "sb", "sbv", "sbSbv"];

export const adStrategyStartTime = "2022-05-29";

export const overData = {
  code: 1,
  data: {
    spTotalNum: 94,
    sbTotalNum: 9,
    sbvTotalNum: 1,
    sbSbvTotalNum: 0,
    dates: [
      "2022-04-24",
      "2022-05-01",
      "2022-05-08",
      "2022-05-15",
      "2022-05-22",
      "2022-05-29",
      "2022-06-05",
      "2022-06-12",
      "2022-06-19",
      "2022-06-26",
      "2022-07-03",
      "2022-07-10",
      "2022-07-17",
      "2022-07-24",
      "2022-07-31",
      "2022-08-07",
      "2022-08-14",
      "2022-08-21",
      "2022-08-28",
      "2022-09-04",
      "2022-09-11",
      "2022-09-18",
      "2022-09-25",
      "2022-10-02",
      "2022-10-09",
      "2022-10-16",
      "2022-10-23",
      "2022-10-30",
      "2022-11-06",
      "2022-11-13",
      "2022-11-20",
      "2022-11-27",
      "2022-12-04",
      "2022-12-11",
      "2022-12-18",
      "2022-12-25",
      "2023-01-01",
      "2023-01-08",
      "2023-01-15",
      "2023-01-22",
      "2023-01-29",
      "2023-02-05",
      "2023-02-12",
      "2023-02-19",
      "2023-02-26",
      "2023-03-05",
      "2023-03-12",
      "2023-03-19",
      "2023-03-26",
      "2023-04-02",
      "2023-04-09",
      "2023-04-16",
      "2023-04-23",
      "2023-04-30",
      "2023-05-07",
      "2023-05-14",
      "2023-05-21",
      "2023-05-28",
      "2023-06-04",
      "2023-06-11",
      "2023-06-18",
      "2023-06-25",
      "2023-07-02",
      "2023-07-09",
      "2023-07-16",
      "2023-07-23",
      "2023-07-30",
      "2023-08-06",
      "2023-08-13",
      "2023-08-20",
      "2023-08-27",
      "2023-09-03",
      "2023-09-10",
      "2023-09-17",
      "2023-09-24",
      "2023-10-01",
      "2023-10-08",
      "2023-10-15",
      "2023-10-22",
      "2023-10-29",
      "2023-11-05",
      "2023-11-12",
      "2023-11-19",
      "2023-11-26",
      "2023-12-03",
      "2023-12-10",
      "2023-12-17",
      "2023-12-24",
      "2023-12-31",
      "2024-01-07",
      "2024-01-14",
      "2024-01-21",
      "2024-01-28",
      "2024-02-04",
      "2024-02-11",
      "2024-02-18",
      "2024-02-25",
      "2024-03-03",
      "2024-03-10",
      "2024-03-17",
      "2024-03-24",
      "2024-03-31",
      "2024-04-07",
      "2024-04-14",
      "2024-04-21",
      "2024-04-28",
      "2024-05-05",
      "2024-05-12",
      "2024-05-19",
      "2024-05-26",
      "2024-06-02",
      "2024-06-09",
      "2024-06-16",
      "2024-06-23",
      "2024-06-30",
      "2024-07-07",
      "2024-07-14",
      "2024-07-21",
      "2024-07-28",
      "2024-08-04",
      "2024-08-11",
      "2024-08-18",
      "2024-08-25",
      "2024-09-01",
      "2024-09-08",
      "2024-09-15",
      "2024-09-22",
      "2024-09-29",
      "2024-10-06",
      "2024-10-13",
    ],
    overviews: {
      "2022-04-24": {
        spNum: 35,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "1676",
            encryptCampaignId:
              "Z7+pDkB2cjZzmn5XEvFXZuUHX2LNMGuZBlBFxFMjtiEUBIJs5Rlolqq92MZAUtY1GqMELWW4sWIioxj9EEhg+lR49BXdtPVs6kPnj3dBl7NOzsuPva4EWUZiDNExW5z2gvoqcWOBYN0yeXVQzGTx9lOTsISoBEpM9Zu8w9jSjQw=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "3AAE",
            encryptCampaignId:
              "LFcHSRa+slUvCg/7DZiHJN9MOe6b6zmw818mIh4d3HTG6kogAeTev2KvL5jP7O79SWNRhrsHWOneidMs0eaWFYfrbEbW8QI5WBHSBoOXheLeCfTx2PPBb5Kzv7cr2DZV3JmzgAArN28/cuXN5Yn+uI7eKhhe/QYnCn94CwKX3IM=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "7116",
            encryptCampaignId:
              "H/9/zVu6pKYF6AtSdlqUKO5NqT/vPoCAbKCFcKN6olhsKU+l95rZGr2K58KBWno11qsiXs1tDGWI7v2dCAtJAin31jyyd5ow0f88y9eWFP3103M9u9CXBVn467kKF6sx4Gm5VRHTDRGxbFvxwMFS6KBi6kmzdu0B+BMiGVCDq1k=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "81DE",
            encryptCampaignId:
              "Z5lb5lrVH0hY5biP0WwyJfX95adhSZY7AnfkGRQ8xHuC9yps9TfqANb2PEhFE0ibBOVURMf7yFDHBkPFHUvnNwt+JvwYdnS5bt/7Czilyc6LW12YZqZDxGHdu/QZbd3FC0aOfpgDVzNShNDvd5+e+RMbnsa9r94cjGz3hhcGO1g=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "D57B",
            encryptCampaignId:
              "J6G1qeO2rLnXW8n230Uji8lQCeMDB/on5D7xtRG3afwAFMzW1w4yD+0adWUC2x6VovvYRmU07BqVYN9mjrJcQN7V54WEjDVv4lFDqKRkHIx3P86oqj5y2MQhI6SXIVSH4z8xpOAsnbGtV13V+V2pRrtlecdF+ooCm1gShJ4RG3w=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "F43A",
            encryptCampaignId:
              "iO9/v8MEHJxHuUlUPu6I6Hl9oowGY88HGAqV2MmJXELSpKsbXEDx8pnFlZnu0MGR6rtq+VqpKD7iqTicP9m2VQlvIqwgSQIvJITp1DyXaqlPHRwFe7Uv0JZbM5mWCDCzaXPhvbZ4CHvfebt1q+yTUOaOqxksBP3N60BlMI3O+p8=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-30",
          },
          {
            fakeCampaignId: "0CA0",
            encryptCampaignId:
              "NgKGMiGmcYHGmcijVd/PXA5UPwsDdUq7zMdMju1Kx4dWPrR/vHusHOPmsFNx9aBHusNW5n6wplKEl8HReuxNEgaXAr8WnUv6uI0YCLQG+wjG4Z+McNokT1uyiThiqVsMJSaUKQA8VbfI0xisx4UVw8RIbH2rIwWKRg+xIvnlbB8=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "121D",
            encryptCampaignId:
              "STf1EYKvkhl1fScaIJg3RLYh/2bOGNoVAPbw4Zq2up28I+egcldMfgFd6oScnMCm1S4Bkol75MfJYlP+U43PbVvn8yY0zyeGN3ZCULZ1+KhTXgD/vKEjhHo4CQpqWPrPDhert2+mb0MH1WxwSb3OK4jr1WsaEw2qasliqORUwYk=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "314D",
            encryptCampaignId:
              "S8SvultgaBh/zrxrW3eUl+M3GKUEiyGjpYUeFGLqoINqIZguLs0HFP2dBcIPAjvWoWcTNylGZ/Rvkqtf7fAuAhV3UoYsgWrxJ+xg6xXqTdNP06ZSL1Arj69Eu8cYjxAaB4UhUt10+AyNxsHzEYIhP9275S/Yk4WGRcWihIiSivE=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "32CA",
            encryptCampaignId:
              "iPLlYaoYpVupmdZvAZdVlYje1i9RodQL167aWyoJiimYaWzCC7UI3eQbVL439Ig+aXhrf8AJLpWHdcRxF7B5/pqUafZpdN8WjLffaxAjKlVBKvst8HvLT1MtY6ghJFyQjJZD5t8rmkw0r/WNZ4SdyaA7H6tOwloaxqePiRwE0Lc=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "3916",
            encryptCampaignId:
              "IeUo9IcBCBhOaDMi/YtR1hCa+kZ903qc8Jj2gImQS524MRvDOZkQRKJiwx1iVokZCQeU7/onsODC+UBfDU1epxaJMrzBpgJfCUi/W8pGSs9WFBXR5BY6wesnjXL3hU4X8JalmQ/Szlu+zZGzyrhzouVxYl69ZIOmROrpSp3mBeg=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "39BB",
            encryptCampaignId:
              "aaVHACelaixwZ0iVkjoWpf1Ern/ANagbja258J1odbeTkUk+tHoKpetwut58SIeyA6839VvbLcZHMTzkH/kMgYp3QbuPo4CybmPy6lq7EFOCKBhXyWplNju5X/nNZAZhzTDAH/VodzNT9YqzN/owvBMzNOO2c3wZfML6oD8eEKU=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "4552",
            encryptCampaignId:
              "giD3dtRPXa78AViYUM0M/h+yJJlvkwkp/tFa+Xjv0S3DGfMiMxPHWghiJ9eBPkzGnV458eGi64fEw0ATkd7vjbozA+nVayZC5Uqy9fErWN63UI1wEZRPDTsYbrrde6UQRyOJZwMadc3SPbvrosHvPnm3/iJvVVudnt7SYXbPnao=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "4A00",
            encryptCampaignId:
              "FPtD15lSmpwjJ8vpIbVUTkvS5HdNrQhvtMnUZcOJ7gMBnVS8IJPS7B7FtPwxF6cTn0/PCO+a3IR7f/kbPnEv3aB7XSIcQBDtIw46p+5TYkmtDi3qJLkXXzGwFKcsESlbaJg5Ybx+QzoBBTtMUlmgqEgX5sOFTjgM6wS+9ydytRA=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "5301",
            encryptCampaignId:
              "OfP5xDRGaLZOTqe2HQce+HF8PDUUdvtfvaerxuq0P1yfNnk8EIl3lDb5N8q4qH+VqzKjkSsttlwUtUdIvR5SM/SJ2KOKLN9IiXMCUvzNfcmuEtf7xCJfzxGGdxeuZQOuKWSOfzyACXmt/nGh1Z9XpfQ3lgRz8/e2lbmXHnL6sYw=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "53BC",
            encryptCampaignId:
              "gBftB/zC6Cw7wAFk1ziZWsrtK2oxrDGny2h+duhfnfXKaAf3eyJqvbVUXk7oz9hW7KXnfJZNAAvJ5sGoliX+sQu9+hykqdAZYA7p5BbYvDwrBgJsGA9iX7os/wPGyszvCWURreHjm4bO1SwtSJd3nAiqHD0FURsDUzkDeXojAss=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "580E",
            encryptCampaignId:
              "GFHkdoZdyi46JZcr4srUwXnNsMs4yq9dSV/Ip5LkJkk2as9RSHXQq3O2dUpnVcc6Xe4oU41texjdG7RyX48+AIC6dG+do5LpdqktlAeSo+iD4C01A/aPE70bSUzbB4AouKtbB9eioCizKlG79ktdXM6dzioHrKMq57BY8cWHB7o=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "5C83",
            encryptCampaignId:
              "bhH8UzhyYGioYdqvf8hD49/CBbDGWnl1QMu9wHi9sQsk5LIP0sZMcI/56+dbiTLl3KNwxS4uAlfLVggvbWeXlsZzVJzDInWbT6ZOkgNlkzcdRLJ4HLtuag6NyGAg4k+//qQlWXkdORM+MYKloL72rjfr7JJrulXBtIWnEp3v+mA=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "5FD0",
            encryptCampaignId:
              "Zko64Yf7dwciasxINwhaeXlDnGGHch7XdkHQWs4q18/llMy7bZgyiV2oAEtdqPengnD+InA8yyr+z6DL0g+7jbl3liRS/e+XU2lcAUkd26knv+AdWwG2DALH3zz+JvcxmPbTNOzCafNlV85Gyd7Gff9q4BU5JjAktqdlXcVCOcY=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "6863",
            encryptCampaignId:
              "gG55FlSiA2h9nyzSMAT0zjWziVKaefUh1tsEDT9Iok3ruKKe2jr0pZ756rBb6rA91ax7XoDFcN/9rgO5k/yd9VFoZ10x/c9Qw81XFAWGxPHb6fQ979gfafgqeq5FVbQC/Air25nOiym5wo83rI9civOZ5TT8gEtTEkx61iacMzo=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "73AB",
            encryptCampaignId:
              "Z4Z/ZtTtERqSsEbkDFRmgf7+plnidCBFyGPzaVVLUxXRtK2X/tbc8cmQVlaHHjNY2t/XFyxt+j7NWuKlTdhmTwOs3sJNS1GTSnTxTouf6bJhuqCVKr7GdTDmiZ53GJgsXLh70wt6Ar8O+z702LbnMHEhYE1wmWXeixvnf+h4B3g=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "74A6",
            encryptCampaignId:
              "TyLhA+rAHwrPrctAadkkWZU4mHAHx9gpjoNXd83GS8m6m+vLvWH2Rk1s2TIkW1b0SCAcKSBQBxNdf0psnYJ2QRAGX4/rOnsRo9CSnjylOU5K+6Pvn4ftyVFqtLVe/wLEMFiqcUrx16kmEdAsb3EQM06ZbDfF+T7qTHamfF0AH9c=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "80D0",
            encryptCampaignId:
              "RAuawSxn9SswenYjDH5SHNTy68r+u7FWpf5HMvI3u3gmfP++fyhY46fnontK6rzWC8gamG8nu92bYmHOaf1S3V/aPY+aMJkP0i/WkxlriywP7eVfAoyidrUHtu4LNHfX45yvt6RJkeG0/QfxqECYGzn6PyXTEDEZR8q3BGCxaW4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "829E",
            encryptCampaignId:
              "Us6hmay+DlEHcqKQ8Y4FXGULheUSq4Lq904HJoRjI/6RWoA8EBMnYhJtlIjYQo5kpM+ZVxaNlrhu+uVZTxMiR+rvk68O3Qf1EjtQ3UEC+/GEV6wS4Sp5kbzPlj/gigF9Q63zJsprFAh5rebse/alM3/TxlmJz/DIdpzq1+Zst40=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "8CB7",
            encryptCampaignId:
              "kSauUP12nTwD1F4nwqAFUoZDUwYbmJI82zW978ESQBlGrlIaX3lVxysdJKYRXjvRffwb+3Y3bU8pVQJ6h4HndJ6sDSeVUcDMSDpjwmEI/9myNxic+SoH5SepRq7bt/desMN5voRpJWJ30GBZkhBRcGgKEezZsyxf9oDs69a6zlg=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "8ED8",
            encryptCampaignId:
              "DXJZUaQp/vq6NBtW3asLa+tQiWSV05e4Pi7m44PgrZdedaRfuxqmhyreBgAn69c0xBgkF9Bz577SkPG7QqHAYeuvHJwFAhPE6Fw8K/lQElYCjg5kkYEGwBU/6FYCX62RrTNA+je8TEU7rgo/icavelOJ4QpJEb1EUtUYqCHv59o=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "97BE",
            encryptCampaignId:
              "R8xPafIYTNsjr55rtWjn96haYAI8yIr/72q41A0RhvWr+8xdzjT5fM6TbrV0PdpXi+pJhCaYNWYHivEl+DH0OvMD/WM+9qs5eLYlK2knaqTVoOSK9YOox5xwReRgaI9h7ihAg7rpi/5ftbweT+PUgXjUjnxA/P605p1/578/lpE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "A66F",
            encryptCampaignId:
              "OfRezJH/4UfbWvc9gyCrlr4KWfk9tL2Mv1c7uwMLfLACC6wBiDrY9bC8jQMnP+DYSjETxTzsncQug8VbaQHz7GkI7dpl5qSCcawAavhtpwjf4V8RLwTPB5BjlnvlLe6nGpaBReOmG3syTWFLjgfnlAlF0BcFO2GM0wfH6s+Paxc=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "A685",
            encryptCampaignId:
              "N7UANH1IySPAJcqw+gir8EMr8AZodcq8AVE/YcdM5LwchWNCwmyS8Nt1sA/H2/CW9qB7dFozdjRiqykcc0omOkfXJc3+oK/s58Ka92LXoJhg6C6ImAVqLCuCOI47nGnXxgxrUJIh99qudpa78n73Gmrf96j8f+seIPPfp52iRvo=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "CF20",
            encryptCampaignId:
              "fUjcr/hWUzWmvlVc0neab/Fzz8Zhf5Ne0dBQ8TSoe56lawN3sMtRCP4ZCz/AVuPsiT0MqXuwQr7aKrVdV3yGOR0vo8thVaiJmVoiCkhYeezv+u5mlqISjT+qetxhy/HdCuxQm4Y78ozB3XgAckY1MWiJbG6yP8MT4ps8/nfrh3Y=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "D61B",
            encryptCampaignId:
              "DNUniqwpdzQyTpQMnfRIRx5RkI/HJekO9KbiDHb0TrSGXq0eDUDeqWi7WWVozJ0v8/IanIG97uH7L1LWVPBME6sieygNEEHTFs1XUbgQ5joM5G1Y1K5ZltgRcXerBz5bnEZVCRrE9M03GQA6UlXVUxbmn/BpLLuS7DcVnujeFrc=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "DC91",
            encryptCampaignId:
              "iDy/0LJbOfOPOfxM2Yt/1O+b75I7q6G+w4xA1dlXGev+A7DLUv+Wu+SkIaB7ZBN60C9hNONJSDhMHdMcYu0UJjUThu8IQQEzHclG38y0l3Zz0c4B6Nd4yW7i3mi6T1t+PMTjN0nkM1wfZT2gOnLVj8IGqeoFooaVUiMmUBPgzU4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "EDC7",
            encryptCampaignId:
              "U9azng4KePuvpWWATokorkzs79sS2qiAMRnJ+2pg9YaevxwvZjGBdPZXRsI2nDZinolineelwtfM5GGbeop8i2ZLe7jxVJUm0nBi3KPnstRlnaZGhPynIErJ5lbxHKhUIPH7Md3F9hPtYUTED1zA7QnC3WE3HytOin03e012Ld0=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "EDE6",
            encryptCampaignId:
              "csT8uzSv5LBFpaYy+hw76Jr1+n0wN1lAMSe1sdOzMjA5fwOTN19aEfiXidvqP5dCDLzWCKyt9UxOk8zifLTZspkiqBQ8kOm1pFKuNsjgO92ApdmO1Mmj2VeBGhC3d+5zG+JBxgU02QBJdwdFSOZl+jKsy9rvIqmz/Oo1CoLAq64=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
          {
            fakeCampaignId: "F039",
            encryptCampaignId:
              "U7BrzPNu0aKnIjSMnc+C1CJw89JhS6TBy7VFEr4xyJHab7V3b6Rm0xp96IFijWIgipQp3mILgrdCM1s0EbYNzyGKaxg3kvxUh9PlUAkUTCX9mxEtdwUJ1LALtuMUp1zS2DbRGEw5aAIOiSlV/Y4CXi2IQTpFUlBZ0wg03yjbD9s=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-04-29",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-05-01": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "641E",
            encryptCampaignId:
              "kf8cqnSHEYkgeA+HjzBBsGRzE6uiT5Lj+j5gntheFPfEyBoB6hg0oD1EKDbR5CzPc4t8EYFPenwRQFrZneemzGcwMezCnjoRJnNiFJ6Ej8eHz8q471cTuBkniyhWLRreDy/S3DRgDklNXphjb6xOs+iM+cel1RQbsUW5w8cHgmQ=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-04",
          },
          {
            fakeCampaignId: "973D",
            encryptCampaignId:
              "HyVIWuFQTkUvuzqouxxk6u0eKIJGdKfqjEn/FnoqXCEhnl5fboLYxlCTrSetO7S3uDqbZ9QKlnJboXXY6icdVBdmSpTVm4YWibYD/n+9YPo4iMqTLU1Rcobmo2huvrKteC8wDyd/qWaHv+5mDprGTNJ+Y8i4K2+NTXH/RD3JcTA=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-04",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-05-08": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9503",
            encryptCampaignId:
              "X/zjV8WHhcrf0cyHxa7Q3z4FVulD3LckowqW6vhrtj8vJx3agiQA/FFNNSiowHk0ZrQO50buhAbmstRuBzO71bZVWFhTCuND2DGQ1zPx7pAASuUkklt8pnh+UNu8SqNry1+iGlaHyNFyEN//+RD25uIxVxMgqTVQ1znKFzSWH4k=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-11",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-05-15": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "200A",
            encryptCampaignId:
              "UqkSBFYLf3+K6YSFYDrBqHHZYxYLMptEtAoK67PVXAA0tH5ia6WlsjrZ3efmv6IbRahwQs3FmXD+zehG7pvNMLa8+6S0kOKU9eLNAyvlDz4mKzCc3iNXQAROpV+IIYrsvMjPTAj6PEgV16ILGjPCXUW+FaCYyvDgfG5Uol6Etrg=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-16",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-05-22": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "D69C",
            encryptCampaignId:
              "F+oss5gllEgk/yVSJFX/XT5mgqjorTa1P8f3s/MT8iaRE3YoopJd8qjD0Yy76WrRS0x53kNiSSC1Toey1CW7yjLOQyZIy/OKp6K8H+4UcYso+AGp8Cv8F1RI5yKmoefnpz9B9hhYBPMRNN3QJBj6L3Y8vKbikrrp0oqZzwBS42A=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-25",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-05-29": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9CAC",
            encryptCampaignId:
              "AkU23h8Wxwh5HSBnKMJFdPo2df2gWIYxETdlQ1mTCECtbtvnCO/fZVVj1sRjWN5z6Wja++nUw78RPMN1MzvTUYzahJcVK1KF0o6xxxdyLX5V/JN4h7+SKTF1Yoi7/39uJ3fwe+RurP6OdxRH7faB0a1GKF+pYrtqME8SCMlBsvI=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-05-31",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-06-05": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9614",
            encryptCampaignId:
              "f6hc5Vx3cDk1rCD1irbTjFIzLFsckctvvnLpIqge/L4dhNCjTAavikcqJrkSKiFMNEXOHN/c+xOuuejzPrC+ddQ0s3+dVzAUKGIIXBK+XCgJxBcWhHIiOc8CCFtGHElHXK1SYix7KrE0MNmI7/9aOsBTNYJWdFMo9JXt2eytDf0=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-06-08",
          },
          {
            fakeCampaignId: "9CDA",
            encryptCampaignId:
              "fRD7tkbEwfhrBS0JCN4ieM0dRsBYhCEPBoP+WsTF7BepjbOrtxBWIz8KkMTzR7gSM49yib5VOozczgi97Q9Brhds0VY4BCgumbB0s8lYJV7WzNRmfg7hSatWqzJ3wpvpAStzhXFq+oICcmW2wMCUVDdLrWbq3vHbd3Y4rjNMr7Y=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2022-06-06",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-06-12": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "8056",
            encryptCampaignId:
              "iG6/IY8dyqVQL17EfoQuXxoTla1RBDzCyy92Yc8+pFf0u3nYhdOwFo/o7C1UeL36E1Ly0wxJsWVGAS/I1V4h15UkjnMEOsD7WKo8EVRkrg0YHB7DvAK7ALW5I6xV29qAtswvIOvrOoT822Aqtz9okeZplWGQMu7Xg8Mef/p0lgY=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-06-14",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-08-14": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "4183",
            encryptCampaignId:
              "lOhKIxZxpdH+tOnfuCKmH5tGOs5fslI5RQQ0fL5amrXfxC5qiPLqhMi2X/FcEGSuH4Ava+/cj9ivRqOXltpxLglBCKbl87/Hi7JXdjd8Du7J8x1EDva8CPPmKfM24ZtFHJ/R/dLMTg0QqhFOFehJL9WNHYXxi+TZUU0H3YQI99c=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-08-18",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-08-21": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "5188",
            encryptCampaignId:
              "e2aOBG1qFD9J/iqyOQsOgcGefIQLHwIZ6A1KbQT0Hav+JXPFzQ+BIi2X85Yyi/Y9EYzeoIdvfrsHWoBJ3sPnu832hyjiudzdcPUWwy993xXMJUTo0gS/6lECuw0SDvehZ4ymC2Ww9gOeGfHP1d0gRWvCsEhi98RjLBtBWA4cso0=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-08-23",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-08-28": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "B867",
            encryptCampaignId:
              "TyvD9WDJlOX2kbWO2ZcV4B9FX+xP/5UbZQwMTYv+kN1jZaq6Y6nUEKzmEqTcHuqb5bzi+suyL3/ODMXldp3iC+RS1wITVBG4PVMy7DkJ1fsx90KIQXDWBlbWXsQfK7y6HvaG710EqBdR0C/0RdPIQMm57ypmiTMd/d5iEUR0dWg=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-02",
          },
          {
            fakeCampaignId: "72DB",
            encryptCampaignId:
              "A+lvNs6U6zkpzHnZM+IntiTk01v2ZlY6TVkwXw3SIsg9+hMJT3y5CR2kOXAg8A+PfoJk5dLrH3VZhjFZSCdcN3880SdbhMYhQWOsTdOmeuIpVYZVnAFLnPTttrKA1yf8IHv+f1E9i66gR1gFCp0fvTvn7Q7r7cOvdjYLNvKKWIk=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-08-28",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-09-04": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "6AC7",
            encryptCampaignId:
              "TnJbxwfFCVpQfeXpYVJKfThRY6kVT/71PjQYWQzTb6z5AfhfLNYgNfiovB38MJCZF4ZKSd395CcQ5OxqY/MbMAlCEQ1v4jAvBkBL4LMYluy+IU1SjQU5C8j4cQC4Mo39mTRfcjKus24kVEeSJyz3p9VpaeMftHPoUK/6FZLZQPk=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-08",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-09-11": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "8778",
            encryptCampaignId:
              "B9Yfo5TrasZ9EL5YqlVH08W/DPHDbfy28ZXvJCkvXqo6ktCpN0CUKVYETezwGNMJ/mZdv4dZ8ALcBO3GbRbqtPSACbuLe50Xy+WJifn9kOO1Uusndq9pztzYTvNe1GXnnYO5YnVhOm+vGHTZExvxQYb/Owtc2qTpFckWLBlaOUs=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-14",
          },
          {
            fakeCampaignId: "B54F",
            encryptCampaignId:
              "KcdhwVE8jrmYMbaKMH72s7ouaduAFHmwZteBbeUZQSpuTaARL1CHlE+1QcEFZPPn+kjaohsgS2Z18qOSfEqN/QU7IoGF6ei7jluO+/eM51PSsN+FI/v7c0Gm/32oWuB+9jK8+ReVSu7gsAO9pOLLILuANwGepEQm6DOnIhfoX0E=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-14",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-09-18": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "38DB",
            encryptCampaignId:
              "ajLHZLnCkh8UheigYxRvwzUjm2d9vJ5Cg7C5FvrM0UhLH8wuMWhmkYfO1V5Nfvcq/cnzSn40t6qYZcI3+vuw9MPEFPNPC/BijWZNBQz/lFW0IfMUfuBZ/NIbobHVyt6zmAXAAOL2n461BDZ0wWZg7zTchLVgKThLnTUdeHMyvP0=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-22",
          },
          {
            fakeCampaignId: "49CA",
            encryptCampaignId:
              "MUnFC8uiVZXABBkJr4PlTbZU7aGr/IVW41Ud/2GP4M4FUcstBw8zXn6kDCZorJ0sNso0neV6RfXrnnht2/B/SOKhCi7Mdv1gHKQ2hmT0cvmPjMFApm7BS/Gccwc26CC+7oREge8p3X/enEpzhfzZlv3pYXSQITGEQ3G+EH9Vk94=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-20",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-09-25": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "D627",
            encryptCampaignId:
              "VRhjq1cxr2NAKQrU0vTaEF2GQALkFJpSve2b0ehMk1HF/fCjYifrvSQbmd4pXB/Hj0mzZxbvauso7TV01qdQ13CnBfzekd9dG/3lA3qUkBlcTCS+He9yCGhP6RQFnCBpKIiTFDsHslMNZMBmy8Q5FIILBRhfdrWUg/dwY6kRBU4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-09-27",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-10-09": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9DDE",
            encryptCampaignId:
              "e6L+q38NwYVKiY7Si5iR3gDoP0yldPd77hqOPYUjlZquNDUsKrgV385+0q+LyaAtGYrd93nvshH+anFtXjeau7+ORFHZyU3XTfRbAYbIlhzC5IGs/Y+veZbS4inKvQSwUXvOAABQeBL8x7CsvFcKsngvUIMiXt/ycA/EDMae9Ac=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-13",
          },
          {
            fakeCampaignId: "D575",
            encryptCampaignId:
              "R2OcWuCQvbqsi+af+DPM9cbpxyk24TmtczZqUCUxUovA/LA4gTjB4tGg6Zj2k8NZbz6MSXPYxRJxYb2kVxVydRlNc4zCmgmrECootvktCV8IHjbU9aGJXFyq47wer6YSJbjoyHUAA4+8xYq49JSyUJBB1imReIHo9XjuBrA5RJ4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-12",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-10-16": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "C6B6",
            encryptCampaignId:
              "Q8DYDmzkFmtOghC2OqBfIH1Bgsd+g0EMBgeNg2Sld+X+FVshC0ii8IVp4BQFxEcbgAgQvKr2AuQPQeUhGjQxoCt4fZCOKPJaUao3ERmW8yPf3qLXgm53Xt0Yw+p2dFmHyb+UFDHdYNoC4ZVFOX2GSEnkXE56XkVAET8Wfn+3bBY=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-20",
          },
          {
            fakeCampaignId: "387E",
            encryptCampaignId:
              "hSVOxsHzQ39QECz75pM027ogRhzRvH9oq/pUQGJtC+sSffcTk+eCJiuzc7NiY7gfX4l798MzWso5+IVH+AFMhDMhjjOVcO2+7DioiitZ7soTKZx2ICwki752h8P7Fl3bHIP3yC2+8Bx+5iw66tc0nzkz3UQFpyO6fRosBMateOY=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-16",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-10-23": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "5EEF",
            encryptCampaignId:
              "TdLh220yz8yTFuAt5vOLLlxGl6TtgXi9cEtBP7LPFe4g2bzt8evOrFafBr3pUkl01WrR13vvW5GzSuNpfauexA16jRXEzBIlQaq0hCcKakbgAZ3ours6j8c4tmgE+ACbtNwHiq1T0R2/z3Ecv5OqSLaoYtoYvmJvnaR8hdpgeXY=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-27",
          },
          {
            fakeCampaignId: "F404",
            encryptCampaignId:
              "W3pw5Z+UfM6pIOK0Q8Y1OZFj/K7BlRLIed6V+6/pXl04JEGUzXw1ncTulXQS0qj50MSqR5RliYraewlxiMf4Aagc8PucFtfg+01Obq3zohkbc9Qm2HK04ZciFxwlNdn5YTQ0+JWMV87kquhT+Q1UtxH0YKM6zWe+tRPJH4jPV5Q=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-10-25",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-11-06": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "F2F9",
            encryptCampaignId:
              "SSH67GLzKK5bTj/VsWlSbMv1atxg5/eusKzVrNnkhSCrDGaeqGG7QXwpUlKPAgVkAzvm4utjNDi4GKXHAujxYjS5EaegcNxfDqWsdJuQjetwcGus7ELu1Bje60scZF0jSeerFPD5bFoH/Tf5M1RmSxkco1y/zSQV/f3W2oW7O4U=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-11-09",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2022-11-20": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "CA70",
            encryptCampaignId:
              "i7KEm1NYDXzuL2OVPFYtRyL5Ss+1oi9fFnxq4TBivG5sqGLuLBxP6tAyIQxQ2JuAiDODRMrcZg7NKQO7MlJKypK9TW+mJl2Fe9T0O114/OJxo2Frw9DOSkxoQltFk9JONSIRQdevlBHkSpQRmrox0+eMDj1goCTw+b7Pnk/TpvQ=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2022-11-24",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-01-01": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "3AB0",
            encryptCampaignId:
              "QVr/uru05KqpnXPiimdpwVpwLlOqlfgBUyXHDlI4l7o/ENR8t/V9RjxAG2FBbR02XFKKim5O8z4iFGSg1XhRe/tfnxOEfU6UsptwCRjz1jxyPnlaMssskuFRYmBBo6HwjiaoylgQwmRac59v5F+KvsUSUUlriWiT4BUeI7X82Q8=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2023-01-02",
          },
          {
            fakeCampaignId: "7FC1",
            encryptCampaignId:
              "ZTvMg+t8keiN72zMpJ/QivJ58ZcpPbfP8fLvgMDBGZ4w4mE5/XijkqyCBVBtcfKheYlYeG1C6unrvkTPIMsP+YsUwjorssAWn4iL2dxUGBpk7skHuy+Of1cmmrks7XP3IvJrCmkmx3Xm5YMtzKUhxL6j2uWbkoCA4BkKFMauyak=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-01-02",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-01-08": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "5F20",
            encryptCampaignId:
              "EstkhPrV9N7v4gaq4cPS1bKFKRe2ta2FrhCIDA2GJUdFk5/4W50yAiH5Eaj4jAYl4rcyRrz0oNz+uNTHRacaiv9E8g+xrbrzM+xS9WW5kx0in/u6dfVrZX0zFFjHVAKhSbm7uuQ/cmAZsWcxm8135vt+E2Zv7eOECm0Ddh2oQhE=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2023-01-13",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-02-05": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "0EAB",
            encryptCampaignId:
              "h+JRoVNZQR4znnkp2Uji+tiunxh/aXOWNHOdGFn61GZ/bui0ny7bJYhj67rgRF28He83Sc+TFBQvNp1dCqqL28XtghQgOS21mHr4Gzdsqe3shINbyN3zduPl2XtukMBEwZAvz7TB87FxAZVZhKqZVc+UdEAUAKnk3h3WpRNFoMI=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-02-09",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-03-19": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "04E8",
            encryptCampaignId:
              "NZsEuVgREkTWDb6m2dgBUlGZ8fHfjP794zFByjorIoYxvTq40N9Hif96ur4u3RRtaY63dspXHAoWOTlDcEhI+2t8yjZRyo+SF6hlzzTt/CevVxq61hEId0S4KZWiS8hN5Jm1IwV2VCL30086/dpcL3R61bxEnz3be19/uwvnXqA=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-03-21",
          },
          {
            fakeCampaignId: "E56E",
            encryptCampaignId:
              "An8vCTgRVomzW3YkcO/k8r9iCTzP+D+aE9s0qSpXSLUC7Hb+/Na0Els9NcdPc5eSVx2NDUlQM4D15nFm+osSIAZtpFsH2ZM1y6yGj+hvCgJ3sH2aVZt8+suemGF4Z2fOpUAZI43Ln7x7ye/VGwvkmHNjJ/E6bx7IvUkbsrtHmb4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-03-20",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-04-02": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "DFA7",
            encryptCampaignId:
              "A51kqNq6zufgJQfmnIiHI/tB16av9W+hsS5PFbhRNPnRENy1YvyyG/QQEbQ/u7U3heWXJaTcOeAc2Wjmjj8mrlBcoMCNlkA8ImLinQoe3R334lGB2CGjXu9bn/VnGEw76Kt4eIAFYcsLRuL5C3lybqTIerGDRJXytoo5DrOUFAM=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-04-06",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-04-09": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "BEAC",
            encryptCampaignId:
              "hpSTM275HBP71zz92m7ZJBgf/uUSpqDgQb1k8bcBrB5X2SpzP8/iUxA5SbnTfR2X4UxJQolauXbhzwZnQRBcL98ySek0loBm0VZQET0uA2HCs+DUilc+NjmeOqddY6HPb7062Q/9+dy2bO5lwS9EzaPPt/CMV/I5LGDxdHpl4e4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-04-14",
          },
          {
            fakeCampaignId: "3692",
            encryptCampaignId:
              "UNoVTdUq0zdat3wFBpzeUEiVZFaBwCZvASuwLBAevDC9HfR4/KAUFAl/AxGDgmRDkAq2p8aTUpZ0Q1z+6WYVXDFdEz4dIlFbL3N1vLbEKHoBKPleAuAsMtrS6jGPjcLQqH1JtnH+QyCVtNOuWeDjwHKz2d3vJ3oVx2INf6FCFFU=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-04-12",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-05-07": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "1876",
            encryptCampaignId:
              "iiKlLgV4xPHBKWy8Ts1E5wVM6guRo0miIpo2fynF1RSFpkQCvhMWNBjn9Hg5707bLUsr/Dz+eUNeqrBloPRQu/9nE1Mdo4WPECO+jk3D328FZNEbuNJlbEqqJcFt2JbxLcxzLTeai/2io+cIrHP3XA9DbD0dZm4oE+dLEKIWBRE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-05-12",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-05-14": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "8DFC",
            encryptCampaignId:
              "VP3kAoybtmyVBnyf1+r5cz+E43ahKTQexuiQSgoS1S3bm5j1X9GOftkQQuA9+puRUZ0Tc9nLzeo4Kg+RSXnzLX5zZVwj/fIY2YQKCiK3PUgeO5TF657wt/tZ9p3EgNU+VrcHokrYEUrh/2mDrO6on+DmIolWaSJRREiu7U+jiyk=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-05-17",
          },
          {
            fakeCampaignId: "939A",
            encryptCampaignId:
              "K0oZP886AZmMu3JFAAiHWUUXig/fFil1f38YjrB9vOJEYJWaF45KHqjb9B71bggIZ01QnqiXBP7UTNy9XH51XhQy0HKbPnG+6Me7ZmEYYqAyG7zdLSSewmUd7Pn7wEY3hjHz58t1TNZJPHvOMHZkc8cetZaoHpbuZ/Misd35IyU=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-05-14",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-05-28": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "086E",
            encryptCampaignId:
              "SpVY+DVxoWVUJtzwyEZCDqUsb7o5YUBESqo4XlOnaUoLygEWHp0AjaY/I+wJcaFBwUo8zRe1+ElL1HWi5q0g3y5e3kWyVZ6Xi6KfDdawP6vsOufWKlVUQLaGDRkCrTtNFUcpwJNUolizW3pRDgWdURZ4JYKHiJxxEXvKJL0lxoQ=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-06-02",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-06-04": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9358",
            encryptCampaignId:
              "EqfQ/eN686HD7Z+9Rw6ElS79Nrse+D7KcQWSDDaqOVwaqrb/w7tMcoYYvSFHd8gFMrQRYwQGt84n1KXgsrL2K64lQldsHolCoBBb1blZhZM9mx3BfgPQXaQCAoFlKPMeC+pb8C84tQO7mBre325myaOrt+aeFJ18u7zKasW1thU=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-06-10",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-06-18": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "8DDE",
            encryptCampaignId:
              "Fq6e/h61Qb63dQmVVmEHL444FiRFVGIvvYTRcZqKaoo4LqF8eNA/bAPt7gXjWkVfqetznrLZBTctCwyUZJ7fAS6qdTj/Zw//OmeHfy/MH9xfdN1oqzffFmjjmbui8wo+qus1ycU1rTIA9md2+KGe0D76wqLIpYiQOuqLbletI6c=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-06-20",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-06-25": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "5D42",
            encryptCampaignId:
              "kbrZHoccaiMirxBHRnyX9k6AL34M2OjTLZ8zDPF/gqjgbzb4qF2EQDzpvLnO3awxtbSfmgZnc5iP0eg2CIyFNjCVDdWzpf8j0RNyn76aTchRgH/40hWcORHmfwGUYgSKKaj6xNQVIdgpZJUOY/pFWUJO+BFi4sNT7bIqkf8HY8o=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-06-28",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-07-16": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "0BC2",
            encryptCampaignId:
              "i3guVMfIcMN4UlPCro+VGRUk/fQwXji8npSwNAhSGy+tAenNCGvrJmiBKnTdGwOQ3bNajxlVxDnunJCA3pkXK7TOUTKH3aNg0Qx5KiMpk5UX4Jyq1AbIvfE9+cprXVOtJM0e6zXirzyaIfFMJc8KoqBPY6dPIDmtCX7ToETawXM=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-07-16",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-08-27": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "6279",
            encryptCampaignId:
              "RO6w90NPSPpvxuJuKOK2IH7CeEVghCKJlE0/Dh07CyLNz2cavObjHqgkFmoh3FyiAdbDTOzKwvjIXPI5XcHwB578D4LxKWVxGBNlGXv9q41Cni8gIVZkKZeVW9xP4NkgeVD/AiOc6Z3A7ylZ056I9bEmYPn5PbO1EzfMZeSBkSE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-08-31",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-10-22": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "6941",
            encryptCampaignId:
              "iENWIAtquCLsF2Yr8Oy778NVMeGg2SlN+XK7jNF5jXu0htf0Kbo0ACPYWjBUMQRe8I+LIHg7IQbjlDpZgaCvqh/0KO4KHE35zh8VPJkefllQlGxrJll/LihEOdbxHWOZT0S7XHbAaWTtG4/OVw+tfl5bM4826g7pqiQz1/aLkic=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2023-10-23",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2023-11-26": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "2F4F",
            encryptCampaignId:
              "ZQ6bsJVB/pt7q9aOAiK/uk+UQs3RxYsb7EbIveI//Y5UfnTDensk6WqEz7aSTa6ax67WU3z7oN0v/T9PaDTlzVCSdXiFhWYdA89agbgl2DUVBkM9fq4w6nFnVCy6p5PXwwd/Y0lHGtDj+B1uFTwbOlboLWlVLoG35eeAJ3yzEOM=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2023-11-26",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-04-07": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "1BD7",
            encryptCampaignId:
              "QxnpMZhj4mDKWbu+v248R6CfAVxUSTVmHCih479rrhEZh7wm9e4hgKQSDbCJXbF1Z9sTKVpkbdmnBT4QXbrslaOfLuK6iUUaF/vH5Enh5jSEROSs4vCt1muw+wMymykiRa2X0bWc9zUSxATltojyhaTqKo9cgsgQVuRFSCR8vKk=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-04-10",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-04-28": {
        spNum: 3,
        sbNum: 5,
        sbvNum: 1,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "9B10",
            encryptCampaignId:
              "RrFrmuKAw7VBwjS3RcbDPSvCX+g8xXLbzahvjAdBOlqe+mKD12TmnOKNufGS55stIYit9SUKW67v+oI+7KYET09i1jRsDQirsXDxxrcGRfSOcGIJYoaJf0Iu03d/l4SPvjInjN0CAAvERvFX2ylq4vsFa4hfgqHVxyA0jzjAZ7o=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-05-03",
          },
          {
            fakeCampaignId: "C072",
            encryptCampaignId:
              "L7uk5cAlwKNtoUXCX0/0oNhi37651JX7jZaBbEpkKYDKK7PzMo4s4mw1FiBrXUh7YgQsd8M/drm8WHFSsecO++nXEOBG63B3dCbLFjz6BPlzQ24E4ENluehmhmP6h8mGBmUp5bDBd/eaXFlqbemkvRsi4bBxkTuYWMjkjMezLtE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-05-03",
          },
          {
            fakeCampaignId: "55D4",
            encryptCampaignId:
              "TP/XMbiK3N8EaYbBq6Z0pUfA9Jy87ogkv00iqTNW6k2xDCWEA3AM8GCZKBbC0iOzJgQCke+M1YKzNisjMG2mYyJHPYvX3PP7zis1JJcjxOMFd9k3TxVNgxQ/F35Zb2maVqr89ADigGWJcp2d9tNbwnyNY8pO7Ek3wGJEIUjMhKc=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-05-01",
          },
        ],
        sb: [
          {
            fakeCampaignId: "A5A7",
            encryptCampaignId:
              "Myk369N8ztHWXIl3lsNMqju3KWLc+gb2Xplu6ugJMGSCU3SEQxYYagH8VuqKu8z8au5sdg7oqULdYKvv3UEk6TZZQxYD2v6iwXpUsTPcdslTzOxhIcRqmPLP/0mTjiA0/vk2hTA8wObgpszyVaajYN6BLLCe+cOud91w2GsVkLQ=",
            strategy: "多广告组，多变体/多产品",
            campaignCreatedAt: "2024-05-03",
          },
          {
            fakeCampaignId: "5877",
            encryptCampaignId:
              "OS2QJLJ6y7kNa0jaIuahSeW2njLEBwjplBxDJiOZOea8J3Pg7k/YS4NO/bV0iecRyuGITw3H0crfLVqgy0gtz/ekkEf1wdPEbPpx1+8x5+G3lHmfRHbsoBAQzs2BJVGHCbZ5i+/SF/at8ZLMk06fHZr12GQYVmAflNqgFCJ/+O0=",
            strategy: "单广告组，多变体/多产品",
            campaignCreatedAt: "2024-05-01",
          },
          {
            fakeCampaignId: "97E7",
            encryptCampaignId:
              "Ytx7Z/gBi0PN4NCZiS1WPZxhZ6A6JFqGuyVFgYGiR6BRbu3VhXjjNry8ir0MVEpRySDpHWlYB9tk0nBFiWb5wfzlRrfGp25rZcesMGZNsPepuSf23eHIHQOMaX72ENQev6jOk4CFY5+q0BpLyH6tJ5e1c6scRIuQ+83tXdjoPvA=",
            strategy: "多广告组，多变体/多产品",
            campaignCreatedAt: "2024-05-01",
          },
          {
            fakeCampaignId: "992F",
            encryptCampaignId:
              "FnTnF/iSfQTWJX3z9ehGom+BWUgcvcVfvZrzQ7aOyXjaj8jqZJt5M6yeyhg0emDymBuJePAcIZz0CLqhsTwV8GrCmWt3hlQw13GTtOPz/YlZMOZX8jyfNx1FycTLqVbSGMc3RJgd/oCuuokSNZ70i5ujurn693WyUKVjZ9VcwHE=",
            strategy: "多广告组，多变体/多产品",
            campaignCreatedAt: "2024-05-01",
          },
          {
            fakeCampaignId: "A560",
            encryptCampaignId:
              "irUTMFdYQ6/0fKfb6edjPamznraF2tHASIcUmTqjsk2q3bzzO5U8GA2ZEpAU7wm2K584wXDgG96DLSVXK+wXJRyGB6J/vXeJdZFtP3NfPzG18ix6Qx4wNbPIiKYbakrF+EEIm7QShc2PYqTqc5+xvyfubZExzv8VJzFMh5Un7w8=",
            strategy: "多广告组，多变体/多产品",
            campaignCreatedAt: "2024-05-01",
          },
        ],
        sbv: [
          {
            fakeCampaignId: "891D",
            encryptCampaignId:
              "JGjzaEEVTm4Qw9s6cGot7JW3+6MR5SH2cKAJyesEolEIvoVBwSW8xg920vOYQPQGLySfjF0kQUyex751U1WLqv2vBsYTqS2jEu6VDow9VwlRr4oSjCmzevOMSci93TvJjICzziYmenCk/qLISEqJhrhOpw+s8dcVxkzE/s+xxm4=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-05-01",
          },
        ],
        sbSbv: [],
      },
      "2024-05-05": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "A194",
            encryptCampaignId:
              "HsPlOcyejgTV5BnRnY6grO+PdS4X2rYCvLk2e93hf1n7jQwOP/flrNsIDQhejyiF6KoCY8rM9V3d//mLFs2uBqVdovyusLfZOmZN7k2gcrI2XZZOq+Eis7gAXybXrBgIOjrgBI88LWxJxnHTfghtF9YBNHgMcuafMui0j5ViCy8=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2024-05-07",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-06-02": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "44CD",
            encryptCampaignId:
              "jM9eVW8p35TA/HaYqsEnPYghLNijyF6DQCqXt8TMDrWFz27dcHn8yeACMIPKTEjrGiJE5A/zeEJWg4vV/jLjBPyogrW/mS/bqEaZTLRnEh/9BikeNo+AOM8lHedUnqPvLAHkUgpA21uqE9sfIH3ot42Tw9bd5YcIOC/RizCpG1w=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-06-06",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-06-09": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "6051",
            encryptCampaignId:
              "NbapymvUFPYpTQ7lt0BbW9ubk7NlH5+GosnLLvDqhKhsPOSZLZe3kcGgb1FGJqzvtQA5qieM/fhxb9m75/u3hZDFaxo627XqNW+x7jwhCr2pDVEk3CTxDGlCl9j44qJGiOfrlTxUtjoWwjIGHHErm8SP390SM9t+0QZq4deslO8=",
            strategy: "单个(大概率)或多个广告组，多变体",
            campaignCreatedAt: "2024-06-15",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-07-14": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "3EF4",
            encryptCampaignId:
              "VXtFPCVH6GNxwyHRXi4B6y0k9B+rCG2WAdiwKgPTulETAE2RF7i6tugN0fwQx2JZXhxOtt4Gc1+IfkUciT3UcJs1Hylhq0ENHjn4/CSQMLwEKT4E8Hd0Hfh1gKCDa52nYaY/Vg/pZDAtLxmvV71ZHz/41WUiijivsoygJ3y4oII=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-07-17",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-07-21": {
        spNum: 0,
        sbNum: 1,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [],
        sb: [
          {
            fakeCampaignId: "A635",
            encryptCampaignId:
              "eun/67btzlj2/LZtyF4iX3ufPRxEUh1CcNJHFJ6m63de7Ygt/AOga9AmCzm0/b6QmLGA6Qkn4bnpVfFQI0kkXUf7opW5m7yDAYcXjXMNyOGnNpVm10ngqM8RCeY7z9TwDyARHS1QmMpUdUUofNLjKJ00dl6jJ6Dy1En7q9qtQm0=",
            strategy: "单广告组，多变体/多产品",
            campaignCreatedAt: "2024-07-23",
          },
        ],
        sbv: [],
        sbSbv: [],
      },
      "2024-08-25": {
        spNum: 0,
        sbNum: 1,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [],
        sb: [
          {
            fakeCampaignId: "5DFC",
            encryptCampaignId:
              "ieI6Df2RiSaYd8eJRVhuzikh5ZSuFGIJqhU54wCNp+0V67E9ZrVKpsj1LxIbrDGHj+dxifNcfn6+ypiZzeodJ2O20tVLUG3g1Q9ATeMdtvAQZfFwXJFG2LVWQ3LztlPx+N3dM1/Gs2Mgou1fmqtawkEUK0yfEYxAsbXUH114ajg=",
            strategy: "单广告组，多变体/多产品",
            campaignCreatedAt: "2024-08-25",
          },
        ],
        sbv: [],
        sbSbv: [],
      },
      "2024-09-08": {
        spNum: 1,
        sbNum: 1,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "1DAD",
            encryptCampaignId:
              "Om5W9ZDrvGBwguDKvYeSKqF4EjfqwIm5pyPyJKWBFKOSpNP7K7ondX7dIOoi8Yf4jLgff98pcVfRziTh8ZyqV63LN4pV/V/PpYV+V+4T+E+UxfI7hVsMyoLyFpkbgE4ZL+wndL0DSWeBRrt89T/pNL/EbETSH9Fp8d0/BHT1DI0=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-09-12",
          },
        ],
        sb: [
          {
            fakeCampaignId: "04A0",
            encryptCampaignId:
              "elzCASdSgpmK79v2+5DU3wFT3asRTdioXiqLWCygUuTkUFq+d5CHtU/pTqihTfgoVd8kAcqqXB3hGEdKE5AhDznlOuimwDV/umWGYMon5O3YBQjduTNiLw+e26qLS6YPxBjyiq1vLsjMCzHAmw7SQ6IbqGZzuSlrK4tfOBOOC8o=",
            strategy: "单广告组，多变体/多产品",
            campaignCreatedAt: "2024-09-12",
          },
        ],
        sbv: [],
        sbSbv: [],
      },
      "2024-09-22": {
        spNum: 0,
        sbNum: 1,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [],
        sb: [
          {
            fakeCampaignId: "5DE3",
            encryptCampaignId:
              "L/Zke3H5UBMFR4PTH4XWBDhuHvAiP7LC4bTLuz12hBbIw7yXUZUK2rWM+PdhijiQxUpPHz9iVrNjg5MjP62DHFLY4G1OWsXrqKTCjPB8oXU/S5VeyOwxFesJI3bksq8leNizpRLCzWH/1CrdT+WNb6KZ/InxylFjKrdNecQ3YfU=",
            strategy: "多广告组，多变体/多产品",
            campaignCreatedAt: "2024-09-26",
          },
        ],
        sbv: [],
        sbSbv: [],
      },
      "2024-10-06": {
        spNum: 2,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "DC3F",
            encryptCampaignId:
              "FUBJYzZyMr6JGsZpM9+YHrSdXYcZT25VO2zN99h3Rw8YAua1zai2PE04732UIocfVrbCC8wACVyN8alVDdEDblkmBpjJKL4Bqi2zB4UJ2WJb7uHb6azqGlCZ+7XQDW2AGokFjJACuzAsnMoQ4+CcL14/hF85BLUfeYEfKANL6eE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-10-11",
          },
          {
            fakeCampaignId: "5829",
            encryptCampaignId:
              "CccoiyIikB7bmk45min17Mugw1qGPS/cEw4FakDtCgjJZQY3AC4tMAOc6UZrxJJZNnuny1mNP6exQjoCZzrJkEsJ6igFebHtvew/8Vp13JjSvW7ToJq9EmsbST7COmUdRo9mUBqD9p2N+6WsU3G+UeZhrlE3yLUSdp8SNjeGYUE=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-10-10",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
      "2024-10-13": {
        spNum: 1,
        sbNum: 0,
        sbvNum: 0,
        sbSbvNum: 0,
        sp: [
          {
            fakeCampaignId: "D1F7",
            encryptCampaignId:
              "IDLFE7hRwqbJh6J+yHAm9GMKXrgAtL3Cxlu0DZrA6dtzAlZ7EMoa225/chvRHaVO+PNWSuEisP1DAs4pY0OtNfppXpOm1ReQ7B8w5iGynQ0TeIuTPlPk3QwDCw17ZmpQwIyhhr45izbmlEuwKMXl9e5iMfajRQcFZEIo1vTmcmQ=",
            strategy: "单广告组，单变体",
            campaignCreatedAt: "2024-10-16",
          },
        ],
        sb: [],
        sbv: [],
        sbSbv: [],
      },
    },
  },
  commonMsg: { notificationImgs: {}, version: "1.6.14", notifications: {} },
  message: null,
};
