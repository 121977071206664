import { useTranslation } from "react-i18next";

import BaseLoading from "../baseLoading";
import customWithErrorBoundary from "../errorBoundary";
import { memo } from "react";

function Export(props) {
  const { className, disabled = false, onDownload, loading = false } = props;
  const { t } = useTranslation();
  const handleExport = () => {
    if (disabled) {
      return;
    }
    onDownload && onDownload();
  };

  return (
    <BaseLoading
      disabled={disabled}
      loading={loading}
      className={className}
      onClick={handleExport}
      startIcon={<span className="icon-export"></span>}
    >
      <span className="textFont"> {t("globalBtnType.export")}</span>
    </BaseLoading>
  );
}

export default customWithErrorBoundary(memo(Export));
