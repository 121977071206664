import { countryCodeEnum } from "./country";

export const siteCurrencySymbol = {
  [countryCodeEnum.US]: "$",
  [countryCodeEnum.DE]: "€",
  [countryCodeEnum.UK]: "£",
  [countryCodeEnum.JP]: "￥",
  [countryCodeEnum.CA]: "$",
  [countryCodeEnum.FR]: "€",
  [countryCodeEnum.ES]: "€",
  [countryCodeEnum.IT]: "€",
};

export const siteDomain = {
  [countryCodeEnum.US]: "amazon.com",
  [countryCodeEnum.DE]: "amazon.de",
  [countryCodeEnum.UK]: "amazon.co.uk",
  [countryCodeEnum.JP]: "amazon.co.jp",
  [countryCodeEnum.CA]: "amazon.ca",
  [countryCodeEnum.FR]: "amazon.fr",
  [countryCodeEnum.ES]: "amazon.es",
  [countryCodeEnum.IT]: "amazon.it",
};
