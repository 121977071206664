import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import InputRange from "src/components/picker/inputRange";
import { adStrategyStartTime } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { periodKey } from "src/constants/form";
import { useTranslation } from "react-i18next";
import ChangeActiveSelect from "src/components/customSelect/changeActive";
import SearchInput from "src/components/searchInput";
import ClearAll from "src/components/clearAll";
import style from "src/styles/searchWrap.module.scss";
import dataSearchStyle from "./index.module.scss";
import classNames from "classnames";
import {
  defaultDataSearch,
  resetDataSearch,
  updateDataSearch,
} from "../../store";
import { getDefaultOption } from "src/utils/select";
import { AdStrategyContext } from "../../context";

function DataSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tableKey, fetchTable } = useContext(AdStrategyContext);
  const inputRef = useRef();
  const type = useSelector(
    (state) => state.adStrategy?.baseSearch?.[periodKey]
  );
  const { campaigns, asins } =
    useSelector((state) => state.adStrategy?.campaignsAndAsinsOptions) || {};
  const timerRef = useRef();

  const encryptCampaignId = useSelector(
    (state) => state.adStrategy?.dataSearch?.encryptCampaignId
  );

  const dataSearch = useSelector((state) => state.adStrategy?.dataSearch);
  const { adAsin, time, campaignId } = dataSearch;

  const campaignsData = useMemo(() => {
    return getDefaultOption().concat(
      campaigns?.map((item) => ({
        ...item,
        value: item?.encryptCampaignId,
        label: item?.displayCampaignId,
      })) || []
    );
  }, [campaigns]);

  const asinsData = useMemo(() => {
    return getDefaultOption().concat(
      asins?.map((item) => ({
        ...item,
        value: item,
        label: item,
      })) || []
    );
  }, [asins]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleChangeTime = (time) => {
    dispatch(
      updateDataSearch({
        key: "time",
        value: time,
      })
    );
    fetchTable();
  };

  const onBlur = useCallback((value) => {
    dispatch(
      updateDataSearch({
        key: "campaignId",
        value,
      })
    );

    if (value !== campaignId) {
      fetchTable();
    }
  }, []);

  const searchCallBack = useCallback((value) => {
    dispatch(
      updateDataSearch({
        key: "campaignId",
        value,
      })
    );
    fetchTable();
  }, []);

  const handleClear = useCallback(() => {
    clearTimeout(timerRef.current);
    dispatch(resetDataSearch());
    inputRef.current?.clear();
    if (JSON.stringify(defaultDataSearch) !== JSON.stringify(dataSearch)) {
      fetchTable();
    }
  }, [dataSearch]);

  const handleChangeCampainsData = useCallback((value) => {
    dispatch(
      updateDataSearch({
        key: "encryptCampaignId",
        value,
      })
    );
    fetchTable();
  }, []);

  const handleChangeAsins = useCallback((value) => {
    dispatch(
      updateDataSearch({
        key: "adAsin",
        value,
      })
    );
    fetchTable();
  }, []);

  return (
    <div className="wrap">
      <form
        className={classNames(
          style.searchWrap,
          dataSearchStyle.dataSearchWrap,
          "textFont"
        )}
      >
        <ChangeActiveSelect
          width="100%"
          data={campaignsData}
          labelClassName="textFont"
          value={encryptCampaignId}
          onChange={handleChangeCampainsData}
          label={t(`adStrategy.dataSearch.chooseCampailgns`)}
        ></ChangeActiveSelect>
        <ChangeActiveSelect
          data={asinsData}
          width="100%"
          labelClassName="textFont"
          value={adAsin}
          onChange={handleChangeAsins}
          label={t(`adStrategy.dataSearch.chooseAsins`)}
        ></ChangeActiveSelect>
        <div>
          <div className={classNames(style.label, "stressTextFont")}>
            {t(`adStrategy.dataSearch.chooseTime`)}
          </div>
          <InputRange
            type={type}
            value={time}
            onChange={handleChangeTime}
            startTime={new Date(adStrategyStartTime)}
            endTime={new Date()}
          />
        </div>
        <div>
          <span className="stressTextFont">
            {t(`adStrategy.dataSearch.searchById`)}
          </span>
          <br />
          <SearchInput
            ref={inputRef}
            searchText={campaignId}
            onBlur={onBlur}
            searchCallBack={searchCallBack}
            placeholder={t(`adStrategy.searchCamPlaceHolder`)}
          />
        </div>

        <ClearAll onClear={handleClear} />
      </form>
    </div>
  );
}

export default customWithErrorBoundary(memo(DataSearch));
