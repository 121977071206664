import { memo } from "react";
import CellEmpty from "src/components/table/cellEmpty";

function Attributes({ row, idx }) {
  if (!row?.featureValues?.length) {
    return <CellEmpty value={undefined} />;
  }
  return (
    <div className="multLineTdWrap">
      {row?.featureValues?.map((item) => {
        return (
          <div key={item.name + item.value}>
            {item.name}:{item.value}
          </div>
        );
      })}
    </div>
  );
}

export default memo(Attributes);
