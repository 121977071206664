import { addLinkUrl, pageAction } from "src/constants/page";
import { detailOverId } from "./constant";
import { asinsKey } from "src/constants/form";
import i18next from "i18next";
import { weekMonthEnum } from "src/constants/time";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";

export function getTrack() {
  const ele = document.querySelector(
    `#${detailOverId} .ScrollbarsCustom-TrackX`
  );

  return ele;
}

export function adFormatFreqTableParmas({
  state = {},
  activeCode,
  tableSearchData,
}) {
  try {
    console.log("state.detailEncryptAdInfo", state.detailEncryptAdInfo);
    const data = {
      asin: state.detailEncryptAdInfo?.asin,
      listing: false,
      time: state.detailEncryptAdInfo?.time,
      wordModel: activeCode?.toLocaleUpperCase(),
      page: tableSearchData.page,
      pageSize: tableSearchData.pageSize,
    };

    return data;
  } catch (e) {}
}

export function getOption({ data = {} } = {}) {
  const options = [
    addLinkUrl(pageAction.productSales, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    addLinkUrl(pageAction.trafficStructure, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    addLinkUrl(pageAction.trafficHistory, {
      baseParamsKeys: [asinsKey],
      data,
    }),
  ];

  return [
    addLinkUrl(pageAction.trafficKeywords, {
      baseParamsKeys: [asinsKey],
      data,
    }),
    options,
  ];
}

export const getEmptyReasons = () => [
  i18next.t(`adStrategy.modalList.emptyTips.reason2`),
  i18next.t(`adStrategy.modalList.emptyTips.reason3`),
  i18next.t(`adStrategy.modalList.emptyTips.reason4`),
];

export const getDateFormatFn = (type) => {
  return type === weekMonthEnum.weekly
    ? { endFn: endOfWeek, startFn: startOfWeek }
    : { endFn: endOfMonth, startFn: startOfMonth };
};

export const getPosition = ({ dates, key } = {}) => {
  if (Array.isArray(dates) && key) {
    const idx = dates?.findIndex((item) => item === key);
    const position = idx / (dates?.length - 1);
    return position * 100 + "%";
  }

  return "";
};
