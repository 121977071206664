import classNames from "classnames";
import { memo, useCallback, useEffect, useMemo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import Tip from "src/components/tooltip/tip";
import Btns from "./btns";
import Chart from "../chart";
import { useDispatch, useSelector } from "react-redux";
import FlowPagination from "src/components/pagination";
import { getChartDataThunk } from "../../store";
import Empty from "src/components/empty";
import EmptyTips from "../tips";

function ChartWrap() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chartData = useSelector((state) => state.structure.chartData);
  const chartSearch = useSelector((state) => state.structure.chartSearch);
  const chartFetchStatus = useSelector(
    (state) => state.structure.chartFetchStatus
  );

  const memoChartSearch = useMemo(() => {
    return chartSearch;
  }, [chartData]);

  const memoChartFetchStatus = useMemo(() => {
    return chartFetchStatus;
  }, [chartData]);

  const handleChangePageNumAndFetch = useCallback((e, page) => {
    dispatch(getChartDataThunk({ pageNum: page }));
  }, []);

  return (
    <div className={classNames("wrap", style.chartWrap)}>
      <div
        className={classNames(style.title, "titleSummary", "wrapContentInfo")}
      >
        <div>
          <span className={style.text}>{t(`structure.chart.title`)}</span>
          <Tip title={t("trafficTitleHover")}>
            <span className="icon-tip"></span>
          </Tip>
        </div>
        <Btns />
      </div>
      <div className={style.container}>
        <Empty
          data={chartData}
          status={
            chartData?.total === undefined
              ? chartFetchStatus
              : memoChartFetchStatus
          }
          tips={<EmptyTips />}
        >
          <Chart memoChartSearch={memoChartSearch} />
          <div className="pageContentWrap">
            <FlowPagination
              onChange={handleChangePageNumAndFetch}
              page={chartSearch.pageNum}
              count={Math.ceil((chartData?.total || 0) / chartSearch.pageSize)}
            />
          </div>
        </Empty>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(ChartWrap));
