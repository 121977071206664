import { useEffect, useRef } from "react";
import tableVariables from "src/styles/_table.scss";
import head from "../head";

export const useChangeStickyShadow = (
  deps = [],
  { containerId, fixedHeadId, headId } = {}
) => {
  const hasFoldRef = useRef(false);

  useEffect(() => {
    const tableContainer = document.querySelector(`#${containerId}`);
    const tableContent = document.querySelector(`#${headId}`);
    const tableFixedHeader = document.querySelector(`#${fixedHeadId}`);
    let timer;
    if (!tableContainer || !tableContent) {
      return;
    }

    const tableContainerScroll = () => {
      const wrapWidth = tableContainer.clientWidth;
      const contentWidth = tableContent.clientWidth;

      const leftClassName = tableVariables.pingLeft;
      const rightClassName = tableVariables.pingRight;
      if (wrapWidth === contentWidth) {
        tableContainer.classList.remove(leftClassName);
        tableContainer.classList.remove(rightClassName);
        hasFoldRef.current = false;
      } else if (tableContainer.scrollLeft === 0) {
        tableContainer.classList.remove(leftClassName);
        tableContainer.classList.add(rightClassName);
        hasFoldRef.current = true;
      } else if (tableContainer.scrollLeft + wrapWidth - contentWidth <= 1) {
        tableContainer.classList.remove(rightClassName);
        tableContainer.classList.add(leftClassName);
        hasFoldRef.current = true;
      } else {
        tableContainer.classList.add(leftClassName);
        tableContainer.classList.add(rightClassName);
        hasFoldRef.current = true;
      }

      if (!tableFixedHeader) {
        return;
      }

      if (!hasFoldRef.current) {
        tableFixedHeader.style.zIndex = -2;
        tableFixedHeader.style.opacity = 0;
      }
    };

    tableContainerScroll();
    timer = setTimeout(() => {
      tableContainerScroll();
    }, 800);
    window.addEventListener("resize", tableContainerScroll, {
      passive: true,
    });
    tableContainer.addEventListener("scroll", tableContainerScroll, {
      passive: true,
    });

    return () => {
      tableContainer.removeEventListener("scroll", tableContainerScroll);
      window.removeEventListener("resize", tableContainerScroll);
      clearTimeout(timer);
    };
  }, [deps]);

  return [hasFoldRef];
};
