import { memo, useContext } from "react";
import { useSelector } from "react-redux";
import CustomCheckbox from "src/components/customCheckbox";
import CustomCell from "src/components/table/cell";
import { TableContext } from "./context";

function GlobalChecked({ column, type, style = {} }) {
  const { name, handleSelectAllClick, dataKey } = useContext(TableContext);

  const tableData = useSelector((state) => state.root.table[name].tableData);
  const showAllChecked = useSelector(
    (state) => state.root.table[name].showAllChecked
  );
  const hoverRowAsin = useSelector(
    (state) => state.root.table[name].hoverRowAsin
  );
  const selected = useSelector((state) => state.root.table[name].selected);

  const numSelected = selected?.length;
  const tableList = tableData?.[dataKey];
  const rowCount = tableList?.length;

  return (
    <CustomCell column={column} style={style} type={type}>
      <div
        style={{
          opacity: showAllChecked || hoverRowAsin === "all" ? 1 : 0,
        }}
      >
        <CustomCheckbox
          indeterminate={numSelected > 0 && numSelected < rowCount}
          checked={rowCount > 0 && numSelected === rowCount}
          onChange={handleSelectAllClick}
          sx={{
            padding: "0 9px",
          }}
          inputProps={{
            "aria-label": "select all desserts",
          }}
        />
      </div>
    </CustomCell>
  );
}

export default memo(GlobalChecked);
