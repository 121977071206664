import LoadingButton from "@mui/lab/LoadingButton";
import { memo, useEffect, useRef, useState } from "react";

import customWithErrorBoundary from "../errorBoundary";

import style from "./index.module.scss";
import classNames from "classnames";
function ChartWrapInTable({
  children,
  lineHeight,
  rootMargin = "300px 0px ",
  onClick,
  className,
}) {
  const ref = useRef();
  //   const [showChart, setShowChart] = useState(false);

  //   useEffect(() => {
  //     const observer = new IntersectionObserver(
  //       (entries) => {
  //         entries.forEach((entry) => {
  //           // 根据交叉状态做出响应
  //           if (entry.isIntersecting && ref.current) {
  //             setShowChart(true);
  //           }
  //         });
  //       },
  //       {
  //         rootMargin,
  //       }
  //     );

  //     observer.observe(ref.current);
  //     return () => {
  //       observer.disconnect();
  //     };
  //   }, []);

  return (
    <div
      ref={ref}
      className={classNames(style.chartWrap, className)}
      onClick={onClick}
    >
      {/* {showChart ? (
        children
      ) : (
        <div style={{ lineHeight }}>
          <LoadingButton loading={true} size="small" />
        </div>
      )} */}

      {children}
    </div>
  );
}

export default customWithErrorBoundary(memo(ChartWrapInTable));
