import { siteCurrencySymbol, siteDomain } from "src/constants/amazon";
import { countryCodeEnum } from "src/constants/country";
import { formatNumber, getCountry } from ".";

// 设置站点域名
export function getSiteDomain(code) {
  return siteDomain[code] || siteDomain[countryCodeEnum.US];
}

export function getSiteCurrency(code) {
  return siteCurrencySymbol[code] || siteCurrencySymbol[countryCodeEnum.US];
}

export function getPrice(price) {
  const countryCode = getCountry();
  return `${getSiteCurrency(countryCode)}${formatNumber(price)}`;
}

export function linkToAmazon(asin) {
  const countryCode = getCountry();
  const domain = getSiteDomain(countryCode);
  window.open(`https://www.${domain}/dp/${asin}?th=1&psc=1`);
}

export function linkToAmzonSearch(keyword) {
  const countryCode = getCountry();
  const domain = getSiteDomain(countryCode);
  window.open(`https://www.${domain}/s?k=${keyword}`);
}
export function getAmazonFixedWidthImage(src, size, { hoverRatio = 1 } = {}) {
  try {
    let index = 0;

    if (!src) {
      return src;
    }
    if (src.indexOf("jpg") > -1) {
      index = src.indexOf("jpg");
    } else if (src.indexOf("png") > -1) {
      index = src.indexOf("png");
    } else if (src.indexOf("jpeg") > -1) {
      index = src.indexOf("jpeg");
    } else if (src.indexOf("gif") > -1) {
      index = src.indexOf("gif");
    }
    src = `${src.slice(0, index - 11)}_AC_UL${hoverRatio * size}_.${src.slice(
      index
    )}`;
    return src;
  } catch (e) {
    return src;
  }
}
