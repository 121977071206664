import classNames from "classnames";
import { memo, useContext } from "react";
import CellEmpty from "src/components/table/cellEmpty";

import { KeywordsContext } from "../../context";
import { rankTrend } from "../../constants";
import { highlightByTime } from "src/utils/time";
import { useTranslation } from "react-i18next";

import style from "./index.module.scss";
import RankTooltip from "./rankTooltip";

function SpAdRank({ rowData = {}, isListingSearch = false, time }) {
  const { openRankDrawer } = useContext(KeywordsContext);

  const newRowData = { ...rowData, defaultActive: rankTrend[1] };
  const { t } = useTranslation();
  const res = highlightByTime(rowData?.spPositionTimeUser, time);

  return (
    <RankTooltip
      localTime={rowData?.spPositionTimeUser}
      siteTime={rowData?.spPositionTime}
    >
      <div
        className={classNames("multLineTdWrap", {
          [style.highlight]: res.highlight,
        })}
        onClick={() => openRankDrawer(newRowData)}
        style={{ cursor: "pointer" }}
      >
        <div className={style.adLastRank}>
          <span>
            <CellEmpty value={rowData?.spRank}></CellEmpty>
          </span>
        </div>
        <div>
          <CellEmpty value={rowData?.spPosition}></CellEmpty>
        </div>
        {isListingSearch && (
          <div>
            <CellEmpty value={rowData?.spAsin}></CellEmpty>
          </div>
        )}
        <div>
          <CellEmpty value={res?.date + " " + t(res.unit)}></CellEmpty>
        </div>
      </div>
    </RankTooltip>
  );
}

export default memo(SpAdRank);
