import { memo, useCallback, useState } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import Lengend from "./lengend";
import style from "./index.module.scss";
import ButtonTab from "src/components/buttonTab";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeatures, chartColors } from "src/utils/chart/util";
import { useTranslation } from "react-i18next";
import { getChartDataThunk, updateChangeDimension } from "../../store";
import {} from "src/utils/chart/util";
import { toRGBA } from "src/utils";
import { chartDataOptionList } from "../../constant";
function Nav({ chartRef }) {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const activeCode = useSelector(
    (state) => state.structure.chartSearch.dimension
  );

  const chartData = useSelector((state) => state.structure.chartData);
  const [legendData, setLegendData] = useState(
    chartDataOptionList()?.map((value, idx) => {
      let last = idx === chartDataOptionList().length - 1;
      return {
        text: value,
        datasetIndex: idx,
        fillStyle: chartColors[last ? idx + 1 : idx],
        hide: false,
      };
    })
  );
  const allFeatures = getAllFeatures(
    chartData?.features,
    "trafficDimensionEnum"
  );
  const handleChangeDimension = useCallback((code, data) => {
    dispath(updateChangeDimension(code));
    dispath(getChartDataThunk());
  }, []);

  const onHover = (item, idx) => {
    const chart = chartRef.current;

    chart.data.datasets.forEach((element, index) => {
      if (element.type !== "line") {
        if (idx !== index) {
          element.backgroundColor = element.noHoverColor;
          element.textColor = toRGBA(element.textColor, 0.1);
        } else {
          element.backgroundColor = element.originBackgroundColor;
          element.textColor = element.originTextColor;
        }
      }
    });

    chart.update();
  };

  const onLeave = () => {
    const chart = chartRef.current;

    chart.data.datasets.forEach((element, index) => {
      if (element.type !== "line") {
        element.backgroundColor = element.originBackgroundColor;
        element.textColor = element.originTextColor;
      }
    });
    chart.update();
  };

  const handleToggleLine = useCallback(
    (e, item) => {
      const newLegendData = [...legendData];
      const idx = newLegendData.findIndex((i) => i.text === item.text);

      if (idx !== -1) {
        const data = { ...newLegendData[idx] };
        data.hide = !data.hide;
        newLegendData[idx] = data;
        setLegendData([...newLegendData]);

        const chart = chartRef.current;

        chart.setDatasetVisibility(
          item.datasetIndex,
          !chart.isDatasetVisible(item.datasetIndex),
          false
        );
        chart.update("none");
      }
    },
    [legendData]
  );
  return (
    <div className={style.legendWrap}>
      <div className={style.legendLeft}>
        <ButtonTab
          data={allFeatures}
          activeCode={activeCode}
          onChangeActive={handleChangeDimension}
        />
      </div>
      <ul className={style.legend}>
        {legendData?.map((item, idx) => {
          const color = item.hide ? "#BBBBBB" : item.fillStyle;

          return (
            <span
              onMouseEnter={() => onHover(item, idx)}
              onMouseLeave={() => onLeave(item, idx)}
              key={item.text}
            >
              <Lengend
                item={item}
                color={color}
                key={item.text}
                handleToggleLine={handleToggleLine}
              />
            </span>
          );
        })}
      </ul>
    </div>
  );
}

export default customWithErrorBoundary(memo(Nav));
