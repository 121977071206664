import CellEmpty from "src/components/table/cellEmpty";
import { getPercentage } from "src/utils";

function Variants({ rowData = {} }) {
  const exposureVariants = rowData?.exposureVariants || [];

  return (
    <div className="multLineTdWrap">
      {exposureVariants.map((item) => {
        return (
          <div key={item.asin}>
            <CellEmpty value={item?.asin} />
            :&nbsp;
            <CellEmpty value={getPercentage(item.ratio)}></CellEmpty>
          </div>
        );
      })}
    </div>
  );
}

export default Variants;
