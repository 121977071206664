import { useTranslation } from "react-i18next";
import style from "src/components/empty/tip.module.scss";
import { useSelector } from "react-redux";
import { actionTypeEnum } from "src/constants";
import { getEmptyReasons } from "../../util";
import { useMemo } from "react";
import Tips from "src/components/empty/tips";

function EmptyTips() {
  const actionType = useSelector((state) => state.sales.baseSearch.actionType);
  const isAsin = actionType === actionTypeEnum.asin;
  const reasons = useMemo(() => {
    return getEmptyReasons(isAsin);
  });

  return <Tips reasons={reasons} num="num.two" />;
}

export default EmptyTips;
