import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";

import customWithErrorBoundary from "src/components/errorBoundary";
import Lengend from "./lengend";

import style from "./index.module.scss";
import { formatSalesCount } from "src/utils";
import { chartColors } from "src/utils/chart/util";
import useLanguage from "src/hooksOrClass/language";

const htmlLegendContainerID = "legend-container";

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false, // 禁用默认的图例
    },
    htmlLegend: {
      containerID: "legend-container", // 自定义图例容器的ID
    },
    tooltip: {
      enabled: false,
      position: "nearest",
    },
    hoverCrosshair: {
      format: formatSalesCount,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },

    y: {
      beginAtZero: true,
    },
  },
};

function SalesChart() {
  const chartRef = useRef();
  const chartData = useSelector((state) => state.sales.chartData);
  const updateLengedRef = useRef();
  const { autoFormatLocale } = useLanguage();
  // 初始占位
  const [legendData, setLegendData] = useState([]);

  const legendDataRef = useRef(legendData);

  useEffect(() => {
    legendDataRef.current = legendData;
  }, [legendData]);

  useEffect(() => {
    setLegendData(
      chartData?.list?.map((item, idx) => ({
        text: item.dimVal,
        datasetIndex: idx,
        fillStyle: chartColors[idx],
        hide: false,
      }))
    );
  }, [chartData]);

  const data = {
    labels: chartData?.dates?.map((item) => autoFormatLocale(item)),

    datasets: chartData?.list?.map((item, idx) => {
      return {
        label: item.dimVal,
        data: item.boughtList,
        normalized: true,
        backgroundColor: chartColors[idx],
        borderColor: chartColors[idx],
        fill: false,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        originPointRadius: 1,
      };
    }),
  };

  const handleToggleLine = useCallback((e, item) => {
    if (String(e.target.tagName).toLocaleLowerCase() !== "input") {
      return;
    }
    const newLegendData = [...legendDataRef.current];
    const idx = newLegendData.findIndex((i) => i.text === item.text);

    if (idx !== -1) {
      const data = { ...newLegendData[idx] };
      data.hide = !data.hide;
      newLegendData[idx] = data;
      setLegendData(newLegendData);

      const chart = chartRef.current;

      chart.setDatasetVisibility(
        item.datasetIndex,
        !chart.isDatasetVisible(item.datasetIndex)
      );
      chart.update();
    }
  }, []);

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      if (updateLengedRef.current) {
        return;
      }

      updateLengedRef.current = true;
      chartRef.current = chart;
      // 下面这句话不能删，不然会导致图例点击不能切换图表的展示与隐藏
      chart.options.plugins.legend.labels.generateLabels(chart);
    },
  };

  return (
    <div>
      <div className={style.legendWrap}>
        <ul id={htmlLegendContainerID} className={style.legend}>
          {legendData?.map((item) => {
            const color = item.hide ? "#a9a9a9" : item.fillStyle;
            return (
              <Lengend
                item={item}
                color={color}
                key={item.text}
                handleToggleLine={handleToggleLine}
              />
            );
          })}
        </ul>
      </div>

      <div style={{ height: 273, paddingRight: "40px" }}>
        {chartData?.list?.length && (
          <Line data={data} options={options} plugins={[htmlLegendPlugin]} />
        )}
      </div>
    </div>
  );
}
export default customWithErrorBoundary(memo(SalesChart));
