import i18next from "i18next";
import { asinsKey } from "src/constants/form";
import { addLinkUrl, pageAction } from "src/constants/page";

export const getTrafficOption = (
  multiple = false,
  needDefaultOption = true,
  rowData = {}
) => {
  const commomOption = [
    addLinkUrl(pageAction.trafficKeywords, {
      baseParamsKeys: [asinsKey],
      data: rowData,
    }),
    addLinkUrl(pageAction.trafficStructure, {
      baseParamsKeys: [asinsKey],
      data: rowData,
    }),
    pageAction.trafficHistory,
    addLinkUrl(pageAction.adStrategy, {
      baseParamsKeys: [asinsKey],
      data: rowData,
    }),
  ];

  const singleOption = commomOption.map((item) =>
    addLinkUrl(item, {
      baseParamsKeys: [asinsKey],
      data: rowData,
    })
  );

  const commomMultipleOption = [commomOption[1], pageAction.keywordsGap];
  const multipleOption = commomMultipleOption.map((item) =>
    addLinkUrl(item, {
      baseParamsKeys: [asinsKey],
    })
  );

  // 对应table表格action类型， 单选且不需要
  if (!needDefaultOption) {
    return multiple ? [[], multipleOption] : [[], singleOption];
  }

  return multiple
    ? [commomMultipleOption.shift(), commomMultipleOption]
    : [commomOption.shift(), commomOption];
};

export const getEmptyReasons = (isAsin) => [
  i18next.t(`emptyTips.reason1`),
  i18next.t(isAsin ? `sales.emptyTips.reason2` : `sales.emptyTips.reason3`),
];
