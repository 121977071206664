import { MenuItem, Select } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "./index.module.scss";
import { BaseMenuProps } from "src/styles/theme";
import { timeEnum } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { submitExportTaskThunk } from "../../store";
import { fetchStatus } from "src/constants";

const menuProps = {
  ...BaseMenuProps,
  classes: {
    paper: style.keywordPaper, // 自定义类名
  },
};

const sx = {
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.main",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.main",
  },

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderWidth: 1,
    borderColor: "var(--color)",
  },
};

function ExportKeyword({ disabled = false }) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const exportCycleKeywordStatus = useSelector(
    (state) => state.adStrategy.exportCycleKeywordStatus
  );
  const exportAllKeywordStatus = useSelector(
    (state) => state.adStrategy.exportAllKeywordStatus
  );
  const dispatch = useDispatch();
  const handleChange = useCallback((value) => {
    console.log("value", value);
    dispatch(submitExportTaskThunk({ type: value }));
    setValue(value);
  }, []);

  return (
    <Select
      sx={sx}
      displayEmpty
      disabled={disabled}
      MenuProps={menuProps}
      value={value}
      renderValue={() => {
        // if (
        //   exportCycleKeywordStatus === fetchStatus.loading ||
        //   exportAllKeywordStatus === fetchStatus.loading
        // ) {
        //   return t(`adStrategy.exportBtn.exportingKeyword`);
        // }
        return t(`adStrategy.exportBtn.exportKeyword`);
      }}
    >
      <div>
        <MenuItem
          value={timeEnum.cycle}
          onClick={() => handleChange(timeEnum.cycle)}
        >
          <span className="textFont">
            {t(`adStrategy.exportBtn.cycleKeyword`)}
          </span>
        </MenuItem>
        <MenuItem
          value={timeEnum.all}
          onClick={() => handleChange(timeEnum.all)}
        >
          <span className="textFont">
            {t(`adStrategy.exportBtn.allKeyword`)}
          </span>
        </MenuItem>
      </div>
    </Select>
  );
}

export default customWithErrorBoundary(memo(ExportKeyword));
