import { getServiceDateStartAndEnd } from "src/components/picker/inputRange/util";
import { asinsKey, periodKey } from "src/constants/form";
import buildRequest from "src/request/axios";
import { allFormatEmpty } from "src/utils/select";
import { overData } from "./constant";

export const getCampaignsAndAsinsOptionsApi = ({ data, signal }) => {
  return buildRequest({
    url: "/api/web/ad_strategy/options",
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
};

const formatData = ({ state, tableSearchData }) => {
  const dataSearch = state.dataSearch || {};
  const baseSearch = state?.baseSearch;
  const data = {
    page: tableSearchData.page,
    pageSize: tableSearchData.pageSize,
    asin: baseSearch?.[asinsKey],
    campaignId: dataSearch?.campaignId,
    adAsin: allFormatEmpty(dataSearch?.adAsin),
    period: baseSearch?.[periodKey],
    encryptCampaignId: allFormatEmpty(dataSearch?.encryptCampaignId),
    ...(getServiceDateStartAndEnd({
      value: dataSearch?.time,
      type: baseSearch[periodKey],
    }) || {}),
  };

  return data;
};

export const getAdListApi = ({ state, tableSearchData, signal }) => {
  return buildRequest({
    url: "/api/web/ad_strategy",
    method: "post",
    data: formatData({ state, tableSearchData }),
    extraConfig: {
      signal,
    },
  });
};

export const downloadKeywordsApi = ({ url, data }) => {
  return buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      showErrorMessage: true,
    },
  });
};

export const getAdOverviewApi = ({ data, signal } = {}) => {
  return buildRequest({
    url: "/api/web/ad_strategy/ad_overview",
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
};

export const getAdHistoryApi = ({ data, signal }) => {
  return buildRequest({
    url: "/api/web/ad_strategy/ad_history",
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
};

function formatModalData(state, tableSearchData) {
  const { encryptAdId, time, keyword } = state?.detailEncryptAdInfo || {};
  return {
    encryptAdId,
    time,
    keyword,
    page: tableSearchData?.page,
    pageSize: tableSearchData?.pageSize,
  };
}

export const getAdDetailListApi = ({ state, tableSearchData, signal }) => {
  return buildRequest({
    url: "/api/web/ad_strategy/detail",
    method: "post",
    data: formatModalData(state, tableSearchData),
    extraConfig: {
      signal,
    },
  });
};

export const getModalSelectOptionsApi = (data) => {
  return buildRequest({
    url: "/api/web/ad_strategy/detail/options",
    method: "post",
    data,
  });
};

export const getOverviewsApi = async (data) => {
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 3000);
  });
  return overData.data;
  return buildRequest({
    url: "/api/web/ad_strategy/campaignBrief",
    method: "post",
    data,
  });
};
