import { useDispatch, useSelector } from "react-redux";
import Pie from "../pie";

import style from "./index.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Tip from "src/components/tooltip/tip";
import variables from "src/styles/_chart.scss";
import Export from "src/components/export";
import { fetchStatus } from "src/constants";
import { exportChartData } from "../../store";
const colors = variables.colors.split(",").map((item) => item.trim());

function Chart() {
  const chartData = useSelector((state) => state.keywords.chartData);
  const chartDownLoadStatus = useSelector(
    (state) => state.keywords.chartDownLoadStatus
  );
  const dispath = useDispatch();
  const { t } = useTranslation();
  const newChartData = Object.keys(chartData).map((key) => ({
    data: chartData[key],
    key,
    length: Object.keys(chartData[key] || {}).length,
  }));

  const allLength = newChartData
    .map((item) => item.length)
    .reduce((pre, cur) => {
      return pre + cur;
    }, 0);

  const length = Math.ceil(allLength / colors.length);

  const newColors = Array.from({ length }).reduce((pre) => {
    return pre.concat(colors);
  }, []);

  const handleDownLoadChart = () => {
    dispath(exportChartData());
  };

  return (
    <div className={classNames("wrap", style.chartContainer)}>
      <div className={classNames(style.wrapTitle, "listSummaryFont")}>
        <div>
          <span className={style.containerTitle}>
            {t(`keywords.chart.title`)}
          </span>
          <Tip title={t("trafficTitleHover")}>
            <span className="icon-tip"></span>
          </Tip>
        </div>
        <div>
          <Export
            loading={chartDownLoadStatus === fetchStatus.loading}
            disabled={allLength === 0 || !allLength}
            onDownload={handleDownLoadChart}
          />
        </div>
      </div>
      <div className={style.wrap}>
        {newChartData?.map((item, idx) => {
          const { key, data, length } = item;

          const start = newChartData
            ?.filter((d1, idx1) => idx1 < idx)
            ?.map((d) => d.length)
            ?.reduce((pre, cur) => pre + cur, 0);
          const end = length + start;

          let backgroundColors = newColors.slice(start, end);

          return (
            <div key={key}>
              <div className={classNames(style.title, "listBoldTextFont")}>
                {t(`keywords.chart.${key}`)}
              </div>
              <div className={style.pieWrap}>
                <Pie
                  key={key}
                  emptyTip={t(`keywords.chart.emptyTip`, {
                    type: t(`keywords.chart.${key}`),
                  })}
                  backgroundColors={backgroundColors}
                  data={Object.keys(data)?.map((key1) => {
                    return {
                      name: chartData[key]?.[key1]?.name,
                      value: chartData[key]?.[key1]?.score,
                      ratio: chartData?.[key]?.[key1].ratio,
                    };
                  })}
                />
              </div>
              <div></div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Chart;
