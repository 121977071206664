import { memo, useContext } from "react";
import { useSelector } from "react-redux";
import { AdStrategyContext } from "../../context";
import classNames from "classnames";
import customWithErrorBoundary from "src/components/errorBoundary";

import { useTranslation } from "react-i18next";
import useLanguage from "src/hooksOrClass/language";
import GroupInfo from "../groupInfo";
import ActionView from "../actionView";
import style from "./index.module.scss";

function MemoList() {
  const { tableKey } = useContext(AdStrategyContext);
  const tableData = useSelector(
    (state) => state.root?.table[tableKey]?.tableData
  );
  const trafficTime = useSelector((state) => state.adStrategy.trafficTime);

  const { t } = useTranslation();
  const { autoFormatLocale } = useLanguage();
  return (
    <>
      {tableData?.list?.map((item, idx) => {
        return (
          <div key={item?.displayCampaignId} className={style.listItemWrap}>
            <div className={classNames(style.title)}>
              <div className="mustBoldFont">
                {t(`adStrategy.campaignId`, {
                  campaignId: item?.displayCampaignId,
                })}
              </div>
              <span className={classNames("helperFont", style.tip)}>
                {t(`createdAt`, {
                  time: autoFormatLocale(item?.campaignStartTime),
                })}
              </span>
            </div>
            <div className={style.listItem}>
              <GroupInfo data={item} />
              <ActionView
                data={item}
                dates={tableData?.dates}
                length={tableData.list.length}
                trafficTime={idx === 0 ? trafficTime : null}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

export default customWithErrorBoundary(memo(MemoList));
