import buildRequest from "src/request/axios";
import { formatDataTime } from "src/utils/time";
import { formatAsinArray } from "src/utils";

function formatChartData(state) {
  const baseSearch = state.baseSearch;
  const { dimension, pageNum, pageSize } = state.chartSearch;
  const data = {
    asins: formatAsinArray(baseSearch.asins),
    time: baseSearch.time,
    dim: dimension,
    page: pageNum,
    pageSize,
  };

  return formatDataTime(data);
}

export const getChartDataApi = async (state, signal) => {
  const url = "/api/web/traffic/structure/chart";

  const response = await buildRequest({
    url,
    method: "post",
    data: formatChartData(state),
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downloadStructureChartApi = async (state) => {
  const url = "/api/web/export/submit/traffic_structure_chart";

  const response = await buildRequest({
    url,
    method: "post",
    data: formatChartData(state),
    extraConfig: {
      showErrorMessage: true,
    },
  });
  return response;
};

function formatTableData(state, tableSearchData) {
  const data = {
    asins: formatAsinArray(state.baseSearch.asins),
    time: state.baseSearch.time,
    ...tableSearchData,
  };
  return formatDataTime(data);
}

export const getTableDataApi = async (state, tableSearchData, signal) => {
  const url = "/api/web/traffic/structure";

  const response = await buildRequest({
    url,
    method: "post",
    data: formatTableData(state, tableSearchData),
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const getWordsTotalApi = async (data = {}, signal) => {
  const url = "/api/web/traffic/structure/count";

  const response = await buildRequest({
    url,
    method: "post",
    data: formatDataTime(data),
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downloadStructureTableDataApi = async (state, tableSearchData) => {
  const url = "/api/web/export/submit/traffic_structure";
  const response = await buildRequest({
    url,
    method: "post",
    data: formatTableData(state, tableSearchData),
    extraConfig: {
      showErrorMessage: true,
    },
  });
  return response;
};
