import SerialNumber from "src/components/table/components/serialNumber";
import Volume from "src/components/table/components/volume";
import TrendVolume from "src/components/table/components/trendVolume";
import DisplayAsin from "./displayAsin";
import Traffic from "./traffic";
import RankChart from "./rank";
import PageKeyword from "./keyword";
import tableVariables from "src/styles/_table.scss";

export const getColumns = ({ name } = {}) => {
  return [
    {
      id: "#",
      width: 30,
      align: "center",
      sticky: true,
      stickyStyle: {
        left: 0,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "adKeywords",
      width: 140,
      sticky: true,
      stickyStyle: {
        left: 30 + 16,
      },
      tooltip: "adKeywordsHover",
      className: tableVariables.leftLast,
      render: function ({ row }) {
        return <PageKeyword rowData={row} />;
      },
    },
    {
      id: "displayAsin",
      width: 100,
      tooltip: "displayAsinHover",

      render: function ({ row }) {
        return <DisplayAsin rowData={row} />;
      },
    },
    {
      id: "keywordTraffic",
      width: 110,
      align: "right",
      tooltip: "keywordTrafficHover",
      render: function ({ row }) {
        return <Traffic rowData={row} />;
      },
    },
    {
      id: "spRankInAd",
      width: 150,
      align: "center",
      tooltip: "spRankInAdHover",
      render: function ({ row }) {
        return (
          <RankChart
            rowData={row}
            name={name}
            code="adSpRanks"
            width={120}
            height={90}
            baseConfig={{
              pointRadius: 3,
              pointHoverRadius: 5,
              originPointRadius: 3,
              pointStyle: "triangle",
            }}
          />
        );
      },
    },
    {
      id: "organicRank",
      width: 100,
      align: "center",
      render: function ({ row }) {
        return (
          <RankChart rowData={row} name={name} code="ogRanks" height={90} />
        );
      },
    },
    {
      id: "volume",
      align: "right",
      width: 100,
      render: function ({ row }) {
        return <Volume rowData={row} />;
      },
    },
    {
      id: "trendByVolume",
      width: 130,
      align: "center",
      render: function ({ row }) {
        return <TrendVolume rowData={row} name={name} height={90} />;
      },
    },
  ];
};
