import { Pagination } from "@mui/material";
import { memo } from "react";

function FlowPagination({ onChange, page, count }) {
  return (
    <Pagination
      color="secondary"
      onChange={onChange}
      page={page}
      count={count}
      variant="outlined"
      shape="rounded"
    />
  );
}

export default memo(FlowPagination);
