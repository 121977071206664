import { memo } from "react";
import style from "./index.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Tip from "src/components/tooltip/tip";

function Position({ rowData = {} }) {
  const data = rowData;
  const { t } = useTranslation();

  const adPosition = function (sign = "/") {
    return data?.exposurePositionsAd?.length > 0 &&
      Array.isArray(data?.exposurePositionsAd)
      ? `${data?.exposurePositionsAd.join(sign)}${t(`.`)}`
      : "-";
  };

  const recoPosition = function (sign = "/") {
    return data?.exposurePositionsRec?.length > 0 &&
      Array.isArray(data?.exposurePositionsRec)
      ? `${data?.exposurePositionsRec?.join(sign)}${t(`.`)}`
      : "-";
  };

  const organicPosition = function () {
    return data?.exposurePositionOg ? `${t("yes")}${t(`.`)}` : "-";
  };

  const organicTitle = t(`terminology.organic`);
  const adTitle = t(`terminology.ad`);
  const recoTitle = t(`terminology.reco`);

  return (
    <Tip
      title={t(`keywords.tableTip.position`, {
        keyword: rowData?.keyword,
        organicTitle,
        adTitle,
        recoTitle,
        organic: organicPosition(),
        adPosition: adPosition(", "),
        recoPosition: recoPosition(", "),
      })}
    >
      <div className={classNames(style.position, "multLineTdWrap")}>
        <div className="textOverFlow">
          {organicTitle}:&nbsp;
          {organicPosition()}
        </div>
        <div className="textOverFlow">
          {adTitle}:&nbsp;
          {adPosition()}
        </div>
        <div className="textOverFlow">
          {recoTitle}:&nbsp;
          {recoPosition()}
        </div>
      </div>
    </Tip>
  );
}

export default memo(Position);
