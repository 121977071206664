export function linearMap(val, domain, range, clamp) {
  var d0 = domain[0];
  var d1 = domain[1];
  var r0 = range[0];
  var r1 = range[1];
  var subDomain = d1 - d0;
  var subRange = r1 - r0;

  if (subDomain === 0) {
    return subRange === 0 ? r0 : (r0 + r1) / 2;
  }

  if (clamp) {
    if (subDomain > 0) {
      if (val <= d0) {
        return r0;
      } else if (val >= d1) {
        return r1;
      }
    } else {
      if (val >= d0) {
        return r0;
      } else if (val <= d1) {
        return r1;
      }
    }
  } else {
    if (val === d0) {
      return r0;
    }

    if (val === d1) {
      return r1;
    }
  }

  return ((val - d0) / subDomain) * subRange + r0;
}

export const getSliceData = (data, rangeIdxs = []) => {
  try {
    const [startIndex, endIndex] = rangeIdxs;
    if (Array.isArray(data)) {
      return data.slice(startIndex, endIndex + 1);
    }

    return [];
  } catch (e) {
    return [];
  }
};
