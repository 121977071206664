import React, { memo } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import classNames from "classnames";

import variables1 from "src/styles/_var.scss";
import style from "./index.module.scss";

function Lengend({ item, color, handleToggleLine }) {
  return (
    <div
      onClick={(e) => handleToggleLine && handleToggleLine(e, item)}
      key={item.text}
      className={classNames(style.control, "textFont")}
    >
      <span style={{ background: color }} className={style.lengendSpan}></span>
      {item.text}
    </div>
  );
}

export default memo(Lengend);
