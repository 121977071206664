import { useTranslation } from "react-i18next";
import style from "./index.module.scss";

function ClearAll({ onClear }) {
  const { t } = useTranslation();

  return (
    <div className={style.clear} onClick={onClear}>
      <span>{t(`clearAll`)}</span>
    </div>
  );
}

export default ClearAll;
