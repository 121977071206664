import {
  differenceInDays,
  endOfMonth,
  endOfWeek,
  isValid,
  parse,
  startOfDay,
} from "date-fns";
import { times } from "src/constants/time";
import useLanguage from "src/hooksOrClass/language";
import { format } from "date-fns";

export function getParmarsFormatDate(dateString) {
  try {
    if (!dateString) return {};
    const date = dateString.split("-");
    const length = date.length;

    if (length === 1) {
      return { getYear: true };
    }

    if (length === 2) {
      return { getMonth: true };
    }

    if (length === 3) {
      return {};
    }
  } catch (e) {
    return {};
  }
}

export function getFormatWeek(dateString) {
  try {
    if (!dateString) {
      return "-";
    }
    const { formatLocale, autoFormatLocale } = useLanguage();
    const res = getParmarsFormatDate(dateString);

    if (res?.getYear || res?.getMonth) {
      return autoFormatLocale(dateString);
    }

    return (
      formatLocale(dateString) + " -> " + formatLocale(endOfWeek(dateString))
    );
  } catch (e) {
    return dateString;
  }
}

export function formatDateToOnlyShowStartYear(date) {
  try {
    var newDate = [];
    const { formatLocale } = useLanguage();
    let lastYear;

    date.forEach((element) => {
      const date = element?.date || element;
      const year = new Date(date).getFullYear();
      const showDate = formatLocale(date, { getDayAndMonth: true });
      if (year !== lastYear) {
        lastYear = year;
        newDate.push({
          ...(element || {}),
          date,
          showDate,
          year: year,
        });
      } else {
        newDate.push({
          ...(element || {}),
          date,
          showDate,
        });
      }
    });

    return newDate;
  } catch (e) {
    return date;
  }
}

export function highlightByTime(dateString, time) {
  try {
    if (!dateString) {
      return {
        date: "-",
        highlight: false,
      };
    }
    const { autoFormatLocale } = useLanguage();
    if (time?.type) {
      const end = time?.type === "week" ? endOfWeek : endOfMonth;
      return {
        date: autoFormatLocale(dateString),
        highlight:
          differenceInDays(
            startOfDay(end(time?.date)),
            startOfDay(dateString)
          ) === 0,
      };
    }
    const daysDifference = differenceInDays(
      startOfDay(new Date()),
      startOfDay(dateString)
    );

    if (daysDifference === 0) {
      return {
        date: autoFormatLocale(dateString, { onlyShowTime: true }),
        highlight: true,
        unit: "today",
      };
    }
    if (daysDifference === 1) {
      return {
        date: "",
        highlight: false,
        unit: "yesterday",
      };
    }

    return {
      date: autoFormatLocale(dateString),
      highlight: false,
    };
  } catch (e) {
    console.log("e", e);
    return {};
  }
}

export function formatDataTime(data) {
  try {
    if (data?.time) {
      let time;
      const currentTime = data.time;
      if (currentTime === times.week) {
        time = 7;
      } else if (currentTime === times.month) {
        time = 30;
      } else if (currentTime.type === "week") {
        time = format(currentTime?.date, "yyyy-MM-dd");
      } else if (currentTime.type === "month") {
        time = format(currentTime?.date, "yyyy-MM");
      }
      data.time = time;
    }

    return data;
  } catch (e) {
    return data;
  }
}

export const isInputDateValid = (date, formatParams) => {
  try {
    const parsedDate = parse(date, formatParams, new Date());
    if (isValid(parsedDate)) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const parseDateByFormatParams = (formatParams, date) => {
  if (!date || !formatParams) {
    return;
  }

  return parse(date, formatParams, new Date());
};
