import FlowPagination from "src/components/pagination";
import customWithErrorBoundary from "../errorBoundary";
import { memo, useContext } from "react";
import { TableContext } from "./context";
import { useSelector } from "react-redux";
import classNames from "classnames";
function TablePagination() {
  const { name, numSelected, showPageCompent, onChangePageNumAndFetch } =
    useContext(TableContext);

  const tableData = useSelector((state) => state.root.table[name].tableData);
  const tableSearch = useSelector(
    (state) => state.root?.table[name]?.tableSearch
  );
  return (
    <>
      {tableData?.total && showPageCompent ? (
        <div className={classNames("pageContentWrap", { hide: numSelected })}>
          <FlowPagination
            onChange={onChangePageNumAndFetch}
            page={tableSearch?.pageNum}
            count={Math.ceil(tableData?.total / tableSearch?.pageSize)}
          />
        </div>
      ) : null}
    </>
  );
}

export default customWithErrorBoundary(memo(TablePagination));
