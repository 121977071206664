import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { times } from "src/constants/time";
import { fetchStatus } from "src/constants";
import {
  downLoadRelatedFile,
  downloadKeywordsChartData,
  downloadKeywordsTableData,
  formatRelatedDataParmas,
  getChartData,
} from "./service";
import { exportPureFn } from "src/hooksOrClass/export";
import { updateGlobalLoading } from "src/store/rootReducer";
import { formatTableSearch } from "src/utils/table";
import { asinsKey, timeKey } from "src/constants/form";
import { all } from "src/utils/select";
export const defaultTableSearch = {
  type: [all],
  effect: [all],
  share: all,
  searchText: "",
};

export const defalutTrafficSort = [{ value: "trafficRatio", active: true }];

let tableCancelReject;
let tableResolvePromise;

let chartCancelReject;
let chartResolvePromise;

const initialState = {
  baseSearch: {
    [timeKey]: times.week,
    [asinsKey]: "",
  },
  tableSearch: defaultTableSearch,
  tableSearchOptions: {},
  rankDrawerOpen: false,

  ruleModalOpen: false,
  emptyModalOpen: false,
  asinDetailFetchStatus: fetchStatus.initial,
  detailController: null,
  // 默认为反查子体，否则为反查父体
  isListingSearch: false,
  // 接口返回的数据
  chartFetchStatus: fetchStatus.initial,
  chartData: {},
  chartDownLoadStatus: fetchStatus.initial,
  chartController: null,

  tableDownLoadStatus: fetchStatus.initial,

  trafficSortKeys: [{ value: "trafficRatio", active: true }],
  trafficDataOfCode: defaultTableSearch.share,
};

export const KeywordsSlice = createSlice({
  name: "keywords",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },

    updateTrafficDataOfCode: (state, action) => {
      state.trafficDataOfCode = state.tableSearch.share;
    },
    updateTableSearchType: (state, action) => {
      console.log(" action.payload", action.payload);
      state.tableSearch.type = ["all", action.payload];
      state.trafficDataOfCode = action.payload;
      console.log(" state.tableSearch", state.tableSearch.type);
    },
    updateBaseTime: (state, action) => {
      state.baseSearch.time = action.payload;
    },
    updateRankDrawerOpen: (state, action) => {
      state.rankDrawerOpen = action.payload;
    },
    updateEmptyModalOpen: (state, action) => {
      state.emptyModalOpen = action.payload;
    },
    updatTableDownLoadStatus: (state, action) => {
      state.tableDownLoadStatus = action.payload;
    },
    updateChartDownLoadStatus: (state, action) => {
      state.chartDownLoadStatus = action.payload;
    },

    updateRuleModalOpen: (state, action) => {
      state.ruleModalOpen = action.payload;
    },

    syncBaseSearch: (state, action) => {
      tableCancelReject && tableCancelReject();
      chartCancelReject && chartCancelReject();
      state.baseSearch = action.payload;
    },
    changeAsinDetailFetchStatus: (state, action) => {
      state.asinDetailFetchStatus = action.payload;
    },
    updateSortKeysActive: (state, action) => {
      const value = action.payload;
      state.trafficSortKeys = state.trafficSortKeys?.map((item) =>
        item.value === value
          ? { ...item, active: true }
          : { ...item, active: false }
      );
    },
    updateChartFetchStatus: (state, action) => {
      state.chartFetchStatus = action.payload;
    },
    updateTableSearchOptions: (state, action) => {
      state.tableSearchOptions = action.payload;
      const { trafficTypes } = action.payload || {};
      state.trafficSortKeys = defalutTrafficSort.concat(
        trafficTypes?.map((item) => ({ value: item.code, active: false }))
      );
    },
    resetTableSearch: (state) => {
      tableCancelReject && tableCancelReject();
      state.tableSearch = defaultTableSearch;
    },
    updatDetailSignalController: (state, action) => {
      state.detailController?.abort();
      state.detailController = action.payload;
    },
    updateChartController: (state, action) => {
      state.chartController?.abort();
      state.chartController = action.payload;
    },
    updateListingSearch: (state, action) => {
      chartCancelReject && chartCancelReject();
      tableCancelReject && tableCancelReject();
      state.isListingSearch = action.payload;
    },
    updateTableSearchKey: (state, action) => {
      tableCancelReject && tableCancelReject();
      const { key, value } = action.payload;
      state.tableSearch[key] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartDataThunk.fulfilled, (state, action) => {
      const responseData = action.payload;
      state.chartData = responseData;
    });
  },
});

export const {
  resetStore,
  updateBaseTime,
  updateChartController,
  updateChartFetchStatus,
  updateRankDrawerOpen,
  syncBaseSearch,
  updateListingSearch,
  changeAsinDetailFetchStatus,
  updatDetailSignalController,
  updateTableSearchKey,
  resetTableSearch,
  updateRuleModalOpen,
  updateSortKeysActive,

  updatTableDownLoadStatus,
  updateChartDownLoadStatus,
  updateTableSearchOptions,
  updateEmptyModalOpen,

  updateTableSearchType,
  updateTrafficDataOfCode,
} = KeywordsSlice.actions;

export const exportChartData = createAsyncThunk(
  "data/exportKeywordsChart",
  async (data, { dispatch, getState }) => {
    function getResolve(resolve) {
      chartResolvePromise = resolve;
    }

    function getReject(reject) {
      chartCancelReject = reject;
    }

    function successFn() {
      chartResolvePromise && chartResolvePromise();
    }

    function catchFn() {
      chartCancelReject && chartCancelReject();
    }

    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updateChartDownLoadStatus,
      successFn,
      catchFn,
    });

    const currentState = getState().keywords;
    const taskUrl = "/api/web/export/progress/traffic_keywords_overview";

    exportFn({
      downLoadService: () => {
        return downloadKeywordsChartData(currentState);
      },
      taskUrl,
    });
  }
);

export const exportKeywordsTable = createAsyncThunk(
  "data/exportKeywordsTable",
  async (data, { dispatch, getState }) => {
    const { key } = data || {};
    function getResolve(resolve) {
      tableResolvePromise = resolve;
    }

    function getReject(reject) {
      tableCancelReject = reject;
    }

    function successFn() {
      tableResolvePromise && tableResolvePromise();
    }

    function catchFn() {
      tableCancelReject && tableCancelReject();
    }

    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updatTableDownLoadStatus,
      successFn,
      catchFn,
    });

    const allState = getState();
    const tableSearchData = formatTableSearch(
      allState?.root?.table[key]?.tableSearch
    );
    const currentState = allState?.keywords;
    const taskUrl = "/api/web/export/progress/traffic_keywords";

    exportFn({
      downLoadService: () => {
        return downloadKeywordsTableData(currentState, tableSearchData);
      },
      taskUrl,
    });
  }
);

export const getChartDataThunk = createAsyncThunk(
  "data/keyowrdsGetChartData",
  async (data, { getState, dispatch }) => {
    dispatch(updateChartFetchStatus(fetchStatus.loading));
    const chartController = new AbortController();

    dispatch(updateChartController(chartController));

    const currentState = getState().keywords;

    const response = await getChartData(currentState, chartController.signal);

    if (!response) {
      dispatch(updateChartFetchStatus(fetchStatus.error));
    } else {
      dispatch(updateChartFetchStatus(fetchStatus.success));
      dispatch(updateGlobalLoading(false));
    }

    return response;
  }
);

export default KeywordsSlice.reducer;
