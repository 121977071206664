import classNames from "classnames";
import CustomModal from "../customModal";
import { useEffect, useRef } from "react";

let timer;
function PreviewModal({ previewModalOpen, handleClose, content, handleOk }) {
  const ref = useRef();

  useEffect(() => {
    if (previewModalOpen) {
      timer = setTimeout(() => {
        if (ref.current) {
          console.log("ref.current", ref.current, content);
          ref.current.value = content;
        }
      }, 300);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [previewModalOpen]);

  return (
    <CustomModal
      open={previewModalOpen}
      onClose={handleClose}
      title="Edit Must Read"
      btns={
        <div className="btnsWrap" style={{ marginTop: "10px" }}>
          <div
            className={classNames("cancelBtn", "button")}
            onClick={handleClose}
          >
            Cancel
          </div>
          <div
            className={classNames("okBtn", "button")}
            onClick={() => handleOk(ref.current)}
          >
            Confirm
          </div>
        </div>
      }
    >
      <div>
        <div>
          <div>Paste Content:</div>
          <div style={{ height: "5px" }}></div>
          <textarea rows={11} style={{ width: "99%" }} ref={ref}></textarea>
        </div>
      </div>
    </CustomModal>
  );
}

export default PreviewModal;
