import Collect from "src/components/collect";
import customWithErrorBoundary from "src/components/errorBoundary";
import CommonAction from "src/components/table/components/action";
import { memo } from "react";
import { getTablePageActions } from "../../util";
import { useSelector } from "react-redux";
import { isAsinMultiple } from "src/utils";
function Action({ rowData }) {
  const inLibrary = rowData.inLibrary;
  const baseSearch = useSelector((state) => state.structure.baseSearch);

  const options = getTablePageActions({
    data: {
      time: baseSearch.time,
      ...rowData,
    },
    multiple: isAsinMultiple(baseSearch?.asins),
  });

  return (
    <CommonAction options={options} rowData={rowData}>
      <Collect inLibrary={inLibrary} />
    </CommonAction>
  );
}

export default customWithErrorBoundary(memo(Action));
