import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonTab from "src/components/buttonTab";
import CustomDrawer from "src/components/customDrawer";
import customWithErrorBoundary from "src/components/errorBoundary";

import BoxLoading from "src/components/loading/box";
import FlowTable from "src/components/table";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import { getColumns } from "./column";
import {
  downLoadWordFileThunk,
  resetTable,
  resetTableData,
  updateWordFreqDrawerOpen,
} from "src/store/rootReducer";
import { fetchStatus } from "src/constants";

import style from "./index.module.scss";
import useTable from "src/hooksOrClass/table";
import Export from "src/components/export";
import useExport from "src/hooksOrClass/export";
import Empty from "src/components/empty";
import classNames from "classnames";
import { isLoading } from "src/utils";
import { getWordFreq } from "./service";
import { wordFreqTypes } from "./constant";
import DrawRelated from "../drawRelated";

const columns = getColumns();

function DrwaWordFrequency({ onGetRequestParams, url, exportUrl, taskUrl }) {
  const { t } = useTranslation();
  const name = "WORD_FREQUENCY_" + url;
  useRegisterTableState({ key: name, pageSize: 20 });
  const [activeCode, setActiveCode] = useState(wordFreqTypes[0].code);
  const activeCodeRef = useRef(activeCode);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const request = ({ getState, tableSearchData, tableController }) => {
    const data =
      onGetRequestParams &&
      onGetRequestParams({
        getState,
        tableSearchData,
        tableController,
        activeCode: activeCodeRef.current,
      });

    return getWordFreq({
      url,
      data,
      signal: tableController.signal,
    });
  };

  const [fetchTable, resetRef] = useTable({ name, request });
  const { downLoadStatus, exportFn, cancelRef } = useExport();

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const dispatch = useDispatch();
  const downLoadWordFreq = async () => {
    dispatch(
      downLoadWordFileThunk({
        activeCode,
        key: name,
        exportFn,
        onGetRequestParams,
        taskUrl,
        exportUrl,
      })
    );
  };

  const wordFreqDrawerOpen = useSelector(
    (state) => state.root.wordFreqDrawerOpen
  );

  const tabsData = wordFreqTypes.map((item) => ({
    ...item,
    name: t(`wordFrequencyTypes.${item.code}`),
  }));

  const handleChange = useCallback((code) => {
    setActiveCode(code);
    activeCodeRef.current = code;
  }, []);

  const onClose = () => {
    dispatch(updateWordFreqDrawerOpen(false));
    resetData();
  };

  function resetData() {
    resetRef.current && resetRef.current("cancel");
    setActiveCode(wordFreqTypes[0].code);
    activeCodeRef.current = wordFreqTypes[0].code;
    dispatch(resetTableData(name));
  }

  useEffect(() => {
    cancelRef.current && cancelRef.current();
    if (wordFreqDrawerOpen) {
      fetchTable();
    }
  }, [activeCode, wordFreqDrawerOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetTable(name));
    };
  }, []);

  return (
    <>
      <CustomDrawer
        open={wordFreqDrawerOpen}
        onClose={onClose}
        title={t(`terminology.wordFrequency`)}
      >
        <BoxLoading isLoading={isLoading(tableFetchStatus)} isNotTop>
          <div className={style.actionsWrap}>
            <ButtonTab
              data={tabsData}
              activeCode={activeCode}
              onChangeActive={handleChange}
            />

            <Export
              loading={downLoadStatus === fetchStatus.loading}
              disabled={!tableData?.total}
              onDownload={() => {
                downLoadWordFreq();
              }}
            />
          </div>
          <div
            className={classNames(
              "tableLastNoBorder",
              "drawerTableWrap",
              style.tableWrap
            )}
          >
            <FlowTable
              columns={columns}
              translationKey="wordFrequencyTable"
              uniqId="word"
              name={name}
              hasAddProductBtn={false}
            />
            <Empty
              data={tableData?.list}
              status={
                tableData?.total === undefined
                  ? tableFetchStatus
                  : memoTableFetchStatus
              }
              className="emptyDrawer"
            ></Empty>
          </div>
        </BoxLoading>
      </CustomDrawer>
      <DrawRelated />
    </>
  );
}

export default customWithErrorBoundary(memo(DrwaWordFrequency));
