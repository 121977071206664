import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";

import { useDispatch } from "react-redux";

import style from "./index.module.scss";
import {
  updateWordFreqKeywords,
  updateWordRelatedDrawerOpen,
} from "src/store/rootReducer";

function Word({ rowData = {} }) {
  const dispatch = useDispatch();

  const handleOpenReleatedDrawer = () => {
    dispatch(updateWordFreqKeywords(rowData.keywords));
    dispatch(updateWordRelatedDrawerOpen(true));
  };

  return (
    <div className={style.word} onClick={handleOpenReleatedDrawer}>
      <CellEmpty value={rowData.word} />
    </div>
  );
}

export default customWithErrorBoundary(memo(Word));
