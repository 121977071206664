import classNames from "classnames";
import style from "./index.module.scss";
import customWithErrorBoundary from "../errorBoundary";
import { Fragment, memo } from "react";
import SimpleTooltip from "../tooltip/simpleTooltip";

function ButtonTab({ data = [], activeCode, onChangeActive }) {
  return (
    <div>
      {data.map((item, idx) => {
        const Wrap = item.tooltip ? SimpleTooltip : Fragment;
        const WrapProps = item.tooltip ? { title: item.tooltip } : {};
        return (
          <Wrap {...WrapProps} key={item.code}>
            <div
              className={classNames(style.btn, "button", {
                [style.preActive]: data[idx + 1]?.code === activeCode,
                [style.active]: item.code === activeCode,
              })}
              onClick={() =>
                onChangeActive &&
                item.code !== activeCode &&
                onChangeActive(item.code, item)
              }
              key={item.code}
            >
              {item.name}
            </div>
          </Wrap>
        );
      })}
    </div>
  );
}

export default customWithErrorBoundary(memo(ButtonTab));
