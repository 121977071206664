import classNames from "classnames";
import { memo } from "react";
import CellEmpty from "src/components/table/cellEmpty";
import { formatNumber } from "src/utils";
import style from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
  updateActiveData,
  updateVolumeDrawerOpen,
} from "src/store/rootReducer";

function Volume({ rowData = {} }) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(updateActiveData(rowData));
    dispatch(updateVolumeDrawerOpen(true));
  };

  return (
    <div
      className={classNames("multLineTdWrap", style.pointer)}
      onClick={handleClick}
    >
      <div className={style.estSearchesNum}>
        <span>
          <CellEmpty
            value={formatNumber(rowData?.lastAbaSearchVolume)}
          ></CellEmpty>
        </span>
      </div>
      <div>
        <CellEmpty value={formatNumber(rowData?.lastAbaRank)}></CellEmpty>
      </div>
    </div>
  );
}

export default memo(Volume);
