import { Line } from "react-chartjs-2";
import { rankTrend, typeHistory } from "../../constants";
import customWithErrorBoundary from "src/components/errorBoundary";
import { memo } from "react";
import ChartWrap from "src/components/chart/wrap";
import variables from "src/styles/_chart.scss";
import style from "./index.module.scss";
import Empty from "src/components/empty";
import { useTranslation } from "react-i18next";

import useLanguage from "src/hooksOrClass/language";

const getOptions = (y = {}) => ({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false, // 禁用默认的图例
    },
    tooltip: {
      enabled: false,
      position: "nearest",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },

    y: {
      reverse: true,
      ticks: {
        precision: 0,
      },
      ...y,
    },
  },
});

function RankChart({ originData = {}, type = "" }) {
  const { t } = useTranslation();
  const { autoFormatLocale } = useLanguage();

  if (!originData || !Object.keys(originData).length) {
    return (
      <div className={style.emptyWrap}>
        <Empty
          data={null}
          tips={<div>{t(`keywords.drawRankEmpty`)}</div>}
        ></Empty>
      </div>
    );
  }

  const activeRank = rankTrend.find((item) => item.type === type);

  const y = activeRank.needTooltip
    ? {
        min: 0, // 设置Y轴的最小值为0
        max: activeRank.max || 2, // 设置Y轴的最大值为2
      }
    : {};

  const options = getOptions(y);

  const key = Object.keys(originData)[0];
  const value = originData[key];
  const length = Array.isArray(value) ? value.length : 1;
  const isSB = type === rankTrend[2].type;
  const data = {
    labels: Object.keys(originData).map((item) => autoFormatLocale(item)),
    datasets: new Array(length).fill(".").map((item, index) => ({
      label: t(
        `keywords.drawRankTabs.${
          isSB ? activeRank.labelText[index] : activeRank.labelText
        }`
      ),
      data: Object.keys(originData).map((key) =>
        Array.isArray(originData[key])
          ? originData[key][index]
          : originData[key]
      ),
      normalized: true,
      backgroundColor: "#222222",
      borderColor: "#222222",

      fill: false,
      borderWidth: 1,
      tension: 0.3,
      pointBackgroundColor: "white",
      pointRadius: 1,
      originPointRadius: 1,
    })),
  };

  return <Line options={options} data={data}></Line>;
}

export default customWithErrorBoundary(memo(RankChart));
