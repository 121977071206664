import { memo, useContext } from "react";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import style from "./index.module.scss";
import ImgWrap from "src/components/imgWrap";
import { getPrice, linkToAmazon } from "src/utils/amazon";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import { formatNumber, getPlaceHolderData } from "src/utils";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AmazonIcon } from "src/components/amazon";
import { KeywordsContext } from "../../context";
import { listingAsinType } from "src/constants";

function HoverImg() {
  const { tableKey: name } = useContext(KeywordsContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const info = tableData.asinInfo;
  const { img, title, asin, price, score: star, reviewCnt } = info || {};
  const { t } = useTranslation();
  return (
    <div className={style.hoverImgWrap}>
      <div>
        <ImgWrap src={img} />
      </div>
      <div className="textFont">
        <div className={classNames(style.title)} title={title}>
          {title}
        </div>
        <div className={style.detailInfo}>
          <div>
            {t(`keywords.asinDetail.asin`)}: {getPlaceHolderData(asin)}
          </div>
          <div>
            {t(`keywords.asinDetail.price`)}:&nbsp;
            {getPlaceHolderData(getPrice(price))}
          </div>
          <div>
            {t(`keywords.asinDetail.rating`)}:&nbsp;
            {getPlaceHolderData(formatNumber(reviewCnt))}
          </div>
          <div>
            {t(`keywords.asinDetail.star`)}: {getPlaceHolderData(star)}
          </div>
        </div>
      </div>
    </div>
  );
}

const MemoHoverImg = memo(HoverImg);

function Img() {
  const { tableKey: name } = useContext(KeywordsContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const info = tableData.asinInfo;
  const { img, asin } = info || {};
  if (tableData?.asinType === listingAsinType.LISTING_PASIN) {
    return null;
  }

  const handleClick = () => {
    linkToAmazon(asin);
  };
  return (
    <SimpleTooltip
      title={<MemoHoverImg />}
      tooltipSx={{ maxWidth: "none" }}
      placement="bottom-start"
    >
      <div className={style.imgWrap}>
        <ImgWrap src={img} />

        <div className={style.placeHolder} onClick={handleClick}>
          <AmazonIcon size={14} />
        </div>
      </div>
    </SimpleTooltip>
  );
}

export default customWithErrorBoundary(memo(Img));
