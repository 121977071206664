export const chartData = {
  code: 1,
  data: {
    date: [
      "2024-03-31",
      "2024-04-07",
      "2024-04-14",
      "2024-04-21",
      "2024-04-28",
      "2024-05-05",
      "2024-05-12",
      "2024-05-19",
      "2024-05-26",
      "2024-06-02",
      "2024-06-09",
      "2024-06-16",
      "2024-06-23",
      "2024-06-30",
      "2024-07-07",
      "2024-07-14",
      "2024-07-21",
      "2024-07-28",
      "2024-08-04",
      "2024-08-11",
      "2024-08-18",
      "2024-08-25",
      "2024-09-01",
      "2024-09-08",
      "2024-09-15",
    ],
    totalScore: [
      {
        score: 543076.4089,
        value: 543076.4089,
        isChanged: true,
        diffScore: 104659.2794,
        diffScoreRatio: 0.2387,
        typeRatio: null,
      },
      {
        score: 346070.0664,
        value: 346070.0664,
        isChanged: true,
        diffScore: -197006.3425,
        diffScoreRatio: -0.3628,
        typeRatio: null,
      },
      {
        score: 346514.416,
        value: 346514.416,
        isChanged: true,
        diffScore: 444.3496,
        diffScoreRatio: 0.0013,
        typeRatio: null,
      },
      {
        score: 461674.8461,
        value: 461674.8461,
        isChanged: true,
        diffScore: 115160.4301,
        diffScoreRatio: 0.3323,
        typeRatio: null,
      },
      {
        score: 593238.3716,
        value: 593238.3716,
        isChanged: true,
        diffScore: 131563.5255,
        diffScoreRatio: 0.285,
        typeRatio: null,
      },
      {
        score: 220978.9214,
        value: 220978.9214,
        isChanged: true,
        diffScore: -372259.4502,
        diffScoreRatio: -0.6275,
        typeRatio: null,
      },
      {
        score: 307092.7573,
        value: 307092.7573,
        isChanged: true,
        diffScore: 86113.8359,
        diffScoreRatio: 0.3897,
        typeRatio: null,
      },
      {
        score: 477493.6871,
        value: 477493.6871,
        isChanged: true,
        diffScore: 170400.9298,
        diffScoreRatio: 0.5549,
        typeRatio: null,
      },
      {
        score: 656288.0596,
        value: 656288.0596,
        isChanged: true,
        diffScore: 178794.3725,
        diffScoreRatio: 0.3744,
        typeRatio: null,
      },
      {
        score: 544397.8293,
        value: 544397.8293,
        isChanged: true,
        diffScore: -111890.2303,
        diffScoreRatio: -0.1705,
        typeRatio: null,
      },
      {
        score: 473640.5575,
        value: 473640.5575,
        isChanged: true,
        diffScore: -70757.2718,
        diffScoreRatio: -0.13,
        typeRatio: null,
      },
      {
        score: 396462.5551,
        value: 396462.5551,
        isChanged: true,
        diffScore: -77178.0024,
        diffScoreRatio: -0.1629,
        typeRatio: null,
      },
      {
        score: 328480.6838,
        value: 328480.6838,
        isChanged: true,
        diffScore: -67981.8713,
        diffScoreRatio: -0.1715,
        typeRatio: null,
      },
      {
        score: 522152.1818,
        value: 522152.1818,
        isChanged: true,
        diffScore: 193671.498,
        diffScoreRatio: 0.5896,
        typeRatio: null,
      },
      {
        score: 589381.8633,
        value: 589381.8633,
        isChanged: true,
        diffScore: 67229.6815,
        diffScoreRatio: 0.1288,
        typeRatio: null,
      },
      {
        score: 1089405.2961,
        value: 1089405.2961,
        isChanged: true,
        diffScore: 500023.4328,
        diffScoreRatio: 0.8484,
        typeRatio: null,
      },
      {
        score: 419302.9325,
        value: 419302.9325,
        isChanged: true,
        diffScore: -670102.3636,
        diffScoreRatio: -0.6151,
        typeRatio: null,
      },
      {
        score: 562703.1158,
        value: 562703.1158,
        isChanged: true,
        diffScore: 143400.1833,
        diffScoreRatio: 0.342,
        typeRatio: null,
      },
      {
        score: 415011.0989,
        value: 415011.0989,
        isChanged: true,
        diffScore: -147692.0169,
        diffScoreRatio: -0.2625,
        typeRatio: null,
      },
      {
        score: 366731.6457,
        value: 366731.6457,
        isChanged: true,
        diffScore: -48279.4532,
        diffScoreRatio: -0.1163,
        typeRatio: null,
      },
      {
        score: 419857.4207,
        value: 419857.4207,
        isChanged: true,
        diffScore: 53125.775,
        diffScoreRatio: 0.1449,
        typeRatio: null,
      },
      {
        score: 471752.7962,
        value: 471752.7962,
        isChanged: true,
        diffScore: 51895.3755,
        diffScoreRatio: 0.1236,
        typeRatio: null,
      },
      {
        score: 402671.3456,
        value: 402671.3456,
        isChanged: true,
        diffScore: -69081.4506,
        diffScoreRatio: -0.1464,
        typeRatio: null,
      },
      {
        score: 372127.9939,
        value: 372127.9939,
        isChanged: true,
        diffScore: -30543.3517,
        diffScoreRatio: -0.0759,
        typeRatio: null,
      },
      {
        score: 409733.5959,
        value: 409733.5959,
        isChanged: true,
        diffScore: 37605.602,
        diffScoreRatio: 0.1011,
        typeRatio: null,
      },
    ],
    nfScore: [
      {
        score: 188621.4256,
        value: 188621.4256,
        isChanged: true,
        diffScore: 22381.6306,
        diffScoreRatio: 0.1346,
        typeRatio: 0.3473,
      },
      {
        score: 106409.997,
        value: 106409.997,
        isChanged: true,
        diffScore: -82211.4286,
        diffScoreRatio: -0.4359,
        typeRatio: 0.3075,
      },
      {
        score: 87238.5174,
        value: 87238.5174,
        isChanged: true,
        diffScore: -19171.4796,
        diffScoreRatio: -0.1802,
        typeRatio: 0.2518,
      },
      {
        score: 151131.6896,
        value: 151131.6896,
        isChanged: true,
        diffScore: 63893.1722,
        diffScoreRatio: 0.7324,
        typeRatio: 0.3274,
      },
      {
        score: 204593.5503,
        value: 204593.5503,
        isChanged: true,
        diffScore: 53461.8607,
        diffScoreRatio: 0.3537,
        typeRatio: 0.3449,
      },
      {
        score: 92149.7246,
        value: 92149.7246,
        isChanged: true,
        diffScore: -112443.8257,
        diffScoreRatio: -0.5496,
        typeRatio: 0.417,
      },
      {
        score: 160456.4636,
        value: 160456.4636,
        isChanged: true,
        diffScore: 68306.739,
        diffScoreRatio: 0.7413,
        typeRatio: 0.5225,
      },
      {
        score: 172139.0941,
        value: 172139.0941,
        isChanged: true,
        diffScore: 11682.6305,
        diffScoreRatio: 0.0728,
        typeRatio: 0.3605,
      },
      {
        score: 200368.0077,
        value: 200368.0077,
        isChanged: true,
        diffScore: 28228.9136,
        diffScoreRatio: 0.164,
        typeRatio: 0.3053,
      },
      {
        score: 201677.7415,
        value: 201677.7415,
        isChanged: true,
        diffScore: 1309.7338,
        diffScoreRatio: 0.0065,
        typeRatio: 0.3705,
      },
      {
        score: 211251.8569,
        value: 211251.8569,
        isChanged: true,
        diffScore: 9574.1154,
        diffScoreRatio: 0.0475,
        typeRatio: 0.446,
      },
      {
        score: 165387.5937,
        value: 165387.5937,
        isChanged: true,
        diffScore: -45864.2632,
        diffScoreRatio: -0.2171,
        typeRatio: 0.4172,
      },
      {
        score: 64148.6465,
        value: 64148.6465,
        isChanged: true,
        diffScore: -101238.9472,
        diffScoreRatio: -0.6121,
        typeRatio: 0.1953,
      },
      {
        score: 241180.7858,
        value: 241180.7858,
        isChanged: true,
        diffScore: 177032.1393,
        diffScoreRatio: 2.7597,
        typeRatio: 0.4619,
      },
      {
        score: 306181.3429,
        value: 306181.3429,
        isChanged: true,
        diffScore: 65000.5571,
        diffScoreRatio: 0.2695,
        typeRatio: 0.5195,
      },
      {
        score: 757627.8822,
        value: 757627.8822,
        isChanged: true,
        diffScore: 451446.5393,
        diffScoreRatio: 1.4744,
        typeRatio: 0.6955,
      },
      {
        score: 304650.9565,
        value: 304650.9565,
        isChanged: true,
        diffScore: -452976.9257,
        diffScoreRatio: -0.5979,
        typeRatio: 0.7266,
      },
      {
        score: 318910.9901,
        value: 318910.9901,
        isChanged: true,
        diffScore: 14260.0336,
        diffScoreRatio: 0.0468,
        typeRatio: 0.5667,
      },
      {
        score: 282694.6126,
        value: 282694.6126,
        isChanged: true,
        diffScore: -36216.3775,
        diffScoreRatio: -0.1136,
        typeRatio: 0.6812,
      },
      {
        score: 261482.1338,
        value: 261482.1338,
        isChanged: true,
        diffScore: -21212.4788,
        diffScoreRatio: -0.075,
        typeRatio: 0.713,
      },
      {
        score: 277103.4659,
        value: 277103.4659,
        isChanged: true,
        diffScore: 15621.3321,
        diffScoreRatio: 0.0597,
        typeRatio: 0.66,
      },
      {
        score: 334376.2,
        value: 334376.2,
        isChanged: true,
        diffScore: 57272.7341,
        diffScoreRatio: 0.2067,
        typeRatio: 0.7088,
      },
      {
        score: 286623.394,
        value: 286623.394,
        isChanged: true,
        diffScore: -47752.806,
        diffScoreRatio: -0.1428,
        typeRatio: 0.7118,
      },
      {
        score: 290656.8881,
        value: 290656.8881,
        isChanged: true,
        diffScore: 4033.4941,
        diffScoreRatio: 0.0141,
        typeRatio: 0.7811,
      },
      {
        score: 306769.2732,
        value: 306769.2732,
        isChanged: true,
        diffScore: 16112.3851,
        diffScoreRatio: 0.0554,
        typeRatio: 0.7487,
      },
    ],
    spScore: [
      {
        score: 13857.7105,
        value: 13857.7105,
        isChanged: true,
        diffScore: 4931.8305,
        diffScoreRatio: 0.5525,
        typeRatio: 0.0255,
      },
      {
        score: 7964.8049,
        value: 7964.8049,
        isChanged: true,
        diffScore: -5892.9056,
        diffScoreRatio: -0.4252,
        typeRatio: 0.023,
      },
      {
        score: 13441.9502,
        value: 13441.9502,
        isChanged: true,
        diffScore: 5477.1453,
        diffScoreRatio: 0.6877,
        typeRatio: 0.0388,
      },
      {
        score: 17930.2246,
        value: 17930.2246,
        isChanged: true,
        diffScore: 4488.2744,
        diffScoreRatio: 0.3339,
        typeRatio: 0.0388,
      },
      {
        score: 22154.581,
        value: 22154.581,
        isChanged: true,
        diffScore: 4224.3564,
        diffScoreRatio: 0.2356,
        typeRatio: 0.0373,
      },
      {
        score: 4411.3697,
        value: 4411.3697,
        isChanged: true,
        diffScore: -17743.2113,
        diffScoreRatio: -0.8009,
        typeRatio: 0.02,
      },
      {
        score: 29324.0345,
        value: 29324.0345,
        isChanged: true,
        diffScore: 24912.6648,
        diffScoreRatio: 5.6474,
        typeRatio: 0.0955,
      },
      {
        score: 40354.608,
        value: 40354.608,
        isChanged: true,
        diffScore: 11030.5735,
        diffScoreRatio: 0.3762,
        typeRatio: 0.0845,
      },
      {
        score: 32312.3102,
        value: 32312.3102,
        isChanged: true,
        diffScore: -8042.2978,
        diffScoreRatio: -0.1993,
        typeRatio: 0.0492,
      },
      {
        score: 17699.5867,
        value: 17699.5867,
        isChanged: true,
        diffScore: -14612.7235,
        diffScoreRatio: -0.4522,
        typeRatio: 0.0325,
      },
      {
        score: 21962.4333,
        value: 21962.4333,
        isChanged: true,
        diffScore: 4262.8466,
        diffScoreRatio: 0.2408,
        typeRatio: 0.0464,
      },
      {
        score: 21731.492,
        value: 21731.492,
        isChanged: true,
        diffScore: -230.9413,
        diffScoreRatio: -0.0105,
        typeRatio: 0.0548,
      },
      {
        score: 29621.0697,
        value: 29621.0697,
        isChanged: true,
        diffScore: 7889.5777,
        diffScoreRatio: 0.363,
        typeRatio: 0.0902,
      },
      {
        score: 18971.0,
        value: 18971.0,
        isChanged: true,
        diffScore: -10650.0697,
        diffScoreRatio: -0.3595,
        typeRatio: 0.0363,
      },
      {
        score: 38471.1034,
        value: 38471.1034,
        isChanged: true,
        diffScore: 19500.1034,
        diffScoreRatio: 1.0279,
        typeRatio: 0.0653,
      },
      {
        score: 38934.1974,
        value: 38934.1974,
        isChanged: true,
        diffScore: 463.094,
        diffScoreRatio: 0.012,
        typeRatio: 0.0357,
      },
      {
        score: 4627.3157,
        value: 4627.3157,
        isChanged: true,
        diffScore: -34306.8817,
        diffScoreRatio: -0.8812,
        typeRatio: 0.011,
      },
      {
        score: 9216.7574,
        value: 9216.7574,
        isChanged: true,
        diffScore: 4589.4417,
        diffScoreRatio: 0.9918,
        typeRatio: 0.0164,
      },
      {
        score: 11321.6236,
        value: 11321.6236,
        isChanged: true,
        diffScore: 2104.8662,
        diffScoreRatio: 0.2284,
        typeRatio: 0.0273,
      },
      {
        score: 10759.1519,
        value: 10759.1519,
        isChanged: true,
        diffScore: -562.4717,
        diffScoreRatio: -0.0497,
        typeRatio: 0.0293,
      },
      {
        score: 32201.1502,
        value: 32201.1502,
        isChanged: true,
        diffScore: 21441.9983,
        diffScoreRatio: 1.9929,
        typeRatio: 0.0767,
      },
      {
        score: 27591.38,
        value: 27591.38,
        isChanged: true,
        diffScore: -4609.7702,
        diffScoreRatio: -0.1432,
        typeRatio: 0.0585,
      },
      {
        score: 24949.8374,
        value: 24949.8374,
        isChanged: true,
        diffScore: -2641.5426,
        diffScoreRatio: -0.0957,
        typeRatio: 0.062,
      },
      {
        score: 17194.3464,
        value: 17194.3464,
        isChanged: true,
        diffScore: -7755.491,
        diffScoreRatio: -0.3108,
        typeRatio: 0.0462,
      },
      {
        score: 19169.0093,
        value: 19169.0093,
        isChanged: true,
        diffScore: 1974.6629,
        diffScoreRatio: 0.1148,
        typeRatio: 0.0468,
      },
    ],
    brandScore: [
      {
        score: 270666.3859,
        value: 270666.3859,
        isChanged: true,
        diffScore: 89368.586,
        diffScoreRatio: 0.4929,
        typeRatio: 0.4984,
      },
      {
        score: 192957.6853,
        value: 192957.6853,
        isChanged: true,
        diffScore: -77708.7006,
        diffScoreRatio: -0.2871,
        typeRatio: 0.5576,
      },
      {
        score: 207276.4293,
        value: 207276.4293,
        isChanged: true,
        diffScore: 14318.744,
        diffScoreRatio: 0.0742,
        typeRatio: 0.5982,
      },
      {
        score: 229014.3856,
        value: 229014.3856,
        isChanged: true,
        diffScore: 21737.9563,
        diffScoreRatio: 0.1049,
        typeRatio: 0.4961,
      },
      {
        score: 271819.2858,
        value: 271819.2858,
        isChanged: true,
        diffScore: 42804.9002,
        diffScoreRatio: 0.1869,
        typeRatio: 0.4582,
      },
      {
        score: 102140.1001,
        value: 102140.1001,
        isChanged: true,
        diffScore: -169679.1857,
        diffScoreRatio: -0.6242,
        typeRatio: 0.4622,
      },
      {
        score: 23737.3714,
        value: 23737.3714,
        isChanged: true,
        diffScore: -78402.7287,
        diffScoreRatio: -0.7676,
        typeRatio: 0.0773,
      },
      {
        score: 144715.1143,
        value: 144715.1143,
        isChanged: true,
        diffScore: 120977.7429,
        diffScoreRatio: 5.0965,
        typeRatio: 0.3031,
      },
      {
        score: 295357.7568,
        value: 295357.7568,
        isChanged: true,
        diffScore: 150642.6425,
        diffScoreRatio: 1.041,
        typeRatio: 0.45,
      },
      {
        score: 248165.7427,
        value: 248165.7427,
        isChanged: true,
        diffScore: -47192.0141,
        diffScoreRatio: -0.1598,
        typeRatio: 0.4559,
      },
      {
        score: 152059.1146,
        value: 152059.1146,
        isChanged: true,
        diffScore: -96106.6281,
        diffScoreRatio: -0.3873,
        typeRatio: 0.321,
      },
      {
        score: 155473.8426,
        value: 155473.8426,
        isChanged: true,
        diffScore: 3414.728,
        diffScoreRatio: 0.0225,
        typeRatio: 0.3922,
      },
      {
        score: 213251.5282,
        value: 213251.5282,
        isChanged: true,
        diffScore: 57777.6856,
        diffScoreRatio: 0.3716,
        typeRatio: 0.6492,
      },
      {
        score: 189632.3151,
        value: 189632.3151,
        isChanged: true,
        diffScore: -23619.2131,
        diffScoreRatio: -0.1108,
        typeRatio: 0.3632,
      },
      {
        score: 161093.3144,
        value: 161093.3144,
        isChanged: true,
        diffScore: -28539.0007,
        diffScoreRatio: -0.1505,
        typeRatio: 0.2733,
      },
      {
        score: 110994.557,
        value: 110994.557,
        isChanged: true,
        diffScore: -50098.7574,
        diffScoreRatio: -0.311,
        typeRatio: 0.1019,
      },
      {
        score: 59130.8996,
        value: 59130.8996,
        isChanged: true,
        diffScore: -51863.6574,
        diffScoreRatio: -0.4673,
        typeRatio: 0.141,
      },
      {
        score: 151655.7855,
        value: 151655.7855,
        isChanged: true,
        diffScore: 92524.8859,
        diffScoreRatio: 1.5647,
        typeRatio: 0.2695,
      },
      {
        score: 39033.6429,
        value: 39033.6429,
        isChanged: true,
        diffScore: -112622.1426,
        diffScoreRatio: -0.7426,
        typeRatio: 0.0941,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: true,
        diffScore: -39033.6429,
        diffScoreRatio: -1.0,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
    ],
    vedioScore: [
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
    ],
    acScore: [
      {
        score: 27713.4414,
        value: 27713.4414,
        isChanged: true,
        diffScore: 2218.8274,
        diffScoreRatio: 0.087,
        typeRatio: 0.051,
      },
      {
        score: 9676.5037,
        value: 9676.5037,
        isChanged: true,
        diffScore: -18036.9377,
        diffScoreRatio: -0.6508,
        typeRatio: 0.028,
      },
      {
        score: 9155.4153,
        value: 9155.4153,
        isChanged: true,
        diffScore: -521.0884,
        diffScoreRatio: -0.0539,
        typeRatio: 0.0264,
      },
      {
        score: 14630.4619,
        value: 14630.4619,
        isChanged: true,
        diffScore: 5475.0466,
        diffScoreRatio: 0.598,
        typeRatio: 0.0317,
      },
      {
        score: 28189.1057,
        value: 28189.1057,
        isChanged: true,
        diffScore: 13558.6438,
        diffScoreRatio: 0.9267,
        typeRatio: 0.0475,
      },
      {
        score: 10676.6095,
        value: 10676.6095,
        isChanged: true,
        diffScore: -17512.4962,
        diffScoreRatio: -0.6213,
        typeRatio: 0.0483,
      },
      {
        score: 32241.5341,
        value: 32241.5341,
        isChanged: true,
        diffScore: 21564.9246,
        diffScoreRatio: 2.0198,
        typeRatio: 0.105,
      },
      {
        score: 35668.4524,
        value: 35668.4524,
        isChanged: true,
        diffScore: 3426.9183,
        diffScoreRatio: 0.1063,
        typeRatio: 0.0747,
      },
      {
        score: 38290.2074,
        value: 38290.2074,
        isChanged: true,
        diffScore: 2621.755,
        diffScoreRatio: 0.0735,
        typeRatio: 0.0583,
      },
      {
        score: 36880.5498,
        value: 36880.5498,
        isChanged: true,
        diffScore: -1409.6576,
        diffScoreRatio: -0.0368,
        typeRatio: 0.0677,
      },
      {
        score: 41662.9927,
        value: 41662.9927,
        isChanged: true,
        diffScore: 4782.4429,
        diffScoreRatio: 0.1297,
        typeRatio: 0.088,
      },
      {
        score: 21612.0659,
        value: 21612.0659,
        isChanged: true,
        diffScore: -20050.9268,
        diffScoreRatio: -0.4813,
        typeRatio: 0.0545,
      },
      {
        score: 5149.6785,
        value: 5149.6785,
        isChanged: true,
        diffScore: -16462.3874,
        diffScoreRatio: -0.7617,
        typeRatio: 0.0157,
      },
      {
        score: 44467.5888,
        value: 44467.5888,
        isChanged: true,
        diffScore: 39317.9103,
        diffScoreRatio: 7.635,
        typeRatio: 0.0852,
      },
      {
        score: 52641.7062,
        value: 52641.7062,
        isChanged: true,
        diffScore: 8174.1174,
        diffScoreRatio: 0.1838,
        typeRatio: 0.0893,
      },
      {
        score: 124413.4702,
        value: 124413.4702,
        isChanged: true,
        diffScore: 71771.764,
        diffScoreRatio: 1.3634,
        typeRatio: 0.1142,
      },
      {
        score: 43239.586,
        value: 43239.586,
        isChanged: true,
        diffScore: -81173.8842,
        diffScoreRatio: -0.6525,
        typeRatio: 0.1031,
      },
      {
        score: 55538.9909,
        value: 55538.9909,
        isChanged: true,
        diffScore: 12299.4049,
        diffScoreRatio: 0.2844,
        typeRatio: 0.0987,
      },
      {
        score: 53287.9871,
        value: 53287.9871,
        isChanged: true,
        diffScore: -2251.0038,
        diffScoreRatio: -0.0405,
        typeRatio: 0.1284,
      },
      {
        score: 53773.5867,
        value: 53773.5867,
        isChanged: true,
        diffScore: 485.5996,
        diffScoreRatio: 0.0091,
        typeRatio: 0.1466,
      },
      {
        score: 47937.1099,
        value: 47937.1099,
        isChanged: true,
        diffScore: -5836.4768,
        diffScoreRatio: -0.1085,
        typeRatio: 0.1142,
      },
      {
        score: 63794.1841,
        value: 63794.1841,
        isChanged: true,
        diffScore: 15857.0742,
        diffScoreRatio: 0.3308,
        typeRatio: 0.1352,
      },
      {
        score: 53435.4206,
        value: 53435.4206,
        isChanged: true,
        diffScore: -10358.7635,
        diffScoreRatio: -0.1624,
        typeRatio: 0.1327,
      },
      {
        score: 33916.8669,
        value: 33916.8669,
        isChanged: true,
        diffScore: -19518.5537,
        diffScoreRatio: -0.3653,
        typeRatio: 0.0911,
      },
      {
        score: 43965.5126,
        value: 43965.5126,
        isChanged: true,
        diffScore: 10048.6457,
        diffScoreRatio: 0.2963,
        typeRatio: 0.1073,
      },
    ],
    erScore: [
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
    ],
    trScore: [
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
      {
        score: 0.0,
        value: 0.0,
        isChanged: false,
        diffScore: null,
        diffScoreRatio: null,
        typeRatio: 0.0,
      },
    ],
    otherRecommendedScore: [
      {
        score: 42217.4445,
        value: 42217.4445,
        isChanged: true,
        diffScore: -14241.5977,
        diffScoreRatio: -0.2522,
        typeRatio: 0.0777,
      },
      {
        score: 29061.0753,
        value: 29061.0753,
        isChanged: true,
        diffScore: -13156.3692,
        diffScoreRatio: -0.3116,
        typeRatio: 0.084,
      },
      {
        score: 29402.1023,
        value: 29402.1023,
        isChanged: true,
        diffScore: 341.027,
        diffScoreRatio: 0.0117,
        typeRatio: 0.0849,
      },
      {
        score: 48968.0842,
        value: 48968.0842,
        isChanged: true,
        diffScore: 19565.9819,
        diffScoreRatio: 0.6655,
        typeRatio: 0.1061,
      },
      {
        score: 66481.847,
        value: 66481.847,
        isChanged: true,
        diffScore: 17513.7628,
        diffScoreRatio: 0.3577,
        typeRatio: 0.1121,
      },
      {
        score: 11601.1171,
        value: 11601.1171,
        isChanged: true,
        diffScore: -54880.7299,
        diffScoreRatio: -0.8255,
        typeRatio: 0.0525,
      },
      {
        score: 61333.3552,
        value: 61333.3552,
        isChanged: true,
        diffScore: 49732.2381,
        diffScoreRatio: 4.2868,
        typeRatio: 0.1997,
      },
      {
        score: 84616.4202,
        value: 84616.4202,
        isChanged: true,
        diffScore: 23283.065,
        diffScoreRatio: 0.3796,
        typeRatio: 0.1772,
      },
      {
        score: 89959.7744,
        value: 89959.7744,
        isChanged: true,
        diffScore: 5343.3542,
        diffScoreRatio: 0.0631,
        typeRatio: 0.1371,
      },
      {
        score: 39974.2086,
        value: 39974.2086,
        isChanged: true,
        diffScore: -49985.5658,
        diffScoreRatio: -0.5556,
        typeRatio: 0.0734,
      },
      {
        score: 46704.1598,
        value: 46704.1598,
        isChanged: true,
        diffScore: 6729.9512,
        diffScoreRatio: 0.1684,
        typeRatio: 0.0986,
      },
      {
        score: 32257.561,
        value: 32257.561,
        isChanged: true,
        diffScore: -14446.5988,
        diffScoreRatio: -0.3093,
        typeRatio: 0.0814,
      },
      {
        score: 16309.761,
        value: 16309.761,
        isChanged: true,
        diffScore: -15947.8,
        diffScoreRatio: -0.4944,
        typeRatio: 0.0497,
      },
      {
        score: 27900.4935,
        value: 27900.4935,
        isChanged: true,
        diffScore: 11590.7325,
        diffScoreRatio: 0.7107,
        typeRatio: 0.0534,
      },
      {
        score: 30994.3968,
        value: 30994.3968,
        isChanged: true,
        diffScore: 3093.9033,
        diffScoreRatio: 0.1109,
        typeRatio: 0.0526,
      },
      {
        score: 57435.1885,
        value: 57435.1885,
        isChanged: true,
        diffScore: 26440.7917,
        diffScoreRatio: 0.8531,
        typeRatio: 0.0527,
      },
      {
        score: 7654.1738,
        value: 7654.1738,
        isChanged: true,
        diffScore: -49781.0147,
        diffScoreRatio: -0.8667,
        typeRatio: 0.0183,
      },
      {
        score: 27380.5906,
        value: 27380.5906,
        isChanged: true,
        diffScore: 19726.4168,
        diffScoreRatio: 2.5772,
        typeRatio: 0.0487,
      },
      {
        score: 28673.2328,
        value: 28673.2328,
        isChanged: true,
        diffScore: 1292.6422,
        diffScoreRatio: 0.0472,
        typeRatio: 0.0691,
      },
      {
        score: 40716.7743,
        value: 40716.7743,
        isChanged: true,
        diffScore: 12043.5415,
        diffScoreRatio: 0.42,
        typeRatio: 0.111,
      },
      {
        score: 62615.695,
        value: 62615.695,
        isChanged: true,
        diffScore: 21898.9207,
        diffScoreRatio: 0.5378,
        typeRatio: 0.1491,
      },
      {
        score: 45991.0317,
        value: 45991.0317,
        isChanged: true,
        diffScore: -16624.6633,
        diffScoreRatio: -0.2655,
        typeRatio: 0.0975,
      },
      {
        score: 37662.6931,
        value: 37662.6931,
        isChanged: true,
        diffScore: -8328.3386,
        diffScoreRatio: -0.1811,
        typeRatio: 0.0935,
      },
      {
        score: 30359.8913,
        value: 30359.8913,
        isChanged: true,
        diffScore: -7302.8018,
        diffScoreRatio: -0.1939,
        typeRatio: 0.0816,
      },
      {
        score: 39829.7997,
        value: 39829.7997,
        isChanged: true,
        diffScore: 9469.9084,
        diffScoreRatio: 0.3119,
        typeRatio: 0.0972,
      },
    ],
  },
  commonMsg: {
    notificationImgs: {},
    version: "1.6.11",
    notifications: {},
  },
  message: null,
};
