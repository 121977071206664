import { memo, useContext } from "react";

import { TableRow } from "@mui/material";
import Sort from "src/components/table/sort";
import CustomCell from "src/components/table/cell";
import { sortByEnum } from "src/constants";

import customWithErrorBoundary from "../errorBoundary";
import { useDispatch, useSelector } from "react-redux";
import GlobalChecked from "./globalChecked";
import { TableContext } from "./context";
import { changeTableSearchSort, fetchTableData } from "src/store/rootReducer";
import formatParmars from "./format";
import { getOnionI18nText } from "src/utils";
import Tip from "../tooltip/tip";

import style from "./index.module.scss";

function Head() {
  const {
    name,
    uniqId,
    columns,
    fetchTable,
    handleMouseEnter,
    handleMouseLeave,
    handleSelectAllClick,
    translationKey,
  } = useContext(TableContext);

  const tableSearch = useSelector(
    (state) => state.root?.table?.[name]?.tableSearch
  );
  const sortKey = tableSearch?.sort?.key;

  const dispatch = useDispatch();

  const handleChangeSortAndFetch = (key) => {
    let by;
    if (key === sortKey) {
      by =
        tableSearch.sort.by === sortByEnum.asc
          ? sortByEnum.desc
          : sortByEnum.asc;
    } else {
      by = sortByEnum.desc;
    }

    handleSelectAllClick({ target: { checked: false } });
    dispatch(changeTableSearchSort(formatParmars(name, { key, by })));
    if (fetchTable) {
      fetchTable();
    } else {
      dispatch(fetchTableData(formatParmars(name)));
    }
  };

  const handleSort = (column, key) => {
    if (!column.needSort) {
      return;
    }

    let finalKey;

    if (column?.sortKeys) {
      finalKey = column.sortKeys?.find((item) => item?.active)?.value || key;
    } else {
      finalKey = key;
    }
    handleChangeSortAndFetch(finalKey);
  };

  return (
    <TableRow
      onMouseEnter={(e) => {
        handleMouseEnter &&
          handleMouseEnter({
            [uniqId]: "all",
          });
      }}
      onMouseLeave={handleMouseLeave}
    >
      {columns.map((column) => {
        if (column.id === "checkbox") {
          return (
            <GlobalChecked
              column={column}
              key={column.id}
              type="head"
              handleSelectAllClick={handleSelectAllClick}
            />
          );
        }

        const WrapTip = column.tooltip ? Tip : "div";
        const columnSortKey = column.sortKey || column.id;
        const sortKeys = column?.sortKeys?.map((item) => item?.value) || [];
        const sortActive =
          sortKey === columnSortKey || sortKeys.includes(sortKey);

        const i18nTextArr = (column.ignoreId ? [] : [column.id]).concat([
          `terminology.${column.id}`,
          column.id,
          `tableColumn.${column.id}`,
          `${translationKey}.tableColumn.${column.id}`,
        ]);
        return (
          <CustomCell
            key={column.id}
            column={column}
            style={{ backgroundColor: "var(--hover-background)" }}
            type="head"
            sortActive={sortActive}
            onClick={() => handleSort(column, columnSortKey)}
          >
            <WrapTip
              title={
                (typeof column.tooltip).toLocaleLowerCase() === "string"
                  ? getOnionI18nText([
                      `tableColumn.${column.tooltip}`,
                      `${translationKey}.tableColumn.${column.tooltip}`,
                    ])
                  : column.tooltip
              }
              otherContent={column.otherContent}
            >
              <div className={style.headWrap}>
                <div>{column.label || getOnionI18nText(i18nTextArr)}</div>
                {column.needSort ? (
                  <Sort
                    downActive={
                      sortActive && tableSearch.sort.by === sortByEnum.desc
                    }
                    upActive={
                      sortActive && tableSearch.sort.by === sortByEnum.asc
                    }
                  />
                ) : null}
              </div>
            </WrapTip>
          </CustomCell>
        );
      })}
    </TableRow>
  );
}

export default customWithErrorBoundary(memo(Head));
