import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Export from "src/components/export";
import UpdateVariants from "src/components/updateVariants";
import { fetchStatus } from "src/constants";
import {
  downloadChartDataThunk,
  downloadStructureChartDataThunk,
} from "../../store";
import customWithErrorBoundary from "src/components/errorBoundary";
import CustomSelect from "src/components/customSelect";
import { getListingOption } from "../../util";
import { useTranslation } from "react-i18next";
import style from "./index.module.scss";
import { isAsinMultiple } from "src/utils";

function Btns() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const baseSearch = useSelector((state) => state.structure.baseSearch);
  const asins = baseSearch.asins;

  const chartData = useSelector((state) => state.structure.chartData);
  const chartDownloadStatus = useSelector(
    (state) => state.structure.chartDownloadStatus
  );

  const single = useMemo(() => {
    return asins && !isAsinMultiple(asins);
  }, [chartData]);

  const handleDownLoadChart = useCallback(() => {
    dispatch(downloadStructureChartDataThunk());
  }, []);

  const defaultConfig = getListingOption({ data: baseSearch });

  const options = useMemo(() => {
    return [];
  }, []);

  return (
    <div className="btnsRightWrap">
      {single ? <UpdateVariants asins={asins} /> : null}
      <Export
        disabled={!chartData?.total}
        loading={chartDownloadStatus === fetchStatus.loading}
        onDownload={handleDownLoadChart}
      />

      {single && (
        <CustomSelect
          defaultConfig={{
            ...defaultConfig,
            title: t(`structure.searchListing`),
            style: {
              color: "var(--link-color)",
            },
          }}
          noNeedMoreIcon
          options={options}
        />
      )}
    </div>
  );
}

export default customWithErrorBoundary(memo(Btns));
