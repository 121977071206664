export const rankTrend = [
  {
    code: "organicRankTrend",
    type: "ogRankTrend",
    labelText: "organicRank",
  },
  {
    code: "spAdRankTrend",
    type: "spRankTrend",
    labelText: "spAdRank",
  },
  {
    code: "sbAdRankTrend",
    type: "sbRankTrend",
    labelText: ["sbAdRankTop", "sbAdRankBottom"],
    needTooltip: true,
    max: 3,
  },
  {
    code: "sbvAdRankTrend",
    type: "sbvRankTrend",
    labelText: "sbvAdRank",
    needTooltip: true,
    max: 2,
  },
  {
    code: "acRecoTrend",
    type: "acRankTrend",
    labelText: "acReco",
    needTooltip: true,
    max: 2,
  },
];
