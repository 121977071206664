import classNames from "classnames";
import customWithErrorBoundary from "../errorBoundary";
import style from "./index.module.scss";

interface Props {
  upActive: Boolean;
  downActive: Boolean;
}

function Sort({ upActive = false, downActive = false }: Props) {
  return (
    <div className={style.sortWrap}>
      <div
        className={classNames({
          [style.active]: upActive,
        })}
      >
        <span className="icon-up"></span>
      </div>
      <div
        className={classNames({
          [style.active]: downActive,
        })}
      >
        <span className="icon-down"></span>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(Sort);
