import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetRootLoading } from "src/store/rootReducer";

function useResetLoading() {
  const ref = useRef();
  const dispatch = useDispatch();

  if (!ref.current) {
    ref.current = true;
    dispatch(resetRootLoading(false));
  }

  useEffect(() => {
    return () => {
      dispatch(resetRootLoading(false));
    };
  }, []);
}

export default useResetLoading;
