import { memo } from "react";
import classNames from "classnames";

import customWithErrorBoundary from "src/components/errorBoundary";

import Img from "./img";
import Search from "./search";
import Btns from "./btns";

import style from "./index.module.scss";

function Info() {
  return (
    <div
      className={classNames(
        style.info,
        "wrap",
        "textFont",
        "wrapContentInfo",
        "titleSummary"
      )}
    >
      <div>
        <Img />
        <Search />
      </div>
      <div className="btnsRightWrap">
        <Btns />
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(Info));
