import { memo, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Export from "src/components/export";
import { StructureContext } from "../../context";
import { fetchStatus } from "src/constants";
import { exportStructureTableThunk } from "../../store";
import customWithErrorBoundary from "src/components/errorBoundary";
import { getTablePageActions } from "../../util";
import CustomSelect from "src/components/customSelect";
import { useTranslation } from "react-i18next";
import { isAsinMultiple } from "src/utils";

function TableExport() {
  const dispath = useDispatch();
  const { tableKey: name } = useContext(StructureContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const tableDownloadStatus = useSelector(
    (state) => state.structure.tableDownloadStatus
  );
  const handleDownLoad = useCallback(() => {
    dispath(exportStructureTableThunk({ key: name }));
  }, []);

  return (
    <Export
      disabled={!tableData?.total}
      loading={tableDownloadStatus === fetchStatus.loading}
      onDownload={handleDownLoad}
    />
  );
}

function PageSelectActions() {
  const { t } = useTranslation();
  const baseSearch = useSelector((state) => state.structure.baseSearch);
  const { tableKey: name } = useContext(StructureContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const [defaultOption, options] = getTablePageActions({
    data: baseSearch,
    needDefault: true,
    multiple: isAsinMultiple(baseSearch?.asins),
  });

  return (
    <CustomSelect
      defaultConfig={{
        ...defaultOption,
        style: {
          color: "var(--link-color)",
        },
      }}
      options={options}
    />
  );
}

function Btns() {
  return (
    <>
      <TableExport />
      <PageSelectActions />
    </>
  );
}

export default customWithErrorBoundary(memo(Btns));
