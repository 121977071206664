import { useEffect } from "react";
import { useMatches } from "react-router";
import { modelName } from "src/route/routeModal";

export function useRouterId() {
  const matches = useMatches();

  const routerId = matches[matches.length - 1].id;
  const routerName = modelName[routerId];

  return [routerId, routerName];
}
