import { memo } from "react";
import customWithErrorBoundary from "../../errorBoundary";
import { MenuItem, Paper, MenuList } from "@mui/material";

import style from "../index.module.scss";
import ImgWrap from "../../imgWrap";

function Record({ records = {}, handleSelectAsin, actionTypeValue }) {
  return (
    <Paper className={style.example} elevation={5}>
      <MenuList>
        {records[actionTypeValue]?.map((item, idx) => {
          return (
            <MenuItem
              variant="search"
              value={item?.searchVal}
              key={item?.searchVal + idx}
              onClick={() => handleSelectAsin(item)}
            >
              <div className={style.imgsWrap}>
                <ImgWrap src={item?.img} alt="" />
              </div>
              <span className="textOverFlow" title={item?.searchVal}>
                {item?.searchVal}
              </span>
            </MenuItem>
          );
        })}
      </MenuList>
    </Paper>
  );
}

export default customWithErrorBoundary(memo(Record));
