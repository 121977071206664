import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import {
  formatNumber,
  getPlaceHolderData,
  isAsinMultiple,
  openNewUrl,
} from "src/utils";

import style from "./index.module.scss";
import { getTypeKeyWordsLink } from "../../util";
import { sortByEnum } from "../../constant";
import { asinsKey } from "src/constants/form";
import classNames from "classnames";

function formatype(data, type) {
  return {
    ...(data?.[type] || {}),
    value: data?.[type]?.count,
  };
}
function TableInfo() {
  const { t } = useTranslation();
  const baseSearch = useSelector((state) => state.structure.baseSearch);
  const asinMultiple = isAsinMultiple(baseSearch?.[asinsKey]);
  const wordsTotal = useSelector((state) => state.structure.wordsTotal);

  const originData = [
    {
      title: t(`terminology.total`),
      ...formatype(wordsTotal, sortByEnum.allWords),
    },
    {
      title: t(`terminology.organic`),
      ...formatype(wordsTotal, sortByEnum.organicWords),
    },
    {
      title: t(`terminology.sp`),
      ...formatype(wordsTotal, sortByEnum.spWords),
    },
    {
      title: t(`terminology.sb`),
      ...formatype(wordsTotal, sortByEnum.sbWords),
    },
    {
      title: t(`terminology.sbv`),
      ...formatype(wordsTotal, sortByEnum.sbvWords),
    },
    {
      title: t(`terminology.ac`),
      ...formatype(wordsTotal, sortByEnum.acWords),
    },
    {
      title: t(`terminology.other`),
      ...formatype(wordsTotal, sortByEnum.otherRecWords),
    },
  ];

  const data = originData?.filter((item) => item.value);

  const handleSearchClick = (code) => {
    try {
      const url = getTypeKeyWordsLink({ ...baseSearch, type: code });

      openNewUrl(url);
    } catch (e) {}
  };

  return (
    <>
      {data?.map((item) => (
        <span
          key={item.title}
          className={classNames({
            underDashLine: !asinMultiple,
          })}
          onClick={() => !asinMultiple && handleSearchClick(item.code)}
        >
          <span className="textFont">{`${item.title}: `}</span>
          <span className={classNames(style.infoNum, "listBoldTextFont")}>
            {getPlaceHolderData(formatNumber(item.value))}
          </span>
        </span>
      ))}
    </>
  );
}

export default customWithErrorBoundary(memo(TableInfo));
