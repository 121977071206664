import { memo, useContext } from "react";
import { useSelector } from "react-redux";

import customWithErrorBoundary from "src/components/errorBoundary";
import UpdateVariants from "src/components/updateVariants";
import LinkToAmazonCom from "src/components/amazon";
import Collect from "src/components/collect";
import { KeywordsContext } from "../../context";
import { listingAsinType } from "src/constants";

function Btns() {
  const { tableKey: name } = useContext(KeywordsContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const { asinType } = tableData || {};

  const asin = tableData?.asinInfo?.asin;

  return (
    <>
      {asinType && asinType !== listingAsinType.LISTING_PASIN && (
        <>
          <Collect placement="bottom" isBtn size={14} />
          <LinkToAmazonCom placement="bottom" isBtn size={14} asin={asin} />
        </>
      )}

      <UpdateVariants asins={asin} />
    </>
  );
}

export default customWithErrorBoundary(memo(Btns));
