import { useEffect, useRef } from "react";
import { initializeTabledata } from "src/store/rootReducer";
import { useRouterId } from "./getRouterId";
import { useDispatch, useSelector } from "react-redux";

function useRegisterTableState({
  key,
  sortKey,
  keywordsExport,
  pageSize,
} = {}) {
  const [routerId, name] = useRouterId();
  const dispatch = useDispatch();

  const finalKey = key || name;
  const tableData = useSelector((state) => state.root.table[finalKey]);

  const ref = useRef(false);
  if (!ref.current && (!tableData || Object.keys(tableData)?.length === 0)) {
    ref.current = true;
    dispatch(
      initializeTabledata({
        key: finalKey || name,
        sortKey,
        pageSize,
        keywordsExport,
      })
    );
  }
}

export default useRegisterTableState;
