import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actionTypeEnum, dimensionEnum, fetchStatus } from "src/constants";
import { updateGlobalLoading } from "src/store/rootReducer";

import { columnTypeEnum } from "./constant";

import {
  downloadTableData,
  downloadTrendsData,
  getTrendsChartData,
} from "./service";
import { exportPureFn } from "src/hooksOrClass/export";
import { formatTableSearch } from "src/utils/table";
import { isAsinMultiple } from "src/utils";
import { actionTypeKey, asinsKey } from "src/constants/form";

// 图表下载
let cancelReject;
let resolvePromise;

// 表格下载
let tableCancelReject;
let tableResolvePromise;

const initialState = {
  baseSearch: {
    [actionTypeKey]: actionTypeEnum.asin,
    [asinsKey]: "",
  },
  chartSearch: {
    dimension: dimensionEnum.asin,
    pageNum: 1,
    pageSize: 5,
  },

  showChart: true,
  columnType: columnTypeEnum.asin,

  chartFetchStatus: fetchStatus.initial,

  // 接口返回的数据
  chartData: {},

  chartController: null,
  chartDownLoadStatus: fetchStatus.initial,
  tableDownLoadStatus: fetchStatus.initial,
};

export const SalesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    syncBaseSearch: (state, action) => {
      state.baseSearch = action.payload;
      cancelReject && cancelReject();
      state.chartDownLoadStatus = fetchStatus.initial;

      tableCancelReject && tableCancelReject();
      state.tableDownLoadStatus = fetchStatus.initial;
      state.showChart = action.payload.actionType === actionTypeEnum.asin;
    },
    updateColumnType: (state) => {
      const asins = state.baseSearch.asins;
      const actionType = state.baseSearch.actionType;
      state.columnType =
        actionType === actionTypeEnum.asin
          ? !isAsinMultiple(asins)
            ? columnTypeEnum.asin
            : columnTypeEnum.asins
          : columnTypeEnum.keyWord;
    },
    changeDimension: (state, action) => {
      state.chartSearch.dimension = action.payload;
    },
    changeChartSearchPageNum: (state, action) => {
      state.chartSearch.pageNum = action.payload;
    },

    updatTableDownLoadStatus: (state, action) => {
      state.tableDownLoadStatus = action.payload;
    },

    changeChartFetchStatus: (state, action) => {
      state.chartFetchStatus = action.payload;
    },

    updateChartDownLoadStatus: (state, action) => {
      state.chartDownLoadStatus = action.payload;
    },
    updateAndCancelController: (state, action) => {
      const { key, controllerIns } = action.payload;
      const oldController = state[key];

      if (oldController) {
        oldController.abort();
      }

      state[key] = controllerIns;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartData.fulfilled, (state, action) => {
        const responseData = action.payload;
        state.chartData = responseData;
      })
      .addCase(fetchChartData.rejected, (state, action) => {
        if (action.error.name === "AbortError") {
          // 处理请求被取消的情况
        } else {
          // 处理其他错误情况
        }
      });
  },
});

export const {
  syncBaseSearch,
  changeDimension,
  changeChartSearchPageNum,
  changeChartFetchStatus,
  updateAndCancelController,
  updateChartDownLoadStatus,
  updatTableDownLoadStatus,
  updateColumnType,
  resetStore,
} = SalesSlice.actions;

export const downloadTrendsDataThunk = createAsyncThunk(
  "data/downloadTrendsDataThunk",
  async (data, { getState, dispatch }) => {
    function getResolve(resolve) {
      resolvePromise = resolve;
    }

    function getReject(reject) {
      cancelReject = reject;
    }

    function successFn() {
      resolvePromise && resolvePromise();
    }

    function catchFn() {
      cancelReject && cancelReject();
    }
    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updateChartDownLoadStatus,
      successFn,
      catchFn,
    });
    const currentState = getState().sales;

    exportFn({
      downLoadService: () => {
        return downloadTrendsData(currentState);
      },
      taskUrl: "/api/web/export/progress/product_sales_trend_asin",
    });
  }
);

export const downloadTableDataThunk = createAsyncThunk(
  "data/downloadTableDataThunk",
  async (data, { getState, dispatch }) => {
    const { key } = data || {};
    function getResolve(resolve) {
      tableResolvePromise = resolve;
    }

    function getReject(reject) {
      tableCancelReject = reject;
    }

    function successFn() {
      tableResolvePromise && tableResolvePromise();
    }

    function catchFn() {
      tableCancelReject && tableCancelReject();
    }

    const exportFn = exportPureFn({
      dispatch,
      getResolve,
      getReject,
      changeStatus: updatTableDownLoadStatus,
      successFn,
      catchFn,
    });

    const allState = getState();
    const currentState = allState.sales;
    const isAsin = currentState.baseSearch.actionType === actionTypeEnum.asin;
    const taskUrl = isAsin
      ? "/api/web/export/progress/product_sales_asin"
      : "/api/web/export/progress/product_sales_keyword";

    const tableSearchData = formatTableSearch(
      allState?.root?.table[key]?.tableSearch
    );
    exportFn({
      downLoadService: () => {
        return downloadTableData(currentState, isAsin, tableSearchData);
      },
      taskUrl,
    });
  }
);

export const fetchChartData = createAsyncThunk(
  "data/fetchChartData",
  async (data, { getState, dispatch }) => {
    const pageNum = data?.pageNum || 1;

    dispatch(changeChartSearchPageNum(pageNum));
    dispatch(changeChartFetchStatus(fetchStatus.loading));
    const chartController = new AbortController(); //

    dispatch(
      updateAndCancelController({
        key: "chartController",
        controllerIns: chartController,
      })
    );

    const currentState = getState().sales;

    const response = await getTrendsChartData(
      currentState,
      chartController.signal
    );

    if (!response) {
      dispatch(changeChartFetchStatus(fetchStatus.error));
    } else {
      dispatch(changeChartFetchStatus(fetchStatus.success));
      dispatch(updateGlobalLoading(false));
    }

    return response;
  }
);

export default SalesSlice.reducer;
