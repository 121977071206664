import { useTop } from "src/hooksOrClass/top";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import useResetLoading from "src/hooksOrClass/resetLoading";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import useTableAndRegister from "src/hooksOrClass/table";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import Search from "./components/search";
import {
  getWordsTotalThunk,
  resetStore,
  syncBaseSearch,
  getChartDataThunk,
  updateIsTableDataMultipleAsin,
  updateMemoBaseSearch,
  updateChangeDimension,
} from "./store";
import { resetTable, updateGlobalLoading } from "src/store/rootReducer";
import { StructureContext } from "./context";
import ChartWrap from "./components/chartWrap";
import BoxLoading from "src/components/loading/box";
import { isAsinMultiple, isLoading } from "src/utils";
import Table from "./components/table";
import { getTableDataApi } from "./service";
import { asinsKey } from "src/constants/form";
import { dimensionEnum } from "src/constants";

function Structure(params) {
  const [id, name] = useRouterId();
  const dispatch = useDispatch();
  useTop();
  useResetLoading();
  useRegisterTableState({
    sortKey: "",
    keywordsExport: {
      apiUrl: "/api/web/export/submit/traffic_structure_batch",
      taskUrl: "/api/web/export/progress/traffic_structure_batch",
    },
  });
  const chartFetchStatus = useSelector(
    (state) => state.structure.chartFetchStatus
  );

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );

  const request = async ({ getState, tableSearchData, tableController }) => {
    const state = getState().structure;
    const res = await getTableDataApi(
      state,
      tableSearchData,
      tableController.signal
    );
    return res;
  };
  const callback = (response, getState) => {
    const state = getState().structure;

    dispatch(updateMemoBaseSearch());
    dispatch(
      updateIsTableDataMultipleAsin(
        isAsinMultiple(state?.baseSearch?.[asinsKey])
      )
    );
  };
  const [fetchTable] = useTableAndRegister({ name, request, callback });

  const onSubmit = useCallback(async (formatFormValues) => {
    formatFormValues && dispatch(syncBaseSearch(formatFormValues));
    formatFormValues && dispatch(updateGlobalLoading(true));
    dispatch(updateChangeDimension(dimensionEnum.asin));
    dispatch(getChartDataThunk());
    fetchTable({ resetSortKey: true });
    dispatch(getWordsTotalThunk());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetStore());
      dispatch(resetTable(name));
    };
  }, []);

  const value = useMemo(
    () => ({
      tableKey: name,
      fetchTable,
      onSubmit,
    }),
    [name]
  );

  return (
    <StructureContext.Provider value={value}>
      <Search />
      <BoxLoading>
        <BoxLoading isLoading={isLoading(chartFetchStatus)}>
          <ChartWrap />
        </BoxLoading>

        <BoxLoading isLoading={isLoading(tableFetchStatus)} fixed>
          <Table />
        </BoxLoading>
      </BoxLoading>
    </StructureContext.Provider>
  );
}

export default Structure;
