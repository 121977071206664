import i18next from "i18next";
import {
  connectSymbol,
  formatParamsEnum,
  standardFormatParams,
} from "./constant";
import { parseDateByFormatParams } from "src/utils/time";
import { format } from "date-fns";

export const getFormatParams = (type) => {
  if (!type) {
    return;
  }
  const language = i18next.language;
  const formatParams =
    formatParamsEnum[language]?.type ?? formatParamsEnum.en?.[type];

  return formatParams;
};

export const formatStandardDate = (type, date) => {
  try {
    if (!date) {
      return "";
    }
    const formatParams = getFormatParams(type);

    if (!formatParams) {
      return "";
    }
    const newDateStr = date?.trim();
    const newDate = parseDateByFormatParams(formatParams, newDateStr);
    const result = format(newDate, standardFormatParams[type]);

    return result || "";
  } catch (e) {}
};

export const getServiceDateStartAndEnd = ({ type, value }) => {
  try {
    const [start, end] = value?.split(connectSymbol) || [];

    return {
      dateStart: formatStandardDate(type, start),
      dateEnd: formatStandardDate(type, end),
    };
  } catch (e) {}
};
