import { position } from "src/constants";
import externalTooltipHandler from "./externalTooltipHandler";
import {
  changeRadius,
  findClosestIndex,
  findTooltipEl,
  getTooltipElParent,
  reset,
} from "./util";
import { v4 as uuidv4 } from "uuid";
import { formatNumber, toRGBA } from "src/utils";
const uuidInfo = uuidv4();
const hoverCrosshairPlugin = {
  id: "hoverCrosshair",
  defaults: {
    width: 1,
    tips: "",
    color: "gray",
    pointRadius: 1,
    hoverPointRadius: 3,
    dash: [3, 3],
    tooltipContainer: `tooltip-container-${uuidInfo}`,
    position: position.horizontal,
    priorityTop: false,
    positions: [],
    ticks: [],
    formatTitle: null,
    format: formatNumber,
    activeElementIdx: undefined,
  },
  afterInit: (chart, args, opts) => {
    chart.data.datasets.forEach((dataset, i) => {
      dataset.originPointRadius =
        dataset.originPointRadius || dataset.pointRadius;
    });
    reset(chart);
  },
  beforeRender: (chart, args, opts) => {
    const isBar = chart.config.type === "bar";
    if (isBar) {
      const yScale = chart.scales.y;
      const meta = chart.getDatasetMeta(1);
      const half = Number(meta?.data?.[0]?.height) / 2;

      const positions = [];
      if (positions.length === 0) {
        yScale.ticks.forEach(function (tick, index) {
          var value = yScale.getPixelForValue(tick.value);
          let left = value - half;
          let right = value + half;
          if (positions.indexOf(left) === -1) {
            positions.push(left);
          }

          if (positions.indexOf(right) === -1) {
            positions.push(right);
          }
        });
      }
      opts.positions = positions;
    }
  },
  afterEvent: (chart, args, opts) => {
    const { inChartArea } = args;
    if (!inChartArea || args.event.type === "mouseout") {
      if (chart.corsair.hoverIndex !== undefined) {
        changeRadius(chart, opts);
        chart.update();
      }
      var { tooltipEl } = findTooltipEl(
        getTooltipElParent(),
        opts.tooltipContainer
      );

      if (tooltipEl) {
        tooltipEl.classList.add("hidden");
      }

      opts.activeElementIdx = undefined;
      reset(chart);
      chart.update();
      return;
    }

    const arr = [];
    chart._metasets[0].data.forEach((point) => {
      arr.push(point.x);
    });
    chart.corsair.datasetPosition = arr;

    let hoverIndex;
    let info;
    const isBar = chart.config.type === "bar";

    let needUpdate = false;
    if (isBar && chart.config.options.indexAxis === "y") {
      const { x, y } = args.event;
      const arr = opts.positions;
      var bars = chart.getElementsAtEventForMode(
        args.event,
        "nearest",
        { intersect: true },
        false
      );

      if (bars.length > 0) {
        const activeElement = bars[0];
        const idx = activeElement.datasetIndex;
        if (idx !== opts.activeElementIdx) {
          needUpdate = true;
          opts.activeElementIdx = idx;
          chart.data.datasets.forEach((element, index) => {
            if (element.type !== "line") {
              if (idx !== index) {
                element.backgroundColor = element.noHoverColor;
                element.textColor = toRGBA(element.textColor, 0.1);
              } else {
                element.backgroundColor = element.originBackgroundColor;
                element.textColor = element.originTextColor;
              }
            }
          });
        }
      } else {
        if (opts.activeElementIdx !== undefined) {
          needUpdate = true;
          chart.data.datasets.forEach((element, index) => {
            if (element.type !== "line") {
              element.backgroundColor = element.originBackgroundColor;
              element.textColor = element.originTextColor;
            }
          });
        }
        opts.activeElementIdx = undefined;
      }

      for (let i = 0; i < arr.length - 1; i++) {
        if (y < arr[0]) {
          hoverIndex = 0;
          info = {
            x,
            y: (arr[0] + arr[1]) / 2,
          };
          break;
        }

        if (y > arr[arr.length - 1]) {
          hoverIndex = Math.floor((arr.length - 1) / 2);
          info = {
            x,
            y: (arr[arr.length - 1] + arr[arr.length - 2]) / 2,
          };

          break;
        }

        if (arr[i] < y && y < arr[i + 1]) {
          if (i % 2 === 0) {
            hoverIndex = Math.floor((i + 1) / 2);
            info = {
              x,
              y: (arr[i] + arr[i + 1]) / 2,
            };
          } else {
            const leftDis = Math.abs(arr[i] - y);
            const rightDis = Math.abs(arr[i + 1] - y);
            let num = leftDis < rightDis ? i : i + 1;
            let direction = leftDis < rightDis ? -1 : 1;
            hoverIndex = Math.floor((num + direction) / 2);
            info = {
              x,
              y: (arr[num] + arr[num + direction]) / 2,
            };
          }
          break;
        }
      }
    } else {
      hoverIndex = findClosestIndex(
        chart.corsair.datasetPosition,
        args.event.x
      );

      let element = chart._metasets[0].data[hoverIndex];

      if (!element) {
        return;
      }
      info = {
        x: element?.x,
        y: element?.y,
      };
    }
    opts.hoverIndex = hoverIndex;

    function updateTooltipHandler() {
      externalTooltipHandler({
        chart,
        labels: chart.data.labels,
        corsair: chart.corsair,
        tooltipContainer: opts.tooltipContainer,
        priorityTop: opts.priorityTop,
        opts,
        args,
      });
    }

    if (opts.position === position.mouse && hoverIndex !== undefined) {
      updateTooltipHandler();
    }

    if (
      hoverIndex === undefined ||
      hoverIndex < 0 ||
      hoverIndex >= chart.data.labels.length
    ) {
      reset(chart);
      return;
    }

    if (hoverIndex === chart.corsair.hoverIndex) {
      if (needUpdate) {
        if (opts.position !== position.mouse) {
          updateTooltipHandler();
        }
        chart.update();
      }
      return;
    }

    if (!isBar) {
      changeRadius(chart, opts, hoverIndex);
    }

    chart.corsair = {
      ...info,
      draw: inChartArea,
      nativeX: args.event.native.clientX,
      nativeY: args.event.native.clientY,
      hoverIndex,
    };

    if (opts.position !== position.mouse) {
      updateTooltipHandler();
    }

    chart.draw();
    chart.update();
  },

  afterDatasetsDraw: (chart, args, opts) => {
    const { ctx } = chart;

    const isHorizontal = chart.config.options.indexAxis === "y";

    const { top, bottom, left, right } = chart.chartArea;
    const { x, draw, y } = chart.corsair || {};
    if (!draw) return;

    ctx.save();

    ctx.beginPath();
    ctx.lineWidth = opts.width;
    ctx.strokeStyle = opts.color;
    ctx.setLineDash(opts.dash);

    if (isHorizontal) {
      ctx.moveTo(left, y);
      ctx.lineTo(right, y);
    } else {
      ctx.moveTo(x, bottom);
      ctx.lineTo(x, top);
    }

    ctx.stroke();

    ctx.restore();
  },
};

export default hoverCrosshairPlugin;
