import { useDispatch, useSelector } from "react-redux";
import Search from "src/components/search";
import { useRouterId } from "src/hooksOrClass/getRouterId";
import { memo, useCallback, useContext, useEffect, useMemo } from "react";
import { updateBaseTime, updateListingSearch } from "../../store";
import { KeywordsContext } from "../../context";
import { useTranslation } from "react-i18next";
import getTimeComponent from "src/components/search/components/time";
import customWithErrorBoundary from "src/components/errorBoundary";

function SearchKeyWords() {
  const { t } = useTranslation();
  const baseSearchValues = useSelector((state) => state.keywords.baseSearch);
  const [routerId, name] = useRouterId();
  const { onSubmit } = useContext(KeywordsContext);
  const dispatch = useDispatch();
  const defaultActionType = "keywords";

  const fetchNames = useMemo(() => {
    return [{ key: `${name}`, formatKey: defaultActionType }];
  }, [name]);

  const handleSubmit = async (formatFormValues) => {
    if (formatFormValues?.asins !== baseSearchValues.asins) {
      dispatch(updateListingSearch(false));
    }

    onSubmit && onSubmit(formatFormValues);
  };

  const middleChildren = getTimeComponent({
    baseSearchValues,
    callback: (time) => {
      dispatch(updateBaseTime(time));
    },
  });

  return (
    <Search
      multipleAsin={false}
      defaultActionType={defaultActionType}
      baseSearchValues={baseSearchValues}
      fetchNames={fetchNames}
      onSubmit={handleSubmit}
      MiddleChildren={middleChildren}
      MiddleName="time"
      placeholder={t(`inputPlaceHolder.asinSingle`)}
    />
  );
}

export default customWithErrorBoundary(memo(SearchKeyWords));
