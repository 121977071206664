import { position } from "src/constants";
export function getOptions() {
  const options = {
    maintainAspectRatio: false,
    barPercentage: 0.6,

    onHover: function (event, chartElement) {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },

    scales: {
      y: {
        display: false, // 隐藏 x 轴标签
      },
      x: {},
    },

    indexAxis: "y",

    plugins: {
      hoverCrosshair: {
        position: position.mouse,
      },
      datalabels: false,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
      },
    },
  };

  return options;
}
