import { memo } from "react";
import classNames from "classnames";
import customWithErrorBoundary from "../errorBoundary";
import ImgWrap from ".";

import style from "./index.module.scss";

function getColumnNum(list) {
  const length = list?.length;

  if (length === 1) {
    return length;
  } else if (length >= 2 && length <= 4) {
    return 2;
  } else {
    return 3;
  }
}

function ArrangeMentImg({ list, className, height }) {
  if (!Array.isArray(list)) {
    return null;
  }
  const columnNum = getColumnNum(list);
  const newList = list.slice(0, 9);
  const rowNum = Math.ceil(newList?.length / columnNum);

  return (
    <div
      className={classNames(className, style.arrangeWrap)}
      style={{ gridTemplateColumns: `repeat(${columnNum}, 1fr)` }}
    >
      {newList?.map((item, idx) => {
        return (
          <ImgWrap
            key={`${item?.img || item}-${idx}`}
            src={item?.img || item}
            style={{
              height: (height - (rowNum - 1) * 2) / rowNum + "px",
            }}
          ></ImgWrap>
        );
      })}
    </div>
  );
}

export default customWithErrorBoundary(memo(ArrangeMentImg));
