import { Box } from "@mui/material";
import { Controller } from "react-hook-form";

import TimeSelect from "src/components/timeSelect";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

import style from "./index.module.scss";
import { timeKey } from "src/constants/form";

function getTimeComponent({ callback, baseSearchValues, needSign = false }) {
  return function MiddleChildren({
    className,
    control,
    watch,
    handleSubmit,
    menuClassName,
    ...otherPorps
  }) {
    const { t } = useTranslation();

    const time = watch(timeKey);

    useEffect(() => {
      if (time && time !== baseSearchValues.time) {
        callback && callback(time);
        baseSearchValues.asins && handleSubmit();
      }
    }, [time]);

    return (
      <div className={style.middle}>
        {needSign && (
          <div className={classNames(style.signWrap)}>
            <div className={classNames(style.sign, "helperFont")}>
              {t(`keywords.historicalData`)}
            </div>
          </div>
        )}

        <Box className={className}>
          <Controller
            name={timeKey}
            control={control}
            render={({ field }) => {
              return (
                <TimeSelect
                  {...field}
                  menuClassName={menuClassName}
                  sx={{
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "secondary.main",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderLeft: "1px solid",
                      borderRadius: 0,
                      borderColor: "secondary.main",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: 1,
                      borderColor: "secondary.main",
                    },
                  }}
                ></TimeSelect>
              );
            }}
          />
        </Box>
      </div>
    );
  };
}

export default getTimeComponent;
