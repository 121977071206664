import { memo, useMemo, useState } from "react";
import { useDayPicker, useNavigation } from "react-day-picker";
import customWithErrorBoundary from "src/components/errorBoundary";
import Month from "./month";
import "./index.scss";
import classNames from "classnames";
import Year from "./year";
import { HeadContext } from "./context";
import { addMonths } from "date-fns";

function Head(props) {
  const [activeKey, setActiveKey] = useState();
  const picker = useDayPicker();
  const { classNames: propsClassNames, styles, numberOfMonths } = picker || {};
  const { previousMonth, nextMonth, goToMonth } = useNavigation();
  const { displayMonth, displayIndex } = props;
  const isPreviousDisabled = !previousMonth;
  const isNextDisabled = !nextMonth;

  const onChange = (newMonth) => {
    goToMonth(addMonths(newMonth, displayIndex ? -displayIndex : 0));
    setActiveKey("");
  };

  const onPreviousChange = () => {
    try {
      if (isPreviousDisabled) return;
      goToMonth(addMonths(previousMonth, -(numberOfMonths - 1)));
      setActiveKey("");
    } catch (e) {}
  };
  const onNextChange = () => {
    try {
      if (isNextDisabled) return;
      goToMonth(addMonths(nextMonth, numberOfMonths - 1));
      setActiveKey("");
    } catch (e) {}
  };

  const value = useMemo(() => {
    return {
      activeKey,
      setActiveKey,
    };
  }, [activeKey]);

  return (
    <HeadContext.Provider value={value}>
      <div
        className={classNames(propsClassNames.caption, "rdp-head")}
        style={styles.caption}
      >
        <div
          className={classNames("button", "day-btn", {
            iconButtonDisabled: isPreviousDisabled,
          })}
          onClick={onPreviousChange}
        >
          <span className="icon-previous"></span>
        </div>
        <div>
          <Month
            onChange={onChange}
            displayMonth={displayMonth}
            containerId={props.id}
          />
          <Year
            onChange={onChange}
            displayMonth={displayMonth}
            containerId={props.id}
          />
        </div>
        <div
          className={classNames("button", "day-btn", {
            iconButtonDisabled: isNextDisabled,
          })}
          onClick={onNextChange}
        >
          <span className="icon-next"></span>
        </div>
      </div>
    </HeadContext.Provider>
  );
}

export default customWithErrorBoundary(memo(Head));
