export function getOptions(data) {
  const { onClick } = data || {};
  const options = {
    maintainAspectRatio: false,
    barPercentage: 0.6,

    onHover: function (event, chartElement) {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },

    scales: {
      y: {},
      x: {
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      datalabels: false,
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
      },
    },
    onClick,
    options: {
      interaction: {
        mode: "index",
      },
    },
  };

  return options;
}
