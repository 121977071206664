import { setQueryValues } from "src/utils";
import { asinsKey, timeKey } from "./form";
const getLinkUrl = (baseUrl, params = {}) => {
  const newParams = {};

  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      newParams[key] = value;
    }
  });

  return setQueryValues(baseUrl, newParams);
};

export const addLinkUrl = (
  option,
  { baseParamsKeys = [], data = {}, params = {} } = {}
) => {
  const newParams = { ...params };

  if (baseParamsKeys.includes(asinsKey)) {
    newParams[asinsKey] = data?.asin || data?.asins;
  }

  if (baseParamsKeys.includes(timeKey) && data.time) {
    newParams[timeKey] = JSON.stringify(data.time);
  }

  return {
    ...option,
    linkUrl: option.getParamsLink(newParams),
  };
};

export const getBaseParams = (data) => {
  const { asins, time } = data;
  return {
    [asinsKey]: asins,
    time: JSON.stringify(time),
  };
};

export const pageAction = {
  trafficKeywords: {
    title: "trafficKeywords",
    linkUrl: "/traffic/keywords",
    getParamsLink: getLinkUrl.bind(null, "/traffic/keywords"),
  },
  trafficStructure: {
    title: "trafficStructure",
    linkUrl: "/traffic/structure",
    getParamsLink: getLinkUrl.bind(null, "/traffic/structure"),
  },
  trafficHistory: {
    title: "trafficHistory",
    getParamsLink: getLinkUrl.bind(null, "/traffic/history"),
  },
  adStrategy: {
    title: "adStrategy",
    getParamsLink: getLinkUrl.bind(null, "/ad"),
  },
  keywordsGap: {
    title: "keywordsGap",
    getParamsLink: getLinkUrl.bind(null, ""),
  },
  adsStructure: {
    title: "adsStructure",
    getParamsLink: getLinkUrl.bind(null, ""),
  },
  productSales: {
    title: "productSales",
    linkUrl: "/sales",
    getParamsLink: getLinkUrl.bind(null, "/sales"),
  },
};
