import SerialNumber from "src/components/table/components/serialNumber";
import CellEmpty from "src/components/table/cellEmpty";
import { formatNumber } from "src/utils";
import TrendVolume from "src/components/table/components/trendVolume";
import tableVariables from "src/styles/_table.scss";

export const getColumns = () => {
  return [
    {
      id: "#",
      style: { paddingLeft: "30px", paddingRight: "30px" },
      width: 40,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
      render: function ({ idx, page, pageSize }) {
        return <SerialNumber page={page} pageSize={pageSize} idx={idx} />;
      },
    },
    {
      id: "keyword",
      width: 150,
      sticky: true,
      stickyStyle: {
        left: 40 + 16,
      },
      className: tableVariables.leftLast,

      render: function ({ row }) {
        return <CellEmpty value={row.keyword} />;
      },
    },
    {
      id: "searchVolume",
      width: 200,
      align: "right",
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.searchVolume)} />;
      },
    },
    {
      id: "searchVolumeRank",
      align: "right",
      width: 200,
      style: { paddingLeft: "30px", paddingRight: "30px" },
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.rank)} />;
      },
    },
    {
      id: "trendByVolume",
      width: 266,
      align: "center",
      sticky: true,
      stickyStyle: {
        right: 0,
      },
      className: tableVariables.rightLast,
      render: function ({ row }) {
        return (
          <div>
            <TrendVolume
              rowData={row}
              width={250}
              needClick={false}
              needTips={false}
              labels={row?.dates}
              searchVolumes={row?.searchVolumes}
              ranks={row?.ranks}
            />
          </div>
        );
      },
    },
  ];
};
