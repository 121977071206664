export function reset(chart) {
  chart.corsair = {
    x: 0,
    y: 0,
    hoverIndex: undefined,
    draw: false,
    datasetPosition: chart?.corsair?.datasetPosition,
  };

  chart.data.datasets.forEach((element, index) => {
    if (element.type !== "line") {
      element.backgroundColor =
        element.originBackgroundColor || element.backgroundColor;
      element.textColor = element.originTextColor;
    }
  });
}

export function changeRadius(chart, opts, hoverIndex) {
  chart.data.datasets.forEach((dataset, i) => {
    if (dataset?.originPointRadius) {
      dataset.pointRadius = Array.isArray(dataset.originPointRadius)
        ? [...dataset.originPointRadius]
        : new Array(dataset.data.length).fill(dataset.originPointRadius);

      if (hoverIndex !== undefined) {
        dataset.pointRadius[hoverIndex] =
          dataset.pointHoverRadius || opts.hoverPointRadius;
      }
    }
  });
}

export function findClosestIndex(arr, x) {
  let start = 0;
  let end = arr.length - 1;
  let closestIndex = -1;
  let closestDiff = Infinity;

  while (start <= end) {
    let mid = Math.floor((start + end) / 2);
    let diff = Math.abs(arr[mid] - x);

    if (diff < closestDiff) {
      closestDiff = diff;
      closestIndex = mid;
    }

    if (arr[mid] < x) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  return closestIndex;
}

export function getTooltipElParent() {
  return document.body;
}

export function findTooltipEl(container, tooltipContainerName) {
  var tooltipEl = container.querySelector(`.${tooltipContainerName}`);
  return { tooltipEl };
}
