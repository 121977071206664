import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";

function DisplayAsin({ rowData }) {
  const data = rowData?.asins?.map((item) =>
    item?.featuresValues?.value
      ? `${item.asin}-${item?.featuresValues?.value}`
      : item.asin
  );

  return (
    <div className="multLineTdWrap">
      {data?.map((item) => {
        return (
          <div key={item}>
            <CellEmpty value={item} />
          </div>
        );
      })}
    </div>
  );
}

export default customWithErrorBoundary(memo(DisplayAsin));
