import classNames from "classnames";
import customWithErrorBoundary from "../errorBoundary";
import { memo } from "react";
import { linkToAmazon } from "src/utils/amazon";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export function AmazonIcon({ size = 16, disabled = false }) {
  return (
    <span
      className="icon-amazon"
      style={{ fontSize: size + "px", opacity: disabled ? 0.4 : 1 }}
    >
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
    </span>
  );
}
function LinkToAmazonCom({
  asin,
  placement = "top",
  isBtn = false,
  size = 16,
  disabled = false,
  needLinkToAmazon = true,
}) {
  const { t } = useTranslation();
  const handleClick = () => {
    if (disabled || !needLinkToAmazon) {
      return;
    }
    linkToAmazon(asin);
  };

  return (
    <Tooltip title={t(`amazon`)} placement={placement}>
      <div
        className={classNames({
          button: isBtn,
          disabled: disabled,
        })}
        onClick={handleClick}
      >
        <AmazonIcon size={size} disabled={disabled} />
      </div>
    </Tooltip>
  );
}

export default customWithErrorBoundary(memo(LinkToAmazonCom));
