import { memo, useContext, useEffect } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import { overCodes } from "../../constant";
import { useTranslation } from "react-i18next";
import style from "./index.module.scss";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import {
  resetDataSearch,
  updateDataSearch,
  updateLastClickActiveInfo,
} from "../../store";
import { AdStrategyContext } from "../../context";
function HoverOverview({ data, onMouseEnter, scrollIntoViewWrap }) {
  const { t } = useTranslation();
  const { fetchTable } = useContext(AdStrategyContext);
  const dispatch = useDispatch();
  const handleSelectCampaign = (info, type) => {
    dispatch(resetDataSearch());
    dispatch(updateLastClickActiveInfo({ key: data.key, type }));

    dispatch(
      updateDataSearch({
        key: "encryptCampaignId",
        value: info?.encryptCampaignId,
      })
    );
    fetchTable();
    scrollIntoViewWrap && scrollIntoViewWrap();
  };
  return (
    <div className={style.hoverViewWrap} onMouseEnter={onMouseEnter}>
      <div className={classNames("stressTextFont", style.header)}>
        {data.key}
      </div>

      <div className={style.content}>
        {overCodes.map((code, idx) => {
          const num = data[code + "Num"];
          if (num > 0) {
            return (
              <div key={code}>
                <div
                  className={classNames(style.subHeader, code, {
                    [style.first]: idx === 0,
                  })}
                >
                  开启了
                  <span className={style.highLight}>
                    {num}个{t(`terminology.${code}`)}广告活动
                  </span>
                </div>
                <div className={classNames(style.groupItem)}>
                  {data[code]?.map((item) => {
                    return (
                      <div
                        key={item.fakeCampaignId}
                        onClick={() => handleSelectCampaign(item, code)}
                      >
                        <span className="underDashLine">
                          {item.fakeCampaignId}:{item.strategy}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }

          return null;
        })}
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(HoverOverview));
