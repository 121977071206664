import { memo } from "react";

function SerialNumber({ page, pageSize, idx }) {
  return (
    <span style={{ wordBreak: "initial" }}>
      {(page - 1) * pageSize + (idx + 1)}
    </span>
  );
}

export default memo(SerialNumber);
