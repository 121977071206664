import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { Chart as ChartJS, registerables } from "chart.js";
import corsairPlugin from "src/utils/chart/plugin/hoverCrosshair";
import { ThemeProvider } from "@emotion/react";
import { ClickToComponent } from "click-to-react-component";

import "./i18n";
import "./styles/index.scss";
import router from "./route/router";
import { store } from "./store";
import { customTheme } from "./styles/theme";
import monitorInstance from "./utils/errorMonitor";
import { MessageNotification } from "./components/message";
import Cookies from "js-cookie";
const isDev = process.env.NODE_ENV === "development";

ChartJS.register(...registerables);
ChartJS.register(corsairPlugin);

const root = ReactDOM.createRoot(document.getElementById("root"));

// 全局的loading
function FallbackElement() {
  return <div role="alert">loading.....</div>;
}

Cookies.set("timeZone", Intl.DateTimeFormat().resolvedOptions().timeZone, {
  expires: 10,
});

monitorInstance.addErrorListener();

root.render(
  <>
    {isDev ? <ClickToComponent /> : <></>}

    <ThemeProvider theme={customTheme}>
      <Provider store={store}>
        <RouterProvider router={router} fallbackElement={<FallbackElement />} />
        <MessageNotification></MessageNotification>
      </Provider>
    </ThemeProvider>
  </>
  // <React.StrictMode>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
