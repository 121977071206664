import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import FlowPagination from "src/components/pagination";
import { AdStrategyContext } from "../../context";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import style from "./index.module.scss";
import { getPlaceHolderData } from "src/utils";
import GroupInfo from "../groupInfo";
import ActionView from "../actionView";
import { fetchTableData } from "src/store/rootReducer";
import Empty from "src/components/empty";
import EmptyTips from "../emptyTips";
import Tip from "src/components/tooltip/tip";
import CustomSelect from "src/components/customSelect";
import { getOption, getPosition } from "../../util";
import UpdateVariants from "src/components/updateVariants";
import useLanguage from "src/hooksOrClass/language";
import InputRange from "src/components/picker/inputRange";
import { periodKey } from "src/constants/form";
import { adStrategyStartTime } from "../../constant";
import MemoList from "./memoList";
import {
  connectSymbol,
  standardFormatParams,
} from "src/components/picker/inputRange/constant";
import { endOfDay, format } from "date-fns";
import { formatStandardDate } from "src/components/picker/inputRange/util";
import { autoSetTrafficTime } from "../../store";
function AdList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const areaRef = useRef();
  const type = useSelector(
    (state) => state.adStrategy?.baseSearch?.[periodKey]
  );
  const areaTitleFixedRef = useRef();
  const { tableKey, fetchTable } = useContext(AdStrategyContext);
  const tableData = useSelector(
    (state) => state.root?.table[tableKey]?.tableData
  );

  const trafficTime = useSelector((state) => state.adStrategy.trafficTime);
  const baseSearch = useSelector((state) => state.adStrategy.baseSearch);

  const tableSearch = useSelector(
    (state) => state.root?.table[tableKey]?.tableSearch
  );

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[tableKey]?.tableFetchStatus
  );

  const memoTableFetchStatus = useMemo(() => {
    return tableFetchStatus;
  }, [tableData]);

  const handleChangePageNumAndFetch = useCallback((e, page) => {
    const data = { pageNum: page, key: tableKey };
    if (fetchTable) {
      fetchTable(data);
    } else {
      dispatch(fetchTableData(data));
    }
  }, []);

  const [defaultOption, options] = getOption({
    data: baseSearch,
  });

  const handleChangeTime = useCallback((time) => {
    console.log("time", time);
    const timesArr = time.split(connectSymbol);
    dispatch(
      autoSetTrafficTime({ startDate: timesArr[0], endDate: timesArr[1] })
    );
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const areaEle = areaRef.current;
      const res = areaEle?.getBoundingClientRect();
      if (res.top <= 0 && res.bottom >= 0) {
        areaTitleFixedRef.current?.classList.add(style.visible);
      } else {
        areaTitleFixedRef.current?.classList.remove(style.visible);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="wrap">
      <div
        className={classNames("titleSummary", "wrapContentInfo", style.summary)}
      >
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`adStrategy.tableList.summary`, {
                campaignCount: getPlaceHolderData(tableData?.total),
                adsCount: getPlaceHolderData(tableData?.adCount),
              }),
            }}
          ></span>

          <Tip title={t("adStrategy.tipHover")}>
            <span className="icon-tip" style={{ marginLeft: "6px" }}></span>
          </Tip>
        </div>
        <div className={"btnsRightWrap"}>
          <UpdateVariants asins={baseSearch.asins} />
          <CustomSelect
            defaultConfig={{
              ...defaultOption,
              title: t(`router.${defaultOption.title}`),
              style: {
                color: "var(--link-color)",
              },
            }}
            options={options.map((item) => ({
              ...item,
              title: t(`router.${item.title}`),
            }))}
          />
        </div>
      </div>
      <div className={style.rootListWrap}>
        <div className={classNames("wrap", "textFont", style.contentWrap)}>
          <div className={style.listWrap}>
            <MemoList />
          </div>
        </div>
        <div className={classNames("wrap", style.area)} ref={areaRef}>
          <div className={style.areaTitleFixed} ref={areaTitleFixedRef}>
            同期所有广告活动流量占比
            <InputRange
              type={type}
              value={trafficTime}
              onChange={handleChangeTime}
              startTime={new Date(adStrategyStartTime)}
              endTime={new Date()}
            />
          </div>
          {tableData?.list?.map((item, idx) => {
            return (
              <div className={style.ratio} key={idx}>
                {idx === 0 && (
                  <div className={style.areaTitle}>
                    同期所有广告活动流量占比
                    <InputRange
                      type={type}
                      value={trafficTime}
                      onChange={handleChangeTime}
                      startTime={new Date(adStrategyStartTime)}
                      endTime={new Date()}
                    />
                  </div>
                )}
                <div
                  className={classNames(style.num, {
                    [style.firstNum]: idx === 0,
                  })}
                >
                  30.03%
                </div>
                {/* <div className={style.btn}>对比广告小组流量</div> */}
              </div>
            );
          })}
        </div>
      </div>
      {tableData?.total ? (
        <div className={classNames("pageContentWrap")}>
          <FlowPagination
            onChange={handleChangePageNumAndFetch}
            page={tableSearch?.pageNum}
            count={Math.ceil(tableData?.total / tableSearch?.pageSize)}
          />
        </div>
      ) : null}

      <Empty
        data={tableData?.list}
        tips={<EmptyTips />}
        status={
          tableData?.total === undefined
            ? tableFetchStatus
            : memoTableFetchStatus
        }
        className={style.emptyWrap}
      ></Empty>
    </div>
  );
}

export default customWithErrorBoundary(memo(AdList));
