import Collect from "src/components/collect";
import customWithErrorBoundary from "src/components/errorBoundary";
import CommonAction from "src/components/table/components/action";
import { getTrafficOption } from "../../util";
import { memo } from "react";
function Action({ rowData }) {
  const inLibrary = rowData.inLibrary;
  const [, options] = getTrafficOption(false, false, rowData);

  return (
    <CommonAction options={options} rowData={rowData}>
      <Collect inLibrary={inLibrary} />
    </CommonAction>
  );
}

export default customWithErrorBoundary(memo(Action));
