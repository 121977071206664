import { useDispatch } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import { useTranslation } from "react-i18next";

import { updateRuleModalOpen } from "../../store";
import { openNewUrl } from "src/utils";
import useLanguage from "src/hooksOrClass/language";
const allUrl = {
  en: "https://dev.minusflow.com/",
};

function OtherContent() {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const handleViewClossary = (e) => {
    openNewUrl(allUrl[language]);
  };

  return (
    <p>
      <a onClick={handleViewClossary}>{t(`structure.viewGlossary`)}</a>
    </p>
  );
}

export default customWithErrorBoundary(OtherContent);
