import USImage from "src/assets/country/US@2x.png";
import DEImage from "src/assets/country/Germany@2x.png";
import UKImage from "src/assets/country/UK@2x.png";
import JPImage from "src/assets/country/Japan@2x.png";
import CAImage from "src/assets/country/Canada@2x.png";
import FRImage from "src/assets/country/France@2x.png";
import ESImage from "src/assets/country/Spain@2x.png";
import ITImage from "src/assets/country/Ltaly@2x.png";
import { countryCodeEnum } from "src/constants/country";

export const countryList = [
  {
    groupid: "0",
    code: countryCodeEnum.US,
    flag: USImage,
  },
  {
    groupid: "1",
    code: countryCodeEnum.DE,
    flag: DEImage,
  },
  {
    groupid: "2",
    code: countryCodeEnum.UK,
    flag: UKImage,
  },
  {
    groupid: "3",
    code: countryCodeEnum.JP,
    flag: JPImage,
  },
  {
    groupid: "4",
    code: countryCodeEnum.CA,
    flag: CAImage,
  },
  {
    groupid: "5",
    code: countryCodeEnum.FR,
    flag: FRImage,
  },
  {
    groupid: "6",
    code: countryCodeEnum.ES,
    flag: ESImage,
  },
  {
    groupid: "7",
    code: countryCodeEnum.IT,
    flag: ITImage,
  },
];
