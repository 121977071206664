import React, { useContext } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import muiVariables from "src/styles/_mui.scss";

import GlobalChecked from "./globalChecked";
import Message from "../message";
import { TableContext } from "./context";

import style from "./index.module.scss";
import GlobalDownloadKeyword from "./globalDownloadKeyword";

function GlobalAction({ hasAddProductBtn = true, hasDownloadBtn = false }) {
  const { name, handleSelectAllClick } = useContext(TableContext);
  const selected = useSelector((state) => state.root.table?.[name]?.selected);

  const numSelected = selected?.length;

  const { t } = useTranslation();

  return (
    <>
      {numSelected ? (
        <div className={style.globalActionWrap}>
          <GlobalChecked
            handleSelectAllClick={handleSelectAllClick}
            column={{}}
            style={{
              padding: `0 ${muiVariables.tablePaddingHorizontal}`,
              border: "none",
            }}
          />
          {hasDownloadBtn && <GlobalDownloadKeyword />}
          {hasAddProductBtn && (
            <div className={classNames(style.globalBtn, "button", "textFont")}>
              {t("globalBtn.addLibrary")}
            </div>
          )}
          <CopyToClipboard
            text={selected.join(",")}
            onCopy={() => {
              Message.success(t(`copied`));
            }}
          >
            <div className={classNames(style.globalBtn, "button")}>
              {t("globalBtn.copy")}
            </div>
          </CopyToClipboard>

          <div
            className={classNames(style.globalBtn, "button")}
            onClick={() => handleSelectAllClick({ target: { checked: false } })}
          >
            {t("globalBtn.deselectAll")}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default GlobalAction;
