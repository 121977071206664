import customWithErrorBoundary from "src/components/errorBoundary";
import Search from "./compontents/search";
import { HistoryContext } from "./context";
import { memo, useEffect } from "react";
import Chart from "./compontents/chart";
import "@lottiefiles/lottie-player";

function History() {
  return (
    <HistoryContext.Provider value={{}}>
      <Search />
      <Chart />

      <div></div>
      <lottie-player
        src="/sif-loading.json"
        autoplay
        background="transparent"
        speed="1"
        direction="1"
        playMode="normal"
        loop
        style={{ width: "300px", height: "300px" }}
        controls
      ></lottie-player>
    </HistoryContext.Provider>
  );
}

export default customWithErrorBoundary(memo(History));
