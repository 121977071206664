import { createTheme } from "@mui/material";
import ErrorIcon from "src/assets/tip/error@2x.png";
import WarningIcon from "src/assets/tip/warning@2x.png";
import NormalIcon from "src/assets/tip/normal@2x.png";
import SuccessIcon from "src/assets/tip/success@2x.png";

import variables from "./_var.scss";

import muiVariables from "./_mui.scss";

const color = "var(--color)";

export const BaseMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
export const customTheme = createTheme({
  typography: {
    fontFamily: "var(--font-family)",
    allVariants: {
      fontFamily: "var(--font-family)",
    },
  },
  zIndex: {
    modal: 1600,
  },
  palette: {
    text: {
      primary: variables.color,
    },
    primary: {
      main: variables.primary,
    },
    secondary: {
      main: variables.secondary,
    },
  },
  components: {
    MuiAlert: {
      defaultProps: {
        onClose: () => {},
        iconMapping: {
          error: <img src={ErrorIcon} alt="" />,
          warning: <img src={WarningIcon} alt="" />,
          info: <img src={NormalIcon} alt="" />,
          success: <img src={SuccessIcon} alt="" />,
        },
      },

      styleOverrides: {
        standardSuccess: {
          backgroundColor: "#EAF7EA",
          border: "1px solid #33AF35",
          color,
          "& .MuiAlert-action": {
            color: "#33AF35",
          },
        },
        standardError: {
          backgroundColor: "#FFEAEA",
          color,
          border: "1px solid var(--error);",

          "& .MuiAlert-action": {
            color: "var(--error)",
          },
        },
        standardWarning: {
          backgroundColor: "#FEF2EB",
          color,
          border: "1px solid #F5853B",

          "& .MuiAlert-action": {
            color: "#F5853B",
          },
        },
        standardInfo: {
          backgroundColor: "#E6EFFF",
          color,
          border: "1px solid var(--link-color)",

          "& .MuiAlert-action": {
            color: "var(--link-color)",
          },
        },
        root: {
          padding: "5px 20px",
          alignItems: "center",
          minWidth: "min(568px, 24%)",
          maxWidth: "53%",
          width: "auto",
          zIndex: "9999",
          boxShadow: "var(--box-shadow)",
          "& .MuiAlert-icon": {
            width: "20px",
            height: "20px",
            flex: "0 0 auto",

            "& > img": {
              width: "100%",
              height: "100%",
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        arrow: {
          color,
        },
        tooltip: {
          font: "var(--text-font)",
          fontWeight: "400",
          padding: "10px 12px",
          borderRadius: "var(--border-radius)",
          backgroundColor: color,
          boxShadow: "var(--box-shadow) ",
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "black",
            },
            "&.Mui-checked": {
              color: "black",
            },
          };
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          font: "inherit",
          color,
          padding: `${muiVariables.tablePaddingVertical} ${muiVariables.tablePaddingHorizontal} `,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            backgroundColor: "var(--hover-background)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "& > input::placeholder": {
            color: "var(--tip-text-color)",
            opacity: 1,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return ownerState.variant === "outlined"
            ? {
                "&": {
                  font: "var(--text-font)",
                  border: `1px solid var(--secondary)`,
                },
                "&.MuiPaginationItem-ellipsis": {
                  border: "none",
                },
                "&.Mui-selected": {
                  backgroundColor: "var(--secondary)",
                  color,
                  border: `1px solid var(--secondary)`,
                  pointerEvents: "none",
                  "&:hover": {
                    backgroundColor: "var(--secondary)",
                  },
                },
              }
            : {
                font: "var(--text-font)",
              };
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "var(--border-radius)",
          "& input::placeHolder": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        IconComponent: function IconComponent(props) {
          return (
            <span className={props.className}>
              <span
                {...props}
                className="icon-pull-down"
                style={{ fontSize: "10px" }}
              ></span>
            </span>
          );
        }, // 设置展开时的图标
        OpenIconComponent: function IconComponent(props) {
          return (
            <span className={props.className}>
              <span
                {...props}
                className="icon-pull-up"
                style={{ fontSize: "10px" }}
              ></span>
            </span>
          );
        }, // 设置展
      },
      styleOverrides: {
        icon: {
          right: `min(${variables.selectMenuHor}, 10%)`, // 调整图标的右侧位置
          top: "initial", // 调整图标的上下位置
        },
        root: {
          borderRadius: "var(--border-radius)",
          "& .MuiOutlinedInput-input": {
            paddingLeft: variables.selectMenuHor,
            // paddingRight: `${variables.selectMenuHor} !important`,
          },
          "& .MuiSelect-outlined:not(:has(div))": {
            display: "inline !important",
            overflow: "hidden",
            "text-overflow": "ellipsis",
            "align-content": "center",
            "line-height": "36px",
          },
          "& .MuiPaper-root": {
            maxHeight: "400px !important",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          marginTop: variables.selectMenuDistance,
          boxShadow: "var(--box-shadow)",
          "& .MuiMenu-list": {
            paddingTop: variables.paperMenulistVer,
            paddingBottom: variables.paperMenulistVer,
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiModal-backdrop": {
            backgroundColor: "rgba(0,0,0,0.65)",
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "&:not(.MuiPopover-root) .MuiModal-backdrop": {
            backgroundColor: "rgba(0,0,0,0.65)",
          },
        },
      },
    },
    // MuiP
    MuiMenuItem: {
      variants: [
        {
          props: { variant: "search" },
          style: {
            padding: `${variables.$selectMenuVer} ${variables.selectMenuHor} !important`,
          },
        },
      ],
      styleOverrides: {
        root: {
          "&.MuiMenuItem-root": {
            font: "var(--text-font)",
            padding: `10px  ${parseInt(variables.selectMenuHor) - 3}px`,
            borderLeft: `3px solid transparent`,
          },
          "&:hover": {
            backgroundColor: "var(--hover-background)",
          },
          "&.Mui-selected": {
            backgroundColor: "var(--mini-primary)",
            borderLeft: `3px solid var(--primary)`,
            "&:hover": {
              backgroundColor: "var(--mini-primary)",
            },
          },
          "&.Mui-selected.Mui-focusVisible": {
            backgroundColor: "var(--mini-primary)",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
  },
});
