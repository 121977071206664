import SerialNumber from "src/components/table/components/serialNumber";
import PageKeyword from "./keyword.jsx";
import AsinOption from "./asinOption.jsx";

import style from "./index.module.scss";
import CellEmpty from "src/components/table/cellEmpty";
import { formatNumber } from "src/utils/index.js";
import tableVariables from "src/styles/_table.scss";

export const getColumns = ({ data } = {}) => {
  const asins = data?.[0]?.asins;
  console.log("asins", asins);

  const middleOptions =
    asins?.map((item) => {
      return {
        id: item.asin,
        align: "center",
        label: (
          <div>
            <img
              className={style.tableHeadImg}
              src="https://m.media-amazon.com/images/I/61HEqHMkRhL._AC_UL320_.jpg"
              alt=""
            />
            <div>{item.asin}</div>
          </div>
        ),
        width: 80,
        render: function ({ row }) {
          return <AsinOption rowData={row} asin={item.asin} />;
        },
      };
    }) || [];

  return [
    {
      id: "#",
      width: 60,
      sticky: true,
      stickyStyle: {
        left: 0,
      },
      style: {
        paddingLeft: "40px",
      },
      render: function ({ idx, page, pageSize }) {
        return (
          <SerialNumber page={page} pageSize={pageSize} idx={idx} needLeft />
        );
      },
    },
    {
      id: "keyword",
      width: 150,
      sticky: true,
      tooltip: "keywordHover",
      stickyStyle: {
        left: 60 + 40,
      },
      className: tableVariables.leftLast,
      render: function ({ row }) {
        return <PageKeyword rowData={row} />;
      },
    },
    ...middleOptions,
    {
      id: "validCompetingProducts",
      width: 150,
      align: "right",
      render: function ({ row }) {
        return (
          <div style={{ paddingRight: "20px" }}>
            <CellEmpty value={formatNumber(row.validAsinNum)} />
          </div>
        );
      },
    },
    {
      id: "crawlTime",
      width: 100,
      align: "center",
      render: function ({ row }) {
        return <CellEmpty value={row.updateTime} />;
      },
    },
    {
      id: "searchNumRank",
      width: 110,
      align: "right",
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.searchesRank)} />;
      },
    },

    {
      id: "searchNum",
      width: 70,
      align: "right",
      render: function ({ row }) {
        return <CellEmpty value={formatNumber(row.estSearchesNum)} />;
      },
    },
    {
      id: "csompetingProducts",
      width: 130,
      align: "right",
      render: function ({ row }) {
        return (
          <div style={{ paddingRight: "20px" }}>
            <CellEmpty value={formatNumber(row.saleNum)} />
          </div>
        );
      },
    },
    {
      id: "actions",
      width: 60,
      align: "center",
      sticky: true,
      className: tableVariables.rightLast,
      stickyStyle: {
        right: 0,
      },
      render: function ({ row }) {
        return <div>对比排名</div>;
      },
    },
  ];
};
