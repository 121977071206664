import React, { Fragment, useEffect } from "react";

import { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import router from "src/route/router";

import customWithErrorBoundary from "src/components/errorBoundary";
import Fade from "src/components/fade";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

import style from "./index.module.scss";
import variables from "src/styles/_var.scss";
import classNames from "classnames";
import { useStorageShrinked } from "src/hooksOrClass/localstorge";
import { menuLinkToPath } from "src/utils";

const positionEnum = {
  inside: "inside",
  outside: "outside",
};

function NavWrap({ route = {}, to, title }) {
  const { t } = useTranslation();
  const location = useLocation();

  const Icon = route.icon;
  const path = to || route.path;
  const newPath = menuLinkToPath(path)?.href || path;

  return (
    <div className={classNames(style.navWrap, "menuBtnFont")}>
      <NavLink
        to={newPath}
        className={classNames({
          active: location?.pathname === path,
        })}
      >
        {Icon ? <Icon className={style.icon} /> : null}
        <span> {t(`router.${title || route.showInSidebar}`)}</span>
      </NavLink>
    </div>
  );
}

let timer, timer1;
let timer2, timer3;
function Sidebar() {
  const { t } = useTranslation();

  const [shrinked, setShrinked] = useStorageShrinked();

  // 是否可收缩
  const shrinkedRef = useRef(shrinked);
  const lockRef = useRef();
  const onceRef = useRef();
  const navTopRef = useRef();

  const ref = useRef(null);
  const maskRef = useRef(null);
  let fadeInAimation, fadeOutAnimation;
  const duration = 240;
  const debonceDuration = 50;

  function animateFadeInBar({ fadeInDuration, animate, callback } = {}) {
    fadeInAimation?.cancel();
    navTopRef.current?.classList?.add("hidden");

    fadeInAimation = ref.current.animate(
      animate ?? [
        { transform: "translateX(-100%)" },
        { transform: "translateX(0)" },
      ],
      {
        duration: fadeInDuration || duration,
        fill: "forwards",
        easing: "ease-in-out",
        animationName: "fadeInAimation",
      }
    );
    ref.current?.classList?.remove(style.sidebarOnceFadeOut);

    fadeInAimation.onfinish = () => {
      callback && callback();
    };
  }

  function animateFadeOutBar({ fadeOutDuration, animate, callback } = {}) {
    fadeOutAnimation?.cancel();
    navTopRef.current?.classList?.add("hidden");

    fadeOutAnimation = ref.current.animate(
      animate ?? [
        { transform: "translateX(0px)" },
        { transform: "translateX(-100%)" },
      ],
      {
        duration: fadeOutDuration || duration,
        fill: "forwards",
        easing: "ease-in-out",
        animationName: "fadeOutAnimation",
      }
    );

    fadeOutAnimation.onfinish = () => {
      callback && callback();
      navTopRef.current?.classList?.remove("hidden");
    };
  }

  // 默认情况下是否收缩的，对应设置bar的样式
  useEffect(() => {
    if (shrinked) {
      ref.current?.classList?.add(style.sidebarOnceShink);
    }

    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    if (!onceRef.current) {
      onceRef.current = true;
      return;
    }
    // 如果是在本页面操作的bar固定悬浮切换则不需要执行下述逻辑
    if (shrinkedRef.current === shrinked) {
      return;
    }
    if (shrinked) {
      handleShrinked();
    } else {
      handleFixed();
    }

    return () => {
      clearTimer();
    };
  }, [shrinked]);

  const clearTimer = () => {
    clearTimeout(timer);
    clearTimeout(timer1);
    clearTimeout(timer2);
    clearTimeout(timer3);
  };

  function getPosition() {
    const boundingClientRect = ref.current.getBoundingClientRect();
    if (
      Math.abs(
        parseInt(boundingClientRect.x) -
          parseInt(`-${parseInt(boundingClientRect.width)}`)
      ) <= 1
    ) {
      return positionEnum.outside;
    }

    if (Math.abs(parseInt(boundingClientRect.x) - 0) <= 1) {
      return positionEnum.inside;
    }

    return "";
  }

  const handleFixed = () => {
    if (
      fadeOutAnimation?.playState === "running" ||
      fadeInAimation?.playState === "running"
    ) {
      return;
    }
    clearTimer();

    setShrinked(false);
    shrinkedRef.current = false;
    lockRef.current = true;

    ref.current?.classList?.remove(style.sidebarOnceFixed);
    ref?.current?.classList?.remove(style.sidebarOnceShink);
    ref?.current?.classList?.remove(style.sidebarOnceFadeOut);
    fadeInAimation?.cancel();
    fadeOutAnimation?.cancel();
    animateFadeInBar({
      fadeInDuration: 0,
      animate: [
        {
          transform: "translateX(0)",
        },
      ],
      callback: () => {
        lockRef.current = false;
      },
    });
  };

  const handleShrinked = () => {
    if (
      fadeOutAnimation?.playState === "running" ||
      fadeInAimation?.playState === "running"
    ) {
      return;
    }
    clearTimer();

    setShrinked(true);

    fadeInAimation?.cancel();
    fadeOutAnimation?.cancel();
    ref.current.classList?.remove(style.sidebarOnceFadeOut);
    ref.current.classList?.remove(style.sidebarOnceShink);
    ref.current.classList?.remove(style.sidebarOnceFixed);
    shrinkedRef.current = true;
    lockRef.current = true;

    animateFadeOutBar({
      fadeOutDuration: 0,
      animate: [
        {
          transform: "translateX(-100%)",
        },
      ],
      callback: () => {
        lockRef.current = false;
      },
    });
  };
  const handleMouseMaskEnter = () => {
    clearTimer();
    // console.log(
    //   "bar我进入了",
    //   fadeOutAnimation,
    //   fadeOutAnimation?.playState,
    //   "----",
    //   ref.current.classList.contains(style.sidebarOnceFadeOut),
    //   shrinkedRef.current
    // );

    if (lockRef.current) {
      return;
    }

    if (
      ref.current?.classList?.contains(style.sidebarOnceFadeOut) &&
      getPosition() !== positionEnum.outside
    ) {
      return;
    }

    if (!shrinkedRef.current) {
      return;
    }

    timer = setTimeout(() => {
      if (getPosition() === positionEnum.inside) {
        return;
      }

      if (fadeOutAnimation && fadeOutAnimation.playState === "running") {
        fadeOutAnimation.reverse();
      } else {
        animateFadeInBar();
      }

      timer2 = setTimeout(() => {
        if (getPosition() !== positionEnum.inside) {
          handleMouseMaskEnter();
        }
      }, duration + 40);
    }, debonceDuration);
  };

  const handleMouseLeave = () => {
    clearTimer();

    // console.log(
    //   "bar我离开了",
    //   fadeInAimation,
    //   fadeInAimation?.playState,
    //   "fadeInAimation.currentTime:",
    //   fadeInAimation?.currentTime,
    //   "----",
    //   shrinkedRef.current,
    //   ref.current.classList.contains(style.sidebarOnceFadeOut)
    // );

    if (lockRef.current) {
      return;
    }

    if (ref.current.classList.contains(style.sidebarOnceFadeOut)) {
      return;
    }
    if (!shrinkedRef.current) {
      return;
    }

    timer1 = setTimeout(() => {
      if (getPosition() === positionEnum.outside) {
        return;
      }

      if (fadeInAimation && fadeInAimation.playState === "running") {
        fadeInAimation.reverse();
      } else {
        animateFadeOutBar();
      }

      timer3 = setTimeout(() => {
        if (getPosition() !== positionEnum.outside) {
          handleMouseLeave();
        }
      }, duration + 40);
    }, debonceDuration);
  };

  function FixedBtn({ noNeedEvent = false, placement = "bottom" }) {
    return (
      <Tooltip title={t("sideBar.fixed")} placement={placement}>
        <div
          onClick={!noNeedEvent ? handleFixed : () => {}}
          className={classNames(style.btn)}
        >
          <span className="icon-open"></span>
        </div>
      </Tooltip>
    );
  }

  return (
    <Fragment>
      {shrinked ? (
        <div className={style.navTop} onClick={handleFixed} ref={navTopRef}>
          <FixedBtn noNeedEvent placement="right" />
        </div>
      ) : null}
      <Fade
        in={!shrinked}
        style={{ flex: "0 0 auto" }}
        duration={400}
        transitionStyles={{
          entering: { width: variables.barWidth },
          entered: { width: variables.barWidth },
          exiting: { width: "36px" },
          exited: { width: "36px" },
        }}
      >
        <div
          className={style.sideWrap}
          onMouseEnter={handleMouseMaskEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className={style.mask} ref={maskRef}></div>
          <div className={style.navTopPlaceHolder}>
            <div className={classNames(style.btn)}>
              <span className="icon-open"></span>
            </div>
          </div>
          <div className={classNames(style.shrinkedBar)} ref={ref}>
            <div className={style.logo}>
              <span>logo</span>

              {shrinked ? (
                <FixedBtn />
              ) : (
                <Tooltip title={t("sideBar.shrinked")} placement="bottom">
                  <div
                    onClick={handleShrinked}
                    className={classNames(style.btn)}
                  >
                    <span className="icon-close"></span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={style.content}>
              {router.routes?.[0].children?.map((route) => {
                return (
                  <div key={route.path + route.title}>
                    {route.showInSidebar &&
                      (route?.children?.length > 0 ? (
                        //有小标题的路由，显示小标题
                        <div className={style.sub}>
                          <div
                            className={classNames("menuTitleFont", style.title)}
                          >
                            {t(`router.${route.showInSidebar}`)}
                          </div>
                          <div className={style.routeWrap}>
                            {route.children.map((child) => {
                              return (
                                <NavWrap
                                  key={child.id}
                                  route={route}
                                  title={child.showInSidebar}
                                  to={`${
                                    child.path
                                      ? route.path + "/" + child.path
                                      : route.path
                                  }`}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        <NavWrap key={route.id} route={route} />
                      ))}
                  </div>
                );
              })}
            </div>
            <div className={style.bottom}>
              <div className={classNames(style.utilsWrap, style.routeWrap)}>
                {router.routes?.[1].children?.map((route) => {
                  return <NavWrap key={route.id} route={route} />;
                })}
              </div>
              <div
                className={classNames(style.user, style.navWrap, "menuBtnFont")}
              >
                <span className={classNames(style.name)}>NK</span>
                <span>Nicole Korr</span>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Fragment>
  );
}

export default customWithErrorBoundary(Sidebar);
