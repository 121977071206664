import { useDispatch } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import { updateRuleModalOpen } from "../../store";
import { useTranslation } from "react-i18next";

function OtherContent() {
  const { t } = useTranslation();
  const dispath = useDispatch();

  const viewRule = (e) => {
    e.stopPropagation();
    dispath(updateRuleModalOpen(true));
  };
  return (
    <p>
      <a onClick={viewRule}>{t(`keywords.colorExplain`)}</a>
    </p>
  );
}

export default customWithErrorBoundary(OtherContent);
