import {
  Await,
  createBrowserRouter,
  useLoaderData,
  useNavigation,
} from "react-router-dom";
import React from "react";

import { routes } from "./routes";

const About = function () {
  const navigation = useNavigation();
  const data = useLoaderData();
  if (navigation.state === "loading") {
    return <div>loading...</div>;
  }
  return (
    <React.Suspense fallback={<p>Loading package location...</p>}>
      <Await
        resolve={data.data}
        errorElement={<p>Error loading package location!</p>}
      >
        {(data) => (
          <p>
            About
            {data}
          </p>
        )}
      </Await>
    </React.Suspense>
  );
};

// 对应使用文档链接：https://reactrouter.com/en/main/start/overview
const router = createBrowserRouter(routes);

export default router;
