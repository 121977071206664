import { enUS, ja } from "date-fns/locale";
import { format } from "date-fns";
import { getPlaceHolderData, getStorageValue } from "src/utils";
import { options } from "src/i18n/constant";
import { getParmarsFormatDate } from "src/utils/time";
const locale = {
  en: {
    locale: enUS,
    day: "dd",
    dayAndMonth: "MMM d",
    month: "MMM y",
    year: "y",
  },
};

export const useLanguage = () => {
  const language = getStorageValue(options.lookupLocalStorage) ?? "en";

  function formatLocale(
    date,
    {
      formatParams = "PP",
      getDay = false,
      getMonth = false,
      getYear = false,
      onlyShowTime = false,
      getDayAndMonth = false,
    } = {}
  ) {
    try {
      if (!date) {
        return "-";
      }

      let newDate = date;
      if (!(newDate instanceof Date)) {
        newDate = new Date(newDate);
      }

      if (isNaN(newDate)) {
        return getPlaceHolderData(date);
      }
      if (onlyShowTime) {
        return format(newDate ?? {}, "HH:mm");
      }

      if (getDayAndMonth) {
        return format(newDate ?? {}, locale[language]?.dayAndMonth);
      }

      if (getDay) {
        return format(newDate ?? {}, locale[language]?.day);
      }

      if (getMonth) {
        return format(newDate ?? {}, locale[language]?.month);
      }

      if (getYear) {
        return format(newDate ?? {}, locale[language]?.year);
      }
      return format(newDate ?? {}, formatParams, {
        locale: locale[language]?.locale,
      });
    } catch (e) {
      return date;
    }
  }

  function autoFormatLocale(date, { onlyShowTime = false } = {}) {
    return formatLocale(
      date,
      Object.assign(getParmarsFormatDate(date) ?? {}, { onlyShowTime })
    );
  }

  return { language, locale, formatLocale, autoFormatLocale };
};
export default useLanguage;
