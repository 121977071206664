import { memo, useEffect, useState } from "react";
import CellEmpty from "src/components/table/cellEmpty";
import style from "./index.module.scss";
import customWithErrorBoundary from "src/components/errorBoundary";
import SimpleTooltip from "src/components/tooltip/simpleTooltip";
import { getkeywordImgs } from "../../../pages/keywords/service";
import ImgWrap from "src/components/imgWrap";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { linkToAmzonSearch } from "src/utils/amazon";
import Message from "src/components/message";
import { AmazonIcon } from "src/components/amazon";
import useLanguage from "src/hooksOrClass/language";
import { getFormatWeek } from "src/utils/time";
import { getPlaceHolderData } from "src/utils";
import Empty from "src/components/empty";
import classNames from "classnames";

import PlaceHolderImg from "./placeholder-image@2x.png";
let controller;
function TopImags({ rowData, time }) {
  const keyword = rowData?.keyword;
  const [imgs, setImgs] = useState([]);
  const { formatLocale } = useLanguage();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  let showTime = "";

  if (time.type?.toLocaleLowerCase() === "week") {
    showTime = getFormatWeek(time?.date);
  } else if (time.type?.toLocaleLowerCase() === "month") {
    showTime = formatLocale(time?.date, { getMonth: true });
  } else {
    showTime = t(`timeSelect.${time}`).toLocaleLowerCase();
  }

  async function getImgs() {
    if (!keyword) {
      return;
    }
    setIsLoading(true);
    controller?.abort();
    controller = new AbortController();
    const data = {
      keyword,
      max: 6,
    };
    const imgs = await getkeywordImgs({ data, signal: controller?.signal });
    setImgs(imgs || []);
    setIsLoading(false);
  }
  useEffect(() => {
    getImgs();
  }, []);

  const handleLinkToAmzSearch = () => {
    linkToAmzonSearch(keyword);
  };
  return (
    <div className={style.topImags}>
      <div className={style.title}>{t(`keywords.top6Images`)}</div>
      <div
        className={classNames(style.keywordImgsWrap, {
          [style.oneGrid]: imgs.length <= 0 && !isLoading,
        })}
      >
        {isLoading && (
          <div className="loadingWrap">
            <LoadingButton
              loading={true}
              size="large"
              loadingIndicator={<CircularProgress color="inherit" size={40} />}
            />
          </div>
        )}

        {imgs.length > 0
          ? imgs?.map((item) => {
              return (
                <div key={item}>
                  <ImgWrap src={item} />
                </div>
              );
            })
          : null}

        {!isLoading && imgs.length <= 0 && (
          <Empty
            isHorizontal={false}
            imgSrc={PlaceHolderImg}
            tips={t(`top6EmptyTip`)}
            title={t(`emptyTitle`)}
          />
        )}
      </div>

      <div className={style.tips}>
        {t(`top6Tip`, {
          keyword: getPlaceHolderData(rowData?.keyword),
          time: showTime,
        })}
      </div>
      <div className={style.btns}>
        <div className="button" onClick={handleLinkToAmzSearch}>
          <AmazonIcon size={14} />
          <span>{t(`searchOnAmazon`)}</span>
        </div>

        <CopyToClipboard
          text={rowData.keyword}
          onCopy={() => {
            Message.success(t(`copied`));
          }}
        >
          <div className="button">
            <span className="icon-copy"></span>
            <span>{t(`copyTheKeyword`)}</span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
}

const MemoTopImags = memo(TopImags);

function Keyword({ rowData, time, placement = "bottom-start" }) {
  return (
    <SimpleTooltip
      tooltipSx={{ maxWidth: "none" }}
      title={
        rowData?.keyword ? <MemoTopImags rowData={rowData} time={time} /> : null
      }
      placement={placement}
    >
      <div className={style.keyword}>
        <CellEmpty value={rowData?.keyword} />
      </div>
    </SimpleTooltip>
  );
}

export default customWithErrorBoundary(memo(Keyword));
