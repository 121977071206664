import ChangeActiveSelect from "src/components/customSelect/changeActive";
import classNames from "classnames";
import { memo, useCallback, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  defaultTableSearch,
  resetTableSearch,
  updateSortKeysActive,
  updateTableSearchKey,
} from "../../store";
import { KeywordsContext } from "../../context";

import { useTranslation } from "react-i18next";
import { changeTableSearchSort } from "src/store/rootReducer";
import formatParmars from "src/components/table/format";
import { sortByEnum } from "src/constants";
import customWithErrorBoundary from "src/components/errorBoundary";
import ClearAll from "src/components/clearAll";
import SearchInput from "src/components/searchInput";
import { all, getDefaultOption } from "src/utils/select";

import style from "src/styles/searchWrap.module.scss";
import tableStyle from "./index.module.scss";

function TableSearch() {
  const dispatch = useDispatch();
  const { tableKey } = useContext(KeywordsContext);
  const { t } = useTranslation();
  const searchText = useSelector(
    (state) => state.keywords.tableSearch?.searchText
  );
  const tableSearch = useSelector((state) => state.keywords.tableSearch);
  const tableSearchOptions = useSelector(
    (state) => state.keywords.tableSearchOptions
  );

  const { keywordsTypes, effectKeywordsTypes, trafficTypes } =
    tableSearchOptions || {};
  const { fetchTable } = useContext(KeywordsContext);

  const finalKeywordsTpye = keywordsTypes?.map((item) => ({
    ...item,
    value: item?.code,
    label: item?.name,
  }));
  const finalKeywordsEffects = effectKeywordsTypes?.map((item) => ({
    ...item,
    value: item?.code,
    label: item?.name,
  }));

  const finalShare = [
    { value: all, label: t(`keywords.trafficShare.default`) },
  ].concat(
    trafficTypes?.map((item) => ({
      ...item,
      value: item?.code,
      label: item?.name,
    }))
  );

  const inputRef = useRef();
  const handleClear = () => {
    inputRef.current.clear();
    dispatch(resetTableSearch());
    if (JSON.stringify(defaultTableSearch) !== JSON.stringify(tableSearch)) {
      fetchTable();
    }
  };

  const handleChangeType = useCallback((value) => {
    updateSearch("type", value.length > 0 ? value : [all]);
    fetchTable();
  }, []);

  const updateSearch = (key, value) => {
    dispatch(updateTableSearchKey({ key, value }));
  };
  const handleChangeEffect = useCallback((value) => {
    updateSearch("effect", value);
    fetchTable();
  }, []);

  const handleChangeShare = useCallback((value) => {
    const sortKey = value === all ? "trafficRatio" : value;

    updateSearch("share", value);
    dispatch(updateSortKeysActive(sortKey));
    dispatch(
      changeTableSearchSort(
        formatParmars(tableKey, {
          key: sortKey,
          by: sortByEnum.desc,
        })
      )
    );
    fetchTable();
  }, []);

  const onBlur = useCallback((value) => {
    dispatch(updateTableSearchKey({ key: "searchText", value }));
    fetchTable();
  }, []);

  const searchCallBack = useCallback((value) => {
    dispatch(updateTableSearchKey({ key: "searchText", value }));
    fetchTable();
  }, []);

  return (
    <form
      className={classNames(
        style.searchWrap,
        tableStyle.tableSearchWrap,
        "wrap",
        "textFont"
      )}
    >
      <ChangeActiveSelect
        multiple
        width="100%"
        onChange={handleChangeType}
        labelClassName="textFont"
        data={finalKeywordsTpye}
        defaultValue={getDefaultOption()}
        value={tableSearch.type}
        label={t(`keywords.keywordTypes.label`)}
      />
      <ChangeActiveSelect
        multiple
        width="100%"
        onChange={handleChangeEffect}
        value={tableSearch.effect}
        labelClassName="textFont"
        data={finalKeywordsEffects}
        defaultValue={getDefaultOption()}
        label={t(`keywords.keywordEffects.label`)}
      />
      <ChangeActiveSelect
        width="100%"
        labelClassName="textFont"
        onChange={handleChangeShare}
        data={finalShare}
        value={tableSearch.share}
        label={t(`keywords.trafficShare.label`)}
      />
      <div>
        <span className="stressTextFont">{t(`keywords.searchText.label`)}</span>
        <SearchInput
          ref={inputRef}
          searchText={searchText}
          onBlur={onBlur}
          searchCallBack={searchCallBack}
          placeholder={t(`keywords.searchText.placeHolder`)}
        />
      </div>

      <ClearAll onClear={handleClear} />
    </form>
  );
}

export default customWithErrorBoundary(memo(TableSearch));
