import { memo, useEffect, useMemo, useRef } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import useRegisterTableState from "src/hooksOrClass/registerTableState";
import { getAdOverviewApi } from "../../service";
import useTable from "src/hooksOrClass/table";
import FlowTable from "src/components/table";
import { getColumns } from "./column";
import { useSelector } from "react-redux";

import { getFormatWeek } from "src/utils/time";
import { useTranslation } from "react-i18next";
import style from "./index.module.scss";
import { fetchStatus } from "src/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { HoverAdOverviewContext } from "../../context";
import { useRouterId } from "src/hooksOrClass/getRouterId";

function HoverAdOverview({ data, displayCampaignId, onMouseEnter }) {
  const { encryptAdIds, date } = data;
  const [id, rootName] = useRouterId();
  const name = rootName + "_HOVER_AD_OVERVIEW_" + date + displayCampaignId;
  const { t } = useTranslation();
  useRegisterTableState({ key: name });

  const tableFetchStatus = useSelector(
    (state) => state.root?.table[name]?.tableFetchStatus
  );
  const isLoading = tableFetchStatus === fetchStatus.loading;
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const request = ({ getState, tableSearchData, tableController }) => {
    return getAdOverviewApi({
      data: {
        encryptAdIds,
      },
      signal: tableController?.signal,
    });
  };

  const columns = getColumns();

  const [fetchTable, resetRef] = useTable({ name, request });

  const providerData = useMemo(() => {
    return {
      data: {
        time: date,
        displayCampaignId,
      },
    };
  }, []);

  useEffect(() => {
    if (!tableData || Object.keys(tableData)?.length === 0) {
      fetchTable();
    }
    return () => {
      resetRef.current && resetRef.current("cancel");
    };
  }, []);

  return (
    <div onMouseEnter={onMouseEnter}>
      <div className={style.title}>
        <strong> {getFormatWeek(date)}</strong>
        <span
          dangerouslySetInnerHTML={{
            __html: t(`adStrategy.adCreated`, { count: encryptAdIds?.length }),
          }}
        ></span>
      </div>
      <div>
        <div
          className={style.tableWrap}
          style={{
            height:
              Math.min(
                encryptAdIds?.length * 42,
                encryptAdIds?.length <= 6 ? 252 : 231
              ) +
              49 +
              "px",
          }}
        >
          <HoverAdOverviewContext.Provider value={providerData}>
            <FlowTable
              columns={columns}
              translationKey="adStrategy"
              uniqId="displayAdId"
              name={name}
              showPageCompent={false}
            />
          </HoverAdOverviewContext.Provider>
          {isLoading && (
            <div className={style.loadingWrap}>
              <div className="loadingWrap">
                <LoadingButton
                  loading={true}
                  size="large"
                  loadingIndicator={
                    <CircularProgress color="inherit" size={35} />
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default customWithErrorBoundary(memo(HoverAdOverview));
