import { useRef, useEffect } from "react";
import { registerService, unregisterService } from "src/utils/table";

function useRegisterTableService({ name, request, callback, getReset }) {
  const ref = useRef();

  if (!ref.current) {
    ref.current = true;
    registerService(name, request, callback, getReset);
  }

  useEffect(() => {
    return () => {
      unregisterService(name);
    };
  }, []);
}

export default useRegisterTableService;
