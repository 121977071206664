import React, {
  memo,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import customWithErrorBoundary from "src/components/errorBoundary";
import MuiWeekRange from "../day/muiWeekRange";
import useLanguage from "src/hooksOrClass/language";
import { filterRangeTimes, weekMonthEnum } from "src/constants/time";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Onion from "src/hooksOrClass/onion";
import ChangeActiveSelect from "src/components/customSelect/changeActive";
import { isInputDateValid, parseDateByFormatParams } from "src/utils/time";
import style from "./index.module.scss";
import MonthRangePicker from "../month/range";
import { connectSymbol, formatParamsEnum } from "./constant";
import { getFormatParams } from "./util";
import { BaseMenuProps } from "src/styles/theme";

function InputRange({
  label,
  startTime: propStartTime,
  endTime: propEndTime,
  type = weekMonthEnum.weekly,
  width,
  value: propValue,
  onChange,
}) {
  const { t } = useTranslation();
  const { formatLocale } = useLanguage();
  const isWeek = type === weekMonthEnum.weekly;

  const formatParams = getFormatParams(type);

  const parseDate = parseDateByFormatParams.bind(null, formatParams);

  const formatStartDateFn = (date) => {
    if (!date) {
      return;
    }

    const fn = isWeek ? startOfWeek : startOfMonth;

    let newDate;
    if (date instanceof Date) {
      newDate = date;
    } else {
      newDate = parseDate(date);
    }
    return fn(newDate);
  };

  const formatEndDateFn = (date) => {
    if (!date) {
      return;
    }
    const fn = isWeek ? endOfWeek : endOfMonth;

    let newDate;
    if (date instanceof Date) {
      newDate = date;
    } else {
      newDate = parseDate(date);
    }
    return fn(newDate);
  };

  const startTime = formatStartDateFn(propStartTime);
  const endTime = formatEndDateFn(propEndTime);

  const startTimeStr = formatLocale(startTime, { formatParams });
  const endTimeStr = formatLocale(endTime, { formatParams });

  const inputStartId = useId();
  const inputEndId = useId();
  const inputStartRef = useRef();
  const inputEndRef = useRef();
  const muiWeekRangeRef = useRef();
  const [open, setOpen] = useState(false);

  const [selectValue, setSelectValue] = useState(propValue ?? "");

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();

  const [startErrorMessage, setStartErrorMessage] = useState("");
  const [endErrorMessage, setEndErrorMessage] = useState();
  const endErrorRef = useRef();
  const startErrorRef = useRef();

  const [inputValue, setInputValue] = useState("");
  const [inputEndValue, setInputEndValue] = useState("");

  const [quickSelectCode, setQuickSelectCode] = useState();

  const vaild = (date) => {
    const onion = new Onion();

    if (!date) {
      return Promise.resolve();
    }

    onion.use(async (_, next) => {
      if (isInputDateValid(date, formatParams)) {
        await next();
      } else {
        return Promise.reject(t(`dateErrorVaild.dateError`));
      }
    });

    onion.use(async (_, next) => {
      if (isBefore(parseDate(date), startTime)) {
        return Promise.reject(
          t(`dateErrorVaild.beforeEarliest`, {
            time: startTimeStr,
          })
        );
      } else {
        await next();
      }
    });

    onion.use(async (_, next) => {
      if (isAfter(parseDate(date), endTime)) {
        return Promise.reject(
          t(`dateErrorVaild.latestTime`, {
            time: endTimeStr,
          })
        );
      } else {
        await next();
      }
    });

    onion.use(async (_, next) => {
      try {
        const startValue = inputStartRef.current?.value;
        const endValue = inputEndRef.current?.value;
        if (
          isInputDateValid(startValue, formatParams) &&
          isInputDateValid(endValue, formatParams) &&
          isBefore(parseDate(endValue), parseDate(startValue))
        ) {
          return Promise.reject({
            type: "queueError",
            reason: t(`dateErrorVaild.endLaterStart`),
          });
        }
      } catch (e) {}
    });

    return onion.dispatch();
  };

  const handleStartInputChange = async (
    e,
    needGoToMonth = true,
    { again = false } = {}
  ) => {
    try {
      const value = e?.target?.value ?? inputStartRef.current?.value;
      if (value === inputValue && !again) {
        return;
      }
      setInputValue(value);
      setQuickSelectCode();

      vaild(value)
        .then(() => {
          const date = value ? formatStartDateFn(value) : undefined;
          setDateStart(date);
          setStartErrorMessage();
          if (endErrorRef.current?.type) {
            handleEndBlur(true);
          }
          startErrorRef.current = false;
          needGoToMonth && date && muiWeekRangeRef.current?.setMonth(date);
        })
        .catch((reason) => {
          startErrorRef.current = reason;

          if (reason?.type) {
            setStartErrorMessage();
            setEndErrorMessage(reason);
            return;
          }
          setStartErrorMessage(reason);
        });
    } catch (e) {}
  };

  const handleEndInputChange = (
    e,
    needGoToMonth = true,
    { again = false } = {}
  ) => {
    try {
      const value = e?.target?.value ?? inputEndRef.current?.value;

      if (value === inputEndValue && !again) {
        return;
      }
      setInputEndValue(value);
      setQuickSelectCode();

      vaild(value)
        .then(() => {
          const date = value ? formatEndDateFn(value) : undefined;

          setDateEnd(date);
          setEndErrorMessage();
          endErrorRef.current = false;
          if (startErrorRef.current?.type) {
            handleStartBlur(true);
          }
          needGoToMonth &&
            date &&
            muiWeekRangeRef.current?.setMonth(
              isWeek ? subMonths(date, 1) : date
            );
        })
        .catch((reason) => {
          endErrorRef.current = reason;

          setEndErrorMessage(reason);
        });
    } catch (e) {}
  };

  const handleChange = (date, needGoToMonth = false) => {
    if (!date) {
      return;
    }

    handleStartInputChange(
      {
        target: {
          value: date?.from
            ? formatLocale(formatStartDateFn(date?.from), { formatParams })
            : "",
        },
      },
      needGoToMonth
    );

    handleEndInputChange(
      {
        target: {
          value: date?.to
            ? formatLocale(formatEndDateFn(date?.to), { formatParams })
            : "",
        },
      },
      needGoToMonth
    );

    setQuickSelectCode();
  };

  const value = useMemo(() => {
    return { from: dateStart, to: dateEnd };
  }, [dateStart, dateEnd]);

  const quickSelectTime = (item) => {
    try {
      if (!item || !item?.code) {
        return;
      }

      const value = item.filter();

      value?.end &&
        muiWeekRangeRef.current?.setMonth(
          subMonths(formatEndDateFn(value?.end), 1)
        );

      handleChange({
        from: value?.start,
        to: value?.end,
      });
      setQuickSelectCode(item.code);
    } catch (e) {}
  };

  const handleSubmit = () => {
    if (!startErrorMessage && !endErrorMessage) {
      setOpen(false);
      setSelectValue(
        `${inputStartRef.current?.value} ${connectSymbol} ${inputEndRef.current?.value}`
      );
    }
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = (e) => {
    if (e.target?.classList?.contains("MuiBackdrop-root")) {
      setOpen(false);
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const handleResetValue = () => {
    setSelectValue("");
  };

  const [start, end] = selectValue?.split(connectSymbol) || [];
  const sync = () => {
    handleChange({
      from: start?.trim(),
      to: end?.trim(),
    });
  };

  const handleStartBlur = (again) => {
    try {
      const value = inputStartRef.current?.value;

      vaild(value).then(() => {
        handleStartInputChange(
          {
            target: {
              value: value
                ? formatLocale(formatStartDateFn(value), {
                    formatParams,
                  })
                : "",
            },
          },
          false,
          { again }
        );
      });
    } catch (e) {}
  };

  const handleEndBlur = (again) => {
    try {
      const value = inputEndRef.current?.value;
      vaild(value).then(() => {
        handleEndInputChange(
          {
            target: {
              value: value
                ? formatLocale(formatEndDateFn(value), {
                    formatParams,
                  })
                : "",
            },
          },
          false,
          { again }
        );
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (open) {
      sync();
    }
  }, [open]);

  useEffect(() => {
    if (propValue !== selectValue) {
      setSelectValue(propValue);
    }
  }, [propValue]);

  useEffect(() => {
    if (propValue !== selectValue) {
      onChange && onChange(selectValue);
    }
  }, [selectValue]);

  return (
    <div className={style.selectWrap}>
      <ChangeActiveSelect
        defaultValue=""
        label={label}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        displayEmpty
        value={selectValue}
        width={width || 263}
        beforeIcon={<span className="icon-calendar"></span>}
        renderValue={(value) => {
          if (!value) {
            return (
              <span
                style={{ color: "var(--tip-text-color)" }}
              >{`${formatParams} ${connectSymbol} ${formatParams}`}</span>
            );
          }
          return <span title={value}>{value}</span>;
        }}
        MenuProps={{
          ...BaseMenuProps,
          classes: {
            paper: style.menuNoPadding, // 自定义类名
          },
        }}
      >
        <div className={classNames(style.inputRangeWrap, "textFont")}>
          <div className={style.left}>
            <div className={classNames(style.title, "stressListFont")}>
              {t(`preset`)}
            </div>
            {filterRangeTimes(startTime, endTime)?.map((item) => {
              return (
                <div
                  key={item.code}
                  onClick={() => quickSelectTime(item)}
                  className={classNames(style.quickOption, {
                    [style.active]: item.code === quickSelectCode,
                  })}
                >
                  {t(`timeSelect.${item.code}`)}
                </div>
              );
            })}
          </div>
          <div className={style.right}>
            <div className={style.inputWrap}>
              <div>
                <div className={classNames(style.title, "stressListFont")}>
                  <label htmlFor={inputStartId}>{t(`startDate`)}</label>
                </div>

                <input
                  id={inputStartId}
                  type="text"
                  value={inputValue}
                  ref={inputStartRef}
                  className={classNames({
                    [style.error]: startErrorMessage,
                  })}
                  onBlur={handleStartBlur}
                  placeholder={formatParams}
                  onChange={handleStartInputChange}
                  autoComplete="off"
                />
                <div className={classNames(style.tip, "helperFont")}>
                  {startErrorMessage && (
                    <>
                      <span className="icon-tip"></span>
                      <span>{startErrorMessage}</span>
                    </>
                  )}
                </div>
              </div>

              <div style={{ marginLeft: "40px" }}>
                <div className={classNames(style.title, "stressListFont")}>
                  <label htmlFor={inputEndId}>{t(`endDate`)}</label>
                </div>
                <input
                  id={inputEndId}
                  type="text"
                  value={inputEndValue}
                  ref={inputEndRef}
                  onBlur={handleEndBlur}
                  className={classNames({
                    [style.error]: endErrorMessage,
                  })}
                  placeholder={formatParams}
                  onChange={handleEndInputChange}
                  autoComplete="off"
                />
                <div className={classNames(style.tip, "helperFont")}>
                  {endErrorMessage && (
                    <>
                      <span className="icon-tip"></span>
                      <span>{endErrorMessage?.reason ?? endErrorMessage}</span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className={style.rangePicker}>
              {isWeek ? (
                <MuiWeekRange
                  value={value}
                  startTime={startTime}
                  endTime={endTime}
                  onChange={handleChange}
                  ref={muiWeekRangeRef}
                />
              ) : (
                <MonthRangePicker
                  onChange={handleChange}
                  inputValue={parseDate((end ?? start)?.trim())}
                  value={value}
                  startTime={startTime}
                  endTime={endTime}
                  ref={muiWeekRangeRef}
                />
              )}
            </div>

            <div className={style.buttonWrap}>
              <span className={style.cancel} onClick={onCancel}>
                {t(`button.cancel`)}
              </span>
              <span
                className={classNames(style.apply, "button", {
                  disabled:
                    !dateStart ||
                    !dateEnd ||
                    startErrorMessage ||
                    endErrorMessage,
                })}
                onClick={handleSubmit}
              >
                {t(`button.apply`)}
              </span>
            </div>
          </div>
        </div>
      </ChangeActiveSelect>
      {selectValue && (
        <div className={style.iconWrap}>
          <span className="icon-delete" onClick={handleResetValue}></span>
        </div>
      )}
    </div>
  );
}

export default customWithErrorBoundary(memo(InputRange));
