import React, { ReactNode } from "react";
import customWithErrorBoundary from "../errorBoundary";
import PlaceHolderImg from './placeholder-image.png';
import style from './index.module.scss';
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { fetchStatus } from "src/constants";

interface Props {
    data: any;
    children?: React.ReactElement | ReactNode;
    tips?: string | ReactNode | React.ReactElement;
    status: typeof fetchStatus[keyof typeof fetchStatus];
    className?: string;
    isHorizontal: Boolean;
    title?: string;
    imgSrc?: any;
}

function Empty({ data = [], tips, children, status = fetchStatus.initial, isHorizontal = true, className = "", imgSrc, title }: Props) {
    const { t } = useTranslation();

    let empty = false;

    if (data === undefined || data === null || (Array.isArray(data) && data?.length === 0)) {
        empty = true;
    }
    if (Object.prototype.toString.call(data) === '[object Object]' && (Object.keys(data)?.length === 0 || !data.total)) {
        empty = true;
    }

    if (empty && status !== fetchStatus.loading) {
        return <div className={classNames(style.wrap, className, {
            [style.vertical]: !isHorizontal
        })}>
            <div className={classNames(style.img, "emptyImg")} > <img src={imgSrc || PlaceHolderImg} alt="" /></div>
            <div>
                <div className={classNames(style.title, "emptyTitleFont")}>{title || t("empty")}</div>
                <div className={classNames(style.tips, "emptyTextFont")}>{tips}</div>
            </div>
        </div>
    }

    return children || null;
}

export default customWithErrorBoundary(Empty);