import { memo, useContext } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { position } from "src/constants";
import ChartWrap from "src/components/chart/wrap";
import style from "./index.module.scss";
import { KeywordsContext } from "../../context";

import useLanguage from "src/hooksOrClass/language";
import { useSelector } from "react-redux";
import { dataEmpty } from "src/utils";
function TrendRank({ rowData }) {
  const { t } = useTranslation();
  const { tableKey: name } = useContext(KeywordsContext);
  const tableData = useSelector((state) => state.root?.table[name]?.tableData);

  const { autoFormatLocale } = useLanguage();
  const { openRankDrawer } = useContext(KeywordsContext);

  const options = {
    maintainAspectRatio: false,

    responsive: false,
    aspectRatio: 155 / 101,
    animation: false,
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
        position: "nearest",
      },
      hoverCrosshair: {
        position: position.vertical,
        tips: `* ${t("clickViewHisData")}`,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        reverse: true,
      },
    },
    layout: {
      padding: {
        bottom: 10,
      },
    },
  };

  const ogRankData = rowData?.ogSpHistory?.map((item) => item?.ogRank ?? null);
  const spRankData = rowData?.ogSpHistory?.map((item) => item?.spRank ?? null);

  if (dataEmpty(ogRankData) && dataEmpty(spRankData)) {
    return (
      <div style={{ height: "45px" }} className="center">
        -
      </div>
    );
  }

  const lineData = {
    labels: tableData?.ogSpRankDates?.map((item) => autoFormatLocale(item)),

    datasets: [
      {
        data: ogRankData,
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        label: t(`keywords.tableColumn.organicRank`),
        borderColor: "#222222",
      },
      {
        data: spRankData,
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 3,
        pointHoverRadius: 5,
        label: t(`keywords.tableColumn.spAdRank`),
        pointStyle: "triangle",
        borderColor: "#222222",
        showLine: false,
      },
    ],
  };

  return (
    <>
      <ChartWrap
        onClick={() => openRankDrawer(rowData)}
        className={style.pointer}
      >
        <Line options={options} data={lineData} width={100} height={45} />
      </ChartWrap>
    </>
  );
}

export default memo(TrendRank);
