import { useEffect } from "react";

export function useSyncHeadFixedPositionAndStyle(
  deps = [],
  hasFoldRef = {},
  { containerId, fixedHeadId, headId, needExtraHead } = {}
) {
  useEffect(() => {
    if (!needExtraHead) {
      return;
    }
    let timer;

    const tableHead = document.getElementById(headId);
    const tableHeadFixed = document.getElementById(fixedHeadId);
    const headContent = tableHeadFixed.firstChild;

    const tableContainer = document.getElementById(containerId);

    const handleScroll = () => {
      if (!hasFoldRef.current) {
        return;
      }

      headContent.style.width =
        tableContainer.querySelector("#table").offsetWidth + "px";
      const res = tableHead.getBoundingClientRect();
      const tableContainerRes = tableContainer.getBoundingClientRect();

      tableHeadFixed.style.left = tableContainerRes.left + "px";
      tableHeadFixed.style.right =
        window.innerWidth - tableContainerRes.right + "px";

      if (
        res.top <= 0 &&
        tableContainerRes.top + tableContainerRes.height > 0
      ) {
        tableHeadFixed.style.zIndex = 997;
        tableHeadFixed.style.opacity = 1;
      } else {
        tableHeadFixed.style.zIndex = -2;
        tableHeadFixed.style.opacity = 0;
      }
    };

    handleScroll();

    timer = setTimeout(function () {
      handleScroll();
    }, 800);

    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("resize", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      clearTimeout(timer);
    };
  }, deps);
}
