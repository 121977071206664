import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { weekMonthEnum } from "src/constants/time";

import { asinsKey, periodKey } from "src/constants/form";
import { chartData } from "./constant";

const initialState = {
  baseSearch: {
    [periodKey]: weekMonthEnum.weekly,
    [asinsKey]: "",
  },
  chartData: chartData.data,
};

export const HistorySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetStore: (state, action) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    updateBasePeriod: (state, action) => {
      state.baseSearch[periodKey] = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { resetStore, updateBasePeriod } = HistorySlice.actions;

export default HistorySlice.reducer;
