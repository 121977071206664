import { memo } from "react";
import { getActionOption, getOption } from "../../util";
import CommonAction from "src/components/table/components/action";

function Action({ rowData }) {
  const options = getActionOption({ rowData });
  return (
    <CommonAction options={options}>
      <span className="icon-keyword-library">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    </CommonAction>
  );
}

export default memo(Action);
