import { id } from "date-fns/locale";
import { memo } from "react";
import { Line } from "react-chartjs-2";
import customWithErrorBoundary from "src/components/errorBoundary";
import { chartColors } from "src/utils/chart/util";
import { getOption } from "../../util";
import { useDispatch, useSelector } from "react-redux";
import { autoSetTrafficTime, updateTrafficTime } from "../../store";
import { endOfWeek, format, formatRFC3339 } from "date-fns";
import useLanguage from "src/hooksOrClass/language";
import {
  getFormatParams,
  getServiceDateStartAndEnd,
} from "src/components/picker/inputRange/util";
import { connectSymbol } from "src/components/picker/inputRange/constant";
import { periodKey } from "src/constants/form";
import { findClosestIndex } from "src/utils/chart/plugin/util";

const getOptions = (onClick) => ({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false, // 禁用默认的图例
    },
    htmlLegend: {
      containerID: "legend-container", // 自定义图例容器的ID
    },
    tooltip: {
      enabled: false,
      position: "nearest",
    },
  },
  onClick,
  scales: {
    x: {
      display: false,
    },

    y: {
      beginAtZero: true,
      display: false,
    },
  },
});
function ActionViewChart({ data, dates }) {
  const dispatch = useDispatch();

  const clickTime = (event) => {
    const index = findClosestIndex(
      event.chart.corsair.datasetPosition,
      event.x
    );

    if (index >= 0 && index < dates.length) {
      const date = dates[index];

      dispatch(autoSetTrafficTime({ startDate: date }));
    }
  };

  const chartData = {
    labels: dates,

    datasets: [
      {
        label: "得分",
        data: data,
        normalized: true,
        borderColor: chartColors[1],
        fill: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1.5,
        originPointRadius: 1.5,
      },
    ],
  };
  const options = getOptions(clickTime);
  return <Line data={chartData} options={options} />;
}

export default customWithErrorBoundary(memo(ActionViewChart));
