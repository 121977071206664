import { useTranslation } from "react-i18next";
import { memo } from "react";

import style from "../index.module.scss";
import { Alert } from "@mui/material";
import { useSyncPostion } from "../hooks/useInputPosition";
import customWithErrorBoundary from "src/components/errorBoundary";

function AsinTip({ tipAsins, onClose }) {
  const { t } = useTranslation();
  useSyncPostion(`.${style.tipWrap}`, "#tip-placeHolder");

  return (
    <div className={style.tipWrap}>
      <div id="tip-placeHolder"></div>
      <Alert severity="info" onClose={onClose}>
        {t(`asinInputTip.content`, {
          asins: tipAsins,
        })}
      </Alert>
      {/* </div> */}
    </div>
  );
}

export default customWithErrorBoundary(memo(AsinTip));
