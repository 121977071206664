import { Fragment, useRef, useEffect, memo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import classNames from "classnames";

import { Tooltip } from "@mui/material";
import Message from "src/components/message";
import LinkToAmazonCom from "src/components/amazon";
import CellEmpty from "src/components/table/cellEmpty";
import { formatNumber } from "src/utils";

import style from "./index.module.scss";
import customWithErrorBoundary from "src/components/errorBoundary";
import { useTranslation } from "react-i18next";
import AsinHover from "src/components/table/components/asinHover";

function AsinInfo({ row, hover = false, showSingleAsinStyle }) {
  const { t } = useTranslation();

  return (
    <div className={style.asinInfoWrap}>
      <AsinHover rowData={row} hover={hover} />

      <Tooltip
        className={classNames(style.asinTitle, {
          [style.lineTwo]: showSingleAsinStyle,
        })}
        title={row.title}
      >
        <div>
          <CellEmpty value={row.title} />
        </div>
      </Tooltip>
      {showSingleAsinStyle && (
        <div className={style.rating}>
          <span>
            <CellEmpty value={formatNumber(row.reviewCnt)} />(
            <CellEmpty value={row.score} />) {t(`ratings`)}
          </span>
        </div>
      )}
    </div>
  );
}

export default customWithErrorBoundary(memo(AsinInfo));
