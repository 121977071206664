import { memo, useContext } from "react";
import { useDispatch } from "react-redux";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import {
  updateDetailEncryptAdIdInfo,
  updateDetailSelectAdValue,
  updateDrawModalOpen,
} from "../../store";
import style from "./index.module.scss";
import { HoverAdOverviewContext } from "../../context";
function ClickViewDetail({ rowData, value }) {
  const dispatch = useDispatch();
  const { data } = useContext(HoverAdOverviewContext);

  const handleViewDetail = () => {
    try {
      dispatch(updateDrawModalOpen(true));
      const detailInfo = {
        ...(rowData || {}),
        ...(data || {}),
        keyword: "",
      };
      dispatch(
        updateDetailSelectAdValue({
          value: `${detailInfo.fakeAdId}-${detailInfo.asin}`,
          info: detailInfo,
        })
      );
      dispatch(updateDetailEncryptAdIdInfo(detailInfo));
    } catch (e) {}
  };

  return (
    <div onClick={handleViewDetail} className={style.linkCursor}>
      <CellEmpty value={value} />
    </div>
  );
}

export default customWithErrorBoundary(memo(ClickViewDetail));
