import { memo } from "react";
import { Line } from "react-chartjs-2";
import customWithErrorBoundary from "src/components/errorBoundary";
import ChartWrap from "src/components/chart/wrap";
import { position } from "src/constants";
import variables from "src/styles/_chart.scss";

import { formatSalesCount } from "src/utils";
import { useTranslation } from "react-i18next";
import useLanguage from "src/hooksOrClass/language";
import { differenceInDays, startOfMonth } from "date-fns";

function SalesChart({
  rowData,
  dateKey = "boughtMonthlyHistoryDate",
  dataKey = "boughtMonthlyHistory",
  propDate,
}) {
  const { t } = useTranslation();
  const { autoFormatLocale } = useLanguage();
  let max = 0;

  if (Array.isArray(rowData?.[dataKey])) {
    // 使用扩展运算符(...)将数组中的元素作为参数传递给Math.max()函数
    max = Math.max(...rowData?.[dataKey]);
  }
  let extraOption = !rowData?.[dataKey]?.length
    ? {
        y: {
          min: 0,
          max: 50,
          grid: {
            display: false,
          },

          border: {},
        },
        x: {
          border: {},
        },
      }
    : {};

  const options = {
    maintainAspectRatio: false,

    responsive: false,
    aspectRatio: 155 / 101,
    animation: false,
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
        position: "nearest",
      },
      hoverCrosshair: {
        position: position.vertical,
        format: formatSalesCount,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
        ...extraOption.x,
      },

      y: {
        min: max < 500 ? 0 : undefined,
        ticks: {
          stepSize: 50,
        },
        border: {
          display: false,
          dash: [4, 4],
        },
        ...extraOption.y,
      },
    },
  };

  let pointRadius = 1;
  const dates = rowData?.[dateKey];

  if (propDate) {
    pointRadius = new Array(dates?.length ?? 0).fill(1);
    dates?.forEach((element, idx) => {
      const diffence = differenceInDays(
        startOfMonth(element),
        startOfMonth(propDate)
      );
      if (diffence === 0) {
        pointRadius[idx] = 2.5;
      }
    });
  }

  const lineData = {
    labels: dates?.map((item) => autoFormatLocale(item)),

    datasets: [
      {
        data: rowData?.[dataKey],
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        originPointRadius: pointRadius,
        pointRadius: pointRadius,
        label: t(`sales.chart.monthSales`),
        borderColor: variables.lineChart,
      },
    ],
  };

  return (
    <ChartWrap>
      <Line options={options} data={lineData} height={101} width={155} />
    </ChartWrap>
  );
}

export default customWithErrorBoundary(memo(SalesChart));
