import { InputAdornment, OutlinedInput } from "@mui/material";
import classNames from "classnames";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

const SearchInput = forwardRef((props, ref) => {
  const { placeholder, onBlur, searchCallBack, searchText } = props;
  const searchInputRef = useRef();
  const [hasContent, setHasContent] = useState(false);
  const [focused, setIsFocused] = useState(false);

  useEffect(() => {
    if (searchInputRef.current) {
      // 如果输入框有值，则title为value，否则为placeholder
      const titleValue = searchText ? searchText : placeholder;
      searchInputRef.current.setAttribute("title", titleValue);
    }
  }, [placeholder, searchText]);

  useEffect(() => {
    if (
      searchInputRef?.current &&
      searchInputRef?.current?.value !== searchText
    ) {
      searchInputRef.current.value = searchText;
      setHasContent(searchText && searchText.length > 0);
    }
  }, [searchText]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  useImperativeHandle(ref, () => ({
    clear: () => {
      searchInputRef.current.value = "";
      setHasContent(false);
    },
  }));

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      const value = searchInputRef?.current?.value;
      searchInputRef.current?.blur();

      searchCallBack && searchCallBack(value);
    }
  };
  return (
    <OutlinedInput
      autoComplete="off"
      onKeyDown={handleSearch}
      inputRef={searchInputRef}
      startAdornment={
        <InputAdornment position="start">
          <span
            className={classNames("icon-search", {
              "icon-search-active": focused || hasContent,
            })}
          ></span>
        </InputAdornment>
      }
      sx={{
        "& .MuiOutlinedInput-input": {
          padding: "0 10px 0  0",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor:
            focused || hasContent
              ? "var(--color) !important"
              : "var(--secondary) !important",
          borderWidth: "1px !important",
        },
      }}
      onFocus={handleFocus}
      onBlur={() => {
        setHasContent(
          searchInputRef.current?.value &&
            searchInputRef.current?.value?.length > 0
        );

        const value = searchInputRef?.current?.value;
        onBlur && onBlur(value);

        setTimeout(() => {
          setIsFocused(false);
        }, 200);
      }}
      placeholder={placeholder}
    />
  );
});

export default SearchInput;
