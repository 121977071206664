import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomDrawer from "src/components/customDrawer";
import customWithErrorBoundary from "src/components/errorBoundary";
import {
  getAdHistoryThunk,
  getSelectOptionThunk,
  resetDetailInfo,
  updateDetailEncryptAdInfoTime,
  updateDrawModalOpen,
} from "../../store";
import Scrollbars from "react-scrollbars-custom";
import { detailOverId } from "../../constant";
import BubbleChart from "../bubbleChart";
import classNames from "classnames";
import commonStyle from "../../index.module.scss";
import variables from "../../_var.scss";
import { useTranslation } from "react-i18next";
import { getFormatWeek } from "src/utils/time";
import style from "./index.module.scss";
import ImgWrap from "src/components/imgWrap";
import DetailModalList from "../detailModalList";
import BoxLoading from "src/components/loading/box";
import { getStorageValue, isLoading } from "src/utils";
import { useLocalStorage } from "src/hooksOrClass/localstorge";
import { storageViewAdModalKey } from "src/constants";
import SelectAd from "../selectAd";

function DrawDetailModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref = useRef();
  const timerRef = useRef();

  const firstTimerRef = useRef();
  const [num, setNum] = useLocalStorage(storageViewAdModalKey, 0);
  const [first, setFirst] = useState(false);

  //   detailEncryptAdInfo: {
  //     displayAdId: "ACAB",
  //     encryptAdId: "-zNq4_5NhzgtSsc9oyShL3jdWls3",
  //     asin: "B07D3DDJ4L",
  //     targetKeyword: "TODO",
  //     matchType: "TODO",
  //     keywordCount: 154,
  //     time: "2022-07-31",
  //     displayCampaignId: "E3B4",
  //     keyword: "",
  //   },

  const drawModalOpen = useSelector((state) => state.adStrategy.drawModalOpen);
  const detailEncryptAdInfo = useSelector(
    (state) => state.adStrategy.detailEncryptAdInfo
  );

  const { displayAdId, time, encryptAdId } = detailEncryptAdInfo || {};
  const adHistoryData = useSelector((state) => state.adStrategy.adHistoryData);
  const adHistoryController = useSelector(
    (state) => state.adStrategy.adHistoryController
  );

  const hasValueDates = adHistoryData?.wordCounts
    ?.map((item, idx) => {
      if (item) {
        return adHistoryData?.dates[idx];
      }
      return undefined;
    })
    ?.filter((item) => item);

  const adHistoryFetchStatus = useSelector(
    (state) => state.adStrategy.adHistoryFetchStatus
  );

  const currentIndex = hasValueDates?.findIndex((item) => item === time);
  const handleCloseRankDrawer = () => {
    dispatch(updateDrawModalOpen(false));
    reset();
  };

  const reset = () => {
    adHistoryController?.abort && adHistoryController.abort();
    dispatch(resetDetailInfo());
    clearTimeout(firstTimerRef.current);
    firstTimerRef.current = null;
  };

  useEffect(() => {
    if (drawModalOpen) {
      dispatch(getSelectOptionThunk());
    }
  }, [drawModalOpen]);

  useEffect(() => {
    if (drawModalOpen) {
      dispatch(getAdHistoryThunk());
    }
  }, [encryptAdId, drawModalOpen]);

  const jump = (time) => {
    clearTimeout(timerRef.current);

    const { clientWidth } = ref.current?.scrollValues || {};
    const idx = adHistoryData?.dates?.findIndex((item) => item === time);
    const elementPosition = idx * parseInt(variables.baseBubbleWrapSize);
    ref.current?.scrollTo(Math.max(elementPosition - clientWidth / 2, 0));
  };

  useEffect(() => {
    if (adHistoryData?.wordCounts && !firstTimerRef.current && num < 3) {
      setFirst(true);
      firstTimerRef.current = setTimeout(() => {
        setFirst(false);
      }, 5000);

      setNum(num + 1);
    }

    return () => {
      clearTimeout(firstTimerRef.current);
    };
  }, [adHistoryData]);

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const handleChangePrevious = useCallback(() => {
    const index = Math.max(currentIndex - 1, 0);
    if (hasValueDates[index] === time) {
      return;
    }
    dispatch(updateDetailEncryptAdInfoTime(hasValueDates[index]));

    jump(hasValueDates[index]);
  }, [hasValueDates, currentIndex]);

  const handleChangeNext = useCallback(() => {
    const index = Math.min(currentIndex + 1, hasValueDates?.length - 1);
    if (hasValueDates[index] === time) {
      return;
    }
    dispatch(updateDetailEncryptAdInfoTime(hasValueDates[index]));
    jump(hasValueDates[index]);
  }, [hasValueDates, currentIndex]);

  const title = useMemo(() => {
    return (
      <>
        <span className={style.title}> {t(`adStrategy.productAdTitle`)}</span>
        <SelectAd />
      </>
    );
  }, []);

  const handleCircleClick = useCallback((date) => {
    dispatch(updateDetailEncryptAdInfoTime(date));
  }, []);

  return (
    <CustomDrawer
      open={drawModalOpen}
      title={title}
      titleClassName={style.center}
      contentClassName={classNames(style.adDrawer, {
        [commonStyle.first]: first,
        [commonStyle.notFirst]: !first,
      })}
      onClose={handleCloseRankDrawer}
    >
      {drawModalOpen && (
        <>
          <BoxLoading isLoading={isLoading(adHistoryFetchStatus)} isNotTop>
            <div className={classNames(style.barWrap)}>
              <div className={commonStyle.firstDashedLine}></div>
              {!isLoading(adHistoryFetchStatus) && (
                <div className={style.allLine}></div>
              )}

              <div></div>
              <div style={{ height: variables.height }}>
                <Scrollbars
                  noScrollY
                  style={{ overflow: "auto hidden" }}
                  id={detailOverId}
                  ref={ref}
                >
                  <BubbleChart
                    data={adHistoryData}
                    displayAdId={displayAdId}
                    activeDate={detailEncryptAdInfo.time}
                    onCircleClick={handleCircleClick}
                  />
                </Scrollbars>
              </div>
            </div>
          </BoxLoading>

          <div className={style.loadingWrap}>
            <DetailModalList
              onChangePrevious={handleChangePrevious}
              onChangeNext={handleChangeNext}
            />
          </div>
        </>
      )}
    </CustomDrawer>
  );
}

export default customWithErrorBoundary(memo(DrawDetailModal));
