/**
 * -1: 服务器内部异常，出现未知的错误
 * 10001: 用户未登录
 * 10002: 用户登录名/密码错误
 * 10003: 帐号被禁用
 * 20001: 权限错误，没有授权的操作
 */

export const vaildateResponseCode = (code) => {
  return code === 1;
};

export const vaildateResponseStatus = (response) => {
  const { status } = response;

  return Math.floor(status) >= 200 && Math.floor(status) < 300;
};
