import { memo } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import CellEmpty from "src/components/table/cellEmpty";
import { getPercentage } from "src/utils";

function AsinOption({ rowData, asin }) {
  const data = rowData.asins?.find((item) => item.asin === asin);
  return (
    <div>
      <CellEmpty value={getPercentage(data.ratioScore)} />
    </div>
  );
}

export default customWithErrorBoundary(memo(AsinOption));
