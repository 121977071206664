import React, { ReactNode } from "react";

import { Transition } from "react-transition-group";
import { useRef } from "react";

interface Props {
  // 是否进入
  in: boolean;
  children: ReactNode;
  // 单位是毫秒
  duration: number;
  transitionStyles: any;
  style: any;
}

function Fade({ in: inProp, children, duration, transitionStyles = {}, style = {} }: Props) {
  const nodeRef = useRef(null);
  duration = duration || Math.max(Math.min(window.innerWidth / 100 * 30, 500), 100);

  const defaultStyle = {
    transition: `width ${duration}ms ease-in-out`,
    ...style
  };

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={duration}>
      {(state) => {

        return <div
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      }}
    </Transition>
  );
}

export default Fade;
