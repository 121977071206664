import buildRequest from "src/request/axios";
import { all } from "src/utils/select";
import { formatDataTime } from "src/utils/time";

function formatTableParams(state, tableSearchData) {
  const tableSearch = state?.tableSearch || {};
  const { asins: asin, time } = state?.baseSearch || {};
  const data = {
    keyword: tableSearch.searchText,
    keywordsTypes: tableSearch.type.filter((item) => item !== all),
    listing: state.isListingSearch,
    asin,
    time,
    effectKeywordsTypes: tableSearch.effect.filter((item) => item !== all),
    ...tableSearchData,
  };

  return formatDataTime(data);
}

export const getTableFetchData = async ({
  state,
  signal,
  tableSearchData = {},
} = {}) => {
  const url = `/api/web/traffic/keywords`;
  const data = formatTableParams(state, tableSearchData);

  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downloadKeywordsTableData = async (state, tableSearchData) => {
  const url = `/api/web/export/submit/traffic_keywords`;
  const data = formatTableParams(state, tableSearchData);
  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      showErrorMessage: true,
    },
  });

  return response;
};

function formatChartParams(state) {
  const parmasData = {
    asin: state.baseSearch.asins,
    isListingSearch: state.isListingSearch,
    time: state.baseSearch.time,
  };

  return formatDataTime(parmasData);
}
export const getChartData = async (state, signal) => {
  const url = `/api/web/traffic/keywords/overview`;
  const newData = formatChartParams(state);

  const response = await buildRequest({
    url,
    method: "post",
    data: newData,
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const downloadKeywordsChartData = async (state) => {
  const url = "/api/web/export/submit/traffic_keywords_overview";
  const data = formatChartParams(state);

  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      showErrorMessage: true,
    },
  });

  return response;
};

export const getRankTrendData = async ({ data, signal } = {}) => {
  const url = `/api/web/traffic/keyword/rank/trend`;
  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });
  return response;
};

export const getkeywordImgs = async ({ data, signal } = {}) => {
  const url = `/api/web/keyword/asins_img`;
  const response = await buildRequest({
    url,
    method: "post",
    data,
    extraConfig: {
      signal,
    },
  });

  return response;
};

export const getTableSelectOptionsApi = async () => {
  const res = await buildRequest({
    url: "/api/web/traffic/keywords/options",
    extraConfig: {
      __retryCount: 2,
    },
  });

  return res;
};
