import { memo, useCallback, useContext } from "react";
import { Chart } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ChartWrap from "src/components/chart/wrap";
import customWithErrorBoundary from "src/components/errorBoundary";
import { position } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { getFormatWeek } from "src/utils/time";
import { dataEmpty } from "src/utils";
import {
  updateActiveData,
  updateVolumeDrawerOpen,
} from "src/store/rootReducer";

import style from "./index.module.scss";

function TrendVolumn({
  rowData,
  needClick = true,
  needTips = true,
  labels,
  searchVolumes,
  ranks,
  width = 100,
  height = 104,
  name,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleClick = useCallback(() => {
    if (!needClick) {
      return;
    }
    dispatch(updateActiveData(rowData));
    dispatch(updateVolumeDrawerOpen(true));
  }, []);

  const tableData = useSelector((state) => state.root?.table[name]?.tableData);
  const options = {
    maintainAspectRatio: false,

    responsive: false,
    aspectRatio: 155 / 101,
    animation: false,
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
        position: "nearest",
      },
      hoverCrosshair: {
        formatTitle: (date) => {
          return getFormatWeek(date);
        },
        position: position.vertical,
        tips: needTips ? `* ${t("clickViewHisData")}` : "",
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
      y1: {
        display: false,
        reverse: true,
        min: 0,
      },
    },
    layout: {
      padding: {
        bottom: 18,
      },
    },
  };

  const volumesData =
    searchVolumes ||
    rowData?.abaHistory?.map((item) => item?.searchVolume ?? null);
  const ranksData =
    ranks || rowData?.abaHistory?.map((item) => item?.rank ?? null);

  if (dataEmpty(volumesData) && dataEmpty(ranksData)) {
    return (
      <div style={{ height: "104px" }} className="center">
        -
      </div>
    );
  }

  const lineData = {
    labels: labels || tableData.abaHistoryDates,

    datasets: [
      {
        type: "bar",
        data: volumesData,
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        label: t(`keywords.tableColumn.searchVolume`),
        backgroundColor: "rgba(34,34,34,0.1)",
      },
      {
        data: ranksData,
        type: "line",
        fill: false,
        normalized: true,
        borderWidth: 1,
        tension: 0.3,
        pointBackgroundColor: "white",
        pointRadius: 1,
        label: t(`keywords.tableColumn.abaRank`),
        borderColor: "#222222",
        yAxisID: "y1",
      },
    ],
  };

  return (
    <ChartWrap
      onClick={handleClick}
      className={classNames({
        [style.pointer]: needClick,
      })}
    >
      <Chart options={options} data={lineData} width={width} height={height} />
    </ChartWrap>
  );
}

export default customWithErrorBoundary(memo(TrendVolumn));
