import { forwardRef, memo, useEffect, useState } from "react";
import customWithErrorBoundary from "src/components/errorBoundary";
import Month from "../month";
import { isBefore } from "date-fns";
import style from "./MonthPicker.module.scss";

const MonthRangePicker = forwardRef(
  ({ value, onChange, startTime, endTime, inputValue }, ref) => {
    const [range, setRange] = useState();

    const handleChange = (value) => {
      const date = value?.date;
      let currentRange = {};
      if (!range?.from || (range?.from && range?.to)) {
        currentRange = {
          from: date,
          to: "",
        };
      } else if (range?.from && !range?.to) {
        const isCorrect = isBefore(range?.from, date);
        if (isCorrect) {
          currentRange = {
            ...range,
            to: date,
          };
        } else {
          currentRange = {
            from: date,
            to: range.from,
          };
        }
      }
      setRange(currentRange);

      onChange && onChange(currentRange);
    };

    useEffect(() => {
      setRange(value);
    }, [value]);

    return (
      <div className={style.rangeWrap}>
        <Month
          range={range}
          inputValue={inputValue}
          onChange={handleChange}
          startTime={startTime}
          endTIme={endTime}
          ref={ref}
        />
      </div>
    );
  }
);

export default customWithErrorBoundary(memo(MonthRangePicker), {
  forwardRefBol: true,
});
