import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ImgWrap from "src/components/imgWrap";
import useLanguage from "src/hooksOrClass/language";
import style from "./index.module.scss";
import classNames from "classnames";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { BaseMenuProps } from "src/styles/theme";
import {
  updateDetailEncryptAdIdInfo,
  updateDetailSelectAdValue,
} from "../../store";
import { HoverAdOverviewContext } from "../../context";

function CustomMenuItem({ item, onSelect }) {
  const { autoFormatLocale } = useLanguage();
  const { t } = useTranslation();

  const ads = item.ads;

  return (
    <div key={item.date} className={style.container}>
      <div className={(style.dateTitle, "listSummaryFont")}>
        <div>
          {autoFormatLocale(item.date)}(
          {t(`adStrategy.created_tip`, { count: ads?.length })})
        </div>
      </div>
      <div className={classNames("textFont", style.contentContainer)}>
        {ads?.map((adInfo) => {
          return (
            <MenuItem
              value={`${adInfo.displayAdId}-${adInfo.asin}`}
              key={adInfo.encryptAdId}
              onClick={() => onSelect && onSelect(adInfo, item)}
            >
              {`${adInfo.displayAdId}-${adInfo.asin}`}
              <div className={style.imgWrap}>
                <ImgWrap src={adInfo.img} />
              </div>
            </MenuItem>
          );
        })}
      </div>
    </div>
  );
}
const CustomMemoMenuItem = memo(CustomMenuItem);

function SelectAd() {
  const dispatch = useDispatch();
  const { data } = useContext(HoverAdOverviewContext) || {};
  const detailSelectAdsData = useSelector(
    (state) => state.adStrategy.detailSelectAdsData
  );
  const value = useSelector((state) => state.adStrategy.detailSelectAdValue);

  const [open, setOpen] = useState(false);

  const handleSelct = useCallback((adInfo, item) => {
    dispatch(
      updateDetailSelectAdValue({
        value: `${adInfo.displayAdId}-${adInfo.asin}`,
        info: adInfo,
      })
    );
    dispatch(
      updateDetailEncryptAdIdInfo({
        ...(adInfo || {}),
        ...(data || {}),
        keyword: "",
      })
    );
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const memoMenuPrps = useMemo(() => {
    return {
      ...BaseMenuProps,
      keepMounted: true,
      classes: {
        paper: style.menuPaper,
      },
    };
  }, []);

  return (
    <div>
      <Select
        open={open}
        value={value.value}
        onOpen={handleOpen}
        className={classNames(style.adGroupSelect)}
        onClose={handleClose}
        MenuProps={memoMenuPrps}
        renderValue={(value1) => {
          const info = value.info;
          return (
            <div className={classNames(style.valueWrap, "textFont")}>
              <span>{info.displayAdId}</span>-<span>{info.asin}</span>
              <ImgWrap src={info.img} imgClass={style.imgClass} />
            </div>
          );
        }}
      >
        <>
          {detailSelectAdsData?.map((item) => {
            return (
              <CustomMemoMenuItem
                item={item}
                onSelect={handleSelct}
                key={item.date}
              />
            );
          })}
        </>
      </Select>
    </div>
  );
}

export default SelectAd;
