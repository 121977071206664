import { configureStore } from "@reduxjs/toolkit";
import SalesReducer from "src/pages/sales/store";
import rootReducer from "./rootReducer";
import KeywordsReducer from "src/pages/keywords/store";
import StructureReducer from "src/pages/structure/store";
import AdStrategyReducer from "src/pages/adStrategy/store";
import HistoryReducer from "src/pages/history/store";
import KeywordsGapReducer from "src/pages/keywordsGap/store";

export const store = configureStore({
  reducer: {
    sales: SalesReducer,
    structure: StructureReducer,
    keywords: KeywordsReducer,
    keywordsGap: KeywordsGapReducer,
    adStrategy: AdStrategyReducer,
    root: rootReducer,
    history: HistoryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
